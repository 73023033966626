import { Collapse, Row, Col, Tag, message, Popconfirm } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    dineroDisabledMask,
    ModalItem,
    ButtonItem,
    round2Decimals,
} from "../../../../Components/Items";
import moment from "moment";

const { Panel } = Collapse;

class RelacionarPago extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        pagosHtml: <p>No se encontro ningun pago</p>,
    };
    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible)
            this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "pagos/pagosRelacionarXml",
                { xmlId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    if (res?.data?.pagos?.length > 0) {
                        const html = (
                            <Collapse style={{ marginBottom: 10 }}>
                                {res.data.pagos.map((valuePago) => {

                                    let relacionarPago = null

                                    if (valuePago.estado !== 0) {
                                        if (round2Decimals(valuePago.monto) !== round2Decimals(res.data.montoXml)) {
                                            relacionarPago = (
                                                <Popconfirm
                                                title={
                                                    "Seguro que quieres relacionarlo aunque el monto de pago sea diferente, Pago: "+dineroDisabledMask(valuePago.monto)+" , Complemento: "+dineroDisabledMask(res.data.montoXml)
                                                  }
                                                  onConfirm={() => this.relacionarPago(valuePago._id)}
                                                  okText="Si"
                                                  cancelText="No"
                                                >
                                                    <ButtonItem type="primary" >
                                                        Relacionar Pago
                                                    </ButtonItem>
                                                </Popconfirm>
                                            )
                                        } else {
                                            relacionarPago = (
                                                <ButtonItem type="primary" onClick={() => this.relacionarPago(valuePago._id)}>
                                                    Relacionar Pago
                                                </ButtonItem>
                                            )
                                        }
                                    }

                                    return (
                                        <Panel
                                            extra={
                                                valuePago.estado !== 0 ? (
                                                    valuePago.moneda.abreviacion + ' - ' + dineroDisabledMask(valuePago.monto)
                                                ) : (
                                                    <Tag color="red">
                                                        Cancelada: {valuePago.moneda.abreviacion + ' - ' + dineroDisabledMask(valuePago.monto)}
                                                    </Tag>
                                                )
                                            }
                                            header={moment(valuePago.fechaPago).locale("es").format("ll")}
                                            key={valuePago._id}
                                        >
                                            <Row>
                                                <Col span={12}>
                                                    <p>
                                                        Dia del Pago:{" "}
                                                        {moment(valuePago.fechaPago).locale("es").format("ll")}
                                                    </p>
                                                    <p>
                                                        Complemento de Pago:{" "}
                                                        {valuePago.complemento ? "Si" : "No"}
                                                    </p>
                                                </Col>
                                                <Col span={12}>
                                                    <p>
                                                        Dia de registro:{" "}
                                                        {moment(valuePago.createdAt).locale("es").format("ll")}
                                                    </p>
                                                    <p>Monto: {valuePago.moneda.abreviacion + ' - ' + dineroDisabledMask(valuePago.monto)}</p>
                                                    {relacionarPago}
                                                </Col>
                                            </Row>
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                        );
                        this.setState({ pagosHtml: html });
                    }
                } else if (res.data.success === 1) {
                    message.error(res.data.message);
                } else {
                    message.error("Hubo un error y no se efectuo el pago");
                }
            });
        this.setState({ loading: false });
    }
    relacionarPago(pagoId) {
        axios
            .post(
                "descargaMasiva/relacionarPago",
                { xmlId: this.props.id, pagoId: pagoId, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success('Se relaciono el complemento al pago')
                    this.cerrarRelacionarPago()
                } else {
                    message.error("Hubo un error y no se pudo descargar");
                }
            });
    }
    cerrarRelacionarPago() {
        this.setState({pagosHtml: <p>No se encontro ningun pago</p>})
        this.props.cerrarRelacionarPago();
    }
    cerrarCancelarPagoCajaChica() {
        this.setState({
            modalCancelarPagoCajaChica: false,
            modalCancelarPagoCajaChicaId: null,
        });
        this.consultarTodo()
    }
    render() {
        return (
            <>
                <ModalItem
                    title="Pagos A Relacionar"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarRelacionarPago.bind(this)}
                    paddingTop
                >
                    {this.state.pagosHtml}
                </ModalItem>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(RelacionarPago);