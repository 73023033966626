import { PageHeader, Space, Button, Tag } from "antd";
import React, { Component } from "react";
import axios from "axios";
import { ButtonItem, TableItem } from "../../../Components/Items";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

class Presupuestos extends Component {
  state = {
    todasPresupuestos: [],
    loading: false,
  };
  componentDidMount() {
    this.consultarPresupuestos();
  }

  consultarPresupuestos() {
    this.setState({ loading: true });
    axios
      .post(
        "presupuestos/selectTodasPresupuestos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasPresupuestos: res.data
          });
        }
      });
  }
  verPresupuesto(id) {
    this.props.history.push("/panel/verPresupuesto", { id });
  }
  agregarPresupuesto() {
    this.props.history.push("/panel/agregarPresupuesto");
  }
  editarPresupuesto(id) {
    this.props.history.push("/panel/editarPresupuesto", { id });
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
        ellipsis: true,
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
        ellipsis: true,
      },
      {
        title: "Proyecto",
        dataIndex: "proyecto",
        key: "proyecto",
        sorter: (a, b) => {
          if (a.proyecto < b.proyecto) {
            return -1;
          }
          if (a.proyecto > b.proyecto) {
            return 1;
          }
          return 0;
        },
        buscar: "proyecto",
        ellipsis: true,
      },
      {
        title: "Fecha Inicial",
        dataIndex: "fechaInicial",
        key: "fechaInicial",
        sorter: (a, b) => {
          if (moment(a.fechaInicial) < moment(b.fechaInicial)) {
            return -1;
          }
          if (moment(a.fechaInicial) > moment(b.fechaInicial)) {
            return 1;
          }
          return 0;
        },
        buscar: "fechaInicial",
        render: (value) => moment(value).locale('es').format('L'),
        ellipsis: true,
      },
      {
        title: "Fecha Final",
        dataIndex: "fechaFinal",
        key: "fechaFinal",
        sorter: (a, b) => {
          if (moment(a.fechaFinal) < moment(b.fechaFinal)) {
            return -1;
          }
          if (moment(a.fechaFinal) > moment(b.fechaFinal)) {
            return 1;
          }
          return 0;
        },
        buscar: "fechaFinal",
        render: (value) => value ? moment(value).locale('es').format('L') : 'No tiene',
        ellipsis: true,
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        render: (value) => {
          if (value === 0) {
            return <Tag color="red">Cancelada</Tag>;
          }
          if (value === 1) {
            return <Tag color="blue">Vigente</Tag>;
          }
        },
        width: "10%",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {value.estado !== 0 ? (
              <>
                <ButtonItem
                  type="primary"
                  size="small"
                  onClick={() => this.editarPresupuesto(value._id)}
                >
                  Editar
                </ButtonItem>
              </>
            ) : null}
          </Space>
        ),
        width: "15%",
      },
    ];
    return (
      <>
        <PageHeader
          title="Presupuestos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <Button
              type="primary"
              key="agregarPresupuesto"
              onClick={this.agregarPresupuesto.bind(this)}
            >
              Agregar Presupuesto
            </Button>
          }
        />
        <TableItem
          size="small"
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                this.verPresupuesto(record._id)
              },
            };
          }}
          loading={this.state.loading}
          dataSource={this.state.todasPresupuestos}
          columns={columns}
          pagination={false}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Presupuestos);
