import axios from "axios";

export const SELECCIONAR_EMPRESA = 'SELECCIONAR_EMPRESA'

export const DESELECCIONAR_EMPRESA = 'DESELECCIONAR_EMPRESA'

export const seleccionarEmpresa = (data) => {
    return async (dispatch) => {
        return axios
          .post("empresas/seleccionarEmpresa", data)
          .then((res) => {
            if (res.data.success === 1) {
              dispatch({ type: SELECCIONAR_EMPRESA, data })   
              return {success: 1};
            } else {
              return {success: 0, err: "No se pudo seleccionar la empresa"};
            }
          })
          .catch((err) => {
            throw new Error(err.message);
          });
      };
}

export const seleccionarEmpresaHasSigned = (data) => {
    return async (dispacth) => {
        return dispacth({ type: SELECCIONAR_EMPRESA, data})
    }
}

export const deseleccionarEmpresa = () => {
    return async (dispacth) => {
        return dispacth({ type: DESELECCIONAR_EMPRESA})
    }
}