import { Form, Divider } from "antd";
import React, { Component } from "react";
import validateMessages from "./validateMessages";

class Form2 extends Component {
  formRef = React.createRef();
  getFieldValue(value){
    return this.formRef.current.getFieldValue(value)
  }
  setFieldsValue(value){
    return this.formRef.current.setFieldsValue(value)
  }
  resetFields(value){
    return this.formRef.current.resetFields(value)
  }
  validateFields(value){
    return this.formRef.current.validateFields(value)
  }
  getFieldsError(value){
    return this.formRef.current.getFieldsError(value)
  }
  getFieldError(value){
    return this.formRef.current.getFieldError(value)
  }
  getFieldInstance(value){
    return this.formRef.current.getFieldInstance(value)
  }
  render() {
    let layout;
    if (this.props.layout) {
      layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    } else if(this.props.layoutEspecial){
      layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      };
    } else {
      layout = null;
    }
    let transformedProperties = {};
    for (var key in this.props) {
      if (
        !(
          key.toString() === "defaultBehavior" ||
          key.toString() === "layout" ||
          key.toString() === "layoutEspecial" ||
          key.toString() === "layoutForm" ||
          key.toString() === "bottom"
        )
      ) {
        transformedProperties = {
          ...transformedProperties,
          [key]: this.props[key],
        };
      }
    }
    return (
      <Form layout={this.props.layoutForm} ref={this.formRef} validateMessages={validateMessages} {...transformedProperties} {...layout} onKeyDown={this.props.defaultBehavior ? null :(e) => {
        if (e.key === 'Enter' && e.target.type !== 'textarea'){
          e.preventDefault()
        } 
        }}>
        {this.props.children}
        {this.props.bottom ? (
          <>
            <Divider style={{ margin: 0 }} />
            <div
              style={{ width: "100%", display: "block", padding: "10px 16px" }}
            >
              {this.props.bottom}
            </div>
          </>
        ) : null}
      </Form>
    );
  }
}

export default Form2;
