// import phoneMask from "../Components/phoneMask";
import moment from "moment";
import "moment/locale/es";
import { dineroDisabledMask } from "../Components/Items";
import numeroALetra from "../Components/numeroALetra";

moment.locale("es");

const facturaXmlPdf = (values) => {
  const dataXml = values.dataXml;
  const comprobante = dataXml.attributes;
  const emisor = dataXml.children.find(
    (valueXml) => valueXml.name === "cfdi:Emisor"
  )?.attributes;
  const receptor = dataXml.children.find(
    (valueXml) => valueXml.name === "cfdi:Receptor"
  )?.attributes;
  const conceptosXml = dataXml.children.find(
    (valueXml) => valueXml.name === "cfdi:Conceptos"
  )?.children;
  const timbre = dataXml.children
    .find((valueXml) => valueXml.name === "cfdi:Complemento")
    .children.find(
      (valueXml) => valueXml.name === "tfd:TimbreFiscalDigital"
    )?.attributes;

  const conceptos = conceptosXml.map((valueConcepto) => {
    let iva = 0;
    let ieps = 0;
    const attrInsumo = valueConcepto.attributes;
    valueConcepto.children
      .find((valueTodos) => valueTodos.name === "cfdi:Impuestos")
      ?.children.find((valueTodos) => valueTodos.name === "cfdi:Traslados")
      ?.children.map((valueImpuesto) => {
        const attrImpuesto = valueImpuesto.attributes;
        if (attrImpuesto.Impuesto === "002") {
          iva += attrImpuesto.Importe;
        }
        if (attrImpuesto.Impuesto === "003") {
          ieps += attrImpuesto.Importe;
        }
        return null;
      });
    return [
      {
        style: "textoTablaChico",
        text: attrInsumo.Cantidad,
        alignment: "right",
      },
      {
        text: attrInsumo.ClaveUnidad,
        style: "textoTablaChico",
        alignment: "center",
      },
      {
        text: attrInsumo.ClaveProdServ,
        style: "textoTablaChico",
        alignment: "center",
      },
      {
        text: attrInsumo.Descripcion,
        style: "textoTablaChico",
        alignment: "left",
      },
      {
        text: dineroDisabledMask(iva),
        style: "textoTablaChico",
        alignment: "right",
      },
      {
        text: dineroDisabledMask(ieps),
        style: "textoTablaChico",
        alignment: "right",
      },
      {
        text: dineroDisabledMask(attrInsumo.ValorUnitario),
        style: "textoTablaChico",
        alignment: "right",
      },
      {
        text: dineroDisabledMask(attrInsumo.Importe),
        style: "textoTablaChico",
        alignment: "right",
      },
    ];
  });

  const impuestosTotalesFederales = dataXml.children
    .find((valueTodos) => valueTodos.name === "cfdi:Impuestos")
    ?.children.find((valueTodos) => valueTodos.name === "cfdi:Traslados")
    ?.children.map((valueImpuesto) => {
      const attrImpuesto = valueImpuesto.attributes;
      let nombre = "";
      if (attrImpuesto.Impuesto === "002") {
        nombre = "IVA";
      } else if (attrImpuesto.Impuesto === "003") {
        nombre = "IEPS";
      }

      if (attrImpuesto.TipoFactor === "Tasa") {
        nombre = nombre + " " + Number(attrImpuesto.TasaOCuota * 100) + "%";
      } else if (attrImpuesto.TipoFactor === "Cuota") {
        nombre = nombre + " $" + Number(attrImpuesto.TasaOCuota);
      }

      return [
        {
          border: [true, false, false, false],
          text: nombre,
          style: "subheader",
        },
        {
          border: [false, false, true, false],
          text: dineroDisabledMask(attrImpuesto.Importe),
          style: "textoChico",
          alignment: "right",
        },
      ];
    }) || [];

  const impuestosTotalesLocales = dataXml.children
    .find((valueTodos) => valueTodos.name === "cfdi:Complemento")
    ?.children.find(
      (valueTodos) => valueTodos.name === "implocal:ImpuestosLocales"
    )
    ?.children.map((valueImpuesto) => {
      const attrImpuesto = valueImpuesto.attributes;
      let nombre = attrImpuesto.ImpLocTrasladado;

      return [
        {
          border: [true, false, false, false],
          text: nombre,
          style: "subheader",
        },
        {
          border: [false, false, true, false],
          text: dineroDisabledMask(attrImpuesto.Importe),
          style: "textoChico",
          alignment: "right",
        },
      ];
    }) || [];

  let impuestosTotales = [
    ...impuestosTotalesFederales,
    ...impuestosTotalesLocales,
  ];

  impuestosTotales = impuestosTotales.map((valueImpuesto, index) => {
    let newValueImpuesto = valueImpuesto;

    if (index + 1 === impuestosTotales.length) {
      newValueImpuesto[0].border[3] = true;
      newValueImpuesto[1].border[3] = true;
    }

    return newValueImpuesto;
  });

  const retencionesTotalesFederales = dataXml.children
    .find((valueTodos) => valueTodos.name === "cfdi:Impuestos")
    ?.children.find((valueTodos) => valueTodos.name === "cfdi:Retenciones")
    ?.children.map((valueRetencion) => {
      const attrRetencion = valueRetencion.attributes;
      let nombre = "Retención ";
      if (attrRetencion.Impuesto === "001") {
        nombre = nombre + "ISR";
      } else if (attrRetencion.Impuesto === "002") {
        nombre = nombre + "IVA";
      }

      if (attrRetencion.TipoFactor === "Tasa") {
        nombre = nombre + " " + Number(attrRetencion.TasaOCuota * 100) + "%";
      } else if (attrRetencion.TipoFactor === "Cuota") {
        nombre = nombre + " " + Number(attrRetencion.TasaOCuota);
      }

      return [
        {
          border: [true, false, false, false],
          text: nombre,
          style: "subheader",
        },
        {
          border: [false, false, true, false],
          text: dineroDisabledMask(attrRetencion.Importe),
          style: "textoChico",
          alignment: "right",
        },
      ];
    }) || [];

  const retencionesTotalesLocales = dataXml.children
    .find((valueTodos) => valueTodos.name === "cfdi:Complemento")
    ?.children.find(
      (valueTodos) => valueTodos.name === "implocal:RetencionesLocales"
    )
    ?.children.map((valueRetencion) => {
      const attrRetencion = valueRetencion.attributes;
      let nombre = attrRetencion.ImpLocRetenido;

      return [
        {
          border: [true, false, false, false],
          text: nombre,
          style: "subheader",
        },
        {
          border: [false, false, true, false],
          text: dineroDisabledMask(attrRetencion.Importe),
          style: "textoChico",
          alignment: "right",
        },
      ];
    }) || [];

  let retencionesTotales = [
    ...retencionesTotalesFederales,
    ...retencionesTotalesLocales,
  ];

  retencionesTotales = retencionesTotales.map((valueImpuesto, index) => {
    let newValueImpuesto = valueImpuesto;

    if (index + 1 === retencionesTotales.length) {
      newValueImpuesto[0].border[3] = true;
      newValueImpuesto[1].border[3] = true;
    }

    return newValueImpuesto;
  });

  const totalImpFL =
    Number(
      dataXml.children.find(
        (valueTodos) => valueTodos.name === "cfdi:Impuestos"
      )?.attributes?.TotalImpuestosTrasladados || 0
    ) +
    Number(
      dataXml.children
        .find((valueTodos) => valueTodos.name === "cfdi:Complemento")
        ?.children.find(
          (valueTodos) => valueTodos.name === "implocal:ImpuestosLocales"
        )?.attributes?.TotaldeTraslados || 0
    );
  const totalRetFL =
    Number(
      dataXml.children.find(
        (valueTodos) => valueTodos.name === "cfdi:Impuestos"
      )?.attributes?.TotalImpuestosRetenidos || 0
    ) +
    Number(
      dataXml.children
        .find((valueTodos) => valueTodos.name === "cfdi:Complemento")
        ?.children.find(
          (valueTodos) => valueTodos.name === "implocal:ImpuestosLocales"
        )?.attributes?.TotaldeRetenciones || 0
    );

  let cancelado = {};
  if (values?.estado === 0) {
    cancelado = {
      watermark: {
        text: "CANCELADO",
        color: "GRAY",
        opacity: 0.3,
        bold: true,
        italics: false,
        angle: -50,
        fontSize: 110,
      },
    };
  }

  let tipoComprobante = ''

  if (comprobante.TipoDeComprobante === "I") {
    tipoComprobante = "I - Ingreso";
  } else if (comprobante.TipoDeComprobante === "P") {
    tipoComprobante = "P - Pago";
  } else if (comprobante.TipoDeComprobante === "E") {
    tipoComprobante = "E - Egreso";
  } else if (comprobante.TipoDeComprobante === "N") {
    tipoComprobante = "N - Nomina";
  } else if (comprobante.TipoDeComprobante === "T") {
    tipoComprobante = "T - Traslado";
  }

  const arraySiHayRetenciones =
    totalRetFL > 0
      ? [
          [
            {
              border: [true, false, false, false],
              text: "Retenciones:",
              style: "subheader",
            },
            {
              border: [false, false, true, false],
              text: dineroDisabledMask(totalRetFL),
              style: "textoChico",
              alignment: "right",
            },
          ],
          [
            {
              border: [true, false, false, true],
              text: "TOTAL PAGO:",
              style: "header",
              fillColor: "#e8e8e8",
            },
            {
              border: [false, false, true, true],
              text: dineroDisabledMask(comprobante.Total),
              style: "header",
              fillColor: "#e8e8e8",
              alignment: "right",
            },
          ],
        ]
      : [];

  const total =
    comprobante.SubTotal - Number(comprobante.Descuento || 0) + totalImpFL;

  var dd = {
    ...cancelado,
    content: [
      {
        style: "tableExample",
        table: {
          widths: ["70%", "30%"],
          layout: {
            defaultBorder: false,
          },
          body: [
            [
              {
                border: [false, false, false, false],
                table: {
                  headerRows: 1,
                  widths: ["100%"],

                  body: [
                    [
                      {
                        text: emisor.Nombre,
                        style: "tableHeader",
                        alignment: "left",
                      },
                    ],
                    [
                      {
                        text: "R.F.C: " + emisor.Rfc,
                        style: "textoChico",
                        alignment: "left",
                      },
                    ],
                    [
                      {
                        text: "Codigo Postal: " + comprobante.LugarExpedicion,
                        style: "textoChico",
                        alignment: "left",
                      },
                    ],
                    // empresa.telefono ? [
                    //     {
                    //         text: 'Tel: '+phoneMask(empresa.telefono),
                    //         style: "textoChico",
                    //         alignment: "left",
                    //       },
                    // ] : [],
                    [
                      {
                        text: "Regimen Fiscal: (" + emisor.RegimenFiscal + ") " + values.regimenFiscalEmisorString,
                        style: "textoChico",
                        alignment: "left",
                      },
                    ],
                    [
                      {
                        text: "Tipo De Comprobante: "+tipoComprobante,
                        style: "textoChico",
                        alignment: "left",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
              {
                border: [false, false, false, false],
                table: {
                  headerRows: 1,
                  widths: ["100%"],

                  body: [
                    [
                      {
                        text: "N° Factura:",
                        style: "subheader",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: (comprobante.Serie ? comprobante.Serie : '') + " - " + (comprobante.Folio ? comprobante.Folio : ''),
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: "Moneda:",
                        style: "subheader",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: comprobante.Moneda,
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: "Tipo de Cambio:",
                        style: "subheader",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: dineroDisabledMask(comprobante.TipoCambio ? comprobante.TipoCambio : 1),
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          ],
        },
      },
      {
        style: "tableExample",
        table: {
          widths: ["60%", "40%"],
          layout: {
            defaultBorder: false,
          },

          body: [
            [
              {
                table: {
                  widths: ["30%", "70%"],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                    [
                      { text: "Nombre del cliente :", style: "subheader" },
                      { text: receptor.Nombre, style: "textoChico" },
                    ],
                    [
                      { text: "R.F.C :", style: "subheader" },
                      { text: receptor.Rfc, style: "textoChico" },
                    ],
                    [
                      { text: "Regimen Fiscal :", style: "subheader" },
                      { text: "(" + receptor.RegimenFiscalReceptor + ") " + values.regimenFiscalReceptorString, style: "textoChico" },
                    ],
                    // [
                    //   { text: "Direccion :", style: "subheader" },
                    //   { text: "Variable", style: "textoChico" },
                    // ],
                    [
                      { text: "Uso de CFDI:", style: "subheader" },
                      {
                        text: "(" + receptor.UsoCFDI + ") " + values.usoDeCFDIString,
                        style: "textoChico",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },

              {
                table: {
                  widths: ["40%", "60%"],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                    [
                      { text: "Fecha:", style: "subheader" },
                      {
                        text: moment(comprobante.Fecha)
                          .locale("es")
                          .format("LL"),
                        style: "textoChico",
                      },
                    ],
                    [
                      { text: "Lugar de expedicion:", style: "subheader" },
                      {
                        text: comprobante.LugarExpedicion,
                        style: "textoChico",
                      },
                    ],
                    [
                      { text: "Forma de pago :", style: "subheader" },
                      {
                        text: "(" + comprobante.FormaPago + ") " + values.formaDePagoString,
                        style: "textoChico",
                      },
                    ],
                    [
                      { text: "Metodo de pago :", style: "subheader" },
                      {
                        text:
                          comprobante.MetodoPago === "PUE"
                            ? "PUE - Pago en una sola exhibición"
                            : "PPD - Pago en parcialidades o diferido",
                        style: "textoChico",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return "#adaaaa";
          },
          vLineColor: function (i, node) {
            return "#adaaaa";
          },
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 2, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 2 } };
          },
        },
      },

      {
        style: "tableExample",
        table: {
          widths: ["8%", "6%", "9%", "38%", "8%", "8%", "10.5%", "12.5%"],
          layout: {
            defaultBorder: true,
          },
          body: [
            [
              { text: "Cantidad", style: "tituloTabla" },
              { text: "Unidad", style: "tituloTabla" },
              { text: "Clave SAT", style: "tituloTabla" },
              { text: "Descripción", style: "tituloTabla" },
              { text: "IVA", style: "tituloTabla" },
              { text: "IEPS", style: "tituloTabla" },
              { text: "P/U", style: "tituloTabla" },
              { text: "Importe", style: "tituloTabla" },
            ],
            ...conceptos,
          ],
        },
        layout: {
          fillColor: (rowIndex, node, columnIndex) => {
            return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
          },
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return "#adaaaa";
          },
          vLineColor: function (i, node) {
            return "#adaaaa";
          },
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 2, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 2 } };
          },
        },
      },
      {
        style: "tablaSinMargen",
        table: {
          widths: ["70%", "30%"],
          layout: {
            defaultBorder: true,
          },
          body: [
            [
              {
                table: {
                  widths: ["30%", "70%"],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                    [
                      {
                        border: [true, true, false, false],
                        text: "Cantidad con letra:",
                        style: "subheader",
                      },
                      {
                        border: [false, true, true, false],
                        text:
                          "**( " +
                          numeroALetra(comprobante.Total, comprobante.Moneda) +
                          " )**",
                        style: "textoChico",
                        fontSize: 7,
                      },
                    ],
                    [
                      {
                        border: [true, false, false, true],
                        text: "Uso de CFDI:",
                        style: "subheader",
                      },
                      {
                        border: [false, false, true, true],
                        text: "(" + receptor.UsoCFDI + ") ",
                        style: "textoChico",
                      },
                    ],
                  ],
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return i === 0 || i === node.table.body.length ? 1 : 1;
                  },
                  vLineWidth: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? 1 : 1;
                  },
                  hLineColor: function (i, node) {
                    return "#adaaaa";
                  },
                  vLineColor: function (i, node) {
                    return "#adaaaa";
                  },
                  hLineStyle: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                      return null;
                    }
                    return { dash: { length: 2, space: 1 } };
                  },
                  vLineStyle: function (i, node) {
                    if (i === 0 || i === node.table.widths.length) {
                      return null;
                    }
                    return { dash: { length: 2 } };
                  },
                },
              },
              {
                table: {
                  widths: ["50%", "50%"],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                    [
                      {
                        border: [true, true, false, false],
                        text: "Subtotal:",
                        style: "subheader",
                      },
                      {
                        border: [false, true, true, false],
                        text: dineroDisabledMask(comprobante.SubTotal),
                        style: "textoChico",
                        alignment: "right",
                      },
                    ],
                    [
                      {
                        border: [true, false, false, false],
                        text: "Descuento:",
                        style: "subheader",
                      },
                      {
                        border: [false, false, true, false],
                        text: dineroDisabledMask(comprobante.Descuento || 0),
                        style: "textoChico",
                        alignment: "right",
                      },
                    ],
                    [
                      {
                        border: [true, false, false, false],
                        text: "Impuestos:",
                        style: "subheader",
                      },
                      {
                        border: [false, false, true, false],
                        text: dineroDisabledMask(totalImpFL),
                        style: "textoChico",
                        alignment: "right",
                      },
                    ],
                    [
                      {
                        border: [true, false, false, true],
                        text: "TOTAL:",
                        style: "header",
                        fillColor: "#e8e8e8",
                      },
                      {
                        border: [false, false, true, true],
                        text: dineroDisabledMask(total),
                        style: "header",
                        fillColor: "#e8e8e8",
                        alignment: "right",
                      },
                    ],
                    ...arraySiHayRetenciones,
                    ...impuestosTotales,
                    ...retencionesTotales,
                  ],
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return i === 0 || i === node.table.body.length ? 1 : 1;
                  },
                  vLineWidth: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? 1 : 1;
                  },
                  hLineColor: function (i, node) {
                    return "#adaaaa";
                  },
                  vLineColor: function (i, node) {
                    return "#adaaaa";
                  },
                  hLineStyle: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                      return null;
                    }
                    return { dash: { length: 2, space: 1 } };
                  },
                  vLineStyle: function (i, node) {
                    if (i === 0 || i === node.table.widths.length) {
                      return null;
                    }
                    return { dash: { length: 2 } };
                  },
                },
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      {
        table: {
          widths: ["15%", "30%", "23%", "32%"],
          layout: {
            defaultBorder: false,
          },
          body: [
            [
              {
                colSpan: 4,
                text: '"Este documento es una representacion impresa de un CFDI"',
                style: "header",
                alignment: "center",
              },
              {},
              {},
              {},
            ],
            [
              { text: "Folio Fiscal: ", style: "subheader" },
              { text: timbre.UUID, style: "textoChicoData" },
              { text: "Fecha y Hora de Certificación: ", style: "subheader" },
              {
                text: comprobante.Fecha,
                style: "textoChicoData",
              },
            ],
            [
              { colSpan: 4, text: "Sello digital CFDI: ", style: "subheader" },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 4,
                text: comprobante.Sello,
                style: "textoChicoData",
              },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 2,
                text: "Numero de serie del Certificado de Sello digital: ",
                style: "subheader",
              },
              {},
              {
                colSpan: 2,
                text: "Numero de serie del Certificado de Sello digital del SAT: ",
                style: "subheader",
              },
              {},
            ],
            [
              {
                colSpan: 2,
                text: comprobante.NoCertificado,
                style: "textoChicoData",
              },
              {},
              {
                colSpan: 2,
                text: timbre.NoCertificadoSAT,
                style: "textoChicoData",
              },
              {},
            ],
            [
              {
                colSpan: 4,
                text: "Sello Digital del SAT: ",
                style: "subheader",
              },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 4,
                text: timbre.SelloSAT,
                style: "textoChicoData",
              },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 4,
                text: "Sello Digital del CFDI: ",
                style: "subheader",
              },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 4,
                text: timbre.SelloCFD,
                style: "textoChicoData",
              },
              {},
              {},
              {},
            ],
          ],
        },
        layout: "noBorders",
      },
    ],
    styles: {
      header: {
        fontSize: 9,
        bold: true,
      },
      subheader: {
        fontSize: 8,
        bold: true,
      },
      tableExample: {
        margin: [0, 5, 0, 5],
      },
      tablaSinMargen: {
        margin: [0, 0, 0, 0],
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: "black",
        alignment: "center",
      },
      textoChico: {
        fontSize: 8,
      },
      textoChicoData: {
        fontSize: 6,
      },
      tituloTabla: {
        fontSize: 8,
        bold: true,
        fillColor: "#cccccc",
        style: "subheader",
        alignment: "center",
        border: true,
      },
      textoTabla: {
        fontSize: 8,
        italics: false,
      },
      textoTablaChico: {
        fontSize: 6,
        italics: false,
      },
    },
    pageSize: "LETTER",
  };
  return dd;
};

export default facturaXmlPdf;
