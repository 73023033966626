import { message, Space, notification } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ModalItem,
    ButtonItem,
    Form,
    FormItem,
    DatePickerItem,
    NotificacionPolizas
} from "../../../../Components/Items";
import moment from "moment";

moment.locale("es");


class EntregarEnvio extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        pdf: null,
    };
    onFinishFailed(e) {
        message.error("Porfavor ingrese bien los datos");
    }
    onFinish(values) {
        this.setState({ loading: true });
        axios
            .post(
                "envios/entregarEnvio",
                { ...values, envioId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    message.success("Envio entregado");
                    NotificacionPolizas(res.data.polizasId);
                } else if (res.data.success === 1) {
                    notification.error({
                        message: "Error",
                        description: res.data.message,
                        duration: null,
                    });
                } else {
                    message.error("Sucedio un error y no se pudo entregar");
                }
                this.formRef.current.resetFields();
                this.setState({ loading: false });
                this.cerrarEntregarEnvio();
            });
    }
    cerrarEntregarEnvio() {
        this.props.cerrarEntregarEnvio();
    }
    render() {
        return (
            <ModalItem
                title="Entregar Envio"
                visible={this.props.visible}
                spinning={this.state.loading}
                onCancel={this.cerrarEntregarEnvio.bind(this)}
                paddingTop
            >
                <Form
                    ref={this.formRef}
                    name="entregarEnvio"
                    layout
                    width={700}
                    onCancel={this.cerrarEntregarEnvio.bind(this)}
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed}
                >
                    <FormItem
                        label="Fecha de Entrega"
                        name="fechaEntrega"
                        required
                        margin
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValue={moment().hour(12).minute(0)}
                    >
                        <DatePickerItem
                            placeholder="Fecha de Entrega"
                            allowClear={false}
                            showTime
                            dateFormat='DD/MM/YYYY HH:mm'
                        />
                    </FormItem>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "right",
                            marginTop: 20,
                            marginBottom: 10,
                        }}
                    >
                        <Space>
                            <ButtonItem
                                type="primary"
                                htmlType="submit"
                                style={{ display: "block", margin: "0 0 0 auto" }}
                            >
                                Entregar Envio
                            </ButtonItem>
                            <ButtonItem
                                type="primary"
                                blanco
                                onClick={this.cerrarEntregarEnvio.bind(this)}
                                style={{ display: "block", margin: "0 0 0 auto" }}
                            >
                                Regresar
                            </ButtonItem>
                        </Space>
                    </div>
                </Form>
            </ModalItem>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(EntregarEnvio);
