import {
  Col,
  message,
  PageHeader,
  Row,
  Spin,
  Select,
  Button,
  Space,
  Tabs,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { connect } from "react-redux";
import {
  SelectItem,
  Form,
  FormItem,
  cuentaMask,
  TableItem,
  dineroDisabledMask,
  round2Decimals,
  RangePickerItem,
} from "../../../../Components/Items";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import moment from "moment";
import "moment/locale/es";
import reporteDeBalanzaDeComprobacionPdf from "../../../../Pdf/reporteDeBalanzaDeComprobacionPdf";

moment.locale("es");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select;
const { TabPane } = Tabs;

class ReporteBalanzaDeComprobacion extends Component {
  state = {
    loading: false,
    pdf: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoUniadesDeNegocio: null,
    todasUniadesDeNegocio: null,
    dataReporte: null,
    todasCuentas: [],
  };

  atras() {
    this.props.history.goBack();
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "unidadesdenegocio/selectUnidadesDeNegocio",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasUniadesDeNegocio: res.data });
          this.setState({
            objetoUniadesDeNegocio: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    this.consultarReporteBalanzaDeComprobacion({
      periodo: [moment().subtract(1, "month"), moment()],
      nivel: 5,
    });
  }

  consultarReporteBalanzaDeComprobacion(values) {
    this.setState({ loading: true });
    axios
      .post(
        "reportesFinancieros/reporteBalanzaDeComprobacion",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.setState({
            loading: false,
            dataReporte: res.data,
            todasCuentas: res.data.arrayCuentas.map(valueCuenta => {
              return {
                ...valueCuenta,
                cuenta: cuentaMask(
                  valueCuenta.nivel1 +
                    valueCuenta.nivel2 +
                    valueCuenta.nivel3 +
                    valueCuenta.nivel4 +
                    valueCuenta.nivel5
                )
              }
            })
          });
        } else if (res.data.success === 1) {
          message.error("Ha sucedido un error y no se cargo bien el reporte");
        } else {
          message.error("Ha sucedido un error y no se cargo bien el reporte");
        }
        this.setState({ loading: false });
      });
  }

  descargarExcel() {
    let datosDescargar = [];

    for (const valueCuenta of this.state.todasCuentas) {
      datosDescargar.push({
        Cuenta: valueCuenta.cuenta,
        Descripcion: valueCuenta.descripcion,
        'Saldo Inicial': round2Decimals(valueCuenta.saldoInicial),
        Cargos: round2Decimals(valueCuenta.totalCargos),
        Abonos: round2Decimals(valueCuenta.totalAbonos),
        'Saldo Final': round2Decimals(valueCuenta.saldoFinal),
      });
    }

    const worksheet = XLSX.utils.json_to_sheet(datosDescargar);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Balanza De Comprobacion"
    );
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workbook,
      `Balanza_De_Comprobacion_${moment().locale("es").format("L")}.xlsx`
    );
  }

  descargarPdf() {
    pdfMake
      .createPdf(reporteDeBalanzaDeComprobacionPdf(this.state.dataReporte))
      .download(
        `Balanza_De_Comprobacion_${moment().locale("es").format("L")}`
      );
  }

  onFinishFailed(e) {
    console.log(e);
    message.error("Porfavor ingrese bien los datos");
  }

  render() {
    const columns = [
      {
        title: "Cuenta",
        dataIndex: "cuenta",
        key: "cuenta",
        sorter: (a, b) => {
          if (a.cuenta < b.cuenta) {
            return -1;
          }
          if (a.cuenta > b.cuenta) {
            return 1;
          }
          return 0;
        },
        buscar: "cuenta",
        ellipsis: true,
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
        ellipsis: true,
      },
      {
        title: "Saldo Inicial",
        dataIndex: "saldoInicial",
        key: "saldoInicial",
        sorter: (a, b) => {
          if (a.saldoInicial < b.saldoInicial) {
            return -1;
          }
          if (a.saldoInicial > b.saldoInicial) {
            return 1;
          }
          return 0;
        },
        buscar: "saldoInicial",
        render: (value) => dineroDisabledMask(value),
        align: "right",
        ellipsis: true,
      },
      {
        title: "Cargos",
        dataIndex: "totalCargos",
        key: "totalCargos",
        sorter: (a, b) => {
          if (a.totalCargos < b.totalCargos) {
            return -1;
          }
          if (a.totalCargos > b.totalCargos) {
            return 1;
          }
          return 0;
        },
        buscar: "totalCargos",
        render: (value) => dineroDisabledMask(value),
        align: "right",
        ellipsis: true,
      },
      {
        title: "Abonos",
        dataIndex: "totalAbonos",
        key: "totalAbonos",
        sorter: (a, b) => {
          if (a.totalAbonos < b.totalAbonos) {
            return -1;
          }
          if (a.totalAbonos > b.totalAbonos) {
            return 1;
          }
          return 0;
        },
        buscar: "totalAbonos",
        render: (value) => dineroDisabledMask(value),
        align: "right",
        ellipsis: true,
      },
      {
        title: "Saldo Final",
        dataIndex: "saldoFinal",
        key: "saldoFinal",
        sorter: (a, b) => {
          if (a.saldoFinal < b.saldoFinal) {
            return -1;
          }
          if (a.saldoFinal > b.saldoFinal) {
            return 1;
          }
          return 0;
        },
        buscar: "saldoFinal",
        render: (value) => dineroDisabledMask(value),
        align: "right",
        ellipsis: true,
      },
    ];
    return (
      <>
        <Form
          ref={this.formRef}
          name="rerpoteBalanzaDeComprobacion"
          onFinish={this.consultarReporteBalanzaDeComprobacion.bind(this)}
          onFinishFailed={this.onFinishFailed}
          inita
        >
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Reporte Balanza De Comprobacion"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Space>
                  {this.state.dataReporte && (
                    <Button
                      onClick={() => this.descargarExcel()}
                      icon={<FileExcelOutlined />}
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Descargar Excel
                    </Button>
                  )}
                  {this.state.dataReporte && (
                    <Button
                      onClick={() => this.descargarPdf()}
                      icon={<FilePdfOutlined />}
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Descargar PDF
                    </Button>
                  )}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Consultar
                  </Button>
                </Space>
              </div>
            }
          />
          <Spin spinning={this.state.loading}>
            <div
              style={{
                backgroundColor: "white",
                padding: 20,
                paddingTop: 5,
                marginBottom: 20,
              }}
            >
              <Tabs
                style={{ margin: 0 }}
                tabBarExtraContent={{
                  left: (
                    <h1 style={{ padding: 0, margin: "0 10px 0 0" }}>
                      Filtros:
                    </h1>
                  ),
                }}
              >
                  <TabPane tabKey="3" key="3" forceRender tab="Periodo">
                  <FormItem initialValue={[moment().subtract(1, "month"), moment()]} name="periodo" required noStyle>
                    <RangePickerItem />
                  </FormItem>
                  </TabPane>
                <TabPane tabKey="4" key="4" forceRender tab="Proyecto">
                  <Row>
                    <Col span={8}>
                      <FormItem
                        name="filtrarProyecto"
                        required
                        noStyle
                        initialValue={0}
                      >
                        <SelectItem width="90%" placeholder="Filtrar Proyecto">
                          <Option default value={0}>
                            Niguno
                          </Option>
                          <Option value={1}>Proyectos</Option>
                          <Option value={2}>Unidades de negocio</Option>
                        </SelectItem>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const filtrarProyecto =
                            getFieldValue("filtrarProyecto");
                          if (filtrarProyecto === 1) {
                            return (
                              <FormItem required name="proyectos" noStyle>
                                <SelectItem
                                  width="90%"
                                  placeholder="Proyectos"
                                  mode="multiple"
                                >
                                  {this.state.objetoProyectos}
                                </SelectItem>
                              </FormItem>
                            );
                          }
                          if (filtrarProyecto === 2) {
                            return (
                              <FormItem
                                required
                                name="unidadDeNegocioId"
                                noStyle
                              >
                                <SelectItem
                                  width="90%"
                                  placeholder="Unidades de Negocio"
                                >
                                  {this.state.objetoUniadesDeNegocio}
                                </SelectItem>
                              </FormItem>
                            );
                          }
                        }}
                      </FormItem>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabKey="5" key="5" forceRender tab="Nivel">
                  <FormItem
                    label="Nivel"
                    name="nivel"
                    required
                    marginBottom="0px"
                    initialValue={5}
                  >
                    <SelectItem placeholder="Nivel">
                      <Option value={1}>Nivel 1</Option>
                      <Option value={2}>Nivel 2</Option>
                      <Option value={3}>Nivel 3</Option>
                      <Option value={3}>Nivel 5</Option>
                      <Option value={3}>Nivel 5</Option>
                    </SelectItem>
                  </FormItem>
                </TabPane>
              </Tabs>
            </div>
            <h1 style={{ textAlign: "center" }}>Cuentas</h1>
            <TableItem
              columns={columns}
              dataSource={this.state.todasCuentas}
              size="small"
              pagination={false}
            />
          </Spin>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ReporteBalanzaDeComprobacion);
