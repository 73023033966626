import { message, Space } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalItem,
  ButtonItem,
  Form,
  FormItem,
  InputItem,
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import facturaXmlPdf from "../../../../Pdf/facturaXmlPdf";
import complementoXmlPdf from "../../../../Pdf/complementoXmlPdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class VistaPrevia extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pdf: null,
    emitido: false,
    tipoDeComprobante: null
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "descargaMasiva/datosXml",
        { xmlId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.formRef.current.setFieldsValue({
          correo: res.data?.cliente?.correo ? res.data.cliente.correo : "",
        });
        let pdf
        if(res.data.tipoDeComprobante === 'I' || res.data.tipoDeComprobante === 'E'){
          pdf = pdfMake.createPdf(facturaXmlPdf(res.data));
        }else if(res.data.tipoDeComprobante === 'P'){
          pdf = pdfMake.createPdf(complementoXmlPdf(res.data));
        }
        this.setState(
          {
            loading: false,
            pdf: pdf,
            emitido: res.data.emitido,
            tipoDeComprobante: res.data.tipoDeComprobante
          },
          () => {
            pdf.open({}, window.frames["facturaPdf"]);
          }
        );
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "descargaMasiva/enviarCorreo",
        { ...values, xmlId: this.props.id, empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Factura enviada");
          this.formRef.current.resetFields();
          this.cerrarVistaPrevia();
        } else if (res.data.success === 1) {
          message.error("No se pudo enviar la factura a ese correo");
        } else {
          message.error("Sucedio un error y no se pudo enviar");
        }
      });
  }
  cerrarVistaPrevia() {
    this.props.cerrarVistaPrevia();
  }
  render() {
    return (
      <ModalItem
        title="Vista previa"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarVistaPrevia.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="vistaPrevia"
          layout
          width={700}
          onCancel={this.cerrarVistaPrevia.bind(this)}
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          {this.state.emitido && (this.state.tipoDeComprobante === 'I' || this.state.tipoDeComprobante === 'E' || this.state.tipoDeComprobante === 'P') && (
            <>
              <FormItem label="Correo" name="correo" pattern email margin>
                <InputItem placeholder="Correo" />
              </FormItem>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                <Space>
                  <ButtonItem
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Mandar Correo
                  </ButtonItem>
                  <ButtonItem
                    type="primary"
                    danger
                    onClick={this.cerrarVistaPrevia.bind(this)}
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Cancelar
                  </ButtonItem>
                </Space>
              </div>
            </>
          )}
        </Form>
        <iframe
          title={"factura"}
          style={{ width: "100%", height: "100vh" }}
          id="facturaPdf"
          name="facturaPdf"
        />
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(VistaPrevia);
