import React, { Component } from "react";
import {
  Card,
  Layout,
  Menu,
  notification,
  Result,
} from "antd";
import logoHorizontal from "../../assets/Biwo_Horizontal.png";
import { NavLink } from "react-router-dom";
import ButtonItem from "../../Components/ButtonItem";
import axios from "axios";

const { Header, Footer, Content } = Layout;

class PagoExitoso extends Component {

  state={
    status: "info",
    mensaje: "Espere mientras verificamos el pago"
  }

  componentDidMount() {
    const url = new URL(window.location.href)
    const orderId = url.searchParams.get('order_id')
    const checkoutId = url.searchParams.get('checkout_id')
    axios.post('facturacion/checarPago', {orderId, checkoutId})
    .then((res) => {
      if(res.data.success === 2){
        notification.success({
          message: "Se ha verificado el pago",
        });
        this.setState({status: "success", mensaje: 'Su pago se verifico exitosamente, Gracias por su pago!'})
      }else if(res.data.success === 1){
        notification.info({
          message: "El pago ya fue verificado anteriormente",
        });
        this.setState({status: "success", mensaje: "El pago ya fue verificado anteriormente"})
      }else{
        notification.error({
          message: "Error no se pudo verificar el pago",
        });
        this.setState({status: "error", mensaje: "Error no se pudo verificar el pago"})
      }
    }).catch(err => {
      notification.error({
        message: "Error no se pudo verificar el pago",
      });
      this.setState({status: "error", mensaje: "Error no se pudo verificar el pago"})
    })
  }

  render() {
    const current = this.props.history.location.pathname;
    const url = new URL(window.location.href)
    const checkoutId = url.searchParams.get('checkout_id')
    return (
      <Layout>
        <Header id="header-fixed">
          <Menu
            mode="horizontal"
            selectedKeys={current}
            style={{ height: "100%" }}
          >
            <Menu.Item key={"/pagoExitoso"} id='menuitem-izquierda'>
              <NavLink to={"/pagoExitoso"}>
                <img src={logoHorizontal} alt='Logo' id="img-logo" />
              </NavLink>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{ marginTop: 64 }}>
            <Card id="login-card" bodyStyle={{ paddingTop: 0 }}>
            <Result
              key='pagoExitoso'
              status={this.state.status}
              title={this.state.mensaje}
              subTitle={"Numero de orden: "+checkoutId}
              extra={[
                <ButtonItem onClick={() => this.props.history.push('/login')} type="primary">
                  Iniciar sesion
                </ButtonItem>,
              ]}
            />
            </Card>
        </Content>
        <Footer id="content-background" style={{ textAlign: "center" }}>
          Biwo ©2020
        </Footer>
      </Layout>
    );
  }
}

export default PagoExitoso;
