import { Collapse, Row, Col, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  dineroDisabledMask,
  ModalItem,
  ButtonItem,
} from "../../../../Components/Items";
import moment from "moment";
import CancelarNotaDeCreditoCompra from "../CancelarNotaDeCreditoCompra/CancelarNotaDeCreditoCompra";
// import CancelarNotaDeCreditoCompra from "../CancelarNotaDeCreditoCompra/CancelarNotaDeCreditoCompra";

const { Panel } = Collapse;

class NotasDeCreditoFactura extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    notasHtml: <p>No se encontro ninguna nota de credito</p>,
    modalCancelarNotaDeCreditoCompra: false,
    modalCancelarNotaDeCreditoCompraId: null
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }

  verNotaDeCredito(id){
    this.props.history.push("/panel/verNotaDeCredito", {id})
  }

  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "notasDeCreditoCompra/notasFactura",
        { facturaId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) return;
        const html = (
          <Collapse style={{ marginBottom: 10 }}>
            {res.data.map((valueNota) => {
              return (
                <Panel
                  extra={
                    valueNota.estado !== 0 ? (
                      valueNota.moneda.abreviacion+' - '+dineroDisabledMask(valueNota.totalPagar)
                    ) : (
                      <Tag color="red">
                        Cancelada: {valueNota.moneda.abreviacion+' - '+dineroDisabledMask(valueNota.totalPagar)}
                      </Tag>
                    )
                  }
                  header={moment(valueNota.fechaFactura).locale("es").format("ll")}
                  key={valueNota._id}
                >
                  <Row>
                    <Col span={12}>
                      <p>
                        Fecha:{" "}
                        {moment(valueNota.fechaFactura).locale("es").format("ll")}
                      </p>
                      <p>
                        UUID:{" "}
                        {valueNota.uuid}
                      </p>
                      {valueNota.estado !== 0 && (
                        <ButtonItem type="primary" onClick={() => this.verNotaDeCredito(valueNota._id)}>
                          Ver nota
                        </ButtonItem>
                      )}
                    </Col>
                    <Col span={12}>
                      <p>Total: {valueNota.moneda.abreviacion+' - '+dineroDisabledMask(valueNota.totalPagar)}</p>
                      <p>Codigo: {valueNota.codigoFactura ? valueNota.codigoFactura : ''}</p>
                      {valueNota.estado !== 0 && (
                        <ButtonItem type="primary" danger onClick={() => {
                          this.setState({modalCancelarNotaDeCreditoCompra: true, modalCancelarNotaDeCreditoCompraId: valueNota._id})
                        }}>
                          Cancelar Nota
                        </ButtonItem>
                      )}
                    </Col>
                  </Row>
                </Panel>
              );
            })}
          </Collapse>
        );
        this.setState({ notasHtml: html });
      });
    this.setState({ loading: false });
  }
  cerrarNotasDeCreditoFactura() {
    this.props.cerrarNotasDeCreditoFactura();
  }
  cerrarCancelarNotaDeCreditoCompra() {
    this.setState({
      modalCancelarNotaDeCreditoCompra: false,
      modalCancelarNotaDeCreditoCompraId: null,
    });
    this.consultarTodo()
  }
  render() {
    return (
      <>
      <ModalItem
        title="Notas de Credito Factura"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarNotasDeCreditoFactura.bind(this)}
        paddingTop
      >
        {this.state.notasHtml}
      </ModalItem>
      <CancelarNotaDeCreditoCompra
          visible={this.state.modalCancelarNotaDeCreditoCompra}
          id={this.state.modalCancelarNotaDeCreditoCompraId}
          cerrarCancelarNotaDeCreditoCompra={this.cerrarCancelarNotaDeCreditoCompra.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(NotasDeCreditoFactura);
