import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Select,
} from "antd";
import {
  Form,
  SelectItem,
  FormItem,
  InputNumberItem,
  DatePickerItem,
  ButtonItem,
  InputItem,
  NotificacionPolizas
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";

const {Option} = Select

moment.locale("es");

class TraspasoEntreCuentas extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    datosFactura: undefined,
    todasBancos: [],
    objetoBancos: [],
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
    .post(
      "proyectos/selectProyectos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasProyectos: res.data });
        this.setState({
          objetoProyectos: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre}
              </Option>
            );
          }),
        });
      }
    });
    await axios
    .post(
      "bancos/selectBancos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasBancos: res.data });
        this.setState({
          objetoBancos: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre+' - '+value.banco}
              </Option>
            );
          }),
        });
      }
    });
    await axios
    .post(
      "cajaefectivo/selectCajaEfectivo",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasBancos: [...this.state.todasBancos, ...res.data] });
        this.setState({
            objetoBancos: [this.state.objetoBancos ,...res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre+(value.banco ? ' - '+value.banco: '')}
              </Option>
            )
          })],
        });
      }
    });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if(e.errorFields[index].name[1]){

        message.error(
          "Error: " +
            e.errorFields[index].errors[0] +
            " en la fila " +
            (e.errorFields[index].name[1] + 1)
        )
      }else{
        message.error(
          "Error: Falta un campo"
        )
      }
      return null
    }
    );
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "traspasoentrecuentas/traspaso",
        {
          ...values,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se ha registrado una transferencia"
          );
          NotificacionPolizas(res.data.polizasId)
          this.formRef.current.resetFields();
          this.atras(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se pudo efectuar el traspaso");
        } else {
          message.error("Hubo un error y no se efectuo el traspaso");
        }
      });
  }
  atras() {
    this.props.history.goBack();
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Traspaso entre Cuentas"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="traspasoEntreCuentas"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Traspasar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
            <Row>
                <Col span={8}>
                <FormItem label="Proyecto" name="proyectoId" margin required>
                    <SelectItem placeholder="Proyecto">
                      {this.state.objetoProyectos}
                    </SelectItem>
                  </FormItem>
                </Col>
            <Col span={8}>
                <FormItem
                    name="referencia"
                    label="Referencia"
                    pattern
                  >
                    <InputItem placeholder="Referencia" />
                  </FormItem>
                </Col>
                <Col span={8}>
                <FormItem
                    label="Fecha de Pago"
                    name="fechaPago"
                    required
                    margin
                    initialValue={moment()}
                  >
                    <DatePickerItem
                      placeholder="Fecha de Pago"
                      allowClear={false}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <FormItem label="Cuenta Origen" name="bancoOrigenId" margin required>
                    <SelectItem placeholder="Cuenta Origen">
                      {this.state.objetoBancos}
                    </SelectItem>
                  </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem required number margin label='Monto' name='monto' type='number'>
                        <InputNumberItem dinero placeholder='Monto'/>
                    </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label="Cuenta Destino" name="bancoDestinoId" margin required>
                    <SelectItem placeholder="Cuenta Destino">
                      {this.state.objetoBancos}
                    </SelectItem>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(TraspasoEntreCuentas);
