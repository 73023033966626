import {
  Button,
  Col,
  Divider,
  PageHeader,
  Row,
  Spin,
  Form as FormInitial,
  message,
  Select,
  Space,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  SelectItem,
  InputNumberItem,
  NotificacionPolizas,
  DatePickerItem,
  round10Decimals,
} from "../../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import VistaPrevia from "../VistaPrevia/VistaPrevia";

const { Option } = Select;

class AgregarOrdenDeProduccion extends Component {
  formRef = React.createRef();
  formRefPrimerForm = React.createRef();
  state = {
    loading: false,
    objetoProyectos: null,
    todasProyectos: null,
    todasInsumos: null,
    todasListaDeMateriales: null,
    objetoListaDeMateriales: null,
    listaDeMaterialesId: null,
    cantidadAGenerarMinima: 0,
    objetoInsumos: null,
    modalVistaPrevia: false,
    modalVistaPreviaId: null,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "listasdemateriales/selectListas",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasListaDeMateriales: res.data,
          });
          this.setState({
            objetoListaDeMateriales: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.insumoAGenerar.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "insumos/selectInsumosInventariables",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasInsumos: res.data });
          this.setState({
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  atras() {
    this.props.history.goBack();
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1] || e.errorFields[index].name[1] === 0) {
        message.error(
          "Error: " +
            e.errorFields[index].errors[0] +
            " en la fila " +
            (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "produccion/agregarOrdenDeProduccion",
        {
          ...values,
          empresaId: this.props.empresaId,
          listaDeMateriales: true,
          listaDeMaterialesId: this.state.listaDeMaterialesId
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se agrego una orden de producción");
          NotificacionPolizas(res.data.polizasId);
          this.setState({
            modalVistaPrevia: true,
            modalVistaPreviaId: res.data.id,
          });
        } else if (res.data.success === 1) {
          message.error(res.data.message);
        } else {
          message.error("Hubo un error y el insumo no se produjo");
        }
      });
  }

  async onFinishPrimerForm(values) {
    const listaDeMateriales = this.state.todasListaDeMateriales.find(
      (valueTodasLM) =>
        valueTodasLM._id.toString() === values.listaDeMaterialesId.toString()
    );
    await this.setState({ loading: true, listaDeMaterialesId: values.listaDeMaterialesId, proyectoId: values.proyectoId, cantidadAGenerarMinima: listaDeMateriales.cantidadAGenerar });
    await this.formRef.current.setFieldsValue({
      insumosUtilizados: listaDeMateriales.insumosAUtilizar,
      insumoGeneradoId: listaDeMateriales.insumoAGenerarId,
      cantidadGenerada: listaDeMateriales.cantidadAGenerar,
      proyectoId: values.proyectoId
    });
    await Promise.all(listaDeMateriales.insumosAUtilizar.map(async (valueInsumo, keyInsumo) => {
      return await this.onChangeInsumo(null, keyInsumo)
    }))
    await this.setState({loading: false})
  }
  deselectListaDeMateriales() {
    this.setState({ listaDeMaterialesId: null });
  }
  async onChangeInsumo(value, key) {
    let arrayValue = await this.formRef.current.getFieldValue(
      "insumosUtilizados"
    );
    let proyecto = await this.formRef.current.getFieldValue("proyectoId");
    const insumoId = arrayValue[key].insumoId;
    const insumo = this.state.todasInsumos.find(
      (valueInsumos) => valueInsumos._id === insumoId
    );
    if (!insumo) return;
    await axios
      .post(
        "inventarios/inventarioInsumoProyecto",
        {
          proyectoId: proyecto,
          insumoId: insumoId,
          empresaId: this.props.empresaId,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        arrayValue[key].cantidadProyecto = res.data.cantidad;
      });
    this.formRef.current.setFieldsValue({
      insumosUtilizados: arrayValue,
    });
  }
  cerrarVistaPrevia() {
    this.setState({
      modalVistaPrevia: false,
      modalVistaPreviaId: null,
    });
    this.formRef.current.resetFields();
    this.atras();
  }
  onChangeCantidad(value){
    const listaDeMateriales = this.state.todasListaDeMateriales.find(
      (valueTodasLM) =>
        valueTodasLM._id.toString() === this.state.listaDeMaterialesId.toString()
    );
    const porcentajeDeCantidad = value / listaDeMateriales.cantidadAGenerar
    const nuevosInsumosAGenerar = listaDeMateriales.insumosAUtilizar.map(valueInsumo => {
      return {
        ...valueInsumo,
        cantidad: round10Decimals(valueInsumo.cantidad * porcentajeDeCantidad)
      }
    })
    this.formRef.current.setFieldsValue({insumosUtilizados: nuevosInsumosAGenerar})
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Orden de Producción"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            {this.state.listaDeMaterialesId ? (
              <Form
                ref={this.formRef}
                name="agregarOrdenDeProduccion"
                layout
                bottom={
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Space>
                      <Button
                        style={{ display: "block", margin: "0 0 0 auto" }}
                        onClick={() => this.deselectListaDeMateriales()}
                      >
                        Atras
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Producir
                      </Button>
                    </Space>
                  </div>
                }
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed}
              >
                <Divider
                  style={{ marginBottom: 10, marginTop: 5 }}
                  children="Insumo a Producir"
                />
                <Row>
                  <Col span={12}>
                    <FormItem
                      label="Insumo a Generar"
                      name="insumoGeneradoId"
                      margin
                      required
                    >
                      <SelectItem disabled placeholder="Insumo a Generar">
                        {this.state.objetoInsumos}
                      </SelectItem>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Cantidad a Generar"
                      name="cantidadGenerada"
                      number
                      disabled
                      required
                      type="number"
                    >
                      <InputNumberItem onChange={this.onChangeCantidad.bind(this)} />
                    </FormItem>
                  </Col>
                </Row>
                <Divider
                  style={{ marginBottom: 10, marginTop: 5 }}
                  children="Proyecto"
                />
                <Row>
                  <Col span={12}>
                    <FormItem shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const insumosUtilizados =
                          getFieldValue("insumosUtilizados");
                        const disabled = insumosUtilizados?.length > 0;
                        return (
                          <FormItem
                            label="Proyecto"
                            name="proyectoId"
                            margin
                            required
                          >
                            <SelectItem
                              placeholder="Proyecto"
                              disabled={disabled}
                            >
                              {this.state.objetoProyectos}
                            </SelectItem>
                          </FormItem>
                        );
                      }}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Fecha de Produccion"
                      name="fechaProduccion"
                      required
                      margin
                      initialValue={moment()}
                    >
                      <DatePickerItem
                        placeholder="Fecha de Produccion"
                        allowClear={false}
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Divider
                  style={{ marginBottom: 10, marginTop: 5 }}
                  children="Insumos a Utilizar"
                />
                <FormInitial.List name="insumosUtilizados">
                  {(fields, { add, remove }) => (
                    <>
                      <table className="table">
                        {fields.length > 0 ? (
                          <thead>
                            <tr>
                              <th
                                className="th-border"
                                style={{ width: "60%" }}
                              >
                                Insumo
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "20%" }}
                              >
                                Cantidad Proyecto
                              </th>
                              <th
                                className="th-border"
                                style={{ width: "20%" }}
                              >
                                Cantidad
                              </th>
                            </tr>
                          </thead>
                        ) : null}
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, arrayKey) => (
                            <tbody>
                              <tr>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "insumoId"]}
                                    fieldKey={[arrayKey, "insumoId"]}
                                    required
                                    noStyle
                                  >
                                    <SelectItem
                                      sinBorde
                                      autoFocus
                                      disabled
                                      width="100%"
                                      placeholder="Insumo"
                                      onChange={(value) =>
                                        this.onChangeInsumo(value, arrayKey)
                                      }
                                    >
                                      {this.state.objetoInsumos}
                                    </SelectItem>
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    {...restField}
                                    name={[name, "cantidadProyecto"]}
                                    fieldKey={[arrayKey, "cantidadProyecto"]}
                                    number
                                    noStyle
                                    required
                                    initialValue={undefined}
                                  >
                                    <InputNumberItem sinBorde disabled />
                                  </FormItem>
                                </td>
                                <td className="td-border">
                                  <FormItem
                                    noStyle
                                    shouldUpdate={(prev, curr) =>
                                      prev.insumosUtilizados[arrayKey]
                                        ?.cantidadProyecto !==
                                      curr.insumosUtilizados[arrayKey]
                                        ?.cantidadProyecto
                                    }
                                  >
                                    {({ getFieldValue }) => {
                                      const insumosUtilizados =
                                        getFieldValue("insumosUtilizados");
                                      if (!insumosUtilizados[arrayKey]) return;
                                      const cantidadProyecto =
                                        insumosUtilizados[arrayKey]
                                          .cantidadProyecto;
                                      let max = cantidadProyecto;
                                      return (
                                        <FormItem
                                          {...restField}
                                          name={[name, "cantidad"]}
                                          fieldKey={[arrayKey, "cantidad"]}
                                          number
                                          noStyle
                                          required
                                          max={max}
                                          type="number"
                                          initialValue={undefined}
                                        >
                                          <InputNumberItem sinBorde disabled />
                                        </FormItem>
                                      );
                                    }}
                                  </FormItem>
                                </td>
                              </tr>
                            </tbody>
                          )
                        )}
                      </table>
                    </>
                  )}
                </FormInitial.List>
              </Form>
            ) : (
              <Form
                ref={this.formRefPrimerForm}
                layout
                bottom={
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Siguiente
                  </Button>
                }
                onFinish={this.onFinishPrimerForm.bind(this)}
                onFinishFailed={this.onFinishFailed}
              >
                <Row>
                  <Col span={12}>
                    <FormItem
                      label="Lista De Materiales"
                      name="listaDeMaterialesId"
                      margin
                      required
                    >
                      <SelectItem placeholder="Lista De Materiales">
                        {this.state.objetoListaDeMateriales}
                      </SelectItem>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      label="Proyecto"
                      name="proyectoId"
                      margin
                      required
                    >
                      <SelectItem placeholder="Proyecto">
                        {this.state.objetoProyectos}
                      </SelectItem>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            )}
          </Spin>
        </div>
        <VistaPrevia
          visible={this.state.modalVistaPrevia}
          id={this.state.modalVistaPreviaId}
          cerrarVistaPrevia={this.cerrarVistaPrevia.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarOrdenDeProduccion);
