import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Select,
  Form as FormInitial,
  Button,
} from "antd";
import {
  HeaderItem,
  FormItem,
  InputNumberItem,
  SelectItem,
  Form
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import CostearInsumo from "./CostearInsumo/CostearInsumo";

const { Option } = Select;

moment.locale("es");

class CostearVenta extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    datosVenta: undefined,
    todasInsumos: null,
    objetoInsumos: null,
    todasProyectos: null,
    objetoProyectos: null,
    modalCostearInsumo: false,
    modalCostearInsumoInsumoVentaId: null,
    modalCostearInsumoProyectoId: null,
    modalCostearInsumoInsumoId: null,
    id: this.props.location.state.id,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "insumos/selectInsumosVenta",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasInsumos: res.data });
          this.setState({
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
      await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
      await axios
      .post(
        "ventas/datosVentaPCostearP",
        {
          empresaId: this.props.empresaId,
          ventaId: this.state.id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error(
            "Error no se encontro informacion de la venta"
          );
        } else {
          this.setState({ datosVenta: res.data });
          if(res.data.insumos.length > 0){
              this.formRef.current.setFieldsValue({ insumos: res.data.insumos.map(value => {
                return {...value, precioUnitarioAjustado: (value.precioUnitario*(1-(value.descuento/100)))}
              })});
          }else{
            this.formRef.current.setFieldsValue({ insumos: []});
          }
        }
      });
    this.setState({ loading: false });
  }
  atras() {
    this.props.history.goBack();
  }
  onFinish() {
    this.atras()
  }
  abrirCostearInsumo(id, proyectoId, insumoId) {
    this.setState({ modalCostearInsumo: true, modalCostearInsumoInsumoVentaId: id, modalCostearInsumoProyectoId: proyectoId, modalCostearInsumoInsumoId: insumoId });
  }
  cerrarCostearInsumo() {
    this.setState({ modalCostearInsumo: false });
    this.consultarTodo();
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Costear Venta"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <h1>
              Fecha:{" "}
              {moment(this.state.datosVenta?.createdAt).format("LL")}
            </h1>
          }
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
          <Form
              ref={this.formRef}
              name="costearVenta"
              layout
              bottom={
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Finalizar
                </Button>
              }
              onFinish={this.onFinish.bind(this)}
            >
              <Row>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>
                    Proyecto: {this.state.datosVenta?.proyecto.nombre}
                  </h1>
                  <p>
                    Descripcion:{" "}
                    {this.state.datosVenta?.proyecto.descripcion}
                  </p>
                </Col>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>Dirección</h1>
                  {this.state.datosVenta?.proyecto.calle ? (
                    <>
                      <p style={{ margin: 0 }}>
                        Calle: {this.state.datosVenta?.proyecto.calle}
                      </p>
                      <p style={{ margin: 0 }}>
                        Numero:{" "}
                        {this.state.datosVenta?.proyecto.numeroCalle}
                      </p>
                    </>
                  ) : (
                    <>
                      <p style={{ margin: 0 }}>
                        Marca: {this.state.datosVenta?.proyecto.marca}
                      </p>
                      <p style={{ margin: 0 }}>
                        Modelo: {this.state.datosVenta?.proyecto.modelo}
                      </p>
                      <p style={{ margin: 0 }}>
                        Placas: {this.state.datosVenta?.proyecto.placas}
                      </p>
                    </>
                  )}
                </Col>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>
                    Cliente:{" "}
                    {this.state.datosVenta?.cliente.nombre
                      ? this.state.datosVenta?.cliente.nombre +
                        " " +
                        this.state.datosVenta?.cliente
                          .apellidoPaterno +
                        " " +
                        this.state.datosVenta?.cliente.apellidoMaterno
                      : this.state.datosVenta?.cliente.razonSocial}
                  </h1>
                  <p style={{ margin: 0 }}>
                    Rfc: {this.state.datosVenta?.cliente.rfc}
                  </p>
                  <p style={{ margin: 0 }}>
                    Correo: {this.state.datosVenta?.cliente.correo}
                  </p>
                  <p style={{ margin: 0 }}>
                    Telefono:{" "}
                    {this.state.datosVenta?.cliente.telefono}
                  </p>
                  <p style={{ margin: 0 }}>
                    Celular: {this.state.datosVenta?.cliente.celular}
                  </p>
                </Col>
                <Col span={6}>
                  <p style={{ margin: 0 }}>
                    Contacto:{" "}
                    {this.state.datosVenta?.cliente.nombreContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Tel. Contacto:{" "}
                    {this.state.datosVenta?.cliente.telefonoContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Correo Contacto:{" "}
                    {this.state.datosVenta?.cliente.correoContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Dias de crédito:{" "}
                    {this.state.datosVenta?.cliente.diasCredito}
                  </p>
                </Col>
              </Row>
              <HeaderItem>Insumos por Costear</HeaderItem>
              <FormInitial.List name="insumos">
                {(fields) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "40%" }}>
                              Insumo
                            </th>
                            <th className="th-border" style={{ width: "15%" }}>
                              Proyecto
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Precio Unitario
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Cantidad Pedida
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Cantidad Entregada
                            </th>
                            <th className="th-border" style={{ width: "15%" }}>
                              Costear
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                        <tbody key={fieldKey}>
                          <tr>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "insumoId"]}
                                fieldKey={[fieldKey, "insumoId"]}
                                required
                                noStyle
                              >
                                <SelectItem
                                sinBorde
                                fondoBlanco
                                  style={{ width: "100%" }}
                                  placeholder="Insumo"
                                  disabled
                                >
                                  {this.state.objetoInsumos}
                                </SelectItem>
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "proyectoId"]}
                                fieldKey={[fieldKey, "proyectoId"]}
                                required
                                noStyle
                              >
                                <SelectItem
                                disabled
                                sinBorde
                                fondoBlanco
                                  style={{ width: "100%" }}
                                  placeholder="Proyecto"
                                  onChange={(value) =>
                                    this.onChangeInsumoProyecto(
                                      value,
                                      arrayKey
                                    )
                                  }
                                >
                                  {this.state.objetoProyectos}
                                </SelectItem>
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "precioUnitarioAjustado"]}
                                fieldKey={[fieldKey, "precioUnitarioAjustado"]}
                                noStyle
                              >
                                <InputNumberItem
                                sinBorde
                                  placeholder="Precio Unitario"
                                  disabled
                                  dineroDisabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "cantidad"]}
                                fieldKey={[fieldKey, "cantidad"]}
                                required
                                number
                                noStyle
                              >
                                <InputNumberItem
                                sinBorde
                                  placeholder="Cantidad Venida"
                                  disabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "entregado"]}
                                fieldKey={[fieldKey, "entregado"]}
                                required
                                numberCero
                                noStyle
                                initialValue={0}
                              >
                                <InputNumberItem
                                sinBorde
                                  placeholder="Cantidad Entregada"
                                  disabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border-center">
                              <FormItem shouldUpdate noStyle>
                                {({getFieldValue}) => {
                                  let id =
                                    this.state.datosVenta?.insumos[
                                      fieldKey
                                    ]?._id;
                                const insumos = getFieldValue("insumos");
                                const proyectoId = insumos[arrayKey]?.proyectoId ?
                                    insumos[arrayKey].proyectoId : undefined;
                                const insumoId = insumos[arrayKey]?.insumoId ?
                                    insumos[arrayKey].insumoId : undefined;
                                  return (
                                    <Button size='small' type='primary' onClick={() => this.abrirCostearInsumo(id, proyectoId, insumoId)}>Costear</Button>
                                  );
                                }}
                              </FormItem>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </>
                )}
              </FormInitial.List>
              </Form>
          </Spin>
        </div>
        <CostearInsumo 
            insumoVentaId={this.state.modalCostearInsumoInsumoVentaId}
            insumoId={this.state.modalCostearInsumoInsumoId}
            proyectoId={this.state.modalCostearInsumoProyectoId}
            ventaId={this.state.id}
            visible={this.state.modalCostearInsumo}
            cerrarCostearInsumo={this.cerrarCostearInsumo.bind(
            this
            )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(CostearVenta);
