import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Select,
  notification,
  Popconfirm,
} from "antd";
import {
  dineroDisabledMask,
  Form,
  SelectItem,
  FormItem,
  InputNumberItem,
  round2Decimals,
  DatePickerItem,
  ButtonItem,
  InputItem,
  NotificacionPolizas,
  HeaderItem,
  BoxItem,
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import MandarCorreoComplemento from "../MandarCorreoComplemento/MandarCorreoComplemento";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import complementoPdf from "../../../../Pdf/complementoPdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const { Option } = Select;

moment.locale("es");

class CobrarVenta extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    datosVenta: undefined,
    todasBancos: null,
    objetoBancos: null,
    objetoFormasDePago: null,
    todasFormasDePago: null,
    objetoMonedas: null,
    todasMonedas: null,
    modalCorreo: false,
    modalCorreoId: null,
    id: this.props.location.state.id,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "bancos/selectBancos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasBancos: res.data });
          this.setState({
            objetoBancos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.banco}
                </Option>
              );
            }),
          });
        }
      });
      await axios
      .post(
        "cajaefectivo/selectCajaEfectivo",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasBancos: [...this.state.todasBancos, ...res.data] });
          this.setState({
              objetoBancos: [this.state.objetoBancos ,...res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre+(value.banco ? ' - '+value.banco: '')}
                </Option>
              )
            })],
          });
        }
      });
      await axios
      .post(
        "formasdepago/selectFormasDePago",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasFormasDePago: res.data });
          this.setState({
            objetoFormasDePago: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
      await axios
      .post("monedas/selectMonedas", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMonedas: res.data });
          this.setState({
            objetoMonedas: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviacion}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "cuentasporcobrar/datosCobrarVenta",
        {
          empresaId: this.props.empresaId,
          ventaId: this.state.id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion de la venta");
        } else {
          this.setState({ datosVenta: res.data[0] });
          this.formRef.current.setFieldsValue({monedaId: res.data[0].monedaId, tipoDeCambioVenta: res.data[0].tipoDeCambio, tipoDeCambioPago: res.data[0].tipoDeCambio})
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
            e.errorFields[index].errors[0] +
            " en la fila " +
            (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "cuentasporcobrar/cobrarVenta",
        {
          ...values,
          empresaId: this.props.empresaId,
          ventaId: this.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha registrado un pago");
          if (this.state.datosVenta.factura && !this.state.datosVenta.cuentasXC ) {
              notification.success({
                description: "Haga click para descargar",
                message: "Complemento de Pago Emitido",
                duration: 0,
                style: { cursor: "pointer" },
                onClick: () => {
                  var xmltext = res.data.respuestaComplemento.data.cfdi;

                  var filename = res.data.respuestaComplemento.data.uuid+".xml";
                  var pom = document.createElement("a");
                  var bb = new Blob([xmltext], { type: "text/plain" });

                  pom.setAttribute("href", window.URL.createObjectURL(bb));
                  pom.setAttribute("download", filename);

                  pom.dataset.downloadurl = [
                    "text/plain",
                    pom.download,
                    pom.href,
                  ].join(":");
                  pom.draggable = true;
                  pom.classList.add("dragout");

                  pom.click();

                  const pdf = complementoPdf(res.data);

                  pdfMake.createPdf(pdf).download(res.data.respuestaComplemento.data.uuid+".pdf");
                },
              });
              NotificacionPolizas(res.data.polizasId)
              this.setState({modalCorreo: true, modalCorreoId: res.data.pagoId})
          }else{
            NotificacionPolizas(res.data.polizasId)
            this.formRef.current.resetFields();
            this.atras(res.data.codigo);
          }
        } else if (res.data.success === 1) {
          message.error("No se pudo efectuar el pago");
          notification.warning({
            description: res.data.description,
            message: res.data.message,
            duration: 0,
          });
        } else {
          message.error("Hubo un error y no se efectuo el pago");
        }
        this.setState({ loading: false });
      });
  }
  cerrarMandarCorreoComplemento() {
    this.setState({
      modalCorreo: false,
      modalCorreoId: null,
    });
    this.formRef.current.resetFields();
    this.atras();
  }
  atras() {
    this.props.history.goBack();
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Cobrar Venta"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <h1>
              Fecha Vencimiento:{" "}
              {moment(this.state.datosVenta?.fechaPago).format("LL")}
            </h1>
          }
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="pagarFactura"
              layout
              bottom={
                <Popconfirm
                  title={
                    (this.state?.datosVenta?.factura && !this.state?.datosVenta?.cuentasXC)
                      ? "Se generara un complemento de pago"
                      : "Cobrar venta"
                  }
                  onConfirm={() => this.formRef.current.formRef.current.submit()}
                  okText="Ok"
                  cancelText="Cancelar"
                >
                  <ButtonItem
                    type='primary'
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Cobrar
                  </ButtonItem>
                </Popconfirm>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
              <Col span={8}>
                  <HeaderItem>MONEDA</HeaderItem>
                  <BoxItem>
                    <p style={{textAlign: 'center'}}>Moneda de la venta: {this.state.datosVenta?.moneda?.abreviacion}</p>
                    <FormItem
                      required
                      number
                      margin
                      label={`T. C. ${this.state.datosVenta?.moneda?.abreviacion ? this.state.datosVenta?.moneda?.abreviacion : ''}`}
                      name="tipoDeCambioVenta"
                      type="number"
                      initialValue={1}
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumberItem
                        disabled={this.state.datosVenta?.monedaId === '60c7e4396465ad440c41c15a'}
                        dinero
                        placeholder={`T. C. ${this.state.datosVenta?.moneda?.abreviacion ? this.state.datosVenta?.moneda?.abreviacion : ''}`}
                      />
                    </FormItem>
                    <p style={{textAlign: 'center'}}>Tipo de cambio venta: {dineroDisabledMask(this.state.datosVenta?.tipoDeCambio)}</p>
                    <FormItem
                      required
                      margin
                      name="monedaId"
                      label="Moneda Pago"
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                      initialValue={'60c7e4396465ad440c41c15a'}
                    >
                      <SelectItem onChange={(value) => {
                        if(value === '60c7e4396465ad440c41c15a'){
                          this.formRef.current.setFieldsValue({tipoDeCambioPago: 1})
                        }
                      }} placeholder="Moneda">
                        {this.state.objetoMonedas}
                      </SelectItem>
                    </FormItem>
                    <FormItem noStyle shouldUpdate={(prev, curr) => prev.monedaId !== curr.monedaId}>
                      {({getFieldValue}) => {

                        const monedaId = getFieldValue('monedaId')

                        const moneda = this.state.todasMonedas ? this.state.todasMonedas.find((valueTodasMonedas) => valueTodasMonedas._id.toString() === monedaId.toString()) : null

                        return(
                          <FormItem
                            required
                            number
                            margin
                            label={`T. C. ${moneda?.abreviacion ? moneda.abreviacion : ''}`}
                            name="tipoDeCambioPago"
                            type="number"
                            initialValue={1}
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                          >
                            <InputNumberItem
                              disabled={monedaId === '60c7e4396465ad440c41c15a'}
                              dinero
                              placeholder={`T. C. ${moneda?.abreviacion ? moneda.abreviacion : ''}`}
                            />
                          </FormItem>
                        )
                      }}
                    </FormItem>
                  </BoxItem>
                </Col>  
                <Col span={8}>
                  <HeaderItem>DATOS DE PAGO</HeaderItem>
                  <BoxItem>
                    <FormItem
                      label="Fecha de Pago"
                      name="fechaPago"
                      required
                      margin
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValue={moment().hour(12).minute(0)}
                    >
                      <DatePickerItem
                        placeholder="Fecha de Pago"
                        allowClear={false}
                        showTime
                        dateFormat='DD/MM/YYYY HH:mm'
                      />
                    </FormItem>
                    <FormItem
                      label="Banco"
                      name="bancoId"
                      margin
                      required
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <SelectItem placeholder="Banco">
                        {this.state.objetoBancos}
                      </SelectItem>
                    </FormItem>
                    <FormItem
                      name="formaDePagoId"
                      label="F Pago"
                      initialValue="6125149afe07662acc100d58"
                      margin
                      required
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <SelectItem placeholder="Forma de Pago">
                        {this.state.objetoFormasDePago}
                      </SelectItem>
                    </FormItem>
                    <Row>
                      <Col span={16}>
                        <FormItem noStyle shouldUpdate>
                          {({getFieldValue}) => {
                            let tipoDeCambioVenta = getFieldValue('tipoDeCambioVenta')
                            let tipoDeCambioPago = getFieldValue('tipoDeCambioPago')
                            const equivalencia = tipoDeCambioPago / tipoDeCambioVenta
                            let totalPagar = this.state.datosVenta?.totalPagar || 0;
                            let totalPagado =
                              this.state.datosVenta?.totalPagado || 0;

                            let saldoPendiente = round2Decimals(
                              (totalPagar - totalPagado) / equivalencia
                            );


                            return (
                              <FormItem
                                required
                                number
                                margin
                                label="Monto"
                                name="monto"
                                type="number"
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                                max={saldoPendiente}
                              >
                                <InputNumberItem dinero placeholder="Monto" />
                              </FormItem>
                            );
                          }}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <ButtonItem
                          size="small"
                          type="primary"
                          onClick={() => {
                            let tipoDeCambioVenta = this.formRef.current.getFieldValue('tipoDeCambioVenta')
                            let tipoDeCambioPago = this.formRef.current.getFieldValue('tipoDeCambioPago')
                            const equivalencia = tipoDeCambioPago / tipoDeCambioVenta
                            let totalPagar = this.state.datosVenta?.totalPagar || 0;
                            let totalPagado =
                              this.state.datosVenta?.totalPagado || 0;

                            let saldoPendiente = round2Decimals(
                              (totalPagar - totalPagado) / equivalencia
                            );

                            this.formRef.current.setFieldsValue({
                              monto: saldoPendiente,
                            });
                          }}
                        >
                          Total
                        </ButtonItem>
                      </Col>
                    </Row>
                    <FormItem
                      name="referencia"
                      label="Referencia"
                      margin
                      pattern
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <InputItem placeholder="Referencia" />
                    </FormItem>
                  </BoxItem>
                </Col>
                <Col span={8}>
                  <HeaderItem>TOTALES</HeaderItem>
                  <BoxItem>
                    <table className="table" style={{ marginTop: 0 }}>
                      <tbody>
                        <tr>
                          <th className="th-totales">Total a Cobrar</th>
                          <td className="td-totales-right">
                            {dineroDisabledMask(this.state.datosVenta?.totalPagar)}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <th className="th-totales">Total Cobrado</th>
                          <td className="td-totales-right">
                            {dineroDisabledMask(
                              this.state.datosVenta?.totalPagado
                            )}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <th className="th-totales">Saldo Pendiente</th>
                          <td className="td-totales-right">
                            {dineroDisabledMask(
                              this.state.datosVenta?.totalPagar -
                                this.state.datosVenta?.totalPagado
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </BoxItem>
                </Col>
              </Row>
              <Row>
              <Col span={24}>
                  <HeaderItem>DATOS COMPLEMENTO DE PAGO</HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={8}>
                        <FormItem
                            label="Num Operacion"
                            name="numOperacion"
                            margin
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            tooltip={{ title: "Se puede registrar el número de cheque, número de autorización, número de referencia, clave de rastreo en caso de ser SPEI, línea de captura o algún número de referencia o identificación análogo que permita identificar la operación correspondiente al pago efectuado." }}
                          >
                            <InputItem placeholder="Numero de Operacion" />
                          </FormItem>
                          <FormItem
                            label="Cta Ordenante"
                            name="ctaOrdenante"
                            number
                            max={50}
                            min={10}
                            margin
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                            tooltip={{ title: "Se puede registrar el número de la cuenta con la que se realizó el pago" }}
                          >
                            <InputItem placeholder="Cuenta Ordenante" />
                          </FormItem>

                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="Rfc Ordenante"
                          name="rfcEmisorCtaOrd"
                          rfc
                          margin
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          tooltip={{ title: "Se puede registrar la clave en el RFC de la entidad operadora de la cuenta destino, es decir, la operadora, el banco, la institución financiera, emisor de monedero electrónico, etc" }}
                        >
                          <InputItem placeholder="Rfc Emisor Cuenta Ordenante" />
                        </FormItem>
                        <FormItem
                          name="nomBancoOrdExt"
                          label="Banco Ordenante"
                          margin
                          pattern
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          tooltip={{ title: "Se puede registrar el nombre del banco ordenante, es requerido en caso de ser extranjero" }}
                        >
                          <InputItem placeholder="Nombre Banco Ordenante" />
                        </FormItem>

                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="Cta Beneficiario"
                          name="ctaBeneficiario"
                          number
                          max={50}
                          min={10}
                          margin
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          tooltip={{ title: "Se puede registrar el número de cuenta en donde se recibió el pago." }}
                        >
                          <InputItem placeholder="Cuenta Beneficiario" />
                        </FormItem>
                        <FormItem
                          label="Rfc Beneficiaria"
                          name="rfcEmisorCtaBen"
                          rfc
                          margin
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          tooltip={{ title: "Se puede registrar la clave en el RFC de la entidad operadora de la cuenta destino, es decir, la operadora, el banco, la institución financiera, emisor de monedero electrónico, etc." }}
                        >
                          <InputItem placeholder="Rfc Emisor Cuenta Beneficiaria" />
                        </FormItem>

                      </Col>
                    </Row>
                  </BoxItem>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
        <div style={{ backgroundColor: "white", padding: 20, marginTop: 20 }}>
          <Row>
            <Col span={12}>
              <HeaderItem>DATOS DE CONTACTO</HeaderItem>
              <BoxItem>
                <Row>
                  <Col span={12}>
                    <h1 style={{ margin: 0, fontSize: 12.5 }}>
                      Cliente:{" "}
                      {this.state.datosVenta?.cliente.nombre
                        ? this.state.datosVenta?.cliente.nombre +
                          " " +
                          this.state.datosVenta?.cliente.apellidoPaterno +
                          " " +
                          this.state.datosVenta?.cliente.apellidoMaterno
                        : this.state.datosVenta?.cliente.razonSocial}
                    </h1>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      RFC:{" "}
                      {this.state.datosVenta?.cliente?.rfc
                        ? this.state.datosVenta?.cliente.rfc
                        : ""}
                    </p>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Correo:{" "}
                      {this.state.datosVenta?.cliente?.correo
                        ? this.state.datosVenta?.cliente.correo
                        : ""}
                    </p>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Teléfono:{" "}
                      {this.state.datosVenta?.cliente?.telefono
                        ? this.state.datosVenta?.cliente.telefono
                        : ""}
                    </p>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Celular:{" "}
                      {this.state.datosVenta?.cliente?.celular
                        ? this.state.datosVenta?.cliente.celular
                        : ""}
                    </p>
                  </Col>
                  <Col span={12}>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Contacto:{" "}
                      {this.state.datosVenta?.cliente?.nombreContacto
                        ? this.state.datosVenta?.cliente.nombreContacto
                        : ""}
                    </p>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Tel. Contacto:{" "}
                      {this.state.datosVenta?.cliente?.telefonoContacto
                        ? this.state.datosVenta?.cliente.telefonoContacto
                        : ""}
                    </p>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Correo Contacto:{" "}
                      {this.state.datosVenta?.cliente?.correoContacto
                        ? this.state.datosVenta?.cliente.correoContacto
                        : ""}
                    </p>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Días de crédito:{" "}
                      {this.state.datosVenta?.cliente?.diasCredito
                        ? this.state.datosVenta?.cliente.diasCredito
                        : ""}
                    </p>
                  </Col>
                </Row>
              </BoxItem>
            </Col>
            <Col span={6}>
              <HeaderItem>DATOS FACTURA</HeaderItem>
              <BoxItem>
                <h1 style={{ margin: 0, fontSize: 12.5 }}>
                  Proyecto: {this.state.datosVenta?.proyecto.nombre}
                </h1>
                <p style={{ margin: 0, fontSize: 12.5 }}>
                  Descripcion: {this.state.datosVenta?.proyecto.descripcion}
                </p>
              </BoxItem>
            </Col>
            <Col span={6}>
              <HeaderItem>DIRECCION DE ENVIO</HeaderItem>
              <BoxItem>
                <h1 style={{ margin: 0, fontSize: 12.5 }}>
                  Dirección de envio
                </h1>
                {this.state.datosVenta?.proyecto.calle ? (
                  <>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Calle: {this.state.datosVenta?.proyecto.calle}
                    </p>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Numero: {this.state.datosVenta?.proyecto.numeroCalle}
                    </p>
                  </>
                ) : (
                  <>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Marca: {this.state.datosVenta?.proyecto.marca}
                    </p>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Modelo: {this.state.datosVenta?.proyecto.modelo}
                    </p>
                    <p style={{ margin: 0, fontSize: 12.5 }}>
                      Placas: {this.state.datosVenta?.proyecto.placas}
                    </p>
                  </>
                )}
              </BoxItem>
            </Col>
          </Row>
        </div>
        <MandarCorreoComplemento
          visible={this.state.modalCorreo}
          id={this.state.modalCorreoId}
          cerrarMandarCorreoComplemento={this.cerrarMandarCorreoComplemento.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(CobrarVenta);
