import { PageHeader, Space } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem, dineroDisabledMask } from "../../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import AgregarConciliacion from "./AgregarConciliacion/AgregarConciliacion";

moment.locale("es");

class Bancos extends Component {
  state = {
    todasConciliaciones: [],
    agregarConciliacion: false,
    modalAgregarConciliacion: false,
    loading: false
  };
  atras() {
    this.props.history.goBack();
  }
  componentDidMount() {
    this.consultarConciliaciones();
  }

  async consultarConciliaciones() {
    this.setState({loading: true})
    await axios
    .post(
      "conciliaciones/selectConciliacionesCuenta",
      { 
        empresaId: this.props.empresaId,
        bancoId: this.props.location.state.id
      },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
            if (res.data.status === 404) {
            } else {
            this.setState({
                todasConciliaciones: res.data.map(value => {
                    return {...value, accion: { _id: value._id, estado: value.estado }}
                }),
            });
        }
    });
    await axios
    .post(
      "conciliaciones/checarAgregarConciliacion",
      { 
        empresaId: this.props.empresaId,
        bancoId: this.props.location.state.id
      },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
        if (res.data.success === 2) {
            this.setState({
                agregarConciliacion: true
            });
        }else{
            this.setState({
                agregarConciliacion: false
            });
        }
    });
    this.setState({loading: false})
  }

  abrirReanudarConciliacion(id) {
    this.props.history.push('/panel/conciliacion', {id, nombre: this.props.location.state.nombre})
  }

  abrirAgregarConciliacion() {
    this.setState({ modalAgregarConciliacion: true });
  }
  cerrarAgregarConciliacion() {
    this.setState({ modalAgregarConciliacion: false });
    this.consultarConciliaciones();
  }

  render() {
    const columns = [
      {
        title: "Fecha Creación",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => {
          if (moment(a.createdAt) < moment(b.createdAt)) {
            return -1;
          }
          if (moment(a.createdAt) > moment(b.createdAt)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale("es").format("ll"),
      },
      {
        title: "Referencia",
        dataIndex: "referencia",
        key: "referencia",
      },
      {
        title: "Fecha Inicial",
        dataIndex: "fechaInicial",
        key: "fechaInicial",
        sorter: (a, b) => {
            if (moment(a.createdAt) < moment(b.createdAt)) {
              return -1;
            }
            if (moment(a.createdAt) > moment(b.createdAt)) {
              return 1;
            }
            return 0;
          },
        render: (value) => value ? moment(value).locale("es").format("ll") : 'Sin fecha',
      },
      {
        title: "Fecha Final",
        dataIndex: "fechaFinal",
        key: "fechaFinal",
        sorter: (a, b) => {
            if (moment(a.createdAt) < moment(b.createdAt)) {
              return -1;
            }
            if (moment(a.createdAt) > moment(b.createdAt)) {
              return 1;
            }
            return 0;
          },
        render: (value) => value ? moment(value).locale("es").format("ll") : 'Sin fecha',
      },
      {
        title: "Saldo Inicial",
        dataIndex: "saldoInicial",
        key: "saldoInicial",
        render: (value) => dineroDisabledMask(value)
      },
      {
        title: "Saldo Final",
        dataIndex: "saldoFinal",
        key: "saldoFinal",
        render: (value) => dineroDisabledMask(value)
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value, record, index) => (
          <Space size="middle" key={index.toString()}>
                {value.estado === 1 && (
                    <ButtonItem key={index.toString()} type='primary' onClick={() => this.abrirReanudarConciliacion(value._id)} size='small'>
                        Reanudar Conciliacion
                    </ButtonItem>
                )}
          </Space>
        ),
      },
    ]
    return (
      <>
        <PageHeader
          title={"Conciliaciones: "+this.props.location.state.nombre}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            this.state.agregarConciliacion && (
              <ButtonItem
                type="primary"
                key="agregarConciliacion"
                onClick={this.abrirAgregarConciliacion.bind(this)}
              >
                Agregar Conciliacion
              </ButtonItem>
            )
          }
          onBack={this.atras.bind(this)}
        />
        <TableItem           
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                if(record.estado === 2){
                  this.props.history.push("/panel/verConciliacion", { id: record._id, bancoId: this.props.location.state.id, nombre: this.props.location.state.nombre });
                }
              },
            };
          }} 
          size='small' loading={this.state.loading} dataSource={this.state.todasConciliaciones} columns={columns} />
        <AgregarConciliacion
              id={this.props.location.state.id}
            visible={this.state.modalAgregarConciliacion}
            cerrarAgregarConciliacion={this.cerrarAgregarConciliacion.bind(
                this
            )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Bancos);
