import React, {Component} from "react";
import {Input} from 'antd'

class TextAreItem extends Component{
    state={
        value: ''
    }
    render(){
        const style = {
            ...this.props.style,
            borderBottom: this.props.sinBorde ? null : '1px solid #DADADA',
            borderRadius: 0,
            width: this.props.width ? this.props.width : '100%',
        }
        return(
            <Input.TextArea {...this.props} style={style} bordered={false}/>
        )
    }
}

export default TextAreItem