import { Modal, Spin } from "antd";
import React, { Component } from "react";

class ModalItem extends Component {
  render() {
    return (
      <Modal
        {...this.props}
        footer={this.props.footer ? undefined : null}
        bodyStyle={
          this.props.paddingTop
            ? { padding: 0, paddingTop: 10 }
            : { padding: 0 }
        }
      >
        <Spin spinning={this.props.spinning} size="large">
          <div style={{ padding: "0 24px 5px 24px" }}>
            {this.props.children}
          </div>
        </Spin>
      </Modal>
    );
  }
}

export default ModalItem;
