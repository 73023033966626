import { Collapse, Row, Col, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ModalItem,
    ButtonItem,
} from "../../../../Components/Items";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MandarCorreoCartaPorte from "../MandarCorreoCartaPorte/MandarCorreoCartaPorte";
import EntregarEnvio from "../EntregarEnvio/EntregarEnvio";
import CancelarEnvio from "../CancelarEnvio/CancelarEnvio";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Panel } = Collapse;

class EnviosVenta extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        enviosHtml: <p>No se encontro ningun envio</p>,
        modalCancelarEnvio: false,
        modalCancelarEnvioId: null,
        modalVerCartaPorte: false,
        modalVerCartaPorteId: null,
        modalEntregarEnvio: false,
        modalEntregarEnvioId: null
    };
    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible)
            this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "envios/enviosVenta",
                { ventaId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.status === 404) return;
                const html = (
                    <Collapse style={{ marginBottom: 10 }}>
                        {res.data.map((valueEnvio) => {
                            return (
                                <Panel
                                    extra={
                                        valueEnvio.estado !== 0 ? valueEnvio.numero : (
                                            <Tag color="red">
                                                Cancelada {valueEnvio.numero}
                                            </Tag>
                                        )
                                    }
                                    header={moment(valueEnvio.createdAt).locale("es").format("ll")}
                                    key={valueEnvio._id}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <p>
                                                Origen:{" "}
                                                {valueEnvio.proyectoOrigen.nombre}
                                            </p>
                                            <p>
                                                Carta Porte:{" "}
                                                {valueEnvio.generarCarta ? "Si" : "No"}
                                            </p>
                                        </Col>
                                        <Col span={12}>
                                            <p>
                                                Destino:{" "}
                                                {(valueEnvio.calleDestino || '') + ' #' + (valueEnvio.numeroCalleDestino || '') + ' CP: ' + valueEnvio.coloniaDestino.cp}
                                            </p>
                                            <p>Transporte: {valueEnvio.proyectoTransporte.nombre}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            {(valueEnvio.estado === 1 || valueEnvio.estado === 2) ?
                                                <ButtonItem size="small" type="primary" onClick={() => {
                                                    this.setState({ modalEntregarEnvio: true, modalEntregarEnvioId: valueEnvio._id })
                                                }}>
                                                    Entregar Envio
                                                </ButtonItem>
                                                : null}
                                        </Col>
                                        <Col span={6}>
                                            <ButtonItem size="small" type="primary" onClick={() => this.props.history.push("/panel/verEnvio", { id: valueEnvio._id })}>
                                                Ir al Envio
                                            </ButtonItem>
                                        </Col>
                                        <Col span={6}>
                                            {valueEnvio.generarCarta && (
                                                <ButtonItem size="small" type="primary" blanco onClick={() => {
                                                    this.setState({ modalVerCartaPorte: true, modalVerCartaPorteId: valueEnvio._id })
                                                }}>
                                                    Ver Carta Porte
                                                </ButtonItem>
                                            )}
                                        </Col>
                                        <Col span={6}>
                                            {valueEnvio.estado !== 0 && (
                                                <ButtonItem size="small" type="primary" danger onClick={() => {
                                                    this.setState({ modalCancelarEnvio: true, modalCancelarEnvioId: valueEnvio._id })
                                                }}>
                                                    Cancelar Envio
                                                </ButtonItem>
                                            )}
                                        </Col>
                                    </Row>
                                </Panel>
                            );
                        })}
                    </Collapse>
                );
                this.setState({ enviosHtml: html });
            });
        this.setState({ loading: false });
    }
    cerrarEnviosVenta() {
        this.props.cerrarEnviosVenta();
    }
    cerrarCancelarEnvio() {
        this.setState({
            modalCancelarEnvio: false,
            modalCancelarEnvioId: null,
        });
        this.consultarTodo()
    }
    cerrarMandarCorreoCartaPorte() {
        this.setState({
            modalVerCartaPorte: false,
            modalVerCartaPorteId: null,
        });
        this.consultarTodo()
    }
    cerrarEntregarEnvio() {
        this.setState({
            modalEntregarEnvio: false,
            modalEntregarEnvioId: null,
        });
        this.consultarTodo()
    }
    render() {
        return (
            <>
                <ModalItem
                    title="Envios Venta"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarEnviosVenta.bind(this)}
                    paddingTop
                >
                    {this.state.enviosHtml}
                </ModalItem>
                <MandarCorreoCartaPorte
                    visible={this.state.modalVerCartaPorte}
                    id={this.state.modalVerCartaPorteId}
                    history={this.props.history}
                    cerrarMandarCorreoCartaPorte={this.cerrarMandarCorreoCartaPorte.bind(this)}
                />
                <EntregarEnvio
                    visible={this.state.modalEntregarEnvio}
                    id={this.state.modalEntregarEnvioId}
                    history={this.props.history}
                    cerrarEntregarEnvio={this.cerrarEntregarEnvio.bind(this)}
                />
                                <CancelarEnvio
                    visible={this.state.modalCancelarEnvio}
                    id={this.state.modalCancelarEnvioId}
                    history={this.props.history}
                    cerrarCancelarEnvio={this.cerrarCancelarEnvio.bind(this)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(EnviosVenta);
