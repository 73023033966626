import moment from 'moment'
import 'moment/locale/es'
import {cuentaMask, dineroDisabledMask} from '../Components/Items'

moment.locale('es')

const reporteDeEstadoDePosicionFinancieraPdf = (values) => {
    const empresa = values.empresa

    let filtrados = []

    if(values.filtrarProyecto === 1){
        let textoFiltrado = ''
        values.proyectos.map((valueProyectos) => {
            textoFiltrado += `${valueProyectos.nombre}\n`
            return null
        })
        filtrados.push([
            {text: 'Proyectos:', style: 'subheader'},
            
            {text: textoFiltrado, style: 'textoChico'}
          ])
    }

    if(values.filtrarProyecto === 2){
        filtrados.push([
            {text: 'Unidad de Negocio:', style: 'subheader'},
            
            {text: `${cuentaMask(
                values.unidadDeNegocio.nivel1 + values.unidadDeNegocio.nivel2 + values.unidadDeNegocio.nivel3 + values.unidadDeNegocio.nivel4 +values.unidadDeNegocio.nivel5
              ) +
                " - " +
                values.unidadDeNegocio.nombre}`, style: 'textoChico'}
          ])
    }

    if(filtrados.length === 0){
        filtrados.push([
            {text: 'No tiene filtrados adicionales', style: 'subheader'},

            {text: '', style: 'textoChico'}
      ] )
    }

    let totalActivo = 0
    let totalPasivo = 0
    let totalCapital = 0
    let activoTable = values.arrayActivo.map(valueActivo => {
        if(valueActivo.nivel === 1){
            totalActivo += valueActivo.saldo
        }
        return [
            {text: cuentaMask(valueActivo.nivel1+valueActivo.nivel2+valueActivo.nivel3+valueActivo.nivel4+valueActivo.nivel5), style: valueActivo.titulo ? 'tituloCuentasMayor' : valueActivo.titulo2 ? 'tituloCuentasMenor' : 'textoChico', alignment: 'left'}, 
            {text: valueActivo.descripcion, style: valueActivo.titulo ? 'tituloCuentasMayor' : valueActivo.titulo2 ? 'tituloCuentasMenor' : 'textoChico', alignment: 'left'}, 
            {text: dineroDisabledMask(valueActivo.saldo), style: valueActivo.titulo ? 'tituloCuentasMayor' : valueActivo.titulo2 ? 'tituloCuentasMenor' : 'textoChico', alignment: 'right'},
        ]
    })
    let pasivoTable = values.arrayPasivo.map(valuePasivo => {
        if(valuePasivo.nivel === 1){
            totalPasivo += valuePasivo.saldo
        }
        return [
            {text: cuentaMask(valuePasivo.nivel1+valuePasivo.nivel2+valuePasivo.nivel3+valuePasivo.nivel4+valuePasivo.nivel5), style:  valuePasivo.titulo ? 'tituloCuentasMayor' : valuePasivo.titulo2 ? 'tituloCuentasMenor' : 'textoChico', alignment: 'left'}, 
            {text: valuePasivo.descripcion, style: valuePasivo.titulo ? 'tituloCuentasMayor' : valuePasivo.titulo2 ? 'tituloCuentasMenor' : 'textoChico', alignment: 'left'}, 
            {text: dineroDisabledMask(valuePasivo.saldo), style: valuePasivo.titulo ? 'tituloCuentasMayor' : valuePasivo.titulo2 ? 'tituloCuentasMenor' : 'textoChico', alignment: 'right'},
        ]
    })
    let capitalTable = values.arrayCapital.map(valueCapital => {
        if(valueCapital.nivel === 1){
            totalCapital += valueCapital.saldo
        }
        return [
            {text: cuentaMask(valueCapital.nivel1+valueCapital.nivel2+valueCapital.nivel3+valueCapital.nivel4+valueCapital.nivel5), style: valueCapital.titulo ? 'tituloCuentasMayor' : valueCapital.titulo2 ? 'tituloCuentasMenor' : 'textoChico', alignment: 'left'}, 
            {text: valueCapital.descripcion, style: valueCapital.titulo ? 'tituloCuentasMayor' : valueCapital.titulo2 ? 'tituloCuentasMenor' : 'textoChico', alignment: 'left'}, 
            {text: dineroDisabledMask(valueCapital.saldo), style: valueCapital.titulo ? 'tituloCuentasMayor' : valueCapital.titulo2 ? 'tituloCuentasMenor' : 'textoChico', alignment: 'right'},
        ]
    })
    var dd = {
        content: [
            {
                style: 'tableExample',
                table: {
                    widths: [ '18%', '52%', '30%'],
                    layout: {
                        defaultBorder: false,
                    },
                    body: [
                        [
                            {
    //							rowSpan: 3,
                                border: [false, false, false, false],
                                image: 'logo',
                                fit: [80, 80] 
                                //text: 'rowSpan: 3\n\nborder:\n[true, true, true, true]'
                            },
                            {
                            //	rowSpan: 2,
                                border: [false, false, false, false],
                                table: {
                                    headerRows: 1,
                                    widths: ['100%'],
                                
                                    body: [
                                      [
                                          { 
                                          text: empresa.persona ? empresa.nombre : empresa.nombre+' '+empresa.apellidoPaterno+' '+empresa.apellidoMaterno, 
                                          style: "tableHeader" 
                                          }
                                      ],
                                      [
                                        {
                                          text: "R.F.C: "+empresa.rfc,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                      [
                                          {
                                            text: empresa.calle+' '+
                                                  empresa.numeroCalle+' '+
                                                  (empresa.numeroInterior ? empresa.numeroInterior : '')
                                            ,
                                            style: "textoChico",
                                            alignment: "center",
                                          },
                                        ],
                                      [
                                          {
                                            text: empresa.colonia.tipoAsentamiento+' '+
                                            empresa.colonia.nombre+' C.P. '+
                                            empresa.colonia.cp+' '+
                                            empresa.municipio.nombre+' '+
                                            empresa.estado.abreviatura+' '+
                                            empresa.pais.abreviatura+' '
                                            ,
                                            style: "textoChico",
                                            alignment: "center",
                                          },
                                        ],
                                        [{text: 'ESTADO DE POSICION FINANCIERA', style: 'tableHeader'}],
                                    ]
                                },
                                layout: 'noBorders'
                            },
                            {
                                border: [false, false, false, false],
                                    table: {
                                    headerRows: 1,
                                    widths: ['100%'],
                                
                                    body: [
                                        [{text: 'Fecha Final:', style: 'subheader', alignment: 'center'}],
                                        [{text: moment(values.fechaFinal).locale('es').format('LL'), style: 'textoChico', alignment: 'center'}],
                                        [{text: 'Fecha de Impresión:', style: 'subheader', alignment: 'center'}],
                                        [{text: moment().locale('es').format('LL'), style: 'textoChico', alignment: 'center'}],
                                    ]
                                },
                                layout: 'noBorders'
                            },
                        ]
    
                    ]
                },
            },
            {text: 'Datos de filtrado:'},
            {
                style: 'tableExample',
                table: {
                    widths: ['20%', '80%'],
                    layout: {
                                        defaultBorder: false,
                                    },
                    
                    body: filtrados,
                },
                            layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    vLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return {dash: {length: 2, space: 1}};
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return {dash: {length: 2}};
                    },
                    // paddingLeft: function(i, node) { return 4; },
                    // paddingRight: function(i, node) { return 4; },
                    // paddingTop: function(i, node) { return 2; },
                    // paddingBottom: function(i, node) { return 2; },
                    // fillColor: function (i, node) { return null; }
                }
                
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['100%'],
                    layout: {
                        defaultBorder: false,
                     },
                    body: [
                            [
                              {text: 'ESTADO DE POSICION FINANCIERA', alignment: 'center', fontSize: 16, border: [false, false, false, false]}
                            ]
                       ]
                },
                            layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    vLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return {dash: {length: 2, space: 1}};
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return {dash: {length: 2}};
                    },
                }
                
            },
            
            {
                columns: [
                    {
                        width: '49.5%',
                        table: {
                            widths: ['20%', '50%', '30%'],
                            layout: {
                                defaultBorder: true,
                            },
                            body: [
                                [
                                    {colSpan: 3, text: 'ACTIVO', style: 'tituloTabla1'},
                                    {},
                                    {}
                                ],
                                [
                                  {text: 'Cuenta', style: 'tituloTabla'},
                                  {text: 'Descripción', style: 'tituloTabla'},
                                  {text: 'Importe', style: 'tituloTabla'},
                                ],
                                ...activoTable
                            ]
                        },
                        layout: 'noBorders'
                    },
                    {
                        width: '1%',
                        text: ''
                    },
                        {
                        width: '49.5%',
                        table: {
                            widths: ['20%', '50%', '30%'],
                            layout: {
                                defaultBorder: true,
                            },
                            body: [
                                [
                                    {colSpan: 3,text: 'PASIVO', style: 'tituloTabla1'},
                                    {},
                                    {}
                                ],
                                [
                                  {text: 'Cuenta', style: 'tituloTabla'},
                                  {text: 'Descripción', style: 'tituloTabla'},
                                  {text: 'Importe', style: 'tituloTabla'},
                                ],
                                ...pasivoTable,
                                [
                                    {border: [false,false, false,false], text: 'Suma del Pasivo', style: 'subheader', colSpan: 2},
                                    {border: [false,false, false,false], text: '', style: 'textoChico',},
                                    {border: [false,true, false,false], text: dineroDisabledMask(totalPasivo), style: 'textoChico', alignment: 'right'}
                                ],
                                [
                                    {colSpan: 3,text: 'CAPITAL', style: 'tituloTabla1'},
                                    {},
                                    {}
                                ],
                                [
                                  {text: 'Cuenta', style: 'tituloTabla'},
                                  {text: 'Descripción', style: 'tituloTabla'},
                                  {text: 'Importe', style: 'tituloTabla'},
                                ],
                                ...capitalTable,
                                [
                                    {border: [false,false, false,false], text: 'Suma del Capital', style: 'subheader', colSpan: 2},
                                    {border: [false,false, false,false], text: '', style: 'textoChico',},
                                    {border: [false,true, false,false], text: dineroDisabledMask(totalCapital), style: 'textoChico', alignment: 'right'}
                                ]
                            ]
                        },
                        layout: 'noBorders'
                    }
                ],
                style: 'tableExample',
            },
            {
                alignment: 'right',
              table: {
                  widths: ['10%', '19.5%', '20%', '1%', '10%', '19.5%', '20%'],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                      [
                        {border: [false,false, false,false], text: 'Suma del Activo:', style: 'subheader', colSpan: 2},
                        {border: [false,false, false,false], text: '', style: 'subheader',},
                        {border: [false,true, false,false], text: dineroDisabledMask(totalActivo), style: 'subheader',},
                        {border: [false,false, false,false], text: '', style: 'subheader',},
                        {border: [false,false, false,false], text: 'Suma del Pasivo y Capital:', style: 'subheader', colSpan: 2},
                        {border: [false,false, false,false], text: '', style: 'textoChico',},
                        {border: [false,true, false,false], text: dineroDisabledMask(totalPasivo+totalCapital), style: 'textoChico', alignment: 'right'}
                      ],
                  ]
              },
            },
        ],
        images: {
            logo: empresa.logo.base64Completa,
        },
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 8,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 5]
            },
            tablaSinMargen: {
                margin: [0, 0, 0, 0]
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black',
                alignment: 'center'
            },
            textoChico:{
                fontSize: 8
            },
            tituloTabla:{
                fontSize: 8,
                bold: true, 
                fillColor: '#cccccc', 
                alignment: 'center',
            },
            tituloCuentasMayor: {
                fontSize: 8,
                bold: true, 
                fillColor: '#E0E0E0', 
                alignment: 'center',
            },
            tituloCuentasMenor: {
                fontSize: 8,
                bold: true, 
                fillColor: '#F9F9F9', 
                alignment: 'center',
            },
            tituloTabla1:{
                fontSize: 12,
                bold: true, 
                alignment: 'center',
            },
            textoTabla: {
                fontSize: 8,
                italics: false
            }
        },
        pageSize: 'LETTER',
    }
  return dd;
};


export default reporteDeEstadoDePosicionFinancieraPdf;
