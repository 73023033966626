import { LOGIN, SIGNUP, LOGOUT, HASSIGNED } from "../actions/user.js";

const initialState = {
  isUserLoggedIn: false,
  tiempoSesion: 600000,
  usuarioId: null,
  usuarioPrincipal: null,
  nombre: null,
  tipo: null,
  permisos: [],
  headersToken: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP:
      return {
        isUserLoggedIn: true,
        tiempoSesion: action.data.tiempoSesion,
        usuarioId: action.data.usuarioId,
        usuarioPrincipal: action.data.usuarioPrincipal,
        nombre: action.data.nombre,
        tipo: action.data.tipo,
        permisos: action.data.permisos,
        headersToken: {
          "Content-Type": "application/json",
          "Authorization": action.data.jwt,
        },
      };

    case LOGIN:
      return {
        isUserLoggedIn: true,
        tiempoSesion: action.data.tiempoSesion,
        usuarioId: action.data.usuarioId,
        usuarioPrincipal: action.data.usuarioPrincipal,
        nombre: action.data.nombre,
        tipo: action.data.tipo,
        permisos: action.data.permisos,
        headersToken: {
          "Content-Type": "application/json",
          "Authorization": action.data.jwt,
        },
      };

      case HASSIGNED:
        return {
          isUserLoggedIn: true,
          tiempoSesion: action.data.tiempoSesion,
          usuarioId: action.data.usuarioId,
          usuarioPrincipal: action.data.usuarioPrincipal,
          nombre: action.data.nombre,
          tipo: action.data.tipo,
          permisos: action.data.permisos,
          headersToken: {
            "Content-Type": "application/json",
            "Authorization": action.data.jwt,
          },
        };

    case LOGOUT:
      return {
        isUserLoggedIn: false,
        tiempoSesion: 600000,
        usuarioId: null,
        usuarioPrincipal: null,
        nombre: null,
        tipo: null,
        permisos: [],
        headersToken: null,
      };

    default:
      break;
  }
  return state;
};

export default reducer;
