import { message, Space, notification, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalItem,
  ButtonItem,
  Form,
  FormItem,
  InputItem,
  SelectItem
} from "../../../../Components/Items";

const {Option} = Select

class CancelarVenta extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pdf: null,
  };
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "ventas/cancelarVenta",
        { ...values, ventaId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
          if (res.data.success === 2) {
              message.success("Venta cancelada");
            } else if (res.data.success === 1) {
                notification.error({
                    message: "Error",
                    description: res.data.message,
                    duration: null,
                });
            } else {
                message.error("Sucedio un eror y no se pudo cancelar");
            }
            this.formRef.current.resetFields();
        this.setState({ loading: false });
        this.cerrarCancelarVenta();
      });
  }
  cerrarCancelarVenta() {
    this.props.cerrarCancelarVenta();
  }
  render() {
    return (
      <ModalItem
        title="Cancelar Venta"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarCancelarVenta.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="cancelarVenta"
          layout
          width={700}
          onCancel={this.cerrarCancelarVenta.bind(this)}
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem label="Motivo" name="motivo" required pattern margin max={200}>
          <SelectItem placeholder="Motivo">
                <Option value='01'>01 - Comprobante emitido con errores con relación.</Option>
                <Option value='02'>02 - Comprobante emitido con errores sin relación.</Option>
                <Option value='03'>03 - No se llevó a cabo la operación.</Option>
                <Option value='04'>04 - Operación nominativa relacionada en una factura global.</Option>
            </SelectItem>
          </FormItem>
          <FormItem label="Folio Sustitucion" name="folioSustitucion" pattern margin max={200}>
            <InputItem placeholder="Folio de Sustitucion" />
          </FormItem>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            <Space>
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Cancelar Venta
              </ButtonItem>
              <ButtonItem
                type="primary"
                danger
                onClick={this.cerrarCancelarVenta.bind(this)}
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Regresar
              </ButtonItem>
            </Space>
          </div>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(CancelarVenta);
