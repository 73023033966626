const CuentaMask = (event) => {
  let newVal = event.replace(/\D/g, "");
  if (newVal.length === 0) {
    newVal = "";
  } else if (newVal.length <= 3) {
    newVal = newVal.replace(/^(\d{0,3})/, "$1");
  } else if (newVal.length <= 5) {
    newVal = newVal.replace(/^(\d{0,3})(\d{0,2})/, "$1-$2");
  } else if (newVal.length <= 8) {
    newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,3})/, "$1-$2-$3");
  } else if (newVal.length <= 11) {
    newVal = newVal.replace(
      /^(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,3})/,
      "$1-$2-$3-$4"
    );
  } else if (newVal.length <= 14) {
    newVal = newVal.replace(
      /^(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/,
      "$1-$2-$3-$4-$5"
    );
  } else {
    newVal = newVal.substring(0, 14);
    newVal = newVal.replace(
      /^(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/,
      "$1-$2-$3-$4-$5"
    );
  }
  return newVal;
};

export default CuentaMask;
