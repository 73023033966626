import React, { Component } from "react";
import {
  Card,
  Spin,
  Avatar,
  PageHeader,
  Button,
  notification,
  message,
} from "antd";
import AgregarEmpresa from "./AgregarEmpresa/AgregarEmpresa";
import axios from "axios";
import { connect } from "react-redux";
import { seleccionarEmpresa } from "../../../store/actions/empresa";
import AgregarEmpresaBasica from "./AgregarEmpresaBasica/AgregarEmpresaBasica";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const { Meta } = Card;

class Empresas extends Component {
  state = {
    todasEmpresas: null,
    objetoEmpresas: null,
    modalAgregarEmpresa: false,
    modalAgregarEmpresaBasica: false,
    loading: false,
  };

  componentDidMount() {
    this.consultarEmpresas();
  }

  logout() {
    this.props.logout().then(() => {
      this.props.history.push("/login");
    });
  }

  seleccionarEmpresa(id, nombre, rfc, logo, certificada, fechaVencimiento, certificadaDM, envios, complementoDetallista, marbetes) {
    if(moment(fechaVencimiento) < moment()){
      return notification.warning({
        message: 'No se puede utilizar esta empresa por falta de pago',
        description: 'Su tiempo vencio dirigase a facturacion para completar el pago.'
      })
    }else{
      this.props
      .seleccionarEmpresa({ id, nombre, rfc, logo, certificada, certificadaDM, envios, complementoDetallista, marbetes })
      .then((data) => {
        this.setState({ loading: false });
        this.props.history.push("/panel/inicio");
        message.success(nombre);
      })
      .catch((err) => {
        this.setState({ loading: false });
        notification.error({
          message: "Error inesperado",
          description: err.toString(),
        });
      });
    }
  }

  consultarEmpresas() {
    this.setState({ loading: true });
    axios
      .post(
        "empresas/selectEmpresas",
        { usuarioPrincipal: this.props.usuarioPrincipal },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          this.setState({ todasEmpresas: res.data });
          this.setState({
            objetoEmpresas: res.data.map((value) => {
              let logo;
              if (value.logo) {
                logo = value.logo.base64Completa;
              } else {
                logo =
                  "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png";
              }
              let logoCover;
              if (value.logo) {
                logoCover = value.logo.base64Completa;
              } else {
                logoCover =
                  "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png";
              }
              return (
                <Card
                  key={value._id}
                  id="card"
                  onClick={() =>
                    this.seleccionarEmpresa(
                      value._id,
                      value.nombre,
                      value.rfc,
                      value.logo,
                      value.certificada,
                      value.fechaVencimiento,
                      value.certificadaDM,
                      value.envios,
                      value.complementoDetallista,
                      value.marbetes
                    )
                  }
                  cover={<img alt="Empresa" style={{maxHeight: 200, objectFit: 'contain', padding: 40}} src={logoCover} />}
                >
                  <Meta
                    avatar={<Avatar shape="square" src={logo} />}
                    title={value.nombre}
                    description={value.rfc}
                  />
                </Card>
              );
            }),
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: true });
        notification.error({
          message: "Error inesperado",
          description: err.toString(),
        });
      });
  }

  abrirAgregarEmpresa() {
    this.setState({ modalAgregarEmpresa: true });
  }

  cerrarAgregarEmpresa() {
    this.setState({ modalAgregarEmpresa: false });
    this.consultarEmpresas();
  }

  abrirAgregarEmpresaBasica() {
    this.setState({ modalAgregarEmpresaBasica: true });
  }

  cerrarAgregarEmpresaBasica() {
    this.setState({ modalAgregarEmpresaBasica: false });
    this.consultarEmpresas();
  }

  render() {
    const extra = this.props.tipo === 0 ? (
      <>
      {/* Agregar en momento esperado */}
    <Button
      key="agregarEmpresaBasica"
      type="primary"
      onClick={this.abrirAgregarEmpresaBasica.bind(this)}
    >
      Agregar Empresa Basica
    </Button>
    <Button
      key="agregarEmpresa"
      onClick={this.abrirAgregarEmpresa.bind(this)}
    >
      Agregar Empresa
    </Button>

      </>
    ): null
    return (
      <>
          <PageHeader
            title="Empresas"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={extra}
          />
          <Spin spinning={false}>{this.state.objetoEmpresas}</Spin>
        <AgregarEmpresa
          visible={this.state.modalAgregarEmpresa}
          cerrarAgregarEmpresa={this.cerrarAgregarEmpresa.bind(this)}
        />
                <AgregarEmpresaBasica
          visible={this.state.modalAgregarEmpresaBasica}
          cerrarAgregarEmpresaBasica={this.cerrarAgregarEmpresaBasica.bind(this)}
        />
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    usuarioId: state.user.usuarioId,
    usuarioPrincipal: state.user.usuarioPrincipal,
    tipo: state.user.tipo,
    empresaId: state.empresa.id,
    rfc: state.empresa.rfc,
    nombre: state.empresa.nombre,
    logo: state.empresa.logo,
  };
};

export default connect(mapStateToProps, { seleccionarEmpresa })(
  Empresas
);
