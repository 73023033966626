import { PageHeader, Space } from "antd";
import Button from "antd/es/button";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {cuentaMask, TableItem} from "../../../Components/Items";
import AgregarCuentaContable from "./AgregarCuentaContable/AgregarCuentaContable";
import EditarCuentaContable from "./EditarCuentaContable/EditarCuentaContable";

class CuentasContables extends Component {
  state = {
    todasCuentasContables: [],
    modalAgregarCuentaContable: false,
    modalEditarCuentaContable: false,
    modalEditarCuentaContableId: null,
    loading: false
  };
  componentDidMount() {
    this.consultarCuentasContables();
  }
  consultarCuentasContables() {
    this.setState({loading: true})
    axios
      .post(
        "cuentascontables/selectTodasCuentasContables",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({loading: false})
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasCuentasContables: res.data.map((value) => {
              return {
                ...value,
                codigo: cuentaMask(
                  value.nivel1 +
                    value.nivel2 +
                    value.nivel3 +
                    value.nivel4 +
                    value.nivel5
                ),
                accion: { _id: value._id, estado: value.estado },
              };
            }),
          });
        }
      });
  }
  abrirAgregarCuentaContable() {
    this.setState({ modalAgregarCuentaContable: true });
  }
  cerrarAgregarCuentaContable() {
    this.setState({ modalAgregarCuentaContable: false });
    this.consultarCuentasContables();
  }
  abrirEditarCuentaContable(id) {
    this.setState({ modalEditarCuentaContable: true, modalEditarCuentaContableId: id  });
  }
  cerrarEditarCuentaContable() {
    this.setState({ modalEditarCuentaContable: false, modalEditarCuentaContableId: null });
    this.consultarCuentasContables();
  }
  render() {
    const columns = [
      {
        title: "Codigo",
        dataIndex: "codigo",
        key: "codigo",
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarCuentaContable && 
              <Button type='primary' onClick={() => this.abrirEditarCuentaContable(value._id)} size='small'>
                Editar
              </Button>
            }
          </Space>
        ),
      },
    ];
    return (
      <>
        <PageHeader
          title="Cuentas Contables"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarCuentaContable &&
            <Button
              type="primary"
              key="agregarCuentaContable"
              onClick={this.abrirAgregarCuentaContable.bind(this)}
            >
              Agregar Cuenta Contable
            </Button>
          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasCuentasContables} columns={columns} pagination={false} />
        <AgregarCuentaContable
          visible={this.state.modalAgregarCuentaContable}
          cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(
            this
          )}
        />
                <EditarCuentaContable
          visible={this.state.modalEditarCuentaContable}
          id={this.state.modalEditarCuentaContableId}
          cerrarEditarCuentaContable={this.cerrarEditarCuentaContable.bind(
            this
          )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarCuentaContable: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cda85f30e81b54183cf9d")?.activado,
    permisoEditarCuentaContable: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce731f30e81b54183cfb5")?.activado,
  };
};

export default connect(mapStateToProps)(CuentasContables);
