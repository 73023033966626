import { message, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  InputNumberItem,
  SelectItem,
  ButtonItem,
} from "../../../../Components/Items";

const { Option } = Select;

class EditarControlInventario extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    objetoProyectos: null,
    todasProyectos: null,
    objetoInsumos: null,
    todasInsumos: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
    .post(
      "proyectos/selectProyectos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasProyectos: res.data });
        this.setState({
          objetoProyectos: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre}
              </Option>
            );
          }),
        });
      }
    });
    await axios
    .post(
      "insumos/selectInsumosInventariables",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasInsumos: res.data });
        this.setState({
          objetoInsumos: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.stringBuscar}
              </Option>
            );
          }),
        });
      }
    });
    await axios
    .post(
      "controlInventario/datosControl",
      { empresaId: this.props.empresaId, controlId: this.props.id },
      { headers: this.props.headersToken }
    )
    .then((res) => {
      if (res.data.status === 404) {
        message.error("Error no se encontro informacion del control");
      } else {
        this.formRef.current.setFieldsValue({
          ...res.data,
        });
      }
    });
    this.setState({ loading: false });
  }
  cerrarEditarControlInventario(codigo) {
    this.props.cerrarEditarControlInventario(codigo);
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
            e.errorFields[index].errors[0] +
            " en la fila " +
            (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "controlInventario/editarControlInventario",
        { ...values, controlId: this.props.id, empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se edito un control de inventario");
          this.formRef.current.resetFields();
          this.cerrarEditarControlInventario();
        } else if(res.data.success === 1) {
            message.error(res.data.message);
        } else {
          message.error("Sucedio un error y no se guardo");
        }
      });
  }
  render() {
    return (
      <>
      <ModalItem
        title="Editar Control Inventario"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarEditarControlInventario.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="EditarControlInventario"
          layout
          bottom={
            <ButtonItem
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Editar
            </ButtonItem>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormItem label="Proyecto" name="proyectoId" required margin>
            <SelectItem disabled placeholder="Proyecto">
              {this.state.objetoProyectos}
            </SelectItem>
          </FormItem>
          <FormItem label="Insumo" name="insumoId" required margin>
            <SelectItem disabled placeholder="Insumo">
              {this.state.objetoInsumos}
            </SelectItem>
          </FormItem>
          <FormItem
            label="Mín"
            name="min"
            margin
            required
            numberCero
        >
            <InputNumberItem placeholder="Mín" />
        </FormItem>
          <FormItem shouldUpdate noStyle>
            {({getFieldValue}) => {
                const min = getFieldValue('min')
                return (
                    <FormItem
                    label="Máx"
                    name="max"
                    margin
                    required
                    type='number'
                    gt={min}
                    numberCero
                >
                    <InputNumberItem placeholder="Máximo" />
                </FormItem>
                )
            }}
          </FormItem>
        </Form>
      </ModalItem>
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(EditarControlInventario);
