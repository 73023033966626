import { Button, message, Row, Col, Checkbox, Tabs } from "antd";
import axios from "axios";
import { Divider } from "rc-menu";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  TableItem,
  InputItem,
} from "../../../../Components/Items";

const { TabPane } = Tabs;

class AgregarPerfil extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasPermisos: null,
    objetoPermisos: null,
    permisosValues: [],
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  cerrarAgregarPerfil(codigo) {
    this.props.cerrarAgregarPerfil(codigo);
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post("permisos/selectPermisosPGrupos", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          let permisosValues = [];
          res.data.map((valueGrupo) => {
            valueGrupo.permisos.map((valuePermiso) => {
              const id = valuePermiso._id.toString();
              permisosValues.push({ permisoId: id, activado: false });
              return null;
            });
            return null;
          });
          this.setState({ permisosValues, todasPermisos: res.data }, () => {
              //Tabla
              let columns = [
                {
                  title: "Subgrupo",
                  dataIndex: "subgrupo",
                  key: "subgrupo",
                  render: (value) => value.nombre,
                },
                {
                  title: "Permiso",
                  dataIndex: "nombre",
                  key: "nombre",
                },
                {
                  title: "Activar",
                  dataIndex: "_id",
                  key: "_id",
                  render: (text) => {
                    const id = text.toString();
                    const index = this.state.permisosValues.findIndex(valueA => valueA.permisoId === id)
                    const onChange = (e) => {
                        let nuevoArray = [...this.state.permisosValues]
                        nuevoArray[index].activado = e.target.checked
                      this.setState({
                        permisosValues: nuevoArray,
                      });
                    };
                    return (
                      <Checkbox
                        value={this.state.permisosValues[index].activado}
                        defaultChecked={this.state.permisosValues[index].activado}
                        onChange={onChange}
                      />
                    );
                  },
                },
              ];
              this.setState({
                permisosObject: res.data.map((value) => {
                  return (
                    <TabPane tab={value.nombre} key={value.grupoId} forceRender>
                      {value.permisos.length > 0 ? (
                        <>
                          <TableItem
                            pagination={false}
                            size="small"
                            columns={columns}
                            dataSource={value.permisos}
                          />
                        </>
                      ) : (
                        <p>No se encontraron permisos para este grupo</p>
                      )}
                    </TabPane>
                  );
                }),
              });
          });

        }
      });
      this.setState({ loading: false });
  }
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "perfiles/agregarPerfil",
        { ...values, permisos: this.state.permisosValues,usuarioPrincipal: this.props.usuarioPrincipal },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success("Se ha registrado un perfil");
          this.formRef.current.resetFields();
          this.setState({
            objetoPermisos: null,
            todasPermisos: null,
            permisosObject: null
          }, () => {
            this.setState({
              loading: false,
              permisosValues: [],
            })
          })
          this.cerrarAgregarPerfil(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se puede registrar el perfil");
        } else {
          message.error("Hubo un error y el perfil no se registro");
        }
      });
  }
  cerrarModalAgregarPerfil(){
    this.formRef.current.resetFields();
    this.setState({
      objetoPermisos: null,
      todasPermisos: null,
      permisosObject: null
    }, () => {
      this.setState({
        loading: false,
        permisosValues: [],
      })
    })
    this.cerrarAgregarPerfil();
  }
  render() {
    return (
      <>
        <ModalItem
          title="Agregar Perfil"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.cerrarModalAgregarPerfil.bind(this)}
          paddingTop
          width="70%"
        >
          <Form
            ref={this.formRef}
            name="agregarPerfil"
            layout
            bottom={
              <Button
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Agregar
              </Button>
            }
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
            <Row>
              <Col span={12}>
                <FormItem
                  name="nombre"
                  label="Nombre"
                  required
                  max={100}
                  margin
                  pattern
                >
                  <InputItem placeholder="Nombre" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  name="descripcion"
                  label="Descripcion"
                  required
                  max={100}
                  margin
                  pattern
                >
                  <InputItem placeholder="Descripcion" />
                </FormItem>
              </Col>
            </Row>
            <Divider
              style={{ marginBottom: 10, marginTop: 5 }}
              children="Permisos"
            />
            <Row>
              <Col span={24}>
                {this.state.permisosObject ? (
                  <Tabs>{this.state.permisosObject}</Tabs>
                ) : null}
              </Col>
            </Row>
          </Form>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    usuarioPrincipal: state.user.usuarioPrincipal,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarPerfil);
