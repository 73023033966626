import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import userReducer from "./reducers/user";
import empresaReducer from "./reducers/empresa";
import ReduxThunk from "redux-thunk";

const rootReducer = combineReducers({
  user: userReducer,
  empresa: empresaReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(ReduxThunk))
);

export default store;
