import { PageHeader } from "antd";
import React, { Component } from "react";
import axios from 'axios'
import { cuentaMask, dineroDisabledMask, TableItem} from '../../../Components/Items'
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");


class CargosAbonos extends Component {
  state = {
    todasCargoAbono: [],
    loading: false
  }
  componentDidMount() {
    this.consultarCargosAbonos();
  }

  consultarCargosAbonos() {
    this.setState({loading: true})
    axios
    .post(
      "cargosabonos/selectCargosAbonos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
      } else {
        let cargosTotal = 0
        let abonosTotal = 0
        this.setState({
          todasCargoAbono: [...res.data.map(value => {
              let cargos = 0
              let abonos = 0
              if(value.cargo){
                  cargos = value.monto
                  cargosTotal += value.monto
              }else{
                  abonos = value.monto
                  abonosTotal += value.monto
              }
            return {...value, cargos, abonos, poliza: {poliza: value.poliza, tipodepoliza: value.tipodepoliza}}
          }), {proyecto: {nombre: 'Total: '}, cuentacontable: {nivel1: '', nivel2: '', nivel3: '', nivel4: '', nivel5: '', descripcion: 'Total: '},
              cargos: cargosTotal, abonos: abonosTotal, poliza: undefined, concepto: 'total'}],
        });
      }
    });
  }
  render() {
    const columns = [
      {
        title: "Cuenta",
        dataIndex: "cuentacontable",
        key: "cuentacontable",
        render: (value) => cuentaMask(value.nivel1+value.nivel2+value.nivel3+value.nivel4+value.nivel5),
        width: '10%'
      },
      {
        title: "Descripcion",
        dataIndex: "cuentacontable",
        key: "cuentacontable",
        render: (value) => value.descripcion,
        width: '20%'
      },
      {
        title: "Fecha",
        dataIndex: "fechaRegistro",
        key: "fechaRegistro",
        sorter: (a, b) => {
          if (moment(a.fechaRegistro) < moment(b.fechaRegistro)) {
            return -1;
          }
          if (moment(a.fechaRegistro) > moment(b.fechaRegistro)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale('es').format('L'),
        width: '10%',
      },
      {
        title: "Cargo",
        dataIndex: "cargos",
        key: "cargos",
        render: (value) => value === 0 ? '' : dineroDisabledMask(value),
        width: '10%'
      },
      {
        title: "Abono",
        dataIndex: "abonos",
        key: "abonos",
        render: (value) => value === 0 ? '' : dineroDisabledMask(value),
        width: '10%'
      },
      {
        title: "Poliza",
        dataIndex: "poliza",
        key: "poliza",
        render: (value) => {
          if(value){
            let ied = ''
            if(value.tipodepoliza.tipoDePolizaId === 1)ied = 'Ingreso'
            if(value.tipodepoliza.tipoDePolizaId === 2)ied = 'Egreso'
            if(value.tipodepoliza.tipoDePolizaId === 3)ied = 'Traslado'
            if(value.tipodepoliza.tipoDePolizaId === 4)ied = 'Nomina'
            if(value.tipodepoliza.tipoDePolizaId === 5)ied = 'Pago'
            if(value.tipodepoliza.tipoDePolizaId === 6)ied = 'Diario'
            return value.tipodepoliza.descripcion+': '+value.poliza.numeroTipoPoliza+' - '+ied+': '+value.poliza.numeroTipoTipoPoliza
          }
        },
        width:'20%'
      },
      {
        title: "Concepto",
        dataIndex: "concepto",
        key: "concepto",
        width:'20%'
      }
    ]
    return (
      <>
        <PageHeader
          title="Cargos Abonos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <TableItem rowClassName={(record, index) => {
          if(index % 2 === 0){
            return 'zebra-gris'
          }
        }} size='small' loading={this.state.loading} dataSource={this.state.todasCargoAbono} columns={columns} pagination={false} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(CargosAbonos);