import { message, PageHeader, Select, Space } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { SelectItem, TableItem, dineroDisabledMask } from "../../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { EyeOutlined, FilePdfOutlined } from "@ant-design/icons";
import VerFacturasReposicion from "../VerFacturasReposicion/VerFacturasReposicion";
import VerReposicionCajaChica from "../VerReposicionCajaChica/VerReposicionCajaChica";

moment.locale("es");

const { Option } = Select;

class VerPagosCajaChica extends Component {
    state = {
        loading: false,
        objetoCajaYEfectivo: [],
        todasCajaYEfectivo: [],
        todasReposicionesCajaChica: [],
        cajaChicaId: this.props.location.state.cajaChicaId ? this.props.location.state.cajaChicaId : 0,
        insumoId: this.props.location.state.id,
        modalVerFacturasReposicion: false,
        modalVerFacturasReposicionId: null,
        modalVerReposicionCajaChica: false,
        modalVerReposicionCajaChicaId: null
    };

    async componentDidMount() {
        this.setState({ loading: true });
        await axios
            .post(
                "cajaefectivo/selectTodosCajaEfectivo",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({ todasCajaYEfectivo: res.data });
                    this.setState({
                        objetoCajaYEfectivo: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                    });
                }
            });
        await axios
            .post(
                "cajaefectivo/selectReposicionesCajaChica",
                { empresaId: this.props.empresaId, cajaChicaId: this.state.cajaChicaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success !== 2) {
                    this.setState({ todasReposicionesCajaChica: [] })
                } else {
                    this.setState({
                        todasReposicionesCajaChica: res.data.pagos
                    });
                }
                this.setState({ loading: false });
            });
        message.info('Se muestran las reposiciones de 3 meses. Si quisiera ver mas dirigase a reportes')
        this.setState({ loading: false });
    }

    async onChangeCajaChica(cajaChicaId) {
        this.setState({ loading: true })
        await axios
            .post(
                "cajaefectivo/selectReposicionesCajaChica",
                { empresaId: this.props.empresaId, cajaChicaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success !== 2) {
                    this.setState({ todasReposicionesCajaChica: [] })
                } else {
                    this.setState({
                        todasReposicionesCajaChica: res.data.pagos
                    });
                }
                this.setState({ loading: false, cajaChicaId });
            });

    }

    abrirVerFacturasReposicion(value) {
        this.setState({ modalVerFacturasReposicion: true, modalVerFacturasReposicionId: value });
    }
    cerrarVerFacturasReposicion() {
        this.setState({
            modalVerFacturasReposicion: false,
            modalVerFacturasReposicionId: null,
        });
        this.onChangeCajaChica(this.state.cajaChicaId);
    }

    abrirVerReposicionCajaChica(value) {
        this.setState({ modalVerReposicionCajaChica: true, modalVerReposicionCajaChicaId: value });
    }
    cerrarVerReposicionCajaChica() {
        this.setState({
            modalVerReposicionCajaChica: false,
            modalVerReposicionCajaChicaId: null,
        });
        this.onChangeCajaChica(this.state.cajaChicaId);
    }

    render() {
        const columns = [
            {
                title: "Caja Chica",
                dataIndex: "cajaChica",
                key: "cajaChica",
                sorter: (a, b) => {
                    if (a.cajaChica < b.cajaChica) {
                        return -1;
                    }
                    if (a.cajaChica > b.cajaChica) {
                        return 1;
                    }
                    return 0;
                },
                buscar: "cajaChica",
                ellipsis: true,
            },
            {
                title: "Banco",
                dataIndex: "banco",
                key: "banco",
                sorter: (a, b) => {
                    if (a.banco < b.banco) {
                        return -1;
                    }
                    if (a.banco > b.banco) {
                        return 1;
                    }
                    return 0;
                },
                buscar: "banco",
                ellipsis: true,
            },
            {
                title: "Fecha",
                dataIndex: "fechaPago",
                key: "fechaPago",
                sorter: (a, b) => {
                    if (moment(a.fechaPago, "DD/MM/YYYY") < moment(b.fechaPago, "DD/MM/YYYY")) {
                        return -1;
                    }
                    if (moment(a.fechaPago, "DD/MM/YYYY") > moment(b.fechaPago, "DD/MM/YYYY")) {
                        return 1;
                    }
                    return 0;
                },
                buscar: "fechaPago",
                ellipsis: true,
            },
            {
                title: "Proyecto",
                dataIndex: "proyecto",
                key: "proyecto",
                sorter: (a, b) => {
                    if (a.proyecto < b.proyecto) {
                        return -1;
                    }
                    if (a.proyecto > b.proyecto) {
                        return 1;
                    }
                    return 0;
                },
                buscar: "proyecto",
                ellipsis: true,
            },
            {
                title: "Moneda",
                dataIndex: "moneda",
                key: "moneda",
                sorter: (a, b) => {
                    if (a.moneda < b.moneda) {
                        return -1;
                    }
                    if (a.moneda > b.moneda) {
                        return 1;
                    }
                    return 0;
                },
                buscar: "moneda",
                ellipsis: true,
            },
            {
                title: "Monto",
                dataIndex: "monto",
                key: "monto",
                sorter: (a, b) => {
                    if (a.monto < b.monto) {
                        return -1;
                    }
                    if (a.monto > b.monto) {
                        return 1;
                    }
                    return 0;
                },
                buscar: "monto",
                render: (value) => dineroDisabledMask(value),
                align: "right",
                ellipsis: true,
            },
            {
                title: "Referencia",
                dataIndex: "referencia",
                key: "referencia",
                sorter: (a, b) => {
                    if (a.referencia < b.referencia) {
                        return -1;
                    }
                    if (a.referencia > b.referencia) {
                        return 1;
                    }
                    return 0;
                },
                buscar: "referencia",
                ellipsis: true,
            },
            {
                title: "Accion",
                dataIndex: "accion",
                key: "accion",
                render: (value, record, index) => (
                    <Space size="middle" key={index.toString()}>
                        <EyeOutlined
                            onClick={() => this.abrirVerFacturasReposicion(value._id)}
                            style={{ color: "blue" }}
                            key={index.toString()}
                        />
                      <FilePdfOutlined style={{ color: 'red' }} key={index.toString()} onClick={() => this.abrirVerReposicionCajaChica(value._id)} />
                    </Space>
                ),
            },
        ];
        return (
            <>
                <PageHeader
                    title={'Caja Chica - Reposiciones'}
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                    extra={
                        <Space style={{ width: "100%", minWidth: 200 }}>
                            <SelectItem
                                placeholder="cajaChicaId"
                                width={150}
                                onChange={(value) => this.onChangeCajaChica(value)}
                                value={this.state.cajaChicaId}
                            >
                                {this.state.objetoCajaYEfectivo}
                            </SelectItem>
                        </Space>
                    }
                />
                <TableItem
                    size="small"
                    dataSource={this.state.todasReposicionesCajaChica}
                    columns={columns}
                    pagination={false}
                    loading={this.state.loading}
                />
                <VerFacturasReposicion
                    history={this.props.history}
                    visible={this.state.modalVerFacturasReposicion}
                    id={this.state.modalVerFacturasReposicionId}
                    cerrarVerFacturasReposicion={this.cerrarVerFacturasReposicion.bind(this)}
                />
                                <VerReposicionCajaChica
                    history={this.props.history}
                    visible={this.state.modalVerReposicionCajaChica}
                    id={this.state.modalVerReposicionCajaChicaId}
                    cerrarVerReposicionCajaChica={this.cerrarVerReposicionCajaChica.bind(this)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(VerPagosCajaChica);
