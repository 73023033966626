import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ModalItem, cuentaMask } from "../../../../../Components/Items";

class ListadoCuentas extends Component {
  state = {
    loading: false,
    todasCuentasContables: [],
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "cuentascontables/selectCuentasContables",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasCuentasContables: res.data.map((value) => {
              return {
                ...value,
                codigo: cuentaMask(
                  value.nivel1 +
                    value.nivel2 +
                    value.nivel3 +
                    value.nivel4 +
                    value.nivel5
                ),
              };
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  cerrarListadoCuentas(codigo) {
    this.props.cerrarListadoCuentas(codigo);
  }
  render() {
    return (
      <ModalItem
        title="Listado Cuentas"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarListadoCuentas.bind(this)}
        paddingTop
      >
        <table className="table">
          <thead>
            <tr>
              <th className="th-border" style={{ width: "40%" }}>
                Numero
              </th>
              <th className="th-border" style={{ width: "60%" }}>
                Cuenta
              </th>
            </tr>
          </thead>
          {this.state.todasCuentasContables
            .sort((a, b) => {
              if (a.codigo < b.codigo) {
                return -1;
              }
              if (a.codigo > b.codigo) {
                return 1;
              }
              return 0;
            })
            .map((value) => (
              <tbody key={value._id}>
                <tr>
                  <td className="td-border">{value.codigo}</td>
                  <td className="td-border">{value.descripcion}</td>
                </tr>
              </tbody>
            ))}
        </table>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ListadoCuentas);
