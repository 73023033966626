import { Avatar, Card, notification, PageHeader, Spin, Tag } from "antd";
import React, { Component } from "react";
import moment from "moment";
import 'moment/locale/es'
import { connect } from "react-redux";
import { WalletOutlined } from "@ant-design/icons";
import axios from "axios";
import PagarEmpresa from "./PagarEmpresa/PagarEmpresa";

const { Meta } = Card;

moment.locale('es')

class Facturacion extends Component {
  state = {
      paquete: 0,
      fechaVencimiento: new Date(),
      modalPagarEmpresa: false,
      modalPagarEmpresaId: null
  }

  componentDidMount() {
    this.consultarFacturacion()
  }

  consultarFacturacion() {
    this.setState({ loading: true });
    axios
      .post(
        "facturacion/consultarFacturacion",
        { usuarioPrincipal: this.props.usuarioPrincipal },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          this.setState({ todasEmpresas: res.data });
          this.setState({
            objetoEmpresas: res.data.map((value) => {
              let logo;
              if (value.logo) {
                logo = value.logo.base64Completa;
              } else {
                logo =
                  "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png";
              }
              return (
                <Card
                  key={value._id}
                  id="card"
                  actions={[
                    <WalletOutlined onClick={() => this.abrirPagarEmpresa(value._id)} />
                  ]}
                >
                  <Meta
                    avatar={<Avatar shape="square" src={logo} />}
                    title={value.nombre}
                    description={value.rfc}
                  />
                  <br/>
                  <p>Paquete: {value.paquete}</p>
                  {moment(value.fechaVencimiento) < moment() ? 

                  <Tag color='red'>Vencimiento: {moment(value.fechaVencimiento).locale('es').format('LL')}</Tag>

                  : 
                  
                  <Tag color='green'>Vencimiento: {moment(value.fechaVencimiento).locale('es').format('LL')}</Tag> 

                  }
                </Card>
              );
            }),
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: true });
        notification.error({
          message: "Error inesperado",
          description: err.toString(),
        });
      });
  }

  abrirPagarEmpresa(id) {
    this.setState({ modalPagarEmpresa: true, modalPagarEmpresaId: id });
  }
  cerrarPagarEmpresa() {
    this.setState({ modalPagarEmpresa: false });
    this.consultarFacturacion();
  }


  render() {
    return (
      <>
        <PageHeader
          title="Facturacion"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <Spin spinning={false}>{this.state.objetoEmpresas}</Spin>
        {/* <Card 
            actions={[
                <WalletOutlined />
            ]}
        >
            <Meta 
                title={'Paquete: '+ this.state.paquete}
                description={'Vencimiento: '+ moment(this.state.fechaVencimiento).locale('es').format('LL')}
            />
        </Card> */}
        <PagarEmpresa
          visible={this.state.modalPagarEmpresa}
          id={this.state.modalPagarEmpresaId}
          cerrarPagarEmpresa={this.cerrarPagarEmpresa.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    usuarioPrincipal: state.user.usuarioPrincipal,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(Facturacion);