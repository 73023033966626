import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const traspasoEntreAlmacenesPdf = (values) => {
  const empresa = values.empresa;
  const insumos = values.insumos;
  const orden = values.orden;
  const logoBiwo = values.logoBiwo;
  const conceptos = insumos.map((valueConcepto) => {
    return [
      {text: valueConcepto.descripcion, style: 'textoTabla', alignment: 'left'}, 
      {text: valueConcepto.unidad, style: 'textoTabla', alignment: 'center'}, 
      {text: valueConcepto.cantidad, style: 'textoTabla', alignment: 'right'},
    ];
  });
  let cancelado = {}
  if(orden.estado === 0){
    cancelado = {watermark: { text: 'CANCELADO', color: 'GRAY', opacity: 0.3, bold: true, italics: false, angle: -50, fontSize: 110 }}
  }
  var dd = {
    ...cancelado,
    footer: {
        image: 'logoBiwo', alignment: 'center', width: 50
    },
    content: [
      {
        style: 'tableExample',
        table: {
            widths: [ '18%', '52%', '30%'],
            layout: {
              defaultBorder: false,
            },
          body: [
            [
              {
  //							rowSpan: 3,
                border: [false, false, false, false],
                image: 'logo',
                fit: [80, 80] 
                //text: 'rowSpan: 3\n\nborder:\n[true, true, true, true]'
              },
              {
              //	rowSpan: 2,
                border: [false, false, false, false],
                        table: {
                          headerRows: 1,
                          widths: ['100%'],
                        
                          body: [
                            [
                              { 
                              text: empresa.persona ? empresa.nombre : empresa.nombre+' '+empresa.apellidoPaterno+' '+empresa.apellidoMaterno, 
                              style: "tableHeader" 
                              }
                          ],
                          [
                            {
                              text: "R.F.C: "+empresa.rfc,
                              style: "textoChico",
                              alignment: "center",
                            },
                          ],
                          [
                              {
                                text: empresa.calle+' '+
                                      empresa.numeroCalle+' '+
                                      (empresa.numeroInterior ? empresa.numeroInterior : '')
                                ,
                                style: "textoChico",
                                alignment: "center",
                              },
                            ],
                          [
                              {
                                text: empresa.colonia.tipoAsentamiento+' '+
                                empresa.colonia.nombre+' C.P. '+
                                empresa.colonia.cp+' '+
                                empresa.municipio.nombre+' '+
                                empresa.estado.abreviatura+' '+
                                empresa.pais.abreviatura+' '
                                ,
                                style: "textoChico",
                                alignment: "center",
                              },
                            ],
                            [{text: 'TRASPASO ENTRE ALMACENES', style: 'tableHeader'}],
                          ]
                        },
                        layout: 'noBorders'
              },
              {
                border: [false, false, false, false],
                  table: {
                          headerRows: 1,
                          widths: ['100%'],
                        
                          body: [
                            [{text: 'N° Traspaso:', style: 'subheader', alignment: 'center'}],
                            [{text: orden.numero, style: 'textoChico', alignment: 'center'}],
                            [{text: 'Fecha:', style: 'subheader', alignment: 'center'}],
                            [{text: moment(orden.createdAt).locale('es').format('LL'), style: 'textoChico', alignment: 'center'}],
                          ]
                        },
                        layout: 'noBorders'
              },
              ]

          ]
        },
      },
      {text: 'Responsable: '+orden.usuario.nombre+' '+orden.usuario.apellidoPaterno+' '+orden.usuario.apellidoMaterno},
      {
          style: 'tableExample',
          table: {
              widths: ['50%', '50%'],
              layout: {
                              defaultBorder: false,
                            },
              
              body: [
                      [
                          {
                          table: {
                              widths: ['28%', '72%'],
                              layout: {
                              defaultBorder: false,
                            },
                              body: [
                                  [
                                      {text: 'Almacen de Salida:', style: 'subheader',}, 
                                      {text: orden.proyectoOrigen.nombre+' - '+orden.proyectoOrigen.descripcion, style: 'textoChico'}
                                  ],
                              ]
                          },
                          layout: 'noBorders'
                          
                      }, 
                      
                      
                      
                      {
                          table: {
                              widths: ['28%', '72%'],
                              layout: {
                              defaultBorder: false,
                            },
                              body: [
                                  [
                                      {text: 'Almacen de entrada:', style: 'subheader',}, 
                                      {text: orden.proyectoDestino.nombre+' - '+orden.proyectoDestino.descripcion, style: 'textoChico'}
                                  ],
                              ]
                          },
                          layout: 'noBorders'
                      }
                    ]
                ]
          },
                  layout: {
          hLineWidth: function (i, node) {
            return (i === 0 || i === node.table.body.length) ? 1 : 1;
          },
          vLineWidth: function (i, node) {
            return (i === 0 || i === node.table.widths.length) ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return '#adaaaa';
          },
          vLineColor: function (i, node) {
            return '#adaaaa';
          },
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return {dash: {length: 2, space: 1}};
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return {dash: {length: 2}};
          },
          // paddingLeft: function(i, node) { return 4; },
          // paddingRight: function(i, node) { return 4; },
          // paddingTop: function(i, node) { return 2; },
          // paddingBottom: function(i, node) { return 2; },
          // fillColor: function (i, node) { return null; }
        }
          
      },
      
      {
          style: 'tableExample',
          table: {
              widths: ['60%', '20%', '20%'],
              layout: {
              defaultBorder: true,
            },
              body: [
                  [
                    {text: 'Descripción', style: 'tituloTabla'},		              
                    {text: 'Unidad', style: 'tituloTabla'},
                    {text: 'Cantidad', style: 'tituloTabla'},
                  ],
                  ...conceptos
              ]
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                      return rowIndex % 2 === 0 ? "#e8e8e8" : "#ffffff";
                  },
          hLineWidth: function (i, node) {
            return (i === 0 || i === node.table.body.length) ? 1 : 1;
          },
          vLineWidth: function (i, node) {
            return (i === 0 || i === node.table.widths.length) ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return '#adaaaa';
          },
          vLineColor: function (i, node) {
            return '#adaaaa';
          },
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return {dash: {length: 2, space: 1}};
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return {dash: {length: 2}};
          },
        }
      },
    {
          style: 'tableExample', margin: [0,50, 0, 0],
          table: {
              widths: ['35%', '30%', '35%'],
              layout: {
              defaultBorder: false,
            },
              body: [
                  [
                      {text: 'Entrega', style: 'textoTabla', alignment: 'center', border: [false, true, false, false],}, 
                    {text: '', style: 'textoTabla', alignment: 'center'}, 
                    {text: 'Recibe', style: 'textoTabla', alignment: 'center', border: [false, true, false, false]}, 

                  ],
              ]
          },
          layout: {
          defaultBorder: false,
        }
    }		
    ],
    images: {
      logo: empresa.logo.base64Completa,
      logoBiwo: logoBiwo.base64Completa,
    },
    styles: {
      header: {
        fontSize: 9,
        bold: true,
      },
      subheader: {
        fontSize: 8,
        bold: true,
      },
      tableExample: {
        margin: [0, 5, 0, 5]
      },
      tablaSinMargen: {
        margin: [0, 0, 0, 0]
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: 'black',
        alignment: 'center'
      },
      textoChico:{
          fontSize: 8
      },
      tituloTabla:{
          fontSize: 8,
          bold: true, 
          fillColor: '#cccccc', 
          style: 'subheader', 
          alignment: 'center',
          border: true
      },
      textoTabla: {
          fontSize: 8,
          italics: false
      }
    },
    pageSize: 'LETTER',
    pageMargins: [ 20, 20, 20, 20 ],
  }
  return dd;
};

export default traspasoEntreAlmacenesPdf;