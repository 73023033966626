import {
  Col,
  message,
  PageHeader,
  Row,
  Spin,
  Select,
  Button,
  Space,
  Tabs,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { connect } from "react-redux";
import {
  SelectItem,
  Form,
  FormItem,
  cuentaMask,
  TableItem,
  dineroDisabledMask,
  round2Decimals,
  RangePickerItem,
} from "../../../../Components/Items";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import moment from "moment";
import "moment/locale/es";
import reporteDeEstadoDeCuentaPdf from "../../../../Pdf/reporteDeEstadoDeCuentaPdf";

moment.locale("es");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select;
const { TabPane } = Tabs;

class ReporteEstadoDeCuenta extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pdf: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoUniadesDeNegocio: null,
    todasUniadesDeNegocio: null,
    dataReporte: null,
    todasCargosAbonos: [],
    objetoCuentaContable: null,
    todasCuentaContable: null,
  };

  atras() {
    this.props.history.goBack();
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "unidadesdenegocio/selectUnidadesDeNegocio",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasUniadesDeNegocio: res.data });
          this.setState({
            objetoUniadesDeNegocio: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.nombre}
                </Option>
              );
            }),
          });
        }
      });

    let primeraCuentaId = undefined;

    await axios
      .post(
        "cuentascontables/selectCuentasContablesFinales",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          primeraCuentaId = res.data[0]._id;
          this.setState({ todasCuentaContable: res.data });
          this.setState({
            objetoCuentaContable: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
          });
        }
        this.setState({ loading: false });
      });

    this.formRef.current.setFieldsValue({
      periodo: [moment().subtract(1, "month"), moment()],
      cuentaId: primeraCuentaId,
    });

    this.consultarReporteEstadoDeCuenta({
      periodo: [moment().subtract(1, "month"), moment()],
      cuentaId: primeraCuentaId,
    });
  }

  consultarReporteEstadoDeCuenta(values) {
    this.setState({ loading: true });
    axios
      .post(
        "reportesFinancieros/reporteEstadoDeCuenta",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.setState({
            loading: false,
            dataReporte: res.data,
            todasCargosAbonos: res.data.cargosAbonos,
          });
        } else if (res.data.success === 1) {
          message.error("Ha sucedido un error y no se cargo bien el reporte");
        } else {
          message.error("Ha sucedido un error y no se cargo bien el reporte");
        }
        this.setState({ loading: false });
      });
  }

  descargarExcel() {
    let datosDescargar = [];

    for (const valueCA of this.state.todasCargosAbonos) {
      datosDescargar.push({
        Fecha: moment(valueCA.fechaRegistro).locale("es").format("L"),
        Concepto: valueCA.concepto,
        Poliza: valueCA.numPoliza,
        Cargo: valueCA.cargo !== '-' ? round2Decimals(valueCA.cargo) : 0,
        Abono: valueCA.abono !== '-' ? round2Decimals(valueCA.abono) : 0,
        Saldo: round2Decimals(valueCA.saldo),
      });
    }

    const worksheet = XLSX.utils.json_to_sheet(datosDescargar);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Estado de Cuenta");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workbook,
      `Estado_de_Cuenta_${moment().locale("es").format("L")}.xlsx`
    );
  }

  descargarPdf() {
    pdfMake
      .createPdf(reporteDeEstadoDeCuentaPdf(this.state.dataReporte))
      .download(`Estado_de_Cuenta_${moment().locale("es").format("L")}`);
  }

  onFinishFailed(e) {
    console.log(e);
    message.error("Porfavor ingrese bien los datos");
  }

  render() {
    const columns = [
      {
        title: "Fecha",
        dataIndex: "fechaRegistro",
        key: "fechaRegistro",
        sorter: (a, b) => {
          if (moment(a.fechaRegistro) < moment(b.fechaRegistro)) {
            return -1;
          }
          if (moment(a.fechaRegistro) > moment(b.fechaRegistro)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale('es').format('L'),
        buscar: "fechaRegistro",
        ellipsis: true,
        width: '10%'
      },
      {
        title: "Concepto",
        dataIndex: "concepto",
        key: "concepto",
        sorter: (a, b) => {
          if (a.concepto < b.concepto) {
            return -1;
          }
          if (a.concepto > b.concepto) {
            return 1;
          }
          return 0;
        },
        buscar: "concepto",
        ellipsis: true,
        width: '25%'
      },
      {
        title: "Poliza",
        dataIndex: "numPoliza",
        key: "numPoliza",
        sorter: (a, b) => {
          if (a.numPoliza < b.numPoliza) {
            return -1;
          }
          if (a.numPoliza > b.numPoliza) {
            return 1;
          }
          return 0;
        },
        buscar: "numPoliza",
        ellipsis: true,
        width: '25%'
      },
      {
        title: "Cargo",
        dataIndex: "cargo",
        key: "cargo",
        sorter: (a, b) => {
          if (a.cargo < b.cargo) {
            return -1;
          }
          if (a.cargo > b.cargo) {
            return 1;
          }
          return 0;
        },
        buscar: "cargo",
        render: (value) => (value !== "-" ? dineroDisabledMask(value) : "-"),
        align: "right",
        ellipsis: true,
        width: '10%'
      },
      {
        title: "Abono",
        dataIndex: "abono",
        key: "abono",
        sorter: (a, b) => {
          if (a.abono < b.abono) {
            return -1;
          }
          if (a.abono > b.abono) {
            return 1;
          }
          return 0;
        },
        buscar: "abono",
        render: (value) => (value !== "-" ? dineroDisabledMask(value) : "-"),
        align: "right",
        ellipsis: true,
        width: '10%'
      },
      {
        title: "Saldo",
        dataIndex: "saldo",
        key: "saldo",
        sorter: (a, b) => {
          if (a.saldo < b.saldo) {
            return -1;
          }
          if (a.saldo > b.saldo) {
            return 1;
          }
          return 0;
        },
        buscar: "saldo",
        render: (value) => dineroDisabledMask(value),
        align: "right",
        ellipsis: true,
        width: '20%'
      },
    ];
    return (
      <>
        <Form
          ref={this.formRef}
          name="rerpoteEstadoDeCuenta"
          onFinish={this.consultarReporteEstadoDeCuenta.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Reporte Estado de Cuenta"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Space>
                  {this.state.dataReporte && (
                    <Button
                      onClick={() => this.descargarExcel()}
                      icon={<FileExcelOutlined />}
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Descargar Excel
                    </Button>
                  )}
                  {this.state.dataReporte && (
                    <Button
                      onClick={() => this.descargarPdf()}
                      icon={<FilePdfOutlined />}
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Descargar PDF
                    </Button>
                  )}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Consultar
                  </Button>
                </Space>
              </div>
            }
          />
          <Spin spinning={this.state.loading}>
            <div
              style={{
                backgroundColor: "white",
                padding: 20,
                paddingTop: 5,
                marginBottom: 20,
              }}
            >
              <Tabs
                style={{ margin: 0 }}
                tabBarExtraContent={{
                  left: (
                    <h1 style={{ padding: 0, margin: "0 10px 0 0" }}>
                      Filtros:
                    </h1>
                  ),
                }}
              >
                <TabPane tabKey="2" key="2" forceRender tab="Cuenta">
                  <FormItem name="cuentaId" required noStyle>
                    <SelectItem placeholder="Cuenta" width="100%">
                      {this.state.objetoCuentaContable}
                    </SelectItem>
                  </FormItem>
                </TabPane>
                <TabPane tabKey="3" key="3" forceRender tab="Periodo">
                  <FormItem
                    initialValue={[moment().subtract(1, "month"), moment()]}
                    name="periodo"
                    required
                    noStyle
                  >
                    <RangePickerItem />
                  </FormItem>
                </TabPane>
                <TabPane tabKey="4" key="4" forceRender tab="Proyecto">
                  <Row>
                    <Col span={8}>
                      <FormItem
                        name="filtrarProyecto"
                        required
                        noStyle
                        initialValue={0}
                      >
                        <SelectItem width="90%" placeholder="Filtrar Proyecto">
                          <Option default value={0}>
                            Niguno
                          </Option>
                          <Option value={1}>Proyectos</Option>
                          <Option value={2}>Unidades de negocio</Option>
                        </SelectItem>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const filtrarProyecto =
                            getFieldValue("filtrarProyecto");
                          if (filtrarProyecto === 1) {
                            return (
                              <FormItem required name="proyectos" noStyle>
                                <SelectItem
                                  width="90%"
                                  placeholder="Proyectos"
                                  mode="multiple"
                                >
                                  {this.state.objetoProyectos}
                                </SelectItem>
                              </FormItem>
                            );
                          }
                          if (filtrarProyecto === 2) {
                            return (
                              <FormItem
                                required
                                name="unidadDeNegocioId"
                                noStyle
                              >
                                <SelectItem
                                  width="90%"
                                  placeholder="Unidades de Negocio"
                                >
                                  {this.state.objetoUniadesDeNegocio}
                                </SelectItem>
                              </FormItem>
                            );
                          }
                        }}
                      </FormItem>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h1 style={{ textAlign: "center" }}>Cargos y Abonos</h1>
                <TableItem
                  columns={columns}
                  dataSource={this.state.todasCargosAbonos}
                  size="small"
                  pagination={false}
                />
              </Col>
            </Row>
          </Spin>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ReporteEstadoDeCuenta);
