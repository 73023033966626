import { Card, Col, Collapse, message, notification, Row, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ButtonItem,
  dineroDisabledMask,
  ModalItem,
} from "../../../../Components/Items";
import moment from "moment";

const {Panel} = Collapse

class PagarEmpresa extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    fechaVencimiento: null,
    paquete: null,
    pagosHtml: null
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible){
          this.consultarTodo();
    }
  }

  checarPago(orderId){
    axios.post('facturacion/checarPago', {orderId})
    .then((res) => {
      if(res.data.success === 2){
        notification.success({
          message: "Se ha verificado el pago",
        });
      }else if(res.data.success === 1){
        notification.info({
          message: "El pago ya fue verificado anteriormente",
        });
      }else{
        notification.error({
          message: res.data.err,
        });
      }
      this.consultarTodo()
    }).catch(err => {
      notification.error({
        message: "Error no se pudo verificar el pago",
      });
    })
  }


  cerrarPagarEmpresa(codigo) {
    this.props.cerrarPagarEmpresa(codigo);
    this.setState({pagosHtml: null})
  }
  async consultarTodo() {
    this.setState({ loading: true });

    await axios.post('facturacion/selectPagos', {empresaId: this.props.id})
    .then(res => {
      if (res.data.status === 404) return;
      const html = (
        <Collapse style={{ marginBottom: 10 }}>
          {res.data.map((valuePago) => {

            let nombrePago = ''

            if(valuePago.tipoPago){

              if(valuePago.tipoPago === 1){
  
                nombrePago = (valuePago.numTimbres+' Timbres')
  
              }else if(valuePago.tipoPago === 2){
  
                nombrePago = 'Pago Mensual Paquete '+valuePago.paquete
  
              }else if(valuePago.tipoPago === 3){
  
                nombrePago = 'Pago Anual Paquete '+valuePago.paquete
  
              }

            }else{

              if(valuePago.timbres){

                nombrePago = (valuePago.numTimbres+' Timbres')

              }else{
                nombrePago = 'Pago Mensual'
              }

            }


            return (
              <Panel
                extra={
                  valuePago.estado === 2 ? (
                    <Tag color="green">
                      Pagado
                    </Tag>
                  ) : (
                    <Tag color="red">
                      Faltante
                    </Tag>
                  )
                }
                header={nombrePago+' - '+moment(valuePago.createdAt).locale("es").format("ll")+' - '+dineroDisabledMask((valuePago.monto/100))}
                key={valuePago._id}
              >
                <Row>
                  <Col span={12}>
                    <p>
                      Creación Pago:{" "}
                      {moment(valuePago.createdAt).locale("es").format("ll")}
                    </p>
                    <p>
                      Vencimiento:{" "}
                      {moment(valuePago.conektaExpirationDate).locale("es").format("ll")}
                    </p>
                    {valuePago.estado === 0 && moment(valuePago.conektaExpirationDate) > moment()  && (
                      <ButtonItem type="primary" onClick={() => window.open(valuePago.conektaCheckoutUrl)}>
                        Ir al Pago
                      </ButtonItem>
                    )}
                  </Col>
                  <Col span={12}>
                    <p>
                      Dia de registro:{" "}
                      {moment(valuePago.updatedAt).locale("es").format("ll")}
                    </p>
                    <p>Monto: {dineroDisabledMask(valuePago.monto/100)}</p>
                    {valuePago.estado === 0 && (
                      <ButtonItem type="primary" danger onClick={() => this.checarPago(valuePago.conektaOrderId)}>
                        Checar Pago
                      </ButtonItem>
                    )}
                  </Col>
                </Row>
              </Panel>
            );
          })}
        </Collapse>
      );
      this.setState({ pagosHtml: html });
    })

    await axios.post('facturacion/datosEmpresa', {empresaId: this.props.id})
    .then(res => {
      if (res.data.status === 404){
        message.error('No se encontro informacion de la empresa')
        this.cerrarPagarEmpresa()
      }else{
        this.setState({
          fechaVencimiento: res.data.fechaVencimiento,
          paquete: res.data.paquete,
        })
      };
    })

    this.setState({ loading: false });
  }


  onFinish(tipoPago) {
    this.setState({ loading: true });
    axios.post('facturacion/pagarEmpresa', {
      empresaId: this.props.id,
      tipoPago: tipoPago,
      usuarioId: this.props.usuarioId
    }).then((res) => {
      this.setState({ loading: false });
      if (res.data.success === 2) {
          window.open(res.data.url, '_blank');
      } else {
          message.error("Sucedio un error y no se pudo generar la ficha de pago");
      }
  });
  }
  render() {

    let pagoPaqueteMes = ''
    let pagoPaqueteAno = ''
    let usuariosPaquete = ''

    if(this.state.paquete === 1){
      pagoPaqueteMes = '400'
      pagoPaqueteAno = '4,000'
      usuariosPaquete = 2
    }else if(this.state.paquete === 2){
      pagoPaqueteMes = '750'
      pagoPaqueteAno = '8,000'
      usuariosPaquete = 5
    }else if(this.state.paquete === 3){
      pagoPaqueteMes = '1,100'
      pagoPaqueteAno = '11,000'
      usuariosPaquete = 8
    }

    return (
      <>
        <ModalItem
          title="Facturación"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.cerrarPagarEmpresa.bind(this)}
          paddingTop
        >
            {this.state.pagosHtml }
            <Row style={{width: '100%'}} gutter={5}>
              <Col span={12}>
                <Card hoverable title="Mensual" style={{width: '100%'}}>
                  {moment(this.state.fechaVencimiento) < moment() ?
                    <p>Se agregara 1 mes a partir de hoy</p>
                  :
                    <p>Se agregara 1 mes a partir del {moment(this.state.fechaVencimiento).format('DD [de] MMMM')}</p>
                  }
                  <p>${pagoPaqueteMes} + IVA</p>
                  <p>Acceso a toda la plataforma</p>
                  <p>{usuariosPaquete} Usuario + Contador</p>
                  <p>Facturacion Electronica</p>
                  <p>*Por el momento regalaremos los timbres fiscales</p>
                  <p>*Los timbres se pagan individualmente</p>
                  <ButtonItem
                    onClick={() => this.onFinish(2)}
                    type="primary"
                    size='large'
                    style={{ display: "block", margin: "0 auto 0 auto" }}
                  >
                    Pagar 1 Mes
                  </ButtonItem>
                </Card>
              </Col>
              <Col span={12}>
                <Card hoverable title="Anual" style={{width: '100%'}}>
                  {moment(this.state.fechaVencimiento) < moment() ?
                    <p>Se agregara 1 año a partir de hoy</p>
                  :
                    <p>Se agregara 1 año a partir del {moment(this.state.fechaVencimiento).format('DD [de] MMMM')}</p>
                  }
                  <p>${pagoPaqueteAno} + IVA</p>
                  <p>Acceso a toda la plataforma</p>
                  <p>{usuariosPaquete} Usuarios + Contador</p>
                  <p>Facturacion Electronica</p>
                  <p>*Por el momento regalaremos los timbres fiscales</p>
                  <p>*Los timbres se pagan individualmente</p>
                  <ButtonItem
                    onClick={() => this.onFinish(3)}
                    type="primary"
                    size='large'
                    style={{ display: "block", margin: "0 auto 0 auto" }}
                  >
                    Pagar 1 Año
                  </ButtonItem>
                </Card>
              </Col>
            </Row>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    usuarioId: state.user.usuarioId,
  };
};

export default connect(mapStateToProps)(PagarEmpresa);