import round2Decimals from "./round2Decimals";


const CuentaMask = (value, truncate) => {
    let numberRound = round2Decimals(value).toFixed(2)
    let arrayString = numberRound.toString().split('.')
    let primero = arrayString[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,')
    let segundo = arrayString[1]
    if(segundo){
      return `$ ${primero}.${segundo}`
    }else{
      return `$ ${primero}`
    }
  };
  
  export default CuentaMask;