import { Collapse, Row, Col, Tag } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  dineroDisabledMask,
  ModalItem,
  ButtonItem,
} from "../../../../Components/Items";
import moment from "moment";

const { Panel } = Collapse;

class FacturasOrdenDeCompra extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pagosHtml: <p>No se encontro ninguna factura</p>,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }

  verFacturaOrdenDeCompra(id){
    this.props.history.push('/panel/verFacturaOrdenDeCompra', {id})
  }

  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "ordenesdecompra/facturasOrdenDeCompra",
        { ordenId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) return;
        const html = (
          <Collapse style={{ marginBottom: 10 }}>
            {res.data.map((valueFactura) => {
              return (
                <Panel
                  extra={
                    valueFactura.estado !== 0 ? (
                      valueFactura.moneda.abreviacion+' - '+dineroDisabledMask(valueFactura.totalPagar)
                    ) : (
                      <Tag color="red">
                        Cancelada: {valueFactura.moneda.abreviacion+' - '+dineroDisabledMask(valueFactura.totalPagar)}
                      </Tag>
                    )
                  }
                  header={moment(valueFactura.fechaPago).locale("es").format("ll")}
                  key={valueFactura._id}
                >
                  <Row>
                    <Col span={12}>
                      <p>
                        Fecha Factura:{" "}
                        {moment(valueFactura.fechaFactura).locale("es").format("ll")}
                      </p>
                    </Col>
                    <Col span={12}>
                      <p>
                        Dia de registro:{" "}
                        {moment(valueFactura.createdAt).locale("es").format("ll")}
                      </p>
                      {valueFactura.estadoFactura !== 0 && (
                        <ButtonItem type="primary" onClick={() => this.verFacturaOrdenDeCompra(valueFactura._id)}>
                          Ver Factura
                        </ButtonItem>
                      )}
                    </Col>
                  </Row>
                </Panel>
              );
            })}
          </Collapse>
        );
        this.setState({ pagosHtml: html });
      });
    this.setState({ loading: false });
  }
  cerrarFacturasOrdenDeCompra() {
    this.props.cerrarFacturasOrdenDeCompra();
  }
  render() {
    return (
      <>
      <ModalItem
        title="Facturas Orden De Compra"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarFacturasOrdenDeCompra.bind(this)}
        paddingTop
      >
        {this.state.pagosHtml}
      </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(FacturasOrdenDeCompra);
