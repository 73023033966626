import { UserOutlined } from "@ant-design/icons";
import { Button, message, Input, Tabs, Select } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  SelectItem,
  InputNumberItem,
  InputItem
} from "../../../../Components/Items";
import TextAreItem from "../../../../Components/TextAreaItem";

const { TabPane } = Tabs;
const { Option, OptGroup } = Select;

class EditarUsuario extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasPerfiles: null,
    objetoPerfiles: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  cerrarEditarUsuario(codigo) {
    this.props.cerrarEditarUsuario(codigo);
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "perfiles/selectPerfiles",
        { usuarioPrincipal: this.props.usuarioPrincipal },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasPerfiles: res.data });
          this.setState({
            objetoPerfiles: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
      await axios
      .post(
        "proyectos/selectProyectosPEmpresa",
        { usuarioPrincipal: this.props.usuarioPrincipal },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              let proyectos = value.proyectos.map((valueProyecto) => {
                return (
                  <Option value={valueProyecto._id} key={valueProyecto._id}>
                    {valueProyecto.nombre}
                  </Option>
                );
              });
              return (
                <OptGroup label={value.nombre} key={value._id}>
                  {proyectos}
                </OptGroup>
              );
            }),
          });
        }
      });
      await axios
      .post(
        "usuarios/datosUsuario",
        { usuarioPrincipal: this.props.usuarioPrincipal, usuarioId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion del usuario");
        } else {
          this.formRef.current.setFieldsValue({
            ...res.data,
          });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "usuarios/editarUsuario",
        { ...values, usuarioPrincipal: this.props.usuarioPrincipal, usuarioId: this.props.id },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data?.success === 2) {
            message.success("Se ha editado un usuario");
            this.formRef.current.resetFields();
            this.cerrarEditarUsuario();
          } else if(res.data.nombre === 1){
            message.error('El nombre ya existe')
          }else if(res.data.error){
            message.error(res.data.error)
          }else{
            message.error('Ha sucedido un error y no se pudo editar el usuario')
          }
      });
  }
  render() {
    return (
      <>
        <ModalItem
          title="Editar Usuario"
          visible={this.props.visible}
          spinning={this.state.loading}
          onCancel={this.cerrarEditarUsuario.bind(this)}
          paddingTop
          width='50%'
        >
          <Form
            ref={this.formRef}
            name="agregarUsuario"
            layout
            bottom={
              <Button
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Guardar
              </Button>
            }
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
          >
            <Tabs defaultActiveKey={1} style={{width: '100%'}}>
              <TabPane tab="General" tabKey={1} key={1} forceRender>
              <FormItem label="Proyectos" name="proyectos">
                  <SelectItem placeholder="Proyectos" mode="multiple">
                    {this.state.objetoProyectos}
                  </SelectItem>
                </FormItem>
                <FormItem label="Perfil" name="perfilId" required>
                  <SelectItem placeholder="Perfil">
                    {this.state.objetoPerfiles}
                  </SelectItem>
                </FormItem>
                  <FormItem label='Nombre'> 
                  <Input.Group compact>
                    <FormItem name="nombre" noStyle pattern required max={200}>
                      <Input
                        style={{ width: "33%" }}
                        placeholder="Nombre"
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                      />
                    </FormItem>
                    <FormItem
                      name="apellidoPaterno"
                      noStyle
                      pattern
                      required
                      max={200}
                    >
                      <Input
                        style={{ width: "33%" }}
                        placeholder="Apellido P."
                      />
                    </FormItem>
                    <FormItem
                      name="apellidoMaterno"
                      noStyle
                      pattern
                      required
                      max={200}
                    >
                      <Input
                        style={{ width: "33%" }}
                        placeholder="Apellido M."
                      />
                    </FormItem>
                  </Input.Group>
                  </FormItem>
                  <FormItem
                  name="nombreUsuario"
                  label="Nombre Usuario"
                  pattern
                  required
                  max={200}
                  min={5}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Nombre de usuario"
                  />
                </FormItem>
                {/* <FormItem name="correo" pattern required max={200} email>
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Correo"
                  />
                </FormItem> */}
              </TabPane>
              <TabPane tab="Avanzado" tabKey={2} key={2} forceRender>
                <FormItem
                    label="Rfc"
                    name="rfc"
                    rfc
                    margin
                >
                    <InputItem placeholder="Registro Federal de Contribuyentes" />
                </FormItem>
                <FormItem
                  label="Curp"
                  name="curp"
                  curp
                  margin
                >
                  <InputItem placeholder="Clave Unica de Registro de Poblacion" />
                </FormItem>
                <FormItem label="Celular" name="celular" number margin>
                <InputNumberItem placeholder="Celular" celular />
              </FormItem>
              <FormItem label="Telefono" name="telefono" number margin>
                <InputNumberItem placeholder="Telefono" celular />
              </FormItem>
              <FormItem
                  label="Nss"
                  name="nss"
                  margin
                >
                  <InputItem placeholder="Numero seguro social" />
                </FormItem>
                <FormItem
                        label="Observaciones"
                        name="observaciones"
                        margin
                        pattern
                      >
                        <TextAreItem placeholder="Observaciones" />
                      </FormItem>
              </TabPane>
            </Tabs>
          </Form>
        </ModalItem>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    usuarioPrincipal: state.user.usuarioPrincipal,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(EditarUsuario);
