import React, { Component } from "react";
import {
  Switch,
  Button,
  message,
  Spin,
  Select,
} from "antd";
import { Form, FormItem, SelectItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";

const { Option } = Select

class EnvioConfiguracion extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
    todasUnidadesPeso: null,
    objetoUnidadesPeso: null
  }

  componentDidMount() {
    this.consultarConfiguracion()
  }
  onFinish(values) {
    axios
      .post(
        "empresas/editarConfiguracionEnvios",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha modificado la configuracion de la empresa");
          this.consultarConfiguracion();
        } else if (res.data.success === 1) {
          message.error("No se pudo modificar la configuracion");
        } else {
          message.error("Hubo un error y no se modifico la configuracion");
        }
      });
  }
  async consultarConfiguracion() {
    this.setState({ loading: true })
    await axios
      .post(
        "empresas/selectConfiguracionEnvios",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro configuracion de esta empresa");
        } else {
          this.formRef.current.setFieldsValue({
            envios:
              res.data.envios,
              unidadPesoId:
              res.data.unidadPesoId,
          });
        }
      });
    await axios
      .post(
        "unidades/selectUnidadesPeso",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("No se encotraron unidades de peso");
        } else {
          this.setState({
            todasUnidadesPeso: res.data,
            objetoUnidadesPeso: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.unidadSat.c_ClaveUnidad + " - " + value.nombre}
                </Option>
              );
            }),
          })
        }
      });
    this.setState({ loading: false })
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
          e.errorFields[index].errors[0] +
          " en la fila " +
          (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Form
          name="envioConfiguracion"
          layout
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
        >
          <FormItem
            label="Activar Envios"
            name="envios"
            margin
            initialValue={false}
            valuePropName="checked"
            tooltip='Al activar esta casilla se activan los envios en el apartado de las ventas, tambien proporciona la carta porte'
          >
            <Switch />
          </FormItem>
          <FormItem shouldUpdate noStyle>
            {({ getFieldValue }) => {
              if (getFieldValue('envios')) {
                return (
                  <FormItem name="unidadPesoId" label="Unidad Peso" required margin>
                    <SelectItem placeholder="Unidad Peso" width="60%">
                      {this.state.objetoUnidadesPeso}
                    </SelectItem>
                  </FormItem>
                )
              }
            }}
          </FormItem>
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "0 0 0 auto" }}
          >
            Guardar Configuracion
          </Button>
        </Form>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(EnvioConfiguracion);
