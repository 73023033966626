import React, { Component } from "react";
import { PageHeader } from "antd";
import { Tabs } from "antd5";
import GeneralConfiguracion from "./GeneralConfiguracion/GeneralConfiguracion";
import {
  HomeFilled,
} from "@ant-design/icons";
// import TimbradoConfiguracion from "./TimbradoConfiguracion/TimbradoConfiguracion";

class Configuracion extends Component {
  render() {

    const tabItems = [
      {
        label: (
          <span>
            <HomeFilled />
            General
          </span>
        ),
        key: "1",
        children: <GeneralConfiguracion/>
      },
    ]

    return (
      <>
        <PageHeader
          title="Configuracion"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{backgroundColor: 'white', padding: 20, paddingTop: 5}}>
            <Tabs defaultActiveKey="1" items={tabItems} />
        </div>
      </>
    );
  }
}

export default Configuracion;
