import { Button, PageHeader, Popconfirm, Space, Tag, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import {
  TableItem,
  dineroDisabledMask,
  ButtonItem,
} from "../../../Components/Items";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import {
  BookOutlined,
  CloseCircleOutlined,
  EditOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import FacturasOrdenDeCompra from "./FacturasOrdenDeCompra/FacturasOrdenDeCompra";

moment.locale("es");

class OrdenesDeCompras extends Component {
  state = {
    todasOrdenesDeCompra: [],
    modalFacturas: false,
    modalFacturasId: null
  };
  componentDidMount() {
    this.consultarOrdenesDeCompra();
  }

  consultarOrdenesDeCompra() {
    this.setState({ loading: true });
    axios
      .post(
        "ordenesdecompra/selectOrdenesDeCompra",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status === 404) {
        } else {
          this.setState({
            todasOrdenesDeCompra: res.data.map((value) => {
              return {
                ...value,
                proveedorNombre: value.proveedor.nombre ? value.proveedor.nombre + " " + value.proveedor.apellidoPaterno + " " + value.proveedor.apellidoMaterno
                : value.proveedor.razonSocial,
                accion: {
                  _id: value._id,
                  estado: value.estado,
                  xml: value.xml,
                  estadoFactura: value.estadoFactura,
                  requisicion: value.requisicion,
                },
              };
            }),
          });
        }
      });
  }
  agregarOrdenDeCompra() {
    this.props.history.push("/panel/agregarOrdenDeCompra");
  }
  recibirInsumosOrden(id) {
    this.props.history.push("/panel/recibirInsumosOrdenDeCompra", { id });
  }
  recibirFacturaOrden(id) {
    this.props.history.push("/panel/recibirFacturaOrdenDeCompra", { id });
  }
  editarOrdenDeCompra(id) {
    this.props.history.push("/panel/editarOrdenDeCompra", { id });
  }
  descargarXml(value) {
    axios
      .post(
        "ordenesdecompra/descargarXml",
        { ordenDeCompraId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        var xmltext = res.data.string;

        var filename = "file.xml";
        var pom = document.createElement("a");
        var bb = new Blob([xmltext], { type: "text/plain" });

        pom.setAttribute("href", window.URL.createObjectURL(bb));
        pom.setAttribute("download", filename);

        pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
          ":"
        );
        pom.draggable = true;
        pom.classList.add("dragout");

        pom.click();
      });
  }
  cancelarOrdenDeCompra(value) {
    axios
      .post(
        "ordenesdecompra/cancelarOrden",
        { ordenId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Orden de compra cancelada");
        } else {
          message.error("Sucedio un error y no se pudo cancelar");
        }
        this.consultarOrdenesDeCompra();
      });
  }


  cerrarFacturasOrdenDeCompra(){
    this.setState({modalFacturas: false, modalFacturasId: null})
  }

  render() {
    const columns = [
      {
        title: "Proyecto",
        dataIndex: "proyecto",
        key: "proyecto",
        sorter: (a, b) => {
          if (a.proyecto.nombre < b.proyecto.nombre) {
            return -1;
          }
          if (a.proyecto.nombre > b.proyecto.nombre) {
            return 1;
          }
          return 0;
        },
        render: (value) => value.nombre,
        width: "16%",
        ellipsis: true,
      },
      {
        title: "Proveedor",
        dataIndex: "proveedorNombre",
        key: "proveedorNombre",
        sorter: (a, b) => {
          if (a.proveedorNombre < b.proveedorNombre) {
            return -1;
          }
          if (a.proveedorNombre > b.proveedorNombre) {
            return 1;
          }
          return 0;
        },
        buscar: 'proveedorNombre',
        width: "16%",
        ellipsis: true,
      },
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a, b) => {
          if (moment(a.createdAt) < moment(b.createdAt)) {
            return -1;
          }
          if (moment(a.createdAt) > moment(b.createdAt)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale("es").format("ll"),
        wdith: "15%",
        ellipsis: true,
        align: "center",
      },
      {
        title: "Subtotal",
        dataIndex: "subtotal",
        key: "subtotal",
        sorter: (a, b) => {
          if (a.subtotal < b.subtotal) {
            return -1;
          }
          if (a.subtotal > b.subtotal) {
            return 1;
          }
          return 0;
        },
        render: (value) => dineroDisabledMask(value),
        buscar: "subtotal",
        width: "8%",
        ellipsis: true,
        align: "right",
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        sorter: (a, b) => {
          if (a.total < b.total) {
            return -1;
          }
          if (a.total > b.total) {
            return 1;
          }
          return 0;
        },
        render: (value) => dineroDisabledMask(value),
        buscar: "total",
        width: "8%",
        ellipsis: true,
        align: "right",
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        render: (value) => {
          if (value === 0) {
            return <Tag color="red">Cancelada</Tag>;
          }
          if (value === 1) {
            return <Tag color="blue">Sin recibir</Tag>;
          }
          if (value === 2) {
            return <Tag color="orange">Recibido parcialmente</Tag>;
          }
          if (value === 3) {
            return <Tag color="green">Recibido</Tag>;
          }
        },
        width: "10%",
        ellipsis: true,
      },
      {
        title: "Acción",
        dataIndex: "accion",
        key: "accion",
        render: (value, record, index) => {
          let buttons = [];
          if (value.estado === 1 && value.estadoFactura === 0) {
            buttons.push(
              <EditOutlined
                key={index.toString()}
                onClick={() => this.editarOrdenDeCompra(value._id)}
                style={{ color: "blue" }}
              />
            );
          }
          if (
            value.estado === 1 &&
            value.estadoFactura === 0 &&
            this.props.permisoCancelarOrdenDeCompra
          ) {
            buttons.push(
              <Popconfirm
                title={value.requisicion ? 
                  "Seguro que quieres cancelar esta orden de compra, se regresaran los insumos a la requisicion"
                  :
                  
                  "Seguro que quieres cancelar esta orden de compra"}
                onConfirm={() => this.cancelarOrdenDeCompra(value._id)}
                okText="Si"
                cancelText="No"
                key={index.toString()}
              >
                <CloseCircleOutlined style={{ color: "red" }} />
              </Popconfirm>
            );
          }
          if (
            (value.estadoFactura === 0 || value.estadoFactura === 2) &&
            this.props.permisoRecibirFacturaOrdenDeCompra &&
            value.estado !== 0
          ) {
            buttons.push(
              <ButtonItem
                key={index.toString()}
                type="primary"
                size="small"
                onClick={() => this.recibirFacturaOrden(value._id)}
              >
                Recibir Factura
              </ButtonItem>
            );
          }
          if (value.estado !== 0 && value.xml) {
            buttons.push(
              <FileExcelOutlined
                key={index.toString()}
                onClick={() => this.descargarXml(value._id)}
              />
            );
          }
          if (
            (value.estado === 1 || value.estado === 2) &&
            this.props.permisoRecibirInsumosOrdenDeCompra
          ) {
            buttons.push(
              <Button
                key={index.toString()}
                type="default"
                size="small"
                onClick={() => this.recibirInsumosOrden(value._id)}
              >
                Recibir Insumos
              </Button>
            );
          }

          if(value.estadoFactura !== 0 && value.estado !== 0){
            buttons.push(
              <BookOutlined key={index.toString()} onClick={() => this.setState({modalFacturas: true, modalFacturasId: value._id})} />
            )
          }

          return <Space>{buttons}</Space>;
        },
        width: "27%",
        ellipsis: true,
      },
    ];
    return (
      <>
        <PageHeader
          title="Ordenes de Compras"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            this.props.permisoAgregarOrdenDeCompra && (
              <ButtonItem
                type="primary"
                key="agregarOrdenDeCompra"
                onClick={this.agregarOrdenDeCompra.bind(this)}
              >
                Agregar Orden de Compra
              </ButtonItem>
            )
          }
        />
        <TableItem
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                this.props.history.push("/panel/verOrdenDeCompra", { id: record._id });
              },
            };
          }}
          loading={this.state.loading}
          size="small"
          dataSource={this.state.todasOrdenesDeCompra}
          columns={columns}
          pagination={false}
        />
        <FacturasOrdenDeCompra
          history={this.props.history}
          visible={this.state.modalFacturas}
          id={this.state.modalFacturasId}
          cerrarFacturasOrdenDeCompra={this.cerrarFacturasOrdenDeCompra.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarOrdenDeCompra:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cbdcaf30e81b54183cf5e"
      )?.activado,
    permisoRecibirFacturaOrdenDeCompra:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cc021f30e81b54183cf62"
      )?.activado,
    permisoRecibirInsumosOrdenDeCompra:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "615cc05bf30e81b54183cf63"
      )?.activado,
    permisoCancelarOrdenDeCompra:
      state.user.tipo === 0 ||
      state.user.permisos.find(
        (valueTodos) =>
          valueTodos.permisoId.toString() === "6472d26e4b2da7c85c1a8cfd"
      )?.activado,
  };
};

export default connect(mapStateToProps)(OrdenesDeCompras);
