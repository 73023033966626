import React, { Component } from "react";
import { Divider, Select, Space } from "antd";
import {ButtonItem} from "./Items";

class SelectItem extends Component {
  render() {
    let propsNuevas = {};
    if (!this.props.noBuscar) {
      propsNuevas.filterOption = (input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      propsNuevas.showSearch = true;
    }
    if(this.props.mode === 'multiple'){
      propsNuevas.maxTagCount = 'responsive'
    }
    if(this.props.selectAll){
      propsNuevas.dropdownRender = (menu) => {
        return (
          <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
              <ButtonItem type="text" onClick={this.props.onSelectAll}>
                Seleccionar Todos
              </ButtonItem>
          </Space>
          </>
        )
      }
    }
    let transformedProperties = {};
    for (var key in this.props) {
      if (
        !(
          key.toString() === "noBuscar"
        )
      ) {
        transformedProperties = {
          ...transformedProperties,
          [key]: this.props[key],
        };
      }
    }
    const style = {
      color: this.props.disabled ? 'black' : null,
      backgroundColor: this.props.disabled ? this.props.fondoBlanco ? 'white' : '#F5F5F5' : null,
      borderBottom: this.props.sinBorde ? null : '1px solid #DADADA',
      borderRadius: 0,
      width: this.props.width ? this.props.width : undefined,
      ...this.props.style
    }
    return (
      <Select
        dropdownMatchSelectWidth={this.props.placeholder === 'Insumo' ? 600 : 400}
        {...propsNuevas}
        {...transformedProperties}
        placeholder={this.props.placeholder}
        style={style}
        bordered={false}
      >
        {this.props.children}
      </Select>
    );
  }
}

export default SelectItem;
