import moment from 'moment'
import 'moment/locale/es'
import {cuentaMask, dineroDisabledMask} from '../Components/Items'

moment.locale('es')

const reporteLibroDiarioPdf = (values) => {
    const empresa = values.empresa

    let polizasTables = []

    for (const valuePoliza of values.polizasFinales) {
        let ied
        let iedInicial
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 1){
            ied = 'Ingreso'
            iedInicial = 'I'
        }
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 2){
            ied = 'Egreso'
            iedInicial = 'E'
        }
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 3){
            ied = 'Traslado'
            iedInicial = 'T'
        }
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 4){
            ied = 'Nomina'
            iedInicial = 'N'
        }
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 5){
            ied = 'Pago'
            iedInicial = 'P'
        }
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 6){
            ied = 'Diario'
            iedInicial = 'D'
        }
        polizasTables.push({
            style: "tableExample",
            table: {
              widths: ['40%', '30%', '30%'],
              layout: {
                defaultBorder: false,
              },
    
              body: [
                [
                  {
                    table: {
                      widths: ['30%', '70%'],
                      layout: {
                        defaultBorder: false,
                      },
                      body: [
                        [
                            { text: "Fecha :", style: "subheader" },
                            { text: moment(valuePoliza.fecha).locale("es").format("L"), style: "textoChico" },
                        ],
                        [
                            { text: "Concepto :", style: "subheader" },
                            { text: valuePoliza.concepto, style: "textoChico" },
                        ],
                      ],
                    },
                    layout: "noBorders",
                  },
    
                  {
                    table: {
                      widths: ["30%", "70%"],
                      layout: {
                        defaultBorder: false,
                      },
                      body: [
                        [
                          { text: "N. Poliza:", style: "subheader" },
                          {
                            text: ied+': '+valuePoliza.numeroTipoTipoPoliza,
                            style: "textoChico",
                          },
                        ],
                        [
                            { text: "N. Poliza:", style: "subheader" },
                            {
                              text: valuePoliza.tipodepoliza.descripcion+': '+valuePoliza.numeroTipoPoliza,
                              style: "textoChico",
                            },
                        ],
                      ],
                    },
                    layout: "noBorders",
                  },
                  {
                    table: {
                      widths: ["30%", "70%"],
                      layout: {
                        defaultBorder: false,
                      },
                      body: [
                        [
                          { text: "Tipo Poliza: ", style: "subheader" },
                          {
                            text: '('+iedInicial+') '+ied,
                            style: "textoChico",
                          },
                        ],
                        [
                            { text: "Proyecto: ", style: "subheader" },
                            {
                              text: valuePoliza.proyecto.nombre+' - '+valuePoliza.proyecto.descripcion,
                              style: "textoChico",
                            },
                        ],
                      ],
                    },
                    layout: "noBorders",
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
          })

          let cargosTotal = 0
          let abonosTotal = 0
          let cargosAbonosTable = valuePoliza.cargosAbonos.map(valueCA => {
              let cargos = 0
              let abonos = 0
              if(valueCA.cargo){
                  cargos = valueCA.monto
                  cargosTotal += valueCA.monto
              }else{
                  abonos = valueCA.monto
                  abonosTotal += valueCA.monto
              }
              return [
                  {text: cuentaMask(valueCA.cuentaContable.nivel1+valueCA.cuentaContable.nivel2+valueCA.cuentaContable.nivel3+valueCA.cuentaContable.nivel4+valueCA.cuentaContable.nivel5), style: 'textoChico',  alignment: 'left'}, 
                  {text: valueCA.cuentaContable.descripcion, style: 'textoChico', alignment: 'left'}, 
                  {text: valueCA.concepto, style: 'textoChico', alignment: 'left'},
                  {text: cargos === 0 ? '-' : dineroDisabledMask(cargos), style: 'textoChico', alignment: 'right'},
                  {text: abonos === 0 ? '-' : dineroDisabledMask(abonos), style: 'textoChico', alignment: 'right'}, 
              ]
          })

          polizasTables.push(
            {
                style: 'tableExample',
                table: {
                    widths: ['14%', '35%', '31%', '10%', '10%'],
                    layout: {
                        defaultBorder: true,
                    },
                    body: [
                        [
                          {text: 'Cuenta', style: 'tituloTabla'},
                          {text: 'Descripción', style: 'tituloTabla'},
                          {text: 'Concepto', style: 'tituloTabla'},
                          {text: 'Cargo', style: 'tituloTabla'},
                          {text: 'Abono', style: 'tituloTabla'},
    
                        ],
                        ...cargosAbonosTable
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    vLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return {dash: {length: 2, space: 1}};
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return {dash: {length: 2}};
                    },
                }
            },
            {
                alignment: 'right',
              table: {
                  widths: ['15%', '30%', '25%','10%', '10%', '10%'],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                      [
                        {border: [false,false, false,false], text: '', style: 'subheader',},
                        {border: [false,false, false,false], text: '', style: 'subheader',},
                        {border: [false,false, false,false], text: '', style: 'subheader',},
                        {border: [false,false, false,false], text: 'Total:', style: 'subheader',},
                        {border: [false,false, false,false], text: dineroDisabledMask(cargosTotal), style: 'textoChico',},
                        {border: [false,false, false,false], text: dineroDisabledMask(abonosTotal), style: 'textoChico', alignment: 'right'}
                      ],
                  ]
              },
            }
          )
        
    }
    var dd = {
        content: [
            {
                style: 'tableExample',
                table: {
                    widths: [ '18%', '52%', '30%'],
                    layout: {
                        defaultBorder: false,
                    },
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                image: 'logo',
                                fit: [80, 80] 
                            },
                            {
                                border: [false, false, false, false],
                                table: {
                                  headerRows: 1,
                                  widths: ["100%"],
                
                                  body: [
                                    [
                                        { 
                                        text: empresa.persona ? empresa.nombre : empresa.nombre+' '+empresa.apellidoPaterno+' '+empresa.apellidoMaterno, 
                                        style: "tableHeader" 
                                        }
                                    ],
                                    [
                                      {
                                        text: "R.F.C: "+empresa.rfc,
                                        style: "textoChico",
                                        alignment: "center",
                                      },
                                    ],
                                    [
                                        {
                                          text: empresa.calle+' '+
                                                empresa.numeroCalle+' '+
                                                (empresa.numeroInterior ? empresa.numeroInterior : '')
                                          ,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                    [
                                        {
                                          text: empresa.colonia.tipoAsentamiento+' '+
                                          empresa.colonia.nombre+' C.P. '+
                                          empresa.colonia.cp+' '+
                                          empresa.municipio.nombre+' '+
                                          empresa.estado.abreviatura+' '+
                                          empresa.pais.abreviatura+' '
                                          ,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                    // empresa.telefono ? [
                                    //     {
                                    //         text: 'Tel: '+phoneMask(empresa.telefono),
                                    //         style: "textoChico",
                                    //         alignment: "center",
                                    //       },
                                    // ] : [],
                                    [
                                        {
                                          text: 'Regimen Fiscal: ('+empresa.regimenFiscal.c_RegimenFiscal+') '+empresa.regimenFiscal.descripcion
                                          ,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                  ],
                                },
                                layout: "noBorders",
                              },
                        ]
    
                    ]
                },
            },
            ...polizasTables,
        ],
        images: {
            logo: empresa.logo.base64Completa,
        },
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 8,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 5]
            },
            tablaSinMargen: {
                margin: [0, 0, 0, 0]
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black',
                alignment: 'center'
            },
            textoChico:{
                fontSize: 8
            },
            tituloTabla:{
                fontSize: 8,
                bold: true, 
                fillColor: '#cccccc', 
                style: 'subheader', 
                alignment: 'center',
                border: true
            },
            textoTabla: {
                fontSize: 8,
                italics: false
            }
        },
        pageSize: 'LETTER',
        // pageMargins: [ 20, 20, 20, 20 ],
    }
  return dd;
};


export default reporteLibroDiarioPdf;
