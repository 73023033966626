import { Button, message, Checkbox, Row, Col } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  ModalItem,
  FormItem,
  InputItem,
} from "../../../../Components/Items";


class AgregarTipoDeFlujo extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasConceptosIETU: null,
    objetoConceptosIETU: null,
  };
  cerrarAgregarTipoDeFlujo(codigo) {
    this.props.cerrarAgregarTipoDeFlujo(codigo);
  }
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "tiposdeflujos/agregarTipoDeFlujo",
        { ...values, empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.id) {
          message.success("Se agrego un tipo de flujo");
          this.formRef.current.resetFields();
          this.cerrarAgregarTipoDeFlujo(res.data.id);
        } else {
          message.error("Sucedio un error y no se guardo");
        }
      });
  }
  render() {
    return (
      <ModalItem
        title="Agregar Tipo de Flujo"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarAgregarTipoDeFlujo.bind(this)}
        paddingTop
        width="50%"
      >
        <Form
          ref={this.formRef}
          name="agregarTipoDeFlujo"
          layout
          bottom={
            <Button
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Agregar
            </Button>
          }
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <Row style={{ width: "100%" }}>
            <Col span={12}>
              <FormItem
                name="nombre"
                label="Nombre"
                required
                max={100}
                margin
                pattern
              >
                <InputItem placeholder="Nombre" />
              </FormItem>
              <FormItem
                name="descripcion"
                label="Descripción"
                margin
                pattern
                required
              >
                <InputItem placeholder="Descripción" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="ingreso"
                label="Ingreso"
                specialLayout
                margin
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox />
              </FormItem>
              <FormItem
                name="considerarEnFlujo"
                label="Considerar en flujo"
                specialLayout
                margin
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox />
              </FormItem>
              <FormItem
                name="generarDIOT"
                label="Generar DIOT"
                specialLayout
                margin
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarTipoDeFlujo);
