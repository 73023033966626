import { PageHeader, Button, Space, Popconfirm, message, Menu, Dropdown } from "antd";
import React, { Component } from "react";
import axios from 'axios'
import { TableItem } from "../../../Components/Items";
import { connect } from "react-redux";
import { StopOutlined, EditOutlined, CheckCircleOutlined, PlusOutlined, ImportOutlined, DownOutlined } from '@ant-design/icons'

class Clientes extends Component {
  state = {
    todasClientes: [],
    loading: false
  }
  componentDidMount() {
    this.consultarClientes();
  }

  consultarClientes() {
    this.setState({loading: true})
    axios
    .post(
      "clientes/selectTodosClientes",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasClientes: res.data
        });
      }
    });
  }
  agregarCliente(){
    this.props.history.push('/panel/agregarCliente')
  }
  importarClientes(){
    this.props.history.push('/panel/importarClientes')
  }
  editarCliente(id){
    this.props.history.push('/panel/editarCliente', {id})
  }
  deshabilitarCliente(value) {
    axios
      .post(
        "clientes/deshabilitarCliente",
        { clienteId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Cliente deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarClientes();
      });
  }
  habilitarCliente(value) {
    axios
      .post(
        "clientes/habilitarCliente",
        { clienteId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Cliente habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarClientes();
      });
  }
  handleMenuClick(e) {
    if(e.key === '1'){
      this.agregarCliente()
    }
    if(e.key === '2'){
      this.importarClientes()
    }
  }
  render() {
    const columns = [
      {
        title: "Codigo",
        dataIndex: "codigo",
        key: "codigo",
        sorter: (a, b) => {
          if (a.codigo < b.codigo) {
            return -1;
          }
          if (a.codigo > b.codigo) {
            return 1;
          }
          return 0;
        },
        buscar: "codigo",
      },
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Nombre Comercial",
        dataIndex: "nombreComercial",
        key: "nombreComercial",
        sorter: (a, b) => {
          if (a.nombreComercial < b.nombreComercial) {
            return -1;
          }
          if (a.nombreComercial > b.nombreComercial) {
            return 1;
          }
          return 0;
        },
        buscar: "nombreComercial",
        ellipsis: true,
      },
      {
        title: "RFC",
        dataIndex: "rfc",
        key: "rfc",
        sorter: (a, b) => {
          if (a.rfc < b.rfc) {
            return -1;
          }
          if (a.rfc > b.rfc) {
            return 1;
          }
          return 0;
        },
        buscar: "rfc",
      },
      {
        title: "Tipo de Cliente",
        dataIndex: "tipoCliente",
        key: "tipoCliente",
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            {this.props.permisoEditarCliente &&
              <EditOutlined
                onClick={() => this.editarCliente(value._id)}
                style={{ color: "blue" }}
              />
            }
            {this.props.permisoDeshabilitarCliente && 
            <>
              {value.estado === 1 ? (
                <Popconfirm
                  title="Seguro que quieres deshabilitar este cliente"
                  onConfirm={() => this.deshabilitarCliente(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Seguro que quieres habilitar este cliente"
                  onConfirm={() => this.habilitarCliente(value._id)}
                  okText="Si"
                  cancelText="No"
                >
                  <StopOutlined style={{ color: "red" }} />
                </Popconfirm>
              )}
            </>
            }
          </Space>
        ),
      },
    ];
        const menu = (
          <Menu onClick={this.handleMenuClick.bind(this)}>
            <Menu.Item key="1" icon={<PlusOutlined />}>
              Agregar Cliente
            </Menu.Item>
            <Menu.Item key="2" icon={<ImportOutlined />}>
              Importar Clientes
            </Menu.Item>
          </Menu>
        );
    return (
      <>
        <PageHeader
          title="Clientes"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarCliente &&
            <Dropdown overlay={menu} trigger={['click']}>
            <Button type='primary'>
          Agregar <DownOutlined />
        </Button>
          </Dropdown>
          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasClientes} columns={columns} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarCliente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd44bf30e81b54183cf88")?.activado,
    permisoEditarCliente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cd47cf30e81b54183cf89")?.activado,
    permisoDeshabilitarCliente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce419f30e81b54183cfb1")?.activado,
  };
};

export default connect(mapStateToProps)(Clientes);
