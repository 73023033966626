import { Collapse, Row, Col, Tag, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    dineroDisabledMask,
    ModalItem,
    ButtonItem,
} from "../../../../Components/Items";
import moment from "moment";
import xmlToJson from "../../../../Components/xmlToJson";

const { Panel } = Collapse;

class AgregarAOrdenDeCompra extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        ordenesHtml: <p>No se encontro ninguna orden de compra del proveedor</p>,
    };
    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible)
            this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "ordenesdecompra/ordenesDeCompraRelacionarXml",
                { xmlId: this.props.id, empresaId: this.props.empresaId },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    if (res?.data?.ordenesDeCompra?.length > 0) {
                        const html = (
                            <Collapse style={{ marginBottom: 10 }}>
                                {res.data.ordenesDeCompra.map((valueODC) => {

                                    let relacionarAOrdenDeCompra = null

                                    if (valueODC.estado !== 0) {
                                            relacionarAOrdenDeCompra = (
                                                <ButtonItem type="primary" onClick={() => this.agregarAOrdendeCompra(valueODC._id)}>
                                                    Relacionar a Orden
                                                </ButtonItem>
                                            )
                                    }

                                    return (
                                        <Panel
                                            extra={
                                                valueODC.estado !== 0 ? (
                                                    valueODC.moneda.abreviacion + ' - ' + dineroDisabledMask(valueODC.totalPagar)
                                                ) : (
                                                    <Tag color="red">
                                                        Cancelado: {valueODC.moneda.abreviacion + ' - ' + dineroDisabledMask(valueODC.totalPagar)}
                                                    </Tag>
                                                )
                                            }
                                            header={moment(valueODC.createdAt).locale("es").format("ll")}
                                            key={valueODC._id}
                                        >
                                            <Row>
                                                <Col span={12}>
                                                    <p>
                                                        Fecha de la Orden:{" "}
                                                        {moment(valueODC.createdAt).locale("es").format("ll")}
                                                    </p>
                                                    <p>
                                                        Subtotal:{" "}
                                                        {valueODC.moneda.abreviacion + ' - ' + dineroDisabledMask(valueODC.subtotal)}
                                                    </p>
                                                </Col>
                                                <Col span={12}>
                                                    <p>
                                                        Dia de registro:{" "}
                                                        {moment(valueODC.createdAt).locale("es").format("ll")}
                                                    </p>
                                                    <p>Total: {valueODC.moneda.abreviacion + ' - ' + dineroDisabledMask(valueODC.totalPagar)}</p>
                                                    {relacionarAOrdenDeCompra}
                                                </Col>
                                            </Row>
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                        );
                        this.setState({ ordenesHtml: html });
                    }
                } else if (res.data.success === 1) {
                    message.error(res.data.message);
                } else {
                    message.error("Hubo un error y no se efectuo el pago");
                }
            });
        this.setState({ loading: false });
    }


    async agregarAOrdendeCompra(ordenId) {
        this.setState({loading: true})
        await axios.post(
            "descargaMasiva/descargarXml",
            { xmlId: this.props.id, empresaId: this.props.empresaId },
            { headers: this.props.headersToken }
        )
        .then((res) => {
            if (res.data.success === 2) {
                const xmlJsonData = JSON.stringify(new xmlToJson().parseFromString(res.data.xml))
                this.props.history.push("/panel/recibirFacturaOrdenDeCompra", { id: ordenId, fileData: xmlJsonData, fileName: res.data.name });
              } else {
                message.error("Hubo un error y no se pudo descargar");
              }
        })
    }

    cerrarModalOrdenDeCompra() {
        this.setState({ordenesHtml: <p>No se encontro ninguna orden de compra del proveedor</p>})
        this.props.cerrarModalOrdenDeCompra();
    }

    render() {
        return (
            <>
                <ModalItem
                    title="Ordenes A Relacionar"
                    visible={this.props.visible}
                    spinning={this.state.loading}
                    onCancel={this.cerrarModalOrdenDeCompra.bind(this)}
                    paddingTop
                >
                    {this.state.ordenesHtml}
                </ModalItem>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(AgregarAOrdenDeCompra);