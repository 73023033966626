import React, { Component } from "react";
import {
    PageHeader,
    Spin,
    Row,
    Col,
    Select,
    Form as FormInitial,
    notification,
    Tabs,
    Switch,
    Space
} from "antd";
import {
    Form,
    FormItem,
    InputNumberItem,
    SelectItem,
    DatePickerItem,
    HeaderItem,
    BoxItem,
    ButtonItem,
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import MandarCorreoCartaPorte from "../MandarCorreoCartaPorte/MandarCorreoCartaPorte";
import EntregarEnvio from "../EntregarEnvio/EntregarEnvio";
import CancelarEnvio from "../CancelarEnvio/CancelarEnvio";

const { Option } = Select;
const { TabPane } = Tabs;

moment.locale("es");

class VerEnvio extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        datosVenta: undefined,
        todasInsumos: null,
        objetoInsumos: null,
        todasDireccionesCliente: null,
        objetoDireccionesCliente: null,
        todasProyectosFisicos: null,
        objetoProyectosFisicos: null,
        todasProyectosMoviles: null,
        objetoProyectosMoviles: null,
        todasProyectos: null,
        objetoProyectos: null,
        todasUbicacionesPolos: null,
        objetoUbicacionesPolos: null,
        todasChoferes: null,
        objetoChoferes: null,
        id: this.props.location.state.id,
        numeroEnvio: 0,
        modalVerCartaPorte: false,
        modalVerCartaPorteId: null,
        datosEnvio: null,
        modalEntregarEnvio: false,
        modalEntregarEnvioId: null,
        modalCancelarEnvio: false,
        modalCancelarEnvioId: null,
    };
    componentDidMount() {
        this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "envios/datosEnvio",
                { empresaId: this.props.empresaId, envioId: this.state.id },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.success === 2) {
                    this.setState({ datosEnvio: res.data.envio, numeroEnvio: res.data.envio.numero, todasChoferes: res.data.choferes, todasProyectos: res.data.todosProyectos, todasDireccionesCliente: res.data.direccionesCliente, datosVenta: res.data.venta, todasInsumos: res.data.insumos, todasProyectosFisicos: res.data.proyectosFisicos, todasProyectosMoviles: res.data.proyectosMoviles, todasUbicacionesPolos: res.data.ubicacionesPolos });
                    this.setState({
                        objetoDireccionesCliente: res.data.direccionesCliente.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombreDireccion}
                                </Option>
                            );
                        }),
                        objetoInsumos: res.data.insumos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.stringBuscar}
                                </Option>
                            );
                        }),
                        objetoProyectosFisicos: res.data.proyectosFisicos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoProyectosMoviles: res.data.proyectosMoviles.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoProyectos: res.data.todosProyectos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                        objetoUbicacionesPolos: res.data.ubicacionesPolos.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.Clave + ' - ' + value.Descripcion}
                                </Option>
                            );
                        }),
                        objetoChoferes: res.data.choferes.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                    });
                    this.formRef.current.setFieldsValue({ ...res.data.envio, fechaSalida: moment(res.data.envio.fechaSalida), fechaLlegada: moment(res.data.envio.fechaLlegada) })

                } else {
                    notification.warning({
                        message: 'No se encontro informacion del envio',
                        description: res.data.message
                    })
                }
            });
        this.setState({ loading: false });
    }
    atras() {
        this.props.history.goBack();
    }
    cerrarMandarCorreoCartaPorte() {
        this.setState({
            modalVerCartaPorte: false,
            modalVerCartaPorteId: null,
        });
    }
    cerrarEntregarEnvio() {
        this.setState({
            modalEntregarEnvio: false,
            modalEntregarEnvioId: null,
        });
        this.consultarTodo()
    }
    cerrarCancelarEnvio() {
        this.setState({
            modalCancelarEnvio: false,
            modalCancelarEnvioId: null,
        });
        this.consultarTodo()
    }
    render() {
        return (
            <>
                <PageHeader
                    onBack={this.atras.bind(this)}
                    title="Ver Envio"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                />
                <div style={{ backgroundColor: "white", paddingRight: 20, paddingLeft: 20 }}>
                    <Spin spinning={this.state.loading}>
                        <Form
                            ref={this.formRef}
                            name="verEnvioVenta"
                            layout
                            bottom={(
                                <div style={{ display: "flex", justifyContent: "right" }}>
                                    <Space>
                                        {(this.state?.datosEnvio?.estado === 1 || this.state?.datosEnvio?.estado === 2) ?
                                            <ButtonItem type="primary" onClick={() => {
                                                this.setState({ modalEntregarEnvio: true, modalEntregarEnvioId: this.state.id })
                                            }}>
                                                Entregar Envio
                                            </ButtonItem>
                                            : null}
                                        {this.state?.datosEnvio?.generarCarta && (
                                            <ButtonItem type="primary" blanco onClick={() => {
                                                this.setState({ modalVerCartaPorte: true, modalVerCartaPorteId: this.state.id })
                                            }}>
                                                Ver Carta Porte
                                            </ButtonItem>
                                        )}
                                        {this.state?.datosEnvio?.estado !== 0 && (
                                            <ButtonItem type="primary" danger onClick={() => {
                                                this.setState({ modalCancelarEnvio: true, modalCancelarEnvioId: this.state.id })
                                            }}>
                                                Cancelar Envio
                                            </ButtonItem>
                                        )}
                                    </Space>
                                </div>
                            )}
                        >
                            <Tabs defaultActiveKey={1}>
                                <TabPane tab="General" tabKey={1} key={1} forceRender>

                                    <HeaderItem>GENERAL</HeaderItem>
                                    <BoxItem>

                                        <Row>
                                            <Col span={12}>

                                                <FormItem
                                                    label="Generar Carta Porte"
                                                    name="generarCarta"
                                                    margin
                                                    initialValue={false}
                                                    valuePropName="checked"
                                                >
                                                    <Switch disabled />
                                                </FormItem>
                                                <FormItem label="Proyecto Origen" name="proyectoOrigenId" margin required>
                                                    <SelectItem placeholder="Proyecto Origen" disabled>
                                                        {this.state.objetoProyectosFisicos}
                                                    </SelectItem>
                                                </FormItem>
                                                <FormItem label="Destino" name="direccionClienteDestinoId" margin required>
                                                    <SelectItem placeholder="Destino" disabled>
                                                        {this.state.objetoDireccionesCliente}
                                                    </SelectItem>
                                                </FormItem>
                                                <FormItem label="Automovil Traslado" name="proyectoTransporteId" margin required>
                                                    <SelectItem placeholder="Automovil Traslado" disabled>
                                                        {this.state.objetoProyectosMoviles}
                                                    </SelectItem>
                                                </FormItem>
                                                <FormItem label="Choferes" name="choferesIds" margin>
                                                    <SelectItem placeholder="Choferes" mode="multiple" allowClear disabled>
                                                        {this.state.objetoChoferes}
                                                    </SelectItem>
                                                </FormItem>

                                            </Col>
                                            <Col span={12}>

                                                <FormItem
                                                    label="Transporte Internacional"
                                                    name="tranporteInt"
                                                    margin
                                                    initialValue={false}
                                                    valuePropName="checked"
                                                >
                                                    <Switch disabled />
                                                </FormItem>
                                                <FormItem
                                                    label='Distancia Recorrida'
                                                    placeholder='Distancia Recorrida'
                                                    name='totalDistRecorrida'
                                                    number
                                                    margin
                                                >
                                                    <InputNumberItem disabled />
                                                </FormItem>
                                                <FormItem
                                                    label="Registro ISTMO"
                                                    name="registroISTMO"
                                                    margin
                                                    initialValue={false}
                                                    valuePropName="checked"
                                                >
                                                    <Switch disabled />
                                                </FormItem>
                                                <FormItem shouldUpdate noStyle>
                                                    {({ getFieldValue }) => {
                                                        if (getFieldValue('registroISTMO')) {
                                                            return <>
                                                                <FormItem label="Ubicacion Polo Origen" name="ubicacionPoloOrigenId" margin required>
                                                                    <SelectItem disabled placeholder="Ubicacion Polo Origen">
                                                                        {this.state.objetoUbicacionesPolos}
                                                                    </SelectItem>
                                                                </FormItem>
                                                                <FormItem label="Ubicacion Polo Destino" name="ubicacionPoloDestinoId" margin required>
                                                                    <SelectItem disabled placeholder="Ubicacion Polo Destino">
                                                                        {this.state.objetoUbicacionesPolos}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </>
                                                        }
                                                    }}
                                                </FormItem>
                                            </Col>
                                        </Row>

                                    </BoxItem>

                                    <HeaderItem>FECHAS Y HORAS</HeaderItem>
                                    <BoxItem>
                                        <Row>
                                            <Col span={12}>

                                                <FormItem
                                                    label="Fecha de Salida"
                                                    name="fechaSalida"
                                                    required
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={moment().hour(12).minute(0)}
                                                >
                                                    <DatePickerItem
                                                        disabled
                                                        placeholder="Fecha de Salida"
                                                        allowClear={false}
                                                        showTime
                                                        dateFormat='DD/MM/YYYY HH:mm'
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col span={12}>
                                                <FormItem
                                                    label="Fecha de Llegada"
                                                    name="fechaLlegada"
                                                    required
                                                    margin
                                                    labelCol={{ span: 8 }}
                                                    wrapperCol={{ span: 16 }}
                                                    initialValue={moment().hour(12).minute(0)}
                                                >
                                                    <DatePickerItem
                                                        disabled
                                                        placeholder="Fecha de Llegada"
                                                        allowClear={false}
                                                        showTime
                                                        dateFormat='DD/MM/YYYY HH:mm'
                                                    />
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </BoxItem>

                                </TabPane>
                                <TabPane tab="Insumos" tabKey={2} key={2} forceRender>

                                    <FormInitial.List name="insumos">
                                        {(fields) => (
                                            <>
                                                <table className="table">
                                                    {fields.length > 0 ? (
                                                        <thead>
                                                            <tr>
                                                                <th className="th-border" style={{ width: "40%" }}>
                                                                    Insumo
                                                                </th>
                                                                <th className="th-border" style={{ width: "40%" }}>
                                                                    Proyecto
                                                                </th>
                                                                <th className="th-border" style={{ width: "20%" }}>
                                                                    Cantidad Enviada
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                    ) : null}
                                                    {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                                                        <tbody key={fieldKey}>
                                                            <tr>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "insumoId"]}
                                                                        fieldKey={[fieldKey, "insumoId"]}
                                                                        required
                                                                        noStyle
                                                                    >
                                                                        <SelectItem
                                                                            sinBorde
                                                                            style={{ width: "100%" }}
                                                                            placeholder="Insumo"
                                                                            disabled
                                                                        >
                                                                            {this.state.objetoInsumos}
                                                                        </SelectItem>
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem
                                                                        {...restField}
                                                                        name={[name, "proyectoId"]}
                                                                        fieldKey={[fieldKey, "proyectoId"]}
                                                                        required
                                                                        noStyle
                                                                    >
                                                                        <SelectItem
                                                                            disabled
                                                                            sinBorde
                                                                            style={{ width: "100%" }}
                                                                            placeholder="Proyecto"
                                                                            onChange={(value) =>
                                                                                this.onChangeInsumoProyecto(
                                                                                    value,
                                                                                    arrayKey
                                                                                )
                                                                            }
                                                                        >
                                                                            {this.state.objetoProyectos}
                                                                        </SelectItem>
                                                                    </FormItem>
                                                                </td>
                                                                <td className="td-border">
                                                                    <FormItem shouldUpdate noStyle>
                                                                        <FormItem
                                                                            {...restField}
                                                                            name={[name, "cantidad"]}
                                                                            fieldKey={[fieldKey, "cantidad"]}
                                                                            number
                                                                            noStyle
                                                                            type="number"
                                                                        >
                                                                            <InputNumberItem
                                                                                sinBorde
                                                                                disabled
                                                                            />
                                                                        </FormItem>
                                                                    </FormItem>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ))}
                                                </table>
                                            </>
                                        )}
                                    </FormInitial.List>
                                </TabPane>
                            </Tabs>
                        </Form>
                    </Spin>
                </div>
                <MandarCorreoCartaPorte
                    history={this.props.history}
                    visible={this.state.modalVerCartaPorte}
                    id={this.state.modalVerCartaPorteId}
                    cerrarMandarCorreoCartaPorte={this.cerrarMandarCorreoCartaPorte.bind(this)}
                />
                <EntregarEnvio
                    visible={this.state.modalEntregarEnvio}
                    id={this.state.modalEntregarEnvioId}
                    history={this.props.history}
                    cerrarEntregarEnvio={this.cerrarEntregarEnvio.bind(this)}
                />
                                                <CancelarEnvio
                    visible={this.state.modalCancelarEnvio}
                    id={this.state.modalCancelarEnvioId}
                    history={this.props.history}
                    cerrarCancelarEnvio={this.cerrarCancelarEnvio.bind(this)}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(VerEnvio);
