import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivRoute = ({ component, isUserLoggedIn, ...rest }) => {

    let ComponentToRender = component;

    return (
        <Route
            {...rest}
            render={props =>
                isUserLoggedIn ? (
                    <ComponentToRender {...props} />
                ) : 
                (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location, redirect: true }
                        }}
                    />
                )
            }
        />
    );
}

const mapStateToProps  = (state) => ({ isUserLoggedIn: state.user.isUserLoggedIn });

export default connect(mapStateToProps)(PrivRoute)