import {
  Col,
  message,
  PageHeader,
  Row,
  Spin,
  Select,
  Button,
  Checkbox,
  Space,
  Tabs,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { connect } from "react-redux";
// import balanceGeneralPdf from "../../../../Pdf/balanceGeneralPdf";
import {
  SelectItem,
  Form,
  FormItem,
  cuentaMask,
  RangePickerItem,
  TableItem,
  dineroDisabledMask,
} from "../../../../Components/Items";
import reportesDeVentasPdf from "../../../../Pdf/reporteDeVentasPdf";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Option } = Select;
const { TabPane } = Tabs;

class ReporteVentas extends Component {
  state = {
    loading: false,
    pdf: null,
    objetoClientes: null,
    todasClientes: null,
    objetoUsuarios: null,
    todasUsuarios: null,
    objetoProyectos: null,
    todasProyectos: null,
    objetoUniadesDeNegocio: null,
    todasUniadesDeNegocio: null,
    dataReporte: null,
    todasVentas: [],
    dataGrafico: [],
    subtotal: 0,
    totalImpuestos: 0,
    total: 0,
    totalRetenciones: 0,
    totalACobrar: 0,
    desglosarProductos: false
  };

  atras() {
      this.props.history.goBack();
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await axios
      .post(
        "clientes/selectClientes",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasClientes: res.data });
          this.setState({
            objetoClientes: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "usuarios/selectUsuariosEmpresa",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasUsuarios: res.data });
          this.setState({
            objetoUsuarios: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre +
                    " " +
                    value.apellidoPaterno +
                    " " +
                    value.apellidoMaterno}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "unidadesdenegocio/selectUnidadesDeNegocio",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasUniadesDeNegocio: res.data });
          this.setState({
            objetoUniadesDeNegocio: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    this.consultarReporteVentas({
      periodo: [moment().subtract(1, "month"), moment()],
      clientes: 0,
      vendedores: 0,
      filtrarProyecto: 0,
      estadoFacturas: 5,
    });
  }

  async crearGrafico(valueVentas, fechaInicial, fechaFinal) {
    let grafico = [];
    let graficoSumatoria = [];
    let graficoCostos = [];
    let graficoCostosAcumulados = [];
    let sumatoria = 0;
    let sumatoriaCostos = 0;

    let fechaCambiante = moment(fechaInicial).locale("es");
    const fechaFinalMoment = moment(fechaFinal).locale("es");

    if (fechaFinalMoment.diff(fechaCambiante, "days") <= 31) {
      while (fechaFinalMoment.diff(fechaCambiante, "days") !== -1) {
        // eslint-disable-next-line no-loop-func
        const ventas = valueVentas.filter((todasVentas) => {
          if (isNaN(todasVentas.fechaFactura)) {
            return moment(fechaCambiante).isSame(
              moment(todasVentas.fechaFactura, "DD/MM/YYYY"),
              "date"
            );
          } else {
            return false;
          }
        });

        let totalPagar = 0;
        let costoVenta = 0

        ventas.map((valueVentaDia) => {
          totalPagar += valueVentaDia.totalPagar;
          costoVenta += valueVentaDia.costoVenta;
          return null;
        });

        grafico.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: totalPagar,
        });

        graficoSumatoria.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: totalPagar + sumatoria,
        });

        graficoCostos.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: costoVenta,
        });

        graficoCostosAcumulados.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: costoVenta + sumatoriaCostos,
        });

        sumatoria += totalPagar;
        sumatoriaCostos += costoVenta;
        fechaCambiante = moment(fechaCambiante).add(1, "day").locale("es");
      }
    } else if (fechaFinalMoment.diff(fechaCambiante, "days") < 180) {
      while (fechaFinalMoment.diff(fechaCambiante, "weeks") !== -1) {
        // eslint-disable-next-line no-loop-func
        const ventas = valueVentas.filter((todasVentas) => {
          if (isNaN(todasVentas.fechaFactura)) {
            return moment(fechaCambiante).isSame(
              moment(todasVentas.fechaFactura, "DD/MM/YYYY"),
              "week"
            );
          } else {
            return false;
          }
        });
        let totalPagar = 0;
        let costoVenta = 0

        ventas.map((valueVentaDia) => {
          totalPagar += valueVentaDia.totalPagar;
          costoVenta += valueVentaDia.costoVenta;
          return null;
        });

        grafico.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: totalPagar,
        });

        graficoSumatoria.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: totalPagar + sumatoria,
        });

        graficoCostos.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: costoVenta,
        });

        graficoCostosAcumulados.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: costoVenta + sumatoriaCostos,
        });

        sumatoria += totalPagar;
        sumatoriaCostos += costoVenta;
        fechaCambiante = moment(fechaCambiante).add(1, "week").locale("es");
      }
    } else if (fechaFinalMoment.diff(fechaCambiante, "months") < 36) {
      while (fechaFinalMoment.diff(fechaCambiante, "months") !== -1) {
        // eslint-disable-next-line no-loop-func
        const ventas = valueVentas.filter((todasVentas) => {
          if (isNaN(todasVentas.fechaFactura)) {
            return moment(fechaCambiante).isSame(
              moment(todasVentas.fechaFactura, "DD/MM/YYYY"),
              "month"
            );
          } else {
            return false;
          }
        });
        let totalPagar = 0;
        let costoVenta = 0

        ventas.map((valueVentaDia) => {
          totalPagar += valueVentaDia.totalPagar;
          costoVenta += valueVentaDia.costoVenta;
          return null;
        });

        grafico.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: totalPagar,
        });

        graficoSumatoria.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: totalPagar + sumatoria,
        });

        graficoCostos.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: costoVenta,
        });

        graficoCostosAcumulados.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: costoVenta + sumatoriaCostos,
        });

        sumatoria += totalPagar;
        sumatoriaCostos += costoVenta;
        fechaCambiante = moment(fechaCambiante).add(1, "month").locale("es");
      }
    } else {
      while (fechaFinalMoment.diff(fechaCambiante, "years") !== -1) {
        // eslint-disable-next-line no-loop-func
        const ventas = valueVentas.filter((todasVentas) => {
          if (isNaN(todasVentas.fechaFactura)) {
            return moment(fechaCambiante).isSame(
              moment(todasVentas.fechaFactura, "DD/MM/YYYY"),
              "year"
            );
          } else {
            return false;
          }
        });
        let totalPagar = 0;
        let costoVenta = 0

        ventas.map((valueVentaDia) => {
          totalPagar += valueVentaDia.totalPagar;
          costoVenta += valueVentaDia.costoVenta;
          return null;
        });

        grafico.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: totalPagar,
        });

        graficoSumatoria.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: totalPagar + sumatoria,
        });

        graficoCostos.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: costoVenta,
        });

        graficoCostosAcumulados.push({
          x: moment(fechaCambiante).locale("es").format("DD/MM"),
          y: costoVenta + sumatoriaCostos,
        });

        sumatoria += totalPagar;
        sumatoriaCostos += costoVenta;
        fechaCambiante = moment(fechaCambiante).add(1, "year").locale("es");
      }
    }

    await this.setState({
      dataGrafico: [
        {
          id: "Ventas",
          color: "hsl(15, 70%, 50%)",
          data: grafico,
        },
        {
          id: "Ventas Acumuladas",
          color: "hsl(15, 70%, 50%)",
          data: graficoSumatoria,
        },
        {
          id: "Costo",
          color: "hsl(219, 100%, 45%)",
          data: graficoCostos,
        },
        {
          id: "Costo Acumulado",
          color: "hsl(219, 100%, 68%)",
          data: graficoCostosAcumulados,
        },
      ],
    });
  }

  consultarReporteVentas(values) {
    this.setState({ loading: true });
    axios
      .post(
        "reportesDeVentas/reporteVentas",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          this.crearGrafico(
            res.data.ventas,
            res.data.fechaInicial,
            res.data.fechaFinal
          );
          this.setState({
            loading: false,
            dataReporte: res.data,
            todasVentas: res.data.ventas,
            subtotal: res.data.subtotal,
            totalImpuestos: res.data.totalImpuestos,
            total: res.data.total,
            totalRetenciones: res.data.totalRetenciones,
            totalACobrar: res.data.totalACobrar,
            desglosarProductos: res.data.desglosarProductos,
          });
        } else if (res.data.success === 1) {
          message.error("Ha sucedido un error y no se cargo bien el reporte");
        } else {
          message.error("Ha sucedido un error y no se cargo bien el reporte");
        }
        this.setState({ loading: false });
      });
  }

  descargarExcel() {
    let datosDescargar = []
    if(this.state.desglosarProductos){
      for (const valueVenta of this.state.todasVentas) {
        if(valueVenta.folio === ''){
          const venta = this.state.todasVentas.find(todasventas => todasventas.folio !== '' && todasventas._id.toString() === valueVenta._id.toString())
          datosDescargar.push({
            Folio: venta.folio,
            "# Factura": venta.facturaNumero,
            "# Remision": venta.remisionNumero,
            "Codigo Cliente": venta.codigoCliente,
            "Clave Cliente": venta.claveCliente,
            Fecha: venta.fechaFactura,
            Cliente: venta.cliente,
            "Codigo Insumo": valueVenta.codigoCliente,
            "Clave Insumo": valueVenta.claveCliente,
            "Cantidad": valueVenta.fechaFactura,
            "Descripcion": valueVenta.cliente,
            "Familia": valueVenta.familia,
            "Precio Unitario": valueVenta.total,
            "Descuento": valueVenta.descuento,
            Costo: valueVenta.costoTotal, 
            "Subtotal Insumo": valueVenta.totalPagado,
            "Precio Con Impuestos": valueVenta.saldo,
            "Total Insumo": valueVenta.estado,
            "Fecha probable de pago": venta.fechaPago,
            "Total A Cobrar": venta.totalPagar,
            "Fecha Vencimiento": venta.fechaVencimiento,
            Total: venta.total,
            Abonos: venta.totalPagado,
            Saldo: venta.saldo,
            Estado: venta.estado,
            Vendedor: venta.vendedor,
          })
        }
      }
    }else{
      datosDescargar = this.state.todasVentas.map((valueVenta) => {
        return {
          Folio: valueVenta.folio,
          "# Factura": valueVenta.facturaNumero,
          "# Remision": valueVenta.remisionNumero,
          "Codigo Cliente": valueVenta.codigoCliente,
          "Clave Cliente": valueVenta.claveCliente,
          Fecha: valueVenta.fechaFactura,
          Cliente: valueVenta.cliente,
          "Fecha probable de pago": valueVenta.fechaPago,
          "Total A Cobrar": valueVenta.totalPagar,
          "Fecha Vencimiento": valueVenta.fechaVencimiento,
          Total: valueVenta.total,
          Costo: valueVenta.costoTotal,
          Abonos: valueVenta.totalPagado,
          Saldo: valueVenta.saldo,
          Estado: valueVenta.estado,
          Vendedor: valueVenta.vendedor,
        };
      });
    }
    const worksheet = XLSX.utils.json_to_sheet(datosDescargar);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte Ventas");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `Reporte_De_Ventas_${moment().locale("es").format("L")}.xlsx`);
  }

  descargarPdf() {
    pdfMake
      .createPdf(reportesDeVentasPdf(this.state.dataReporte))
      .download(`Reporte_De_Ventas_${moment().locale("es").format("L")}`);
  }

  onFinishFailed(e) {
    console.log(e);
    message.error("Porfavor ingrese bien los datos");
  }
  render() {
    const columns = [
      {
        title: "Folio",
        dataIndex: "folio",
        key: "folio",
        sorter: (a, b) => {
          if (a.folio < b.folio) {
            return -1;
          }
          if (a.folio > b.folio) {
            return 1;
          }
          return 0;
        },
        buscar: "folio",
        ellipsis: true,
      },
      {
        title: "Fecha",
        dataIndex: "fechaFactura",
        key: "fechaFactura",
        sorter: (a, b) => {
          if (moment(a.fechaFactura, "DD/MM/YYYY") < moment(b.fechaFactura, "DD/MM/YYYY")) {
            return -1;
          }
          if (moment(a.fechaFactura, "DD/MM/YYYY") > moment(b.fechaFactura, "DD/MM/YYYY")) {
            return 1;
          }
          return 0;
        },
        buscar: "fechaFactura",
        ellipsis: true,
      },
      {
        title: "Cliente",
        dataIndex: "cliente",
        key: "cliente",
        sorter: (a, b) => {
          if (a.cliente < b.cliente) {
            return -1;
          }
          if (a.cliente > b.cliente) {
            return 1;
          }
          return 0;
        },
        buscar: "cliente",
        ellipsis: true,
      },
      {
        title: "Vencimiento",
        dataIndex: "fechaVencimiento",
        key: "fechaVencimiento",
        sorter: (a, b) => {
          if (moment(a.fechaVencimiento, "DD/MM/YYYY") < moment(b.fechaVencimiento, "DD/MM/YYYY")) {
            return -1;
          }
          if (moment(a.fechaVencimiento, "DD/MM/YYYY") > moment(b.fechaVencimiento, "DD/MM/YYYY")) {
            return 1;
          }
          return 0;
        },
        buscar: "fechaVencimiento",
        ellipsis: true,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        sorter: (a, b) => {
          if (a.total < b.total) {
            return -1;
          }
          if (a.total > b.total) {
            return 1;
          }
          return 0;
        },
        buscar: "total",
        render: (value) => dineroDisabledMask(value),
        align: "right",
        ellipsis: true,
      },
      {
        title: "Costo",
        dataIndex: "costoTotal",
        key: "costoTotal",
        sorter: (a, b) => {
          if (a.costoTotal < b.costoTotal) {
            return -1;
          }
          if (a.costoTotal > b.costoTotal) {
            return 1;
          }
          return 0;
        },
        buscar: "costoTotal",
        render: (value) => dineroDisabledMask(value),
        align: "right",
        ellipsis: true,
      },
      {
        title: "Abonos",
        dataIndex: "totalPagado",
        key: "totalPagado",
        sorter: (a, b) => {
          if (a.totalPagado < b.totalPagado) {
            return -1;
          }
          if (a.totalPagado > b.totalPagado) {
            return 1;
          }
          return 0;
        },
        buscar: "totalPagado",
        ellipsis: true,
        render: (value) => dineroDisabledMask(value),
        align: "right",
      },
      {
        title: "Saldo",
        dataIndex: "saldo",
        key: "saldo",
        sorter: (a, b) => {
          if (a.saldo < b.saldo) {
            return -1;
          }
          if (a.saldo > b.saldo) {
            return 1;
          }
          return 0;
        },
        buscar: "saldo",
        ellipsis: true,
        render: (value) => dineroDisabledMask(value),
        align: "right",
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "estado",
        sorter: (a, b) => {
          if (a.estado < b.estado) {
            return -1;
          }
          if (a.estado > b.estado) {
            return 1;
          }
          return 0;
        },
        buscar: "estado",
        ellipsis: true,
      },
      {
        title: "Vendedor",
        dataIndex: "vendedor",
        key: "vendedor",
        sorter: (a, b) => {
          if (a.vendedor < b.vendedor) {
            return -1;
          }
          if (a.vendedor > b.vendedor) {
            return 1;
          }
          return 0;
        },
        buscar: "vendedor",
        ellipsis: true,
      },
    ];
    return (
      <>
        <Form
          ref={this.formRef}
          name="agregarUsuario"
          onFinish={this.consultarReporteVentas.bind(this)}
          onFinishFailed={this.onFinishFailed}
          initialValues={{
            periodo: [moment().subtract(1, "month"), moment()],
          }}
        >
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Reporte Ventas"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Space>
                  {this.state.dataReporte && (
                    <Button
                      onClick={() => this.descargarExcel()}
                      icon={<FileExcelOutlined />}
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Descargar Excel
                    </Button>
                  )}
                  {this.state.dataReporte && (
                    <Button
                      onClick={() => this.descargarPdf()}
                      icon={<FilePdfOutlined />}
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Descargar PDF
                    </Button>
                  )}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Consultar
                  </Button>
                </Space>
              </div>
            }
          />
          <Spin spinning={this.state.loading}>
            <div
              style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}
            >
              <Tabs
                style={{ margin: 0 }}
                tabBarExtraContent={{
                  left: (
                    <h1 style={{ padding: 0, margin: "0 10px 0 0" }}>
                      Filtros:
                    </h1>
                  ),
                }}
              >
                <TabPane tabKey="1" key="1" forceRender tab="Periodo">
                  <FormItem name="periodo" required noStyle>
                    <RangePickerItem />
                  </FormItem>
                </TabPane>
                <TabPane tabKey="2" key="2" forceRender tab="Clientes">
                  <FormItem name="clientes" noStyle margin>
                    <SelectItem
                      width="90%"
                      mode="multiple"
                      placeholder="Clientes"
                    >
                      {this.state.objetoClientes}
                    </SelectItem>
                  </FormItem>
                </TabPane>
                <TabPane tabKey="3" key="3" forceRender tab="Vendedores">
                  <FormItem name="vendedores" margin noStyle>
                    <SelectItem
                      width="90%"
                      mode="multiple"
                      placeholder="Vendedores"
                    >
                      {this.state.objetoUsuarios}
                    </SelectItem>
                  </FormItem>
                </TabPane>
                <TabPane tabKey="4" key="4" forceRender tab="Proyecto">
                  <Row>
                    <Col span={8}>
                      <FormItem
                        name="filtrarProyecto"
                        required
                        noStyle
                        initialValue={0}
                      >
                        <SelectItem width="90%" placeholder="Filtrar Proyecto">
                          <Option default value={0}>
                            Niguno
                          </Option>
                          <Option value={1}>Proyectos</Option>
                          <Option value={2}>Unidades de negocio</Option>
                        </SelectItem>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const filtrarProyecto =
                            getFieldValue("filtrarProyecto");
                          if (filtrarProyecto === 1) {
                            return (
                              <FormItem required name="proyectos" margin noStyle>
                                <SelectItem
                                  width="90%"
                                  placeholder="Proyectos"
                                  mode="multiple"
                                >
                                  {this.state.objetoProyectos}
                                </SelectItem>
                              </FormItem>
                            );
                          }
                          if (filtrarProyecto === 2) {
                            return (
                              <FormItem required margin name="unidadDeNegocioId" noStyle>
                                <SelectItem
                                  width="90%"
                                  placeholder="Unidades de Negocio"
                                >
                                  {this.state.objetoUniadesDeNegocio}
                                </SelectItem>
                              </FormItem>
                            );
                          }
                        }}
                      </FormItem>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabKey="5" key="5" forceRender tab="Detalles">
                  <Row style={{ padding: 0, margin: 0 }} gutter={[16, 0]}>
                    <Col span={8}>
                      <FormItem
                        label="Estado"
                        name="estadoFacturas"
                        required
                        initialValue={5}
                        style={{ marginBottom: 0 }}
                      >
                        <SelectItem placeholder="Filtrar Por">
                          <Option default value={5}>
                            Todos
                          </Option>
                          <Option value={4}>Por Cobrar</Option>
                          <Option value={3}>Cobrada</Option>
                          <Option value={2}>Vencida</Option>
                          <Option value={1}>Canceladas</Option>
                        </SelectItem>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        label="Desglosar Productos"
                        name="desglosarProductos"
                        valuePropName="checked"
                        margin
                      >
                        <Checkbox />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      {/* <FormItem
                        label="Detalle"
                        name="detalle"
                        valuePropName="checked"
                        margin
                      >
                        <Checkbox />
                      </FormItem> */}
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div>
            <div
              style={{
                backgroundColor: "white",
                padding: 15,
                marginTop: 10,
                marginBottom: 10,
                height: "400px",
              }}
            >
              <h1 style={{ fontSize: "20px" }}>Grafico</h1>
              <ResponsiveLine
                data={this.state.dataGrafico}
                margin={{ top: 20, right: 80, bottom: 100, left: 80 }}
                yFormat={(e) => dineroDisabledMask(Math.abs(e))}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: 0,
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 90,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                }}
                pointSize={10}
                colors={['#ff6633', '#FF926E', '#339999', '#66cccc']}
                colorBy="index"
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
              />
            </div>
            <div
              style={{
                backgroundColor: "white",
                padding: 15,
                marginBottom: 10,
                height: "75px",
              }}
            >
              <Row>
                <Col span={4} style={{ textAlign: "center" }}>
                  <p style={{ margin: 0 }}>Subtotal</p>
                  <h1 style={{ fontSize: 15 }}>
                    {dineroDisabledMask(this.state.subtotal)}
                  </h1>
                </Col>
                <Col span={1} style={{ textAlign: "center" }}>
                  <h1 style={{ fontSize: 20 }}>+</h1>
                </Col>
                <Col span={4} style={{ textAlign: "center" }}>
                  <p style={{ margin: 0 }}>Impuestos</p>
                  <h1 style={{ fontSize: 15 }}>
                    {dineroDisabledMask(this.state.totalImpuestos)}
                  </h1>
                </Col>
                <Col span={1} style={{ textAlign: "center" }}>
                  <h1 style={{ fontSize: 20 }}>=</h1>
                </Col>
                <Col span={4} style={{ textAlign: "center" }}>
                  <p style={{ margin: 0 }}>Total</p>
                  <h1 style={{ fontSize: 15 }}>
                    {dineroDisabledMask(this.state.total)}
                  </h1>
                </Col>
                <Col span={1} style={{ textAlign: "center" }}>
                  <h1 style={{ fontSize: 20 }}>-</h1>
                </Col>
                <Col span={4} style={{ textAlign: "center" }}>
                  <p style={{ margin: 0 }}>Retenciones</p>
                  <h1 style={{ fontSize: 15 }}>
                    {dineroDisabledMask(this.state.totalRetenciones)}
                  </h1>
                </Col>
                <Col span={1} style={{ textAlign: "center" }}>
                  <h1 style={{ fontSize: 20 }}>=</h1>
                </Col>
                <Col span={4} style={{ textAlign: "center" }}>
                  <p style={{ margin: 0 }}>Total Cobrar</p>
                  <h1 style={{ fontSize: 15 }}>
                    {dineroDisabledMask(this.state.totalACobrar)}
                  </h1>
                </Col>
              </Row>
            </div>
            <TableItem
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: (event) => {
                    this.props.history.push("/panel/verVenta", {
                      id: record._id,
                    });
                  },
                };
              }}
              rowKey={(value) => value._id.toString() + Math.random()}
              columns={columns}
              dataSource={this.state.todasVentas}
              size="small"
              pagination={false}
            />
            {/* <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
          {this.state.pdf && (
            <iframe
              title={"documentos"}
              style={{ width: "100%", height: "100vh" }}
              id="printPdf"
              name="printPdf"
            />
          )}
        </div> */}
          </Spin>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ReporteVentas);
