let nada = null
const validateMessages = {
    default: nada,
    required: nada,
    enum: nada,
    whitespace: nada,
    date: {
      format: nada,
      parse: nada,
      invalid: nada,
    },
    types: {
      string: nada,
      method: nada,
      array: nada,
      object: nada,
      number: nada,
      date: nada,
      boolean: nada,
      integer: nada,
      float: nada,
      regexp: nada,
      email: nada,
      url: nada,
      hex: nada,
    },
    string: {
      len: nada,
      min: nada,
      max: nada,
      range: nada,
    },
    number: {
      len: nada,
      min: nada,
      max: nada,
      range: nada,
    },
    array: {
      len: nada,
      min: nada,
      max: nada,
      range: nada,
    },
    pattern: {
      mismatch: nada,
    },
}

export default validateMessages