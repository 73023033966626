import {
    Button,
    Input,
    message,
    Popconfirm,
    Tabs,
    Upload,
    Select,
    PageHeader,
    Spin,
    Row,
    Col,
    Form as FormInitial,
    Switch,
  } from "antd";
  import {
    DeleteOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
  } from "@ant-design/icons";
  import React, { Component } from "react";
  import axios from "axios";
  import { connect } from "react-redux";
  import {
    Form,
    FormItem,
    ModalItem,
    DatePickerItem,
    InputNumberItem,
    SelectItem, InputItem, ButtonItem, BoxItem, HeaderItem, TextAreaItem, cuentaMask
  } from "../../../../Components/Items";
  import AgregarUnidad from "../../Insumos/AgregarInsumo/AgregarUnidad/AgregarUnidad";
  import moment from "moment";
import AgregarCuentaContable from "../../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";
import AgregarFamilia from "../../Familias/AgregarFamilia/AgregarFamilia";

  
  const { TabPane } = Tabs;
  const { Option } = Select;
  
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  
  class AgregarInsumoCompuesto extends Component {
    formRef = React.createRef();
    state = {
      loading: false,
      archivosPrincipal: [],
      archivosTecnica: [],
      archivosFotos: [],
      todasMonedas: null,
      objetoMonedas: null,
      todasUnidades: null,
      objetoUnidades: null,
      todasImpuestos: null,
      objetoImpuestos: null,
      todasFamilia: null,
      objetoFamilia: null,
      todasProveedores: null,
      objetoProveedores: null,
      todasGrupoSat: null,
      objetoGrupoSat: null,
      todasClaseSat: null,
      objetoClaseSat: null,
      todasCodigoSat: null,
      objetoCodigoSat: null,
      todasRetenciones: null,
      objetoRetenciones: null,
      objetoInsumos: null,
      todasInsumos: null,
      modalAgregarUnidad: false,
      modalAgregarFamilia: false,
      precios: [],
      calcularPreciosAutomaticamente: false,
      calcularPreciosSobre: false,
      impuestoCombustible: false,
      modalAgregarCuentaContable: false,
      todasCuentasContablesFinales: null,
      objetoCuentasContablesFinales: null
    };
    atras() {
      this.props.history.goBack();
    }
    componentDidMount() {
      this.consultarTodo();
    }
    async consultarTodo() {
      this.setState({ loading: true });
      await axios
        .post(
          "insumoscompuestos/selectDatosParaInsumoCompuesto",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasInsumos: res.data.insumos, todasGrupoSat: res.data.subclases,todasProveedores: res.data.proveedores, todasRetenciones: res.data.retenciones, todasImpuestos: res.data.impuestos, todasFamilia: res.data.familias, todasMonedas: res.data.monedas, todasUnidades: res.data.unidades,
              calcularPreciosAutomaticamente:
                  res.data.configuracion.calcularPreciosAutomaticamente,
                calcularPreciosSobre: res.data.configuracion.calcularPreciosSobre,
                precios: res.data.configuracion.precios.map((value) => {
                  return {
                    porcentaje: value.porcentaje,
                    nombre: value.nombre,
                    precioId: value._id,
                  };
                }),
             }, () => {
              this.formRef.current.setFieldsValue({
                codigo: res.data.sigNumero,
                clave: res.data.sigNumero,
                precios: res.data.configuracion.precios.map((value) => {
                  return {
                    porcentaje: value.porcentaje,
                    nombre: value.nombre,
                    precioId: value._id,
                  };
                }),
              });
             });
            this.setState({
              objetoUnidades: res.data.unidades.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.unidadSat.c_ClaveUnidad +
                      " - " +
                      (value.descripcion
                        ? value.nombre + " - " + value.descripcion
                        : value.nombre)}
                  </Option>
                );
              }),
              objetoMonedas: res.data.monedas.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre + " - " + value.abreviacion}
                  </Option>
                );
              }),
              objetoFamilia: res.data.familias.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
              objetoImpuestos: res.data.impuestos.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
              objetoRetenciones: res.data.retenciones.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
              objetoProveedores: res.data.proveedores.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.stringBuscar}
                  </Option>
                );
              }),
              objetoGrupoSat: res.data.subclases.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.codigo + " - " + value.descripcion}
                  </Option>
                );
              }),
              objetoInsumos: res.data.insumos.map((value) => {
                return (
                    <Option value={value._id} key={value._id}>
                    {value.stringBuscar}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
        .post(
          "cuentascontables/selectCuentasContablesFinales",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasCuentasContablesFinales: res.data });
            this.setState({
              objetoCuentasContablesFinales: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {cuentaMask(
                      value.nivel1 +
                        value.nivel2 +
                        value.nivel3 +
                        value.nivel4 +
                        value.nivel5
                    ) +
                      " - " +
                      value.descripcion}
                  </Option>
                );
              }),
            });
          }
        });
      this.setState({ loading: false });
    }
  
    onChangeGrupo(e) {
      this.formRef.current.setFieldsValue({
        claseSatId: undefined,
        codigoSatId: undefined,
      });
      axios
        .post(
          "codigoinsumos/selectClases",
          { empresaId: this.props.empresaId, grupoId: e },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasClaseSat: res.data });
            this.setState({
              objetoClaseSat: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.codigo + " - " + value.descripcion}
                  </Option>
                );
              }),
            });
          }
        });
    }
  
    onChangeClase(e) {
      this.formRef.current.setFieldsValue({ codigoSatId: undefined });
      axios
        .post(
          "codigoinsumos/selectSubclases",
          { empresaId: this.props.empresaId, claseId: e },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasCodigoSat: res.data });
            this.setState({
              objetoCodigoSat: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.c_ClaveProdServ + " - " + value.descripcion}
                  </Option>
                );
              }),
            });
          }
        });
    }
  
    onFinishFailed(e) {
      message.error("Porfavor ingrese bien los datos");
    }
  
    onFinish(values) {
      this.setState({ loading: true });
      let fd = new FormData();
      fd.append("empresaId", this.props.empresaId);
      for (var key in values) {
        if (values[key] === undefined || values[key] === "") {
        } else {
          if (key === "precios") {
            fd.append(key, JSON.stringify(values[key]));
          }else if (key === "insumosUtilizar") {
            fd.append(key, JSON.stringify(values[key]));
          }else if (key === "impuestos") {
            fd.append(key, JSON.stringify(values[key]));
          }else if (key === "retenciones") {
            fd.append(key, JSON.stringify(values[key]));
          } else {
            fd.append(key, values[key]);
          }
        }
      }
      this.state.archivosPrincipal.forEach((element, index) => {
        fd.append("archivoPrincipal" + (index + 1), element);
      });
      this.state.archivosTecnica.forEach((element, index) => {
        fd.append("archivoTecnica" + (index + 1), element);
      });
      this.state.archivosFotos.forEach((element, index) => {
        fd.append("archivoFoto" + (index + 1), element);
      });
      fd.append('inventariable', false);
      fd.append('subinsumo', false);
      axios
        .post("insumoscompuestos/agregarInsumoCompuesto", fd, {
          headers: {
            ...this.props.headersToken,
          },
        })
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success("Se ha registrado un insumo compuesto");
            this.formRef.current.resetFields();
            this.setState({
              archivosPrincipal: [],
              archivosTecnica: [],
              archivosFotos: [],
            });
            this.atras(res.data.codigo);
          } else if (res.data.success === 1) {
            message.error("No se puede registrar el insumo compuesto");
          } else {
            message.error("Hubo un error y el insumo compuesto no se registro");
          }
        });
    }
  
    onBlurDescripcion() {
      if (!this.formRef.current.getFieldValue("descripcionLarga")) {
        let value = this.formRef.current.getFieldValue("descripcion");
        this.formRef.current.setFieldsValue({
          descripcionLarga: value,
        });
      }
    }
  
    customRequestFicha(e) {
      this.setState({ archivosTecnica: [...this.state.archivosTecnica, e.file] });
    }
  
    onRemoveFileFicha(e) {
      let index = this.state.archivosTecnica.indexOf(e);
      let nuevoArray = this.state.archivosTecnica;
      nuevoArray.splice(index, 1);
      this.setState({ archivosTecnica: nuevoArray });
    }
  
    customRequestPrincipal(e) {
      this.setState({
        archivosPrincipal: [...this.state.archivosPrincipal, e.file],
      });
    }
  
    onRemoveFilePrincipal(e) {
      let index = this.state.archivosPrincipal.indexOf(e);
      let nuevoArray = this.state.archivosPrincipal;
      nuevoArray.splice(index, 1);
      this.setState({ archivosPrincipal: nuevoArray });
    }
  
    customRequestFotos(e) {
      this.setState({ archivosFotos: [...this.state.archivosFotos, e.file] });
    }
  
    onRemoveFileFotos(e) {
      let index = this.state.archivosFotos.indexOf(e);
      let nuevoArray = this.state.archivosFotos;
      nuevoArray.splice(index, 1);
      this.setState({ archivosFotos: nuevoArray });
    }
    abrirAgregarUnidad() {
      this.setState({ modalAgregarUnidad: true });
    }
    cerrarAgregarUnidad(codigo) {
      this.setState({ modalAgregarUnidad: false });
      this.consultarTodo().then(() => {
        if (typeof codigo === "string") {
          this.formRef.current.setFieldsValue({ unidadId: codigo });
        }
      });
    }
    abrirAgregarFamilia() {
      this.setState({ modalAgregarFamilia: true });
    }
    cerrarAgregarFamilia(codigo) {
      this.setState({ modalAgregarFamilia: false });
      this.consultarTodo().then(() => {
        if (typeof codigo === "string") {
          this.formRef.current.setFieldsValue({ familiaId: codigo });
        }
      });
    }
    onChangeCosto() {
      const valueCosto = this.formRef.current.getFieldValue("costo")
      if(!valueCosto) return
      let arrayValues = this.formRef.current.getFieldValue("precios");
      let arrayNuevo = arrayValues.map((value, index) => {
        let precio;
        if (this.state.calcularPreciosSobre) {
          precio = ((valueCosto * (100 + value.porcentaje)) / 100).toFixed(2);
        } else {
          precio = (valueCosto / ((100 - value.porcentaje) / 100)).toFixed(2);
        }
        return { ...value, precio };
      });
      this.formRef.current.setFieldsValue({
        precios: arrayNuevo,
      });
    }
  
    handleCancel = () => this.setState({ previewVisible: false });
  
    handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file);
      }
      let preview;
      if (file.base64) {
        preview = file.base64;
      } else {
        preview = file.preview;
      }
      this.setState({
        previewImage: preview,
        previewVisible: true,
        previewTitle:
          file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      });
    };

    onChangeImpuesto(){
      const impuestos = this.formRef.current.getFieldValue('impuestos')
      let combustible = false
      let impuestoIdCombustible
      impuestos.map((valueImpuesto) => {
        if(valueImpuesto?.impuestoId){
          const impuesto = this.state.todasImpuestos.find((valueTodasImpuesto) => valueTodasImpuesto._id.toString() === valueImpuesto.impuestoId.toString())
          if(impuesto.combustible){
            combustible = true
            impuestoIdCombustible = impuesto._id
          }
        }
        return null
      })
      if(combustible){
        message.info('Si el impuesto es especial solamente se puede elegir uno')
        this.setState({impuestoCombustible: combustible})
        this.formRef.current.setFieldsValue({impuestos: [{impuestoId: impuestoIdCombustible}]})
      }else{
        this.setState({impuestoCombustible: combustible})
      }
    }

    abrirAgregarCuentaContable() {
      this.setState({ modalAgregarCuentaContable: true });
    }
    cerrarAgregarCuentaContable(codigo) {
      this.setState({ modalAgregarCuentaContable: false });
      this.consultarTodo()
    }

    render() {
      const { previewVisible, previewImage, previewTitle } = this.state;
      return (
        <>
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Agregar Insumo Compuesto"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
          />
          <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
            <Spin spinning={this.state.loading}>
              <Form
                ref={this.formRef}
                name="agregarInsumoCompuesto"
                layout
                bottom={
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Agregar
                  </Button>
                }
                initialValues={{
                  fechaCalculo: moment(),
                  monedaCompraId: "60c7e4396465ad440c41c15a",
                  retencionId: "613fef2a7ffb183b304924dc",
                  insumosUtilizar: [{insumoId: undefined, cantidad: 0}]
                }}
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed}
              >
                <Tabs defaultActiveKey={1}>
                <TabPane tab="General" tabKey={1} key={1} forceRender>
                  <Row>
                    <Col span={14}>
                      <HeaderItem>DATOS GENERALES</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={12}>
                            <FormItem
                              label="Permitir Venta"
                              name="venta"
                              margin
                              labelCol={{ span: 12 }}
                              wrapperCol={{ span: 10 }}
                              initialValue={true}
                              valuePropName="checked"
                            >
                              <Switch disabled />
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem
                              label="Permitir Compra"
                              name="compra"
                              margin
                              labelCol={{ span: 12 }}
                              wrapperCol={{ span: 10 }}
                              initialValue={false}
                              valuePropName="checked"
                            >
                              <Switch disabled />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <FormItem
                              name="codigo"
                              label="Código"
                              margin
                              labelCol={{ span: 8 }}
                              wrapperCol={{ span: 16 }}
                              pattern
                            >
                              <InputItem disabled placeholder="Código" />
                            </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem
                                name="clave"
                                label="Clave"
                                margin
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                pattern
                              >
                                <InputItem placeholder="Clave" />
                              </FormItem>
                            </Col>
                        </Row>
                        <FormItem
                          label="Cod. barras"
                          name="codigoBarras"
                          margin
                          labelCol={{ span: 3 }}
                          wrapperCol={{ span: 21 }}
                          pattern
                        >
                          <InputItem
                            placeholder="Codigo de barras"
                          />
                        </FormItem>
                        <FormItem
                          label="Descripción"
                          name="descripcion"
                          required
                          margin
                          labelCol={{ span: 3 }}
                          wrapperCol={{ span: 21 }}
                          pattern
                        >
                          <InputItem
                            placeholder="Descripción"
                            onBlur={this.onBlurDescripcion.bind(this)}
                          />
                        </FormItem>
                        <FormItem
                          label="Desc. Larga"
                          name="descripcionLarga"
                          required
                          margin
                          labelCol={{ span: 3 }}
                          wrapperCol={{ span: 21 }}
                          pattern
                        >
                          <TextAreaItem placeholder="Descripción larga" />
                        </FormItem>
                        <Row>
                          <Col span={8}>
                            <FormItem
                              label="Costo"
                              name="costo"
                              required
                              margin
                              labelCol={{ span: 9 }}
                              wrapperCol={{ span: 15 }}
                              numberCero
                            >
                              <InputNumberItem
                                placeholder="Costo"
                                dinero
                                onChange={this.onChangeCosto.bind(this)}
                              />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Unidad"
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <Input.Group compact>
                                <FormItem name="unidadId" required noStyle>
                                  <SelectItem placeholder="Unidad" width="85%">
                                    {this.state.objetoUnidades}
                                  </SelectItem>
                                </FormItem>
                                <ButtonItem
                                  icon={<PlusOutlined />}
                                  style={{ width: "15%" }}
                                  onClick={this.abrirAgregarUnidad.bind(this)}
                                />
                              </Input.Group>
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              name="fechaCalculo"
                              required
                              margin
                              labelCol={{ span: 12 }}
                              wrapperCol={{ span: 24 }}
                            >
                              <DatePickerItem
                                placeholder="Fecha de Calculo"
                                allowClear={false}
                                disabled
                              />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Máx"
                              name="max"
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 24 }}
                              numberCero
                            >
                              <InputNumberItem placeholder="Máximo" />
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem
                              label="Mín"
                              name="min"
                              margin
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 24 }}
                              numberCero
                            >
                              <InputNumberItem placeholder="Mín" />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={14}>
                            <FormItem
                              label="Proveedor"
                              name="proveedorDefaultId"
                              margin
                              labelCol={{ span: 5 }}
                              wrapperCol={{ span: 18 }}
                            >
                              <SelectItem placeholder="Proveedor default">
                                {this.state.objetoProveedores}
                              </SelectItem>
                            </FormItem>
                          </Col>
                          <Col span={10}>
                            <FormItem
                              label="Compra Mínima"
                              name="catidadMinCompra"
                              margin
                              labelCol={{ span: 10 }}
                              wrapperCol={{ span: 18 }}
                              numberCero
                            >
                              <InputNumberItem placeholder="Compra Mínima" />
                            </FormItem>
                          </Col>
                        </Row>
                      </BoxItem>
                    </Col>
                    <Col span={10}>
                    <FormItem noStyle  shouldUpdate={(prev, curr) => prev.venta !== curr.venta} >
                      {({getFieldValue}) => {
                        let venta = getFieldValue('venta')
                        if(venta){
                          return (
                            <>
                            <HeaderItem>LISTAS DE PRECIOS</HeaderItem>
                            <BoxItem>
                              <FormInitial.List name="precios">
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(
                                      (
                                        { key, name, fieldKey, ...restField },
                                        otro
                                      ) => (
                                        <FormItem
                                          key={key}
                                          label={
                                            "Precio " + this.state.precios[key].nombre
                                          }
                                          margin
                                          shouldUpdate
                                        >
                                          <Input.Group compact>
                                            <FormItem
                                              name={[name, "porcentaje"]}
                                              required
                                              numberCero
                                              noStyle
                                            >
                                              <InputNumberItem
                                                porcentaje
                                                sinWidth
                                                onChange={(value) => {
                                                  let arrayValue =
                                                    this.formRef.current.getFieldValue(
                                                      "precios"
                                                    );
                                                  let valueCosto =
                                                    this.formRef.current.getFieldValue(
                                                      "costo"
                                                    );
                                                  if (!valueCosto) return;
                                                  if (
                                                    this.state.calcularPreciosSobre
                                                  ) {
                                                    arrayValue[key].precio = (
                                                      (valueCosto * (100 + value)) /
                                                      100
                                                    ).toFixed(2);
                                                  } else {
                                                    arrayValue[key].precio = (
                                                      valueCosto /
                                                      ((100 - value) / 100)
                                                    ).toFixed(2);
                                                  }
                                                  this.formRef.current.setFieldsValue(
                                                    {
                                                      precios: arrayValue,
                                                    }
                                                  );
                                                }}
                                              />
                                            </FormItem>
                                            <FormItem
                                              name={[name, "precio"]}
                                              required
                                              numberCero
                                              noStyle
                                            >
                                              <InputNumberItem
                                                sinWidth
                                                placeholder={
                                                  "Precio de Venta " +
                                                  this.state.precios[key].nombre
                                                }
                                                dinero
                                                onChange={(value) => {
                                                  let arrayValue =
                                                    this.formRef.current.getFieldValue(
                                                      "precios"
                                                    );
                                                  let valueCosto =
                                                    this.formRef.current.getFieldValue(
                                                      "costo"
                                                    );
                                                  if (!valueCosto) return;
                                                  if (
                                                    this.state.calcularPreciosSobre
                                                  ) {
                                                    arrayValue[key].porcentaje = (
                                                      ((value - valueCosto) /
                                                        valueCosto) *
                                                      100
                                                    ).toFixed(2);
                                                  } else {
                                                    arrayValue[key].porcentaje = (
                                                      ((value - valueCosto) / value) *
                                                      100
                                                    ).toFixed(2);
                                                  }
                                                  this.formRef.current.setFieldsValue(
                                                    {
                                                      precios: arrayValue,
                                                    }
                                                  );
                                                }}
                                              />
                                            </FormItem>
                                          </Input.Group>
                                        </FormItem>
                                      )
                                    )}
                                  </>
                                )}
                              </FormInitial.List>
                            </BoxItem>
                            </>
                          )
                        }else{
                          return null
                        }
                      }}
                      </FormItem>
                      <HeaderItem>ARCHIVO GRAFICO</HeaderItem>
                      <BoxItem>
                        <Row>
                        <Col span={8}>
                          <FormItem
                            name="fotoPrincipal"
                            margin
                          >
                            <Upload
                              showUploadList={{
                                showRemoveIcon: true,
                                showPreviewIcon: true,
                                removeIcon: (file) => (
                                  <Popconfirm
                                    title="Seguro que quieres eliminar el archivo"
                                    onConfirm={() => {
                                      this.onRemoveFilePrincipal(file);
                                    }}
                                    okText="Si"
                                    cancelText="No"
                                  >
                                    <DeleteOutlined
                                      onClick={(e) => e.preventDefault()}
                                    />
                                  </Popconfirm>
                                ),
                              }}
                              fileList={this.state.archivosPrincipal}
                              customRequest={this.customRequestPrincipal.bind(
                                this
                              )}
                              onPreview={this.handlePreview}
                            >
                              <ButtonItem icon={<UploadOutlined />} style={{padding: '0px 20px'}}>
                              Foto Principal
                              </ButtonItem>
                            </Upload>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            name="fichaTecnica"
                            margin
                          >
                            <Upload
                              showUploadList={{
                                showRemoveIcon: true,
                                showPreviewIcon: true,
                                removeIcon: (file) => (
                                  <Popconfirm
                                    title="Seguro que quieres eliminar el archivo"
                                    onConfirm={() => {
                                      this.onRemoveFileFicha(file);
                                    }}
                                    okText="Si"
                                    cancelText="No"
                                  >
                                    <DeleteOutlined
                                      onClick={(e) => e.preventDefault()}
                                    />
                                  </Popconfirm>
                                ),
                              }}
                              fileList={this.state.archivosTecnica}
                              customRequest={this.customRequestFicha.bind(this)}
                              multiple={true}
                              onPreview={this.handlePreview}
                            >
                              <ButtonItem icon={<UploadOutlined />} style={{padding: '0px 20px'}}>
                                Ficha Técnica
                              </ButtonItem>
                            </Upload>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem name="fotos" margin>
                            <Upload
                              showUploadList={{
                                showRemoveIcon: true,
                                showPreviewIcon: true,
                                removeIcon: (file) => (
                                  <Popconfirm
                                    title="Seguro que quieres eliminar el archivo"
                                    onConfirm={() => {
                                      this.onRemoveFileFotos(file);
                                    }}
                                    okText="Si"
                                    cancelText="No"
                                  >
                                    <DeleteOutlined
                                      onClick={(e) => e.preventDefault()}
                                    />
                                  </Popconfirm>
                                ),
                              }}
                              fileList={this.state.archivosFotos}
                              customRequest={this.customRequestFotos.bind(this)}
                              multiple={true}
                              onPreview={this.handlePreview}
                            >
                              <ButtonItem icon={<UploadOutlined />} style={{padding: '0px 45px'}}>
                                Fotos
                              </ButtonItem>
                            </Upload>
                          </FormItem>
                        </Col>
                        </Row>
                      </BoxItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                    <HeaderItem>Impuestos y Retenciones</HeaderItem>
                    <BoxItem>
                    <Row>
                          <Col span={12}>
                            <h1 style={{textAlign: 'center'}}>Impuestos</h1>
                          <FormInitial.List
                            name="impuestos"
                          >
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({name}, arrayKey) => (
                                  <Row>
                                    <Col span={22}>
                                    <FormItem
                                      name={[name, "impuestoId"]}
                                      fieldKey={[arrayKey, "impuestoId"]}
                                      required
                                      margin
                                      noStyle
                                    >
                                      <SelectItem width='100%' placeholder="Impuesto" onChange={this.onChangeImpuesto.bind(this)}>
                                        {this.state.objetoImpuestos}
                                      </SelectItem>
                                    </FormItem>
                                    </Col>
                                    <Col span={2}>
                                      <div style={{textAlign: 'center'}}>
                                        <MinusCircleOutlined
                                            onClick={() => {
                                              remove(name)
                                              this.onChangeImpuesto()
                                            }}
                                          />
                                      </div>
                                    </Col>
                                  </Row>
                                ))}
                                {!this.state.impuestoCombustible ?
                                <div style={{textAlign: 'center'}}>
                                  <Button
                                    type="primary"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                  >
                                    Agregar
                                  </Button>
                                  </div>
                                : null}
                              </>
                            )}
                          </FormInitial.List>
                          </Col>
                          <Col span={12}>
                          <h1 style={{textAlign: 'center'}}>Retenciones</h1>
                          <FormInitial.List
                            name="retenciones"
                          >
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({name}, arrayKey) => (
                                  <Row>
                                    <Col span={22}>
                                    <FormItem
                                      name={[name, "retencionId"]}
                                      fieldKey={[arrayKey, "retencionId"]}
                                      required
                                      margin
                                      noStyle
                                    >
                                      <SelectItem width='100%' placeholder="Retención">
                                        {this.state.objetoRetenciones}
                                      </SelectItem>
                                    </FormItem>
                                    </Col>
                                    <Col span={2}>
                                      <div style={{textAlign: 'center'}}>
                                        <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                          />
                                      </div>
                                    </Col>
                                  </Row>
                                ))}
                                <div style={{textAlign: 'center'}}>
                                  <Button
                                    type="primary"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                  >
                                    Agregar
                                  </Button>
                                  </div>
                              </>
                            )}
                          </FormInitial.List>
                          </Col>
                        </Row>
                    </BoxItem>
                    </Col>
                  </Row>
                  <FormItem noStyle  shouldUpdate={(prev, curr) => prev.venta !== curr.venta} >
                      {({getFieldValue}) => {
                        let venta = getFieldValue('venta')
                        if(venta){
                          return (<Row>
                            <Col span={14}>
                              <HeaderItem>Cuentas de Ingreso</HeaderItem>
                                <BoxItem>
                                  <Row>
                                    <Col span={24}>
                                      <FormItem label="Cuenta Contado" margin>
                                        <Input.Group compact>
                                          <FormItem name="cuentaIngresoContadoId" required noStyle>
                                            <SelectItem placeholder="Cuenta Ingreso Contado" width="80%">
                                              {this.state.objetoCuentasContablesFinales}
                                            </SelectItem>
                                          </FormItem>
                                          <Button
                                            icon={<PlusOutlined />}
                                            style={{ width: "20%" }}
                                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                                          />
                                        </Input.Group>
                                      </FormItem>
                                    <FormItem label="Cuenta Credito" margin>
                                        <Input.Group compact>
                                          <FormItem name="cuentaIngresoCreditoId" required noStyle>
                                            <SelectItem placeholder="Cuenta Ingreso Credito" width="80%">
                                              {this.state.objetoCuentasContablesFinales}
                                            </SelectItem>
                                          </FormItem>
                                          <Button
                                            icon={<PlusOutlined />}
                                            style={{ width: "20%" }}
                                            onClick={this.abrirAgregarCuentaContable.bind(this)}
                                          />
                                        </Input.Group>
                                      </FormItem>
                                    </Col>
                                </Row>
                            </BoxItem>
                            </Col>
                          </Row>)
                        }else{
                          return null
                        }
                      }}
                  </FormItem>
                  <Row>
                    <Col span={14}>
                      <HeaderItem>DATOS FISCALES</HeaderItem>
                      <BoxItem>
                        <Row>
                          <Col span={24}>
                            <FormItem
                              label="Familia"
                              margin
                              labelCol={{ span: 3 }}
                              wrapperCol={{ span: 21 }}
                            >
                              <Input.Group compact>
                                <FormItem name="familiaId" required noStyle>
                                  <SelectItem
                                    placeholder="Familia"
                                    width="90%"
                                  >
                                    {this.state.objetoFamilia}
                                  </SelectItem>
                                </FormItem>
                                <ButtonItem
                                  icon={<PlusOutlined />}
                                  style={{ width: "10%" }}
                                  onClick={this.abrirAgregarFamilia.bind(this)}
                                />
                              </Input.Group>
                            </FormItem>
                          </Col>
                        </Row>
                      </BoxItem>
                    </Col>
                  </Row>
                </TabPane>
                  <TabPane tab="Codigo Sat" tabKey={2} key={2} forceRender>
                    <FormItem label="Grupo Sat" name="grupoSatId" required margin>
                      <SelectItem
                        placeholder="Grupo Sat"
                        onChange={this.onChangeGrupo.bind(this)}
                      >
                        {this.state.objetoGrupoSat}
                      </SelectItem>
                    </FormItem>
                    <FormItem label="Clase Sat" name="claseSatId" required margin>
                      <SelectItem
                        placeholder="Clase Sat"
                        onChange={this.onChangeClase.bind(this)}
                      >
                        {this.state.objetoClaseSat === null ? (
                          <Option disabled>Eliga primero un grupo</Option>
                        ) : (
                          this.state.objetoClaseSat
                        )}
                      </SelectItem>
                    </FormItem>
                    <FormItem
                      label="Codigo Sat"
                      name="codigoSatId"
                      required
                      margin
                    >
                      <SelectItem placeholder="Codigo Sat">
                        {this.state.objetoCodigoSat === null ? (
                          <Option disabled>Eliga primero una clase</Option>
                        ) : (
                          this.state.objetoCodigoSat
                        )}
                      </SelectItem>
                    </FormItem>
                  </TabPane>
                  <TabPane tab="Insumos a utilizar" tabKey={3} key={3} forceRender>
                    <FormInitial.List name="insumosUtilizar">
                    {(fields, { add, remove }) => (
                      <>
                      {fields.length > 0 ? (
                          <h1 style={{textAlign: 'center'}}>Estos insumos se consumiran en la venta</h1>
                          ) : null}
                        <table className="table">
                          {fields.length > 0 ? (
                            <thead>
                              <tr>
                                <th className="th-border" style={{width: '80%'}}>Insumo</th>
                                <th className="th-border" style={{width: '18%'}}>Cantidad</th>
                                <th className="th-border" style={{width: '2%'}}></th>
                                </tr>
                            </thead>
                          ) : null}
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }, arrayKey) => (
                              <tbody>
                                <tr>
                                  <td className="td-border">
                                    <FormItem
                                      {...restField}
                                      name={[name, "insumoId"]}
                                      fieldKey={[arrayKey, "insumoId"]}
                                      required
                                      noStyle
                                    >
                                      <SelectItem
                                        sinBorde
                                       autoFocus
                                        width="100%"
                                        placeholder="Insumo"
                                      >
                                        {
                                          this.state
                                            .objetoInsumos
                                        }
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                        <FormItem
                                        {...restField}
                                        name={[name, "cantidad"]}
                                        fieldKey={[arrayKey, "cantidad"]}
                                        numberCero
                                        noStyle
                                        required
                                        initialValue={undefined}
                                        >
                                        <InputNumberItem sinBorde />
                                        </FormItem>
                                  </td>
                                  <td>
                                    {fields.length > 1 &&
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                        style={{
                                          alignSelf: "center",
                                          justifySelf: "center",
                                        }}
                                      />
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            )
                          )}
                        </table>
                        <FormItem noStyle>
                          <Button
                            style={{ marginTop: 10, marginBottom: 10 }}
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Agregar Campo
                          </Button>
                        </FormItem>
                      </>
                    )}
                  </FormInitial.List>
                  </TabPane>
                </Tabs>
              </Form>
            </Spin>
          </div>
          <ModalItem
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={this.handleCancel}
            spinning={false}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </ModalItem>
          <AgregarUnidad
            visible={this.state.modalAgregarUnidad}
            cerrarAgregarUnidad={this.cerrarAgregarUnidad.bind(this)}
          />
          <AgregarFamilia
            visible={this.state.modalAgregarFamilia}
            cerrarAgregarFamilia={this.cerrarAgregarFamilia.bind(this)}
          />
          <AgregarCuentaContable
          visible={this.state.modalAgregarCuentaContable}
          cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(this)}
        />
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(AgregarInsumoCompuesto);
  