import {
    Col,
    message,
    PageHeader,
    Row,
    Spin,
    Select,
    Button,
    Space,
    Tabs,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  import { connect } from "react-redux";
  import {
    SelectItem,
    Form,
    FormItem,
    cuentaMask,
    TableItem,
    dineroDisabledMask,
    RangePickerItem,
  } from "../../../../Components/Items";
  import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
  import * as XLSX from "xlsx";
  import moment from "moment";
  import "moment/locale/es";
import reporteLibroDiarioPdf from "../../../../Pdf/reporteDeLibroDiarioPdf";
  
  moment.locale("es");
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const { Option } = Select;
  const { TabPane } = Tabs;
  
  class ReporteLibroDiario extends Component {
    state = {
      loading: false,
      pdf: null,
      objetoProyectos: null,
      todasProyectos: null,
      objetoUniadesDeNegocio: null,
      todasUniadesDeNegocio: null,
      dataReporte: null,
      todasPolizasCA: [],
    };
  
    atras() {
      this.props.history.goBack();
    }
  
    async componentDidMount() {
      this.setState({ loading: true });
      await axios
        .post(
          "proyectos/selectProyectos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data });
            this.setState({
              objetoProyectos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      await axios
        .post(
          "unidadesdenegocio/selectUnidadesDeNegocio",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasUniadesDeNegocio: res.data });
            this.setState({
              objetoUniadesDeNegocio: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {cuentaMask(
                      value.nivel1 +
                        value.nivel2 +
                        value.nivel3 +
                        value.nivel4 +
                        value.nivel5
                    ) +
                      " - " +
                      value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      this.consultarReporteLibroDiario({
        periodo: [moment().subtract(1, "month"), moment()],
      });
    }
  
    consultarReporteLibroDiario(values) {
      this.setState({ loading: true });
      axios
        .post(
          "reportesFinancieros/reporteLibroDiario",
          { ...values, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {

            let todasPolizasCA = []

            for (const valuePoliza of res.data.polizasFinales) {
              todasPolizasCA.push({
                colSpan: 5,
                cuenta: valuePoliza.concepto+' : '+valuePoliza.numeroTipoPoliza+' - '+valuePoliza.numeroTipoTipoPoliza,
              })

              for (const valueCA of valuePoliza.cargosAbonos) {
                todasPolizasCA.push({
                  ...valueCA,
                  cargo: valueCA.cargo ? valueCA.monto : '-',
                  abono: !valueCA.cargo ? valueCA.monto : '-',
                  descripcion: valueCA.cuentaContable.descripcion,
                  cuenta: cuentaMask(
                    valueCA.cuentaContable.nivel1 +
                      valueCA.cuentaContable.nivel2 +
                      valueCA.cuentaContable.nivel3 +
                      valueCA.cuentaContable.nivel4 +
                      valueCA.cuentaContable.nivel5
                  )
                })
              }
            }

            this.setState({
              loading: false,
              dataReporte: res.data,
              todasPolizasCA: todasPolizasCA
            });
          } else if (res.data.success === 1) {
            message.error("Ha sucedido un error y no se cargo bien el reporte");
          } else {
            message.error("Ha sucedido un error y no se cargo bien el reporte");
          }
          this.setState({ loading: false });
        });
    }
  
    descargarExcel() {
      let datosDescargar = [];
  
      for (const valuePoliza of this.state.dataReporte.polizasFinales) {
        let ied
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 1){
            ied = 'Ingreso'
        }
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 2){
            ied = 'Egreso'
        }
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 3){
            ied = 'Traslado'
        }
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 4){
            ied = 'Nomina'
        }
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 5){
            ied = 'Pago'
        }
        if(valuePoliza.tipodepoliza.tipoDePolizaId === 6){
            ied = 'Diario'
        }
        for (const valueCA of valuePoliza.cargosAbonos) {
          datosDescargar.push({
            Fecha: moment(valuePoliza.fechaRegistro).locale("es").format("L"),
            "Tipo Poliza ": ied,
            "No. Poliza ": valuePoliza.numeroTipoTipoPoliza,
            "Tipo Poliza": valuePoliza.tipodepoliza.descripcion,
            "No. Poliza": valuePoliza.numeroTipoPoliza,
            Proyecto: valuePoliza.proyecto.nombre+' - '+valuePoliza.proyecto.descripcion,
            "Concepto Poliza": valuePoliza.concepto,
            Cuenta: cuentaMask(
              valueCA.cuentaContable.nivel1 +
                valueCA.cuentaContable.nivel2 +
                valueCA.cuentaContable.nivel3 +
                valueCA.cuentaContable.nivel4 +
                valueCA.cuentaContable.nivel5
            ),
            Descripcion: valueCA.cuentaContable.descripcion,
            "Concepto": valueCA.concepto,
            Cargo: valueCA.cargo ? valueCA.monto : 0,
            Abono: !valueCA.cargo ? valueCA.monto : 0,
          });
        }
      }
  
      const worksheet = XLSX.utils.json_to_sheet(datosDescargar);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Libro Diario"
      );
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(
        workbook,
        `Libro_Diario_${moment().locale("es").format("L")}.xlsx`
      );
    }
  
    descargarPdf() {
      pdfMake
        .createPdf(reporteLibroDiarioPdf(this.state.dataReporte))
        .download(
          `Libro_Diario_${moment().locale("es").format("L")}`
        );
    }
  
    onFinishFailed(e) {
      console.log(e);
      message.error("Porfavor ingrese bien los datos");
    }
  
    render() {
      const columns = [
        {
          title: "Cuenta",
          dataIndex: "cuenta",
          key: "cuenta",
          sorter: (a, b) => {
            if (a.cuenta < b.cuenta) {
              return -1;
            }
            if (a.cuenta > b.cuenta) {
              return 1;
            }
            return 0;
          },
          buscar: "cuenta",
          onCell: (record) => {
            return {
              colSpan: record.colSpan === 5 ? 5 : 1,
            }
          },
          ellipsis: false,
        },
        {
          title: "Descripcion",
          dataIndex: "descripcion",
          key: "descripcion",
          sorter: (a, b) => {
            if (a.descripcion < b.descripcion) {
              return -1;
            }
            if (a.descripcion > b.descripcion) {
              return 1;
            }
            return 0;
          },
          buscar: "descripcion",
          onCell: (record) => {
            return {
              colSpan: record.colSpan === 5 ? 0 : 1,
            }
          },
          ellipsis: true,
        },
        {
          title: "Concepto",
          dataIndex: "concepto",
          key: "concepto",
          sorter: (a, b) => {
            if (a.concepto < b.concepto) {
              return -1;
            }
            if (a.concepto > b.concepto) {
              return 1;
            }
            return 0;
          },
          buscar: "concepto",
          onCell: (record) => {
            return {
              colSpan: record.colSpan === 5 ? 0 : 1,
            }
          },
          ellipsis: true,
        },
        {
          title: "Cargo",
          dataIndex: "cargo",
          key: "cargo",
          sorter: (a, b) => {
            if (a.cargo < b.cargo) {
              return -1;
            }
            if (a.cargo > b.cargo) {
              return 1;
            }
            return 0;
          },
          buscar: "cargo",
          render: (value) => isNaN(value) ? value : dineroDisabledMask(value),
          onCell: (record) => {
            return {
              colSpan: record.colSpan === 5 ? 0 : 1,
            }
          },
          align: "right",
          ellipsis: true,
        },
        {
          title: "Abono",
          dataIndex: "abono",
          key: "abono",
          sorter: (a, b) => {
            if (a.abono < b.abono) {
              return -1;
            }
            if (a.abono > b.abono) {
              return 1;
            }
            return 0;
          },
          buscar: "abono",
          render: (value) => isNaN(value) ? value : dineroDisabledMask(value),
          onCell: (record) => {
            return {
              colSpan: record.colSpan === 5 ? 0 : 1,
            }
          },
          align: "right",
          ellipsis: true,
        },
      ];
      return (
        <>
          <Form
            ref={this.formRef}
            name="rerpoteLibroDiario"
            onFinish={this.consultarReporteLibroDiario.bind(this)}
            onFinishFailed={this.onFinishFailed}
            inita
          >
            <PageHeader
              onBack={this.atras.bind(this)}
              title="Reporte Libro Diario"
              style={{
                border: "1px solid rgb(235, 237, 240)",
                backgroundColor: "white",
                marginBottom: 10,
              }}
              extra={
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Space>
                    {this.state.dataReporte && (
                      <Button
                        onClick={() => this.descargarExcel()}
                        icon={<FileExcelOutlined />}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Descargar Excel
                      </Button>
                    )}
                    {this.state.dataReporte && (
                      <Button
                        onClick={() => this.descargarPdf()}
                        icon={<FilePdfOutlined />}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Descargar PDF
                      </Button>
                    )}
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Consultar
                    </Button>
                  </Space>
                </div>
              }
            />
            <Spin spinning={this.state.loading}>
              <div
                style={{
                  backgroundColor: "white",
                  padding: 20,
                  paddingTop: 5,
                  marginBottom: 20,
                }}
              >
                <Tabs
                  style={{ margin: 0 }}
                  tabBarExtraContent={{
                    left: (
                      <h1 style={{ padding: 0, margin: "0 10px 0 0" }}>
                        Filtros:
                      </h1>
                    ),
                  }}
                >
                    <TabPane tabKey="3" key="3" forceRender tab="Periodo">
                    <FormItem initialValue={[moment().subtract(1, "month"), moment()]} name="periodo" required noStyle>
                      <RangePickerItem />
                    </FormItem>
                    </TabPane>
                  <TabPane tabKey="4" key="4" forceRender tab="Proyecto">
                    <Row>
                      <Col span={8}>
                        <FormItem
                          name="filtrarProyecto"
                          required
                          noStyle
                          initialValue={0}
                        >
                          <SelectItem width="90%" placeholder="Filtrar Proyecto">
                            <Option default value={0}>
                              Niguno
                            </Option>
                            <Option value={1}>Proyectos</Option>
                            <Option value={2}>Unidades de negocio</Option>
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            const filtrarProyecto =
                              getFieldValue("filtrarProyecto");
                            if (filtrarProyecto === 1) {
                              return (
                                <FormItem required name="proyectos" noStyle>
                                  <SelectItem
                                    width="90%"
                                    placeholder="Proyectos"
                                    mode="multiple"
                                  >
                                    {this.state.objetoProyectos}
                                  </SelectItem>
                                </FormItem>
                              );
                            }
                            if (filtrarProyecto === 2) {
                              return (
                                <FormItem
                                  required
                                  name="unidadDeNegocioId"
                                  noStyle
                                >
                                  <SelectItem
                                    width="90%"
                                    placeholder="Unidades de Negocio"
                                  >
                                    {this.state.objetoUniadesDeNegocio}
                                  </SelectItem>
                                </FormItem>
                              );
                            }
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
              <h1 style={{ textAlign: "center" }}>Polizas</h1>
              <TableItem
                columns={columns}
                dataSource={this.state.todasPolizasCA}
                size="small"
                pagination={false}
              />
            </Spin>
          </Form>
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(ReporteLibroDiario);
  