import {
    Col,
    message,
    PageHeader,
    Row,
    Spin,
    Select,
    Button,
    Space,
    Tabs,
    Checkbox,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  import { connect } from "react-redux";
  // import balanceGeneralPdf from "../../../../Pdf/balanceGeneralPdf";
  import {
    SelectItem,
    Form,
    FormItem,
    cuentaMask,
    RangePickerItem,
    TableItem,
    dineroDisabledMask,
  } from "../../../../Components/Items";
  import reporteDePagosPdf from "../../../../Pdf/reporteDePagosPdf";
  import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
  import * as XLSX from "xlsx";
  import { ResponsiveLine } from "@nivo/line";
  import moment from "moment";
  import "moment/locale/es";
  moment.locale("es");
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const { Option } = Select;
  const { TabPane } = Tabs;
  
  class ReportePagos extends Component {
    state = {
      loading: false,
      pdf: null,
      objetoBancos: null,
      todasBancos: null,
      objetoProyectos: null,
      todasProyectos: null,
      objetoUniadesDeNegocio: null,
      todasUniadesDeNegocio: null,
      dataReporte: null,
      todosPagos: [],
      dataGrafico: [],
      total: 0,
    };
  
    atras() {
        this.props.history.goBack();
    }
  
    async componentDidMount() {
      this.setState({ loading: true });
      await axios
      .post(
        "bancos/selectBancos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasBancos: res.data });
          this.setState({
            objetoBancos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.banco}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "cajaefectivo/selectCajaEfectivo",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasBancos: [...this.state.todasBancos, ...res.data] });
          this.setState({
            objetoBancos: [...this.state.objetoBancos, ...res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.banco}
                </Option>
              );
            })],
          });
        }
      });
      await axios
        .post(
          "proyectos/selectProyectos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data });
            this.setState({
              objetoProyectos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      await axios
        .post(
          "unidadesdenegocio/selectUnidadesDeNegocio",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasUniadesDeNegocio: res.data });
            this.setState({
              objetoUniadesDeNegocio: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {cuentaMask(
                      value.nivel1 +
                        value.nivel2 +
                        value.nivel3 +
                        value.nivel4 +
                        value.nivel5
                    ) +
                      " - " +
                      value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
      this.consultarReportePagos({
        periodo: [moment().subtract(1, "month"), moment()],
        bancos: 0,
        filtrarProyecto: 0,
      });
    }
  
    async crearGrafico(valuePagos, fechaInicial, fechaFinal) {
      let grafico = [];
      let graficoSumatoria = [];
      let sumatoria = 0;
  
      let fechaCambiante = moment(fechaInicial).locale("es");
      const fechaFinalMoment = moment(fechaFinal).locale("es");
  
      if (fechaFinalMoment.diff(fechaCambiante, "days") <= 31) {
        while (fechaFinalMoment.diff(fechaCambiante, "days") !== -1) {
          // eslint-disable-next-line no-loop-func
          const pagos = valuePagos.filter((todosPagos) => {
            if (isNaN(todosPagos.fechaPago)) {
              return moment(fechaCambiante).isSame(
                moment(todosPagos.fechaPago, "DD/MM/YYYY"),
                "date"
              );
            } else {
              return false;
            }
          });
  
          let total = 0;
  
          pagos.map((pagosDia) => {
            total += pagosDia.monto;
            return null;
          });
  
          grafico.push({
            x: moment(fechaCambiante).locale("es").format("DD/MM"),
            y: total,
          });
  
          graficoSumatoria.push({
            x: moment(fechaCambiante).locale("es").format("DD/MM"),
            y: total + sumatoria,
          });
  
          sumatoria += total;
          fechaCambiante = moment(fechaCambiante).add(1, "day").locale("es");
        }
      } else if (fechaFinalMoment.diff(fechaCambiante, "days") < 180) {
        while (fechaFinalMoment.diff(fechaCambiante, "weeks") !== -1) {
          // eslint-disable-next-line no-loop-func
          const pagos = valuePagos.filter((todosPagos) => {
            if (isNaN(todosPagos.fechaPago)) {
              return moment(fechaCambiante).isSame(
                moment(todosPagos.fechaPago, "DD/MM/YYYY"),
                "week"
              );
            } else {
              return false;
            }
          });
          let total = 0;
  
          pagos.map((pagosDia) => {
            total += pagosDia.monto;
            return null;
          });
  
          grafico.push({
            x: moment(fechaCambiante).locale("es").format("DD/MM"),
            y: total,
          });
  
          graficoSumatoria.push({
            x: moment(fechaCambiante).locale("es").format("DD/MM"),
            y: total + sumatoria,
          });
  
          sumatoria += total;
          fechaCambiante = moment(fechaCambiante).add(1, "week").locale("es");
        }
      } else if (fechaFinalMoment.diff(fechaCambiante, "months") < 36) {
        while (fechaFinalMoment.diff(fechaCambiante, "months") !== -1) {
          // eslint-disable-next-line no-loop-func
          const pagos = valuePagos.filter((todosPagos) => {
            if (isNaN(todosPagos.fechaPago)) {
              return moment(fechaCambiante).isSame(
                moment(todosPagos.fechaPago, "DD/MM/YYYY"),
                "month"
              );
            } else {
              return false;
            }
          });
          let total = 0;
  
          pagos.map((pagosDia) => {
            total += pagosDia.monto;
            return null;
          });
  
          grafico.push({
            x: moment(fechaCambiante).locale("es").format("DD/MM"),
            y: total,
          });
  
          graficoSumatoria.push({
            x: moment(fechaCambiante).locale("es").format("DD/MM"),
            y: total + sumatoria,
          });
  
          sumatoria += total;
          fechaCambiante = moment(fechaCambiante).add(1, "month").locale("es");
        }
      } else {
        while (fechaFinalMoment.diff(fechaCambiante, "years") !== -1) {
          // eslint-disable-next-line no-loop-func
          const pagos = valuePagos.filter((todosPagos) => {
            if (isNaN(todosPagos.fechaPago)) {
              return moment(fechaCambiante).isSame(
                moment(todosPagos.fechaPago, "DD/MM/YYYY"),
                "year"
              );
            } else {
              return false;
            }
          });
          let total = 0;
  
          pagos.map((pagosDia) => {
            total += pagosDia.monto;
            return null;
          });
  
          grafico.push({
            x: moment(fechaCambiante).locale("es").format("DD/MM"),
            y: total,
          });
  
          graficoSumatoria.push({
            x: moment(fechaCambiante).locale("es").format("DD/MM"),
            y: total + sumatoria,
          });
  
          sumatoria += total;
          fechaCambiante = moment(fechaCambiante).add(1, "year").locale("es");
        }
      }
  
      await this.setState({
        dataGrafico: [
          {
            id: "Pagos",
            color: "hsl(15, 70%, 50%)",
            data: grafico,
          },
          {
            id: "Pagos Acumulados",
            color: "hsl(15, 70%, 50%)",
            data: graficoSumatoria,
          },
        ],
      });
    }
  
    consultarReportePagos(values) {
      this.setState({ loading: true });
      axios
        .post(
          "reportesAdministrativos/reportePagos",
          { ...values, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            this.crearGrafico(
              res.data.pagos,
              res.data.fechaInicial,
              res.data.fechaFinal
            );
            this.setState({
              loading: false,
              dataReporte: res.data,
              todosPagos: res.data.pagos,
              total: res.data.total,
            });
          } else if (res.data.success === 1) {
            message.error("Ha sucedido un error y no se cargo bien el reporte");
          } else {
            message.error("Ha sucedido un error y no se cargo bien el reporte");
          }
          this.setState({ loading: false });
        });
    }
  
    descargarExcel() {
      let datosDescargar = []
        datosDescargar = this.state.todosPagos.map((valuePago) => {
          return {
            Banco: valuePago.banco,
            Fecha: valuePago.fechaPago,
            Proveedor: valuePago.proveedor,
            Factura: valuePago.factura,
            Proyecto: valuePago.proyecto,
            'Forma De Pago': valuePago.formaDePago,
            Moneda: valuePago.moneda,
            Monto: valuePago.monto,
            Referencia: valuePago.referencia
          };
        });
      const worksheet = XLSX.utils.json_to_sheet(datosDescargar);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte Pagos");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, `Reporte_De_Pagos_${moment().locale("es").format("L")}.xlsx`);
    }
  
    descargarPdf() {
      pdfMake
        .createPdf(reporteDePagosPdf(this.state.dataReporte))
        .download(`Reporte_De_Pagos_${moment().locale("es").format("L")}`);
    }
  
    onFinishFailed(e) {
      console.log(e);
      message.error("Porfavor ingrese bien los datos");
    }
    render() {
      const columns = [
        {
          title: "Banco",
          dataIndex: "banco",
          key: "banco",
          sorter: (a, b) => {
            if (a.banco < b.banco) {
              return -1;
            }
            if (a.banco > b.banco) {
              return 1;
            }
            return 0;
          },
          buscar: "banco",
          ellipsis: true,
        },
        {
          title: "Fecha",
          dataIndex: "fechaPago",
          key: "fechaPago",
          sorter: (a, b) => {
            if (moment(a.fechaPago, "DD/MM/YYYY") < moment(b.fechaPago, "DD/MM/YYYY")) {
              return -1;
            }
            if (moment(a.fechaPago, "DD/MM/YYYY") > moment(b.fechaPago, "DD/MM/YYYY")) {
              return 1;
            }
            return 0;
          },
          buscar: "fechaPago",
          ellipsis: true,
        },
        {
          title: "Proveedor",
          dataIndex: "proveedor",
          key: "proveedor",
          sorter: (a, b) => {
            if (a.proveedor < b.proveedor) {
              return -1;
            }
            if (a.proveedor > b.proveedor) {
              return 1;
            }
            return 0;
          },
          buscar: "proveedor",
          ellipsis: true,
        },
        {
          title: "Factura",
          dataIndex: "factura",
          key: "factura",
          sorter: (a, b) => {
            if (a.factura < b.factura) {
              return -1;
            }
            if (a.factura > b.factura) {
              return 1;
            }
            return 0;
          },
          buscar: "factura",
          ellipsis: true,
        },
        {
          title: "Proyecto",
          dataIndex: "proyecto",
          key: "proyecto",
          sorter: (a, b) => {
            if (a.proyecto < b.proyecto) {
              return -1;
            }
            if (a.proyecto > b.proyecto) {
              return 1;
            }
            return 0;
          },
          buscar: "proyecto",
          ellipsis: true,
        },
        {
          title: "Forma de pago",
          dataIndex: "formaDePago",
          key: "formaDePago",
          sorter: (a, b) => {
            if (a.formaDePago < b.formaDePago) {
              return -1;
            }
            if (a.formaDePago > b.formaDePago) {
              return 1;
            }
            return 0;
          },
          buscar: "formaDePago",
          ellipsis: true,
        },
        {
          title: "Moneda",
          dataIndex: "moneda",
          key: "moneda",
          sorter: (a, b) => {
            if (a.moneda < b.moneda) {
              return -1;
            }
            if (a.moneda > b.moneda) {
              return 1;
            }
            return 0;
          },
          buscar: "moneda",
          ellipsis: true,
        },
        {
          title: "Monto",
          dataIndex: "monto",
          key: "monto",
          sorter: (a, b) => {
            if (a.monto < b.monto) {
              return -1;
            }
            if (a.monto > b.monto) {
              return 1;
            }
            return 0;
          },
          buscar: "monto",
          render: (value) => dineroDisabledMask(value),
          align: "right",
          ellipsis: true,
        },
        {
          title: "Referencia",
          dataIndex: "referencia",
          key: "referencia",
          sorter: (a, b) => {
            if (a.referencia < b.referencia) {
              return -1;
            }
            if (a.referencia > b.referencia) {
              return 1;
            }
            return 0;
          },
          buscar: "referencia",
          ellipsis: true,
        },
      ];
      return (
        <>
          <Form
            ref={this.formRef}
            name="reportePagos"
            onFinish={this.consultarReportePagos.bind(this)}
            onFinishFailed={this.onFinishFailed}
            initialValues={{
              periodo: [moment().subtract(1, "month"), moment()],
            }}
          >
            <PageHeader
              onBack={this.atras.bind(this)}
              title="Reporte Pagos"
              style={{
                border: "1px solid rgb(235, 237, 240)",
                backgroundColor: "white",
                marginBottom: 10,
              }}
              extra={
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Space>
                    {this.state.dataReporte && (
                      <Button
                        onClick={() => this.descargarExcel()}
                        icon={<FileExcelOutlined />}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Descargar Excel
                      </Button>
                    )}
                    {this.state.dataReporte && (
                      <Button
                        onClick={() => this.descargarPdf()}
                        icon={<FilePdfOutlined />}
                        style={{ display: "block", margin: "0 0 0 auto" }}
                      >
                        Descargar PDF
                      </Button>
                    )}
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ display: "block", margin: "0 0 0 auto" }}
                    >
                      Consultar
                    </Button>
                  </Space>
                </div>
              }
            />
            <Spin spinning={this.state.loading}>
              <div
                style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}
              >
                <Tabs
                  style={{ margin: 0 }}
                  tabBarExtraContent={{
                    left: (
                      <h1 style={{ padding: 0, margin: "0 10px 0 0" }}>
                        Filtros:
                      </h1>
                    ),
                  }}
                >
                  <TabPane tabKey="1" key="1" forceRender tab="Periodo">
                    <FormItem name="periodo" required noStyle>
                      <RangePickerItem />
                    </FormItem>
                  </TabPane>
                  <TabPane tabKey="2" key="2" forceRender tab="Bancos">
                    <FormItem name="bancos" noStyle margin>
                      <SelectItem
                        width="90%"
                        mode="multiple"
                        placeholder="Bancos"
                      >
                        {this.state.objetoBancos}
                      </SelectItem>
                    </FormItem>
                  </TabPane>
                  <TabPane tabKey="4" key="4" forceRender tab="Proyecto">
                    <Row>
                      <Col span={8}>
                        <FormItem
                          name="filtrarProyecto"
                          required
                          noStyle
                          initialValue={0}
                        >
                          <SelectItem width="90%" placeholder="Filtrar Proyecto">
                            <Option default value={0}>
                              Niguno
                            </Option>
                            <Option value={1}>Proyectos</Option>
                            <Option value={2}>Unidades de negocio</Option>
                          </SelectItem>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem shouldUpdate noStyle>
                          {({ getFieldValue }) => {
                            const filtrarProyecto =
                              getFieldValue("filtrarProyecto");
                            if (filtrarProyecto === 1) {
                              return (
                                <FormItem required name="proyectos" margin noStyle>
                                  <SelectItem
                                    width="90%"
                                    placeholder="Proyectos"
                                    mode="multiple"
                                  >
                                    {this.state.objetoProyectos}
                                  </SelectItem>
                                </FormItem>
                              );
                            }
                            if (filtrarProyecto === 2) {
                              return (
                                <FormItem required margin name="unidadDeNegocioId" noStyle>
                                  <SelectItem
                                    width="90%"
                                    placeholder="Unidades de Negocio"
                                  >
                                    {this.state.objetoUniadesDeNegocio}
                                  </SelectItem>
                                </FormItem>
                              );
                            }
                          }}
                        </FormItem>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabKey="5" key="5" forceRender tab="Detalles">
                  <Row style={{ padding: 0, margin: 0 }} gutter={[16, 0]}>
                    <Col span={12}>
                      <FormItem
                        label="Solo Facturas"
                        name="soloFacturas"
                        valuePropName="checked"
                        margin
                      >
                        <Checkbox />
                      </FormItem>
                    </Col>
                  </Row>
                </TabPane>
                </Tabs>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: 15,
                  marginTop: 10,
                  marginBottom: 10,
                  height: "400px",
                }}
              >
                <h1 style={{ fontSize: "20px" }}>Grafico</h1>
                <ResponsiveLine
                  data={this.state.dataGrafico}
                  margin={{ top: 20, right: 80, bottom: 100, left: 80 }}
                  yFormat={(e) => dineroDisabledMask(Math.abs(e))}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: 0,
                    max: "auto",
                    stacked: false,
                    reverse: false,
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 90,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                  }}
                  pointSize={10}
                  colors={['#ff6633', '#FF926E', '#339999', '#66cccc']}
                  colorBy="index"
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor" }}
                  pointLabelYOffset={-12}
                  useMesh={true}
                />
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: 15,
                  marginBottom: 10,
                  height: "75px",
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <p style={{ margin: 0 }}>Total</p>
                    <h1 style={{ fontSize: 15 }}>
                      {dineroDisabledMask(this.state.total)}
                    </h1>
                  </Col>
                </Row>
              </div>
              <TableItem
                // onRow={(record, rowIndex) => {
                //   return {
                //     onDoubleClick: (event) => {
                //       this.props.history.push("/panel/verVenta", {
                //         id: record._id,
                //       });
                //     },
                //   };
                // }}
                rowKey={(value) => value._id.toString() + Math.random()}
                columns={columns}
                dataSource={this.state.todosPagos}
                size="small"
                pagination={false}
              />
              {/* <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
            {this.state.pdf && (
              <iframe
                title={"documentos"}
                style={{ width: "100%", height: "100vh" }}
                id="printPdf"
                name="printPdf"
              />
            )}
          </div> */}
            </Spin>
          </Form>
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(ReportePagos);
  