import { notification, message, Spin } from "antd";
import axios from "axios";
import polizaPdf from "../Pdf/polizaPdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "../store/store";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const NotificacionPolizas = (polizasId) => {
  const storeRedux = store.getState()
  const empresaId = storeRedux.empresa.id;
  const headersToken = storeRedux.user.headersToken;
  const keyNotification = `open${Date.now()}`;
  const tipoUsuario = storeRedux.user.tipo
  const permisoNP = storeRedux.user.permisos.find(
    (valueTodos) =>
      valueTodos.permisoId.toString() === "6489fc4b29c170cb2c526b9a"
  )?.activado
  const permisoNotificacionPolizas = tipoUsuario === 0 || permisoNP
  if (polizasId && permisoNotificacionPolizas) {
    notification.success({
      description: "Haga click para descargar",
      message:
        polizasId.length === 1
          ? "Se emitio " + polizasId.length + " poliza"
          : "Se emitieron " + polizasId.length + " polizas",
      duration: 0,
      key: keyNotification,
      style: { cursor: "pointer" },
      onClick: () => {
        notification.success({
          description: <Spin spinning>Cargando...</Spin>,
          message:
            polizasId.length === 1
              ? "Se emitio " + polizasId.length + " poliza"
              : "Se emitieron " + polizasId.length + " polizas",
          duration: 0,
          key: keyNotification,
          style: { cursor: "pointer" },
        });
        axios
          .post(
            "polizas/datosPolizas",
            { polizasId, empresaId },
            { headers: headersToken }
          )
          .then((resPolizas) => {
            if (resPolizas.data.success === 2) {
              resPolizas.data.polizas.forEach((poliza) => {
                const pdfPoliza = polizaPdf(poliza);
                pdfMake
                  .createPdf(pdfPoliza)
                  .download(
                    poliza?.poliza?.tipodepoliza?.descripcion
                      ? "Poliza_" +
                          poliza.poliza.tipodepoliza.descripcion +
                          " - " +
                          poliza.poliza.numeroTipoPoliza
                      : "Poliza"
                  );
              });
            } else {
              message.error(
                "Ha sucedido un error y no se cargaron bien las polizas"
              );
            }
            notification.close(keyNotification);
          });
      },
    });
  } else {
    return null;
  }
};

export default NotificacionPolizas;
