import axios from "axios";

export const SIGNUP = "SIGNUP";
export const LOGIN = "LOGIN";

export const LOGOUT = "LOGOUT";
export const HASSIGNED = "HASSIGNED";

export const signup = (values) => {
  return async (dispatch) => {
    return axios
      .post("/registro", values, {withCredentials: true})
      .then((res) => {
        if (res.data?.success === 2) {
          dispatch({ type: SIGNUP, data: res.data });
          return {success: 1, err: res.data.nombre};
        } else {
          if (res.data.correo === 1 && res.data.nombre === 1) {
            return {success: 0, err: "El nombre y el correo ya existen"};
          } else if (res.data.correo === 1) {
            return {success: 0, err: "El correo ya existe"};
          } else if (res.data.nombre === 1) {
            return {success: 0, err: "El nombre ya existe"};
          } else {
            throw new Error({
              message: "Error",
              description:
                "Ha sucedido un error y no se pudo registrar su usuario",
            });
          }
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };
};

export const login = (values) => {
  return async (dispatch) => {
    return axios
      .post("/login", values)
      .then((res) => {
        if(res.data["loggedIn"] !== 2){
          return {success: 0, err: res.data.message};
        }else{
          dispatch({ type: LOGIN, data: res.data });
          return {success: 1, nombre: res.data.nombre};
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };
};

export const hasSigned = (values) => {
  return async (dispatch) => {
    return axios
      .get("/hasSigned", values)
      .then((res) => {
        if (res.data["loggedIn"] === 2) {
          dispatch({ type: HASSIGNED, data: res.data });
          return {success: 1, data: res.data};
        } else {
          return {success: 0, err: "No se puede acceder a la pagina sin iniciar sesión"};
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };
};

export const logout = () => {
  return async (dispatch) => {
    return axios
      .post("/logout")
      .then((res) => {
        if (res.data.success === 1) {
          return {success: 1};
        } else {
          dispatch({ type: LOGOUT });
          return {success: 0, err: "No se pudo cerrar la sesion"};
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };
};
