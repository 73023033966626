import { message, PageHeader, Popconfirm, Space, Tag, Button } from "antd";
import axios from "axios";
import React, { Component } from "react";
import {TableItem, dineroDisabledMask, ButtonItem} from "../../../Components/Items";
import moment from "moment";
import 'moment/locale/es'
import { connect } from "react-redux";
import { CloseCircleOutlined, FileExcelOutlined } from "@ant-design/icons";

moment.locale('es')

class FacturasSinOrdenDeCompra extends Component {
  state = {
    todasFacturasSinOrdenDeCompra: [],
    loading: false
  }
  componentDidMount() {
    this.consultarFacturasSinOrdenDeCompra();
  }

  async consultarFacturasSinOrdenDeCompra() {
    this.setState({loading: true})
    await axios
    .post(
      "facturassinordendecompra/selectFacturas",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasFacturasSinOrdenDeCompra: res.data.map(value => {
            return {...value, 
              proveedorNombre: value.proveedor.nombre ? value.proveedor.nombre + " " + value.proveedor.apellidoPaterno + " " + value.proveedor.apellidoMaterno
              : value.proveedor.razonSocial,
              accion: { _id: value._id, estado: value.estado, xml: value.xml, estadoPago: value.estadoPago }}
          }),
        });
      }
    });
  }
  agregarFacturaSinOrdenDeCompra() {
    this.props.history.push('/panel/agregarFacturaSinOrdenDeCompra')
  }
  recibirInsumosFactura(id) {
    this.props.history.push('/panel/recibirInsumosFactura', {id})
  }
  cancelarFactura(value) {
    axios
      .post(
        "facturassinordendecompra/cancelarFactura",
        { facturaId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Factura cancelada");
        } else if (res.data.success === 1) {
          message.warn(res.data.message);
        } else {
          message.error("Sucedio un error y no se pudo cancelar");
        }
        this.consultarFacturasSinOrdenDeCompra();
      });
  }
  descargarXml(value){
    axios
    .post(
      "facturas/descargarXml",
      { facturaId: value, empresaId: this.props.empresaId },
      { headers: this.props.headersToken }
    )
    .then((res) => {
      var xmltext = res.data.string;
    
      var filename = "file.xml";
      var pom = document.createElement("a");
      var bb = new Blob([xmltext], { type: "text/plain" });

      pom.setAttribute("href", window.URL.createObjectURL(bb));
      pom.setAttribute("download", filename);

      pom.dataset.downloadurl = [
        "text/plain",
        pom.download,
        pom.href,
      ].join(":");
      pom.draggable = true;
      pom.classList.add("dragout");

      pom.click();
    });
  }
  render() {
    const columns = [
        {
          title: "Codigo",
          dataIndex: "codigoFactura",
          key: "codigoFactura",
          // sorter: (a, b) => {
          //   if (a.codigoFactura < b.codigoFactura) {
          //     return -1;
          //   }
          //   if (a.codigoFactura > b.codigoFactura) {
          //     return 1;
          //   }
          //   return 0;
          // },
          render: (value) => value,
          buscar: 'codigoFactura',
          width: '8%',
          ellipsis: true,
        },
        {
          title: "Proyecto",
          dataIndex: "proyecto",
          key: "proyecto",
          sorter: (a, b) => {
            if (a.proyecto.nombre < b.proyecto.nombre) {
              return -1;
            }
            if (a.proyecto.nombre > b.proyecto.nombre) {
              return 1;
            }
            return 0;
          },
          render: (value) => value.nombre,
          width: '8%',
          ellipsis: true,
        },
        {
          title: "Proveedor",
          dataIndex: "proveedorNombre",
          key: "proveedorNombre",
          sorter: (a, b) => {
            if (a.proveedorNombre < b.proveedorNombre) {
              return -1;
            }
            if (a.proveedorNombre > b.proveedorNombre) {
              return 1;
            }
            return 0;
          },
          buscar: 'proveedorNombre',
          width: '16%',
          ellipsis: true,
        },
        {
          title: "Fecha",
          dataIndex: "fechaFactura",
          key: "fechaFactura",
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            if (moment(a.fechaFactura) < moment(b.fechaFactura)) {
              return -1;
            }
            if (moment(a.fechaFactura) > moment(b.fechaFactura)) {
              return 1;
            }
            return 0;
          },
          render: (value) => moment(value).locale('es').format('ll'),
          wdith: '15%',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Subtotal',
          dataIndex: 'subtotal',
          key: 'subtotal',
          sorter: (a, b) => {
            if (a.subtotal < b.subtotal) {
              return -1;
            }
            if (a.subtotal > b.subtotal) {
              return 1;
            }
            return 0;
          },
          render: value => dineroDisabledMask(value),
          buscar: 'subtotal',
          width: '8%',
          ellipsis: true,
          align: 'right',
        },
        {
          title: 'Total',
          dataIndex: 'totalPagar',
          key: 'totalPagar',
          sorter: (a, b) => {
            if (a.totalPagar < b.totalPagar) {
              return -1;
            }
            if (a.totalPagar > b.totalPagar) {
              return 1;
            }
            return 0;
          },
          render: value => dineroDisabledMask(value),
          buscar: 'totalPagar',
          width: '8%',
          ellipsis: true,
          align: 'right',
        },
        {
          title: 'Estado',
          dataIndex: 'estado',
          key: 'estado',
          render: (value, record, index) => {
            if(value === 0){
              return <Tag key={index.toString()} color='red'>Cancelada</Tag>
            }
            if(value === 1){
              return <Tag key={index.toString()} color='blue'>Sin recibir</Tag>
            }
            if(value === 2){
              return <Tag key={index.toString()} color='orange'>Recibido parcialmente</Tag>
            }
            if(value === 3){
              return <Tag key={index.toString()} color='green'>Recibido</Tag>
            }
          },
          width: '10%',
          ellipsis: true,
        },
        {
          title: 'Acción',
          dataIndex: 'accion',
          key: 'accion',
          render: (value, record, index) => {
            if(value.estado === 0) return

            let buttons = []
            if(value.estado !== 0 && value.xml){
              buttons.push(<FileExcelOutlined key={index.toString()} onClick={() => this.descargarXml(value._id)} />)
            }

            if((value.estado === 1 || value.estado === 2) && this.props.permisoRecibirInsumosFacturaSinOrdenDeCompra){
              buttons.push(<Button key={index.toString()} type='default' size='small' onClick={() => this.recibirInsumosFactura(value._id)}>Recibir Insumos</Button>)
            }
            if(value.estado !== 0 && value.estadoPago === 0 && this.props.permisoCancelarFacturaSinOrdenDeCompra){
              buttons.push(<Popconfirm
                title="Seguro que quieres cancelar esta factura"
                onConfirm={() => this.cancelarFactura(value._id)}
                okText="Si"
                cancelText="No"
                key={index.toString()}
              >
                <CloseCircleOutlined style={{ color: "red" }} />
              </Popconfirm>)
            }
            return (
              <Space>
                {buttons}
              </Space>
            )
          },
          width:'27%',
          ellipsis: true,
        }
      ]
    return (
      <>
        <PageHeader
          title="Facturas sin Orden de Compra"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarFacturaSinOrdenDeCompra &&
            <ButtonItem
            type="primary"
            key="agregarFacturaSinOrdenDeCompra"
            onClick={this.agregarFacturaSinOrdenDeCompra.bind(this)}
          >
            Agregar Factura
          </ButtonItem>
          }
        />
        <TableItem onRow={(record, rowIndex) => {
          return {
            onDoubleClick: event => {
              this.props.history.push('/panel/verFacturaSinOrdenDeCompra', {id: record._id})
            },
          };
        }} loading={this.state.loading} size='small' dataSource={this.state.todasFacturasSinOrdenDeCompra} columns={columns} pagination={false} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarFacturaSinOrdenDeCompra: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cbf0af30e81b54183cf60")?.activado,
    permisoCancelarFacturaSinOrdenDeCompra: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cbf87f30e81b54183cf61")?.activado,
    permisoRecibirInsumosFacturaSinOrdenDeCompra: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cc149f30e81b54183cf64")?.activado,
  };
};

export default connect(mapStateToProps)(FacturasSinOrdenDeCompra);