import { Button } from "antd";
import React, {Component} from "react";

class ButtonItem extends Component{
    render(){
        let transformedProperties = {};
        for (var key in this.props) {
          if (
            !(
              key.toString() === "noBuscar"
            )
          ) {
            transformedProperties = {
              ...transformedProperties,
              [key]: this.props[key],
            };
          }
        }
        const style = {
            backgroundColor: '#00b5e2', 
            borderColor: '#00b5e2',
            color: 'white',
            ...this.props.style
        }
        if(this.props.danger){
          style.backgroundColor = '#ff4d4f'
          style.borderColor = '#ff4d4f'
        }
        if(this.props.blanco){
          style.backgroundColor = '#fff'
          style.borderColor = '#d9d9d9'
          style.color = '#000000d9'
        }
        return(
            <Button {...transformedProperties} style={style}>{this.props.children}</Button>
        )
    }
}

export default ButtonItem