import React, {Component} from "react";

class BoxItem extends Component{
    render(){
        return(
            <div style={{
                borderTop: '2px solid #00b5e2', 
                borderRadius: 1, 
                // boxShadow: '3px 3px 3px #E9E9E9', 
                paddingRight: 5, 
                paddingLeft: 5, 
                paddingBottom: 5,
                paddingTop: 5,
                margin: '0px 10px 0px 10px',
                }}>
                {this.props.children}
            </div>
        )
    }
}

export default BoxItem