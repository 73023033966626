import React, {Component} from 'react'
import locale from "antd/es/date-picker/locale/es_ES";
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker

class RangePickerItem extends Component{
    render(){
        const dateFormat = 'DD/MM/YYYY'
        const style = {
            color: this.props.disabled ? 'black' : null,
            backgroundColor: this.props.disabled ? '#F5F5F5' : null,
            borderBottom: this.props.sinBorde ? null : '1px solid #DADADA',
            borderRadius: 0,
            width: this.props.width ? this.props.width : undefined,
            ...this.props.style
          }
        return(
            <RangePicker locale={locale} style={style} bordered={false} format={dateFormat} {...this.props} />
        )
    }
}

export default RangePickerItem