import moment from "moment";
import "moment/locale/es";
import {cuentaMask, dineroDisabledMask} from '../Components/Items'

moment.locale("es");

const reporteDeReposicionCajaChicaPdf = (values) => {
    const empresa = values.empresa
    const logoBiwo = values.logoBiwo;
    let filtrados = []
    if(values?.bancos !== 0 && values?.bancos && values?.bancos?.length > 0){
        let textoFiltrado = ''
        values.bancos.map((valueBanco) => {
            textoFiltrado += `${valueBanco.nombre}\n`
            return null
        })
        filtrados.push([
            {text: 'Bancos:', style: 'subheader'},
            
            {text: textoFiltrado, style: 'textoChico'}
          ])
    }

    if(values?.filtrarProyecto === 1){
        let textoFiltrado = ''
        values.proyectos.map((valueProyectos) => {
            textoFiltrado += `${valueProyectos.nombre}\n`
            return null
        })
        filtrados.push([
            {text: 'Proyectos:', style: 'subheader'},
            
            {text: textoFiltrado, style: 'textoChico'}
          ])
    }

    if(values?.filtrarProyecto === 2){
        filtrados.push([
            {text: 'Unidad de Negocio:', style: 'subheader'},
            
            {text: `${cuentaMask(
                values.unidadDeNegocio.nivel1 + values.unidadDeNegocio.nivel2 + values.unidadDeNegocio.nivel3 + values.unidadDeNegocio.nivel4 +values.unidadDeNegocio.nivel5
              ) +
                " - " +
                values.unidadDeNegocio.nombre}`, style: 'textoChico'}
          ])
    }

    let agregarReporte = []

    let facturas = []

    for(const valuePago of values.pagos){

        if(valuePago.cajaChica !== ""){
            if(facturas.length > 0){
                agregarReporte.push(
                    {
                        style: 'tableExample',
                        table: {
                          widths: [ '20%', '20%', '40%', '20%' ],
                          layout: { defaultBorder: true },
                          body: [
                            [
                              { text: 'Fecha Factura', style: 'tituloTabla' },
                              { text: 'Factura', style: 'tituloTabla' },
                              { text: 'Proveedor', style: 'tituloTabla' },
                              { text: 'Monto', style: 'tituloTabla' },
                            ],
                            ...facturas
                          ]
                        },
                        layout: {
                          fillColor: (rowIndex, node, columnIndex) => {
                            return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                          },
                          hLineWidth: function (i, node) {
                            return i === 0 || i === node.table.body.length ? 1 : 1;
                          },
                          vLineWidth: function (i, node) {
                            return i === 0 || i === node.table.widths.length ? 1 : 1;
                          },
                          hLineColor: function (i, node) {
                            return "#adaaaa";
                          },
                          vLineColor: function (i, node) {
                            return "#adaaaa";
                          },
                          hLineStyle: function (i, node) {
                            if (i === 0 || i === node.table.body.length) {
                              return null;
                            }
                            return { dash: { length: 2, space: 1 } };
                          },
                          vLineStyle: function (i, node) {
                            if (i === 0 || i === node.table.widths.length) {
                              return null;
                            }
                            return { dash: { length: 2 } };
                          },
                        }
                      }
                )

                facturas = []
            }

            //Pago
            agregarReporte.push(
                {
                    style: 'tableExample',
                    table: {
                      widths: [ '60%', '40%' ],
                      layout: { defaultBorder: false },
                      body: [
                        [
                          {
                            table: {
                              widths: [ '30%', '70%' ],
                              layout: { defaultBorder: false },
                              body: [
                                [
                                  {text: 'Caja Chica:', style: 'subheader'},
                                  {text: valuePago.cajaChica, style: 'textoChico'}
                                ],
                                [
                                  { text: 'Banco de reposición:', style: 'subheader' },
                                  { text: valuePago.banco, style: 'textoChico' }
                                ],
                                [
                                    { text: 'Referencia:', style: 'subheader' },
                                    { text: valuePago.referencia, style: 'textoChico' }
                                  ]
                              ]
                            },
                            layout: 'noBorders'
                          },
                          {
                            table: {
                              widths: [ '38%', '62%' ],
                              layout: { defaultBorder: false },
                              body: [
                                [
                                  { text: 'Fecha:', style: 'subheader' },
                                  {text: valuePago.fechaPago, style: 'textoChico'}
                                ],
                                [
                                  {text: 'Proyecto:',style: 'subheader'},
                                  { text: valuePago.proyecto, style: 'textoChico' }
                                ],
                                [
                                  {text: 'Monto:',style: 'subheader'},
                                  { text: dineroDisabledMask(valuePago.monto), style: 'textoChico' }
                                ]
                              ]
                            },
                            layout: 'noBorders'
                          }
                        ]
                      ]
                    },
                    layout: {
                      fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                      },
                      hLineWidth: function (i, node) {
                        return i === 0 || i === node.table.body.length ? 1 : 1;
                      },
                      vLineWidth: function (i, node) {
                        return i === 0 || i === node.table.widths.length ? 1 : 1;
                      },
                      hLineColor: function (i, node) {
                        return "#adaaaa";
                      },
                      vLineColor: function (i, node) {
                        return "#adaaaa";
                      },
                      hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                          return null;
                        }
                        return { "radius": 20, dash: { length: 2, space: 1 } };
                      },
                      vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                          return null;
                        }
                        return { dash: { length: 2 } };
                      },
                    }
                  },
            )

        }else{
            //Facturas


            facturas.push([
                { style: 'textoTabla', text: valuePago.fechaPago, alignment: 'center' },
                {
                  text: valuePago.proyecto,
                  style: 'textoTabla',
                  alignment: 'center'
                },
                {
                  text: valuePago.banco,
                  style: 'textoTabla',
                  alignment: 'left'
                },
                {
                  text: dineroDisabledMask(valuePago.monto),
                  style: 'textoTabla',
                  alignment: 'right'
                },
              ])
        }
    }

    agregarReporte.push(
        {
            style: 'tableExample',
            table: {
              widths: [ '20%', '20%', '40%', '20%' ],
              layout: { defaultBorder: true },
              body: [
                [
                  { text: 'Fecha Factura', style: 'tituloTabla' },
                  { text: 'Factura', style: 'tituloTabla' },
                  { text: 'Proveedor', style: 'tituloTabla' },
                  { text: 'Monto', style: 'tituloTabla' },
                ],
                ...facturas
              ]
            },
            layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            }
          }
    )

    if(filtrados.length === 0){
        filtrados.push([
            {text: 'No tiene filtrados adicionales', style: 'subheader'},

            {text: '', style: 'textoChico'}
      ] )
    }

    let agregarSiReporte  = []

    if(!values.reposicion){
      agregarSiReporte.push(
        {text: 'Datos de filtrado:'},
        {
            style: 'tableExample',
            table: {
                widths: ['20%', '80%'],
                layout: {
                                    defaultBorder: false,
                                },
                
                body: filtrados,
            },
                        layout: {
                hLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 1 : 1;
                },
                vLineWidth: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                },
                hLineColor: function (i, node) {
                    return '#adaaaa';
                },
                vLineColor: function (i, node) {
                    return '#adaaaa';
                },
                hLineStyle: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return null;
                    }
                    return {dash: {length: 2, space: 1}};
                },
                vLineStyle: function (i, node) {
                    if (i === 0 || i === node.table.widths.length) {
                        return null;
                    }
                    return {dash: {length: 2}};
                },
                // paddingLeft: function(i, node) { return 4; },
                // paddingRight: function(i, node) { return 4; },
                // paddingTop: function(i, node) { return 2; },
                // paddingBottom: function(i, node) { return 2; },
                // fillColor: function (i, node) { return null; }
            }
            
        }
      )
    }

    var dd = {
        footer: {
            image: 'logoBiwo', alignment: 'center', width: 50
        },
          content: [
              {
                  style: 'tableExample',
                  table: {
                      widths: [ '18%', '52%', '30%'],
                      layout: {
                          defaultBorder: false,
                      },
                      body: [
                          [
                              {
      //							rowSpan: 3,
                                  border: [false, false, false, false],
                                  image: 'logo',
                                  fit: [80, 80] 
                                  //text: 'rowSpan: 3\n\nborder:\n[true, true, true, true]'
                              },
                              {
                              //	rowSpan: 2,
                                  border: [false, false, false, false],
                                  table: {
                                      headerRows: 1,
                                      widths: ['100%'],
                                  
                                      body: [
                                        [
                                            { 
                                            text: empresa.persona ? empresa.nombre : empresa.nombre+' '+empresa.apellidoPaterno+' '+empresa.apellidoMaterno, 
                                            style: "tableHeader" 
                                            }
                                        ],
                                        [
                                          {
                                            text: "R.F.C: "+empresa.rfc,
                                            style: "textoChico",
                                            alignment: "center",
                                          },
                                        ],
                                        [
                                            {
                                              text: empresa.calle+' '+
                                                    empresa.numeroCalle+' '+
                                                    (empresa.numeroInterior ? empresa.numeroInterior : '')
                                              ,
                                              style: "textoChico",
                                              alignment: "center",
                                            },
                                          ],
                                        [
                                            {
                                              text: empresa.colonia.tipoAsentamiento+' '+
                                              empresa.colonia.nombre+' C.P. '+
                                              empresa.colonia.cp+' '+
                                              empresa.municipio.nombre+' '+
                                              empresa.estado.abreviatura+' '+
                                              empresa.pais.abreviatura+' '
                                              ,
                                              style: "textoChico",
                                              alignment: "center",
                                            },
                                          ],
                                          [{text: 'REPORTE DE REPOSICION CAJA CHICA', style: 'tableHeader'}],
                                      ]
                                  },
                                  layout: 'noBorders'
                              },
                              {
                                  border: [false, false, false, false],
                                      table: {
                                      headerRows: 1,
                                      widths: ['100%'],
                                  
                                      body: [
                                          [{text: 'Fecha Inicial:', style: 'subheader', alignment: 'center'}],
                                          [{text: moment(values.fechaInicial).locale('es').format('LL'), style: 'textoChico', alignment: 'center'}],
                                          [{text: 'Fecha Final:', style: 'subheader', alignment: 'center'}],
                                          [{text: moment(values.fechaFinal).locale('es').format('LL'), style: 'textoChico', alignment: 'center'}],
                                          [{text: 'Fecha de Impresión:', style: 'subheader', alignment: 'center'}],
                                          [{text: moment().locale('es').format('LL'), style: 'textoChico', alignment: 'center'}],
                                      ]
                                  },
                                  layout: 'noBorders'
                              },
                          ]
      
                      ]
                  },
              },
              ...agregarSiReporte,
              ...agregarReporte,
              
          ],
          images: {
            logo: empresa.logo.base64Completa,
            logoBiwo: logoBiwo.base64Completa,
          },
          styles: {
              header: {
                  fontSize: 9,
                  bold: true,
              },
              subheader: {
                  fontSize: 8,
                  bold: true,
              },
              tableExample: {
                  margin: [0, 5, 0, 5]
              },
              tablaSinMargen: {
                  margin: [0, 0, 0, 0]
              },
              tableHeader: {
                  bold: true,
                  fontSize: 12,
                  color: 'black',
                  alignment: 'center'
              },
              textoChico:{
                  fontSize: 8
              },
              tituloTabla:{
                  fontSize: 8,
                  bold: true, 
                  fillColor: '#cccccc', 
                  style: 'subheader', 
                  alignment: 'center',
                  border: true
              },
              textoTabla: {
                  fontSize: 8,
                  italics: false
              }
          },
          pageSize: 'LETTER',
          pageMargins: [ 20, 20, 20, 20 ],
    }
  return dd;
};


export default reporteDeReposicionCajaChicaPdf;
