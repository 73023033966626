import React, { Component } from "react";
import { message, PageHeader, Spin, Form as FormInitial, Row, Col, Select, Divider, Popconfirm, notification } from "antd";
import {
  dineroDisabledMask,
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  ButtonItem,
  InputItem,
  NotificacionPolizas,
  HeaderItem,
  BoxItem,
  round2Decimals,
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import MandarCorreoComplemento from "../MandarCorreoComplemento/MandarCorreoComplemento";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import complementoPdf from "../../../../Pdf/complementoPdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const {Option} = Select
moment.locale("es");

class VerVentasCliente extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    ventasCliente: undefined,
    todasBancos: null,
    objetoBancos: null,
    todasFormasDePago: null,
    objetoFormasDePago: null,
    todasMonedas: null,
    objetoMonedas: null,
    numeroFacturas: 0,
    modalCorreo: false,
    modalCorreoId: null,
    monedaDePago: 'MXN',
    id: this.props.location.state.id,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
    .post(
      "formasdepago/selectFormasDePago",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasFormasDePago: res.data });
        this.setState({
          objetoFormasDePago: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.descripcion}
              </Option>
            );
          }),
        });
      }
    });
    await axios
    .post(
      "bancos/selectBancos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasBancos: res.data });
        this.setState({
          objetoBancos: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre+' - '+value.banco}
              </Option>
            );
          }),
        });
      }
    });
    await axios
    .post(
      "cajaefectivo/selectCajaEfectivo",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasBancos: [...this.state.todasBancos, ...res.data] });
        this.setState({
            objetoBancos: [this.state.objetoBancos ,...res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre+(value.banco ? ' - '+value.banco: '')}
              </Option>
            )
          })],
        });
      }
    });
    await axios
    .post("monedas/selectMonedas", null, {
      headers: this.props.headersToken,
    })
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasMonedas: res.data });
        this.setState({
          objetoMonedas: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre + " - " + value.abreviacion}
              </Option>
            );
          }),
        });
      }
    });
    await axios
      .post(
        "cuentasporcobrar/selectVentasPCliente",
        {
          empresaId: this.props.empresaId,
          clienteId: this.state.id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro informacion del cliente");
        } else {
          let numeroFacturas = 0
          let nuevasVentas = res.data.map((value) => {
            return {
              ...value,
              proyectoNombre: value.proyecto.nombre,
              tipoDeCambioVenta: value.tipoDeCambio,
              saldoPendiente: round2Decimals(value.totalPagar-value.totalPagado)
            };
          });
          this.setState({ ventasCliente: nuevasVentas, numeroFacturas });
          this.formRef.current.setFieldsValue({ ventas: nuevasVentas });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (value.name[1] || value.name[1] === 0) {
        message.error(
          "Error: " +
            value.errors[0] +
            " en la fila " +
            (value.name[1] + 1)
        );
      } else {
        message.error("Error");
      }
      return null;
    });
  }
  onFinish(values) {
    let cantidadPagarTotal = 0;
    values.ventas.map(value => {
      let cantidadPagar = value.cantidadPagar ? value.cantidadPagar : 0
      cantidadPagarTotal += cantidadPagar
      return null
    })
    if(cantidadPagarTotal === 0) return message.info('Se neceitan cantidades para poder hacer esta operación')
    this.setState({ loading: true });
    axios
      .post(
        "cuentasporcobrar/cobrarVentasCliente",
        {
          ...values,
          empresaId: this.props.empresaId,
          clienteId: this.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha registrado un pago");
          if (res.data.complemento) {
              notification.success({
                description: "Haga click para descargar",
                message: "Complemento de Pago Emitido",
                duration: 0,
                style: { cursor: "pointer" },
                onClick: () => {
                  var xmltext = res.data.respuestaComplemento.data.cfdi;

                  var filename = res.data.respuestaComplemento.data.uuid+".xml";
                  var pom = document.createElement("a");
                  var bb = new Blob([xmltext], { type: "text/plain" });

                  pom.setAttribute("href", window.URL.createObjectURL(bb));
                  pom.setAttribute("download", filename);

                  pom.dataset.downloadurl = [
                    "text/plain",
                    pom.download,
                    pom.href,
                  ].join(":");
                  pom.draggable = true;
                  pom.classList.add("dragout");

                  pom.click();

                  const pdf = complementoPdf(res.data);

                  pdfMake.createPdf(pdf).download(res.data.respuestaComplemento.data.uuid+".pdf");
                },
              });
              NotificacionPolizas(res.data.polizasId)
              this.setState({modalCorreo: true, modalCorreoId: res.data.pagoId})
          }else{
            NotificacionPolizas(res.data.polizasId)
            this.formRef.current.resetFields();
            this.atras(res.data.codigo);
          }
        } else if (res.data.success === 1) {
          message.error("No se pudo efectuar el pago");
          notification.error({
            message: "Error",
            description: res.data.message,
            duration: null,
          });
        } else {
          message.error("Hubo un error y no se efectuo el pago");
        }
        this.setState({ loading: false });
      });
  }
  cerrarMandarCorreoComplemento() {
    this.setState({
      modalCorreo: false,
      modalCorreoId: null,
    });
    this.formRef.current.resetFields();
    this.atras();
  }
  atras() {
    this.props.history.goBack();
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title={"Ventas por Cobrar : " + this.props.location.state.nombre}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="ventasPendientesCliente"
              layout
              bottom={
                <Popconfirm
                title={
                  this.state?.numeroFacturas > 0
                    ? (this.state?.numeroFacturas === 1 ? "Se generara un complemento de pago de 1 factura" :"Se generara un complemento de pago de "+this.state?.numeroFacturas+" facturas")
                    : "Cobrar"
                }
                onConfirm={() => this.formRef.current.formRef.current.submit()}
                okText="Ok"
                cancelText="Cancelar"
              >
                <ButtonItem
                  type='primary'
                  onClick={() => {
                    let numeroFacturas = 0
                    const ventas = this.formRef.current.formRef.current.getFieldValue('ventas')
                    ventas.map(valueVenta => {
                      if(valueVenta.factura && !valueVenta.cuentasXC && valueVenta.cantidadPagar > 0){
                        numeroFacturas += 1
                      }
                      return null
                    })
                    this.setState({ numeroFacturas })
                  }}
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Cobrar
                </ButtonItem>
              </Popconfirm>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
              <Col span={8}>
                  <HeaderItem>MONEDA</HeaderItem>
                  <BoxItem>
                  <FormItem
                      required
                      margin
                      name="monedaId"
                      label="Moneda Pago"
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 12 }}
                      initialValue={'60c7e4396465ad440c41c15a'}
                    >
                      <SelectItem onChange={(value) => {
                        const moneda = this.state?.todasMonedas.find((todasMonedas) => todasMonedas._id.toString() === value.toString())
                        if(value === '60c7e4396465ad440c41c15a'){
                          this.formRef.current.setFieldsValue({tipoDeCambioPago: 1})
                        }
                        if(moneda){
                          this.setState({monedaDePago: moneda.abreviacion})
                        }
                      }} placeholder="Moneda">
                        {this.state.objetoMonedas}
                      </SelectItem>
                    </FormItem>
                    <FormItem noStyle shouldUpdate={(prev, curr) => prev.monedaId !== curr.monedaId}>
                      {({getFieldValue}) => {

                        const monedaId = getFieldValue('monedaId')

                        const moneda = this.state.todasMonedas ? this.state.todasMonedas.find((valueTodasMonedas) => valueTodasMonedas._id.toString() === monedaId.toString()) : null

                        return(
                          <FormItem
                            required
                            number
                            margin
                            label={`T. C. ${moneda?.abreviacion ? moneda.abreviacion : ''}`}
                            name="tipoDeCambioPago"
                            type="number"
                            initialValue={1}
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                          >
                            <InputNumberItem
                              disabled={monedaId === '60c7e4396465ad440c41c15a'}
                              dinero
                              placeholder={`T. C. ${moneda?.abreviacion ? moneda.abreviacion : ''}`}
                            />
                          </FormItem>
                        )
                      }}
                    </FormItem>
                  </BoxItem>
                </Col>
                <Col span={8}>
                  <HeaderItem>DATOS DE PAGO</HeaderItem>
                  <BoxItem>
                    <FormItem
                      label="Fecha de Pago"
                      name="fechaPago"
                      required
                      margin
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                      initialValue={moment().hour(12).minute(0)}
                    >
                      <DatePickerItem
                        placeholder="Fecha de Pago"
                        allowClear={false}
                        showTime
                        dateFormat='DD/MM/YYYY HH:mm'
                      />
                    </FormItem>
                    <FormItem
                      label="Banco"
                      name="bancoId"
                      margin
                      required
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <SelectItem placeholder="Banco">
                        {this.state.objetoBancos}
                      </SelectItem>
                    </FormItem>
                    <FormItem
                      name="formaDePagoId"
                      label="F Pago"
                      initialValue="6125149afe07662acc100d58"
                      margin
                      required
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <SelectItem placeholder="Forma de Pago">
                        {this.state.objetoFormasDePago}
                      </SelectItem>
                    </FormItem>
                    <FormItem
                      name="referencia"
                      label="Referencia"
                      margin
                      pattern
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 16 }}
                    >
                      <InputItem placeholder="Referencia" />
                    </FormItem>
                  </BoxItem>
                </Col>
                <Col span={8}>
                  <HeaderItem>DATOS COMPLEMENTO DE PAGO</HeaderItem>
                  <BoxItem>
                    <Row>
                      <Col span={24}>
                      <FormItem
                          label="Num Operacion"
                          name="numOperacion"
                          margin
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          tooltip={{ title: "Se puede registrar el número de cheque, número de autorización, número de referencia, clave de rastreo en caso de ser SPEI, línea de captura o algún número de referencia o identificación análogo que permita identificar la operación correspondiente al pago efectuado." }}
                        >
                          <InputItem placeholder="Numero de Operacion" />
                        </FormItem>
                        <FormItem
                          label="Cta Ordenante"
                          name="ctaOrdenante"
                          number
                          max={50}
                          min={10}
                          margin
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          tooltip={{ title: "Se puede registrar el número de la cuenta con la que se realizó el pago" }}
                        >
                          <InputItem placeholder="Cuenta Ordenante" />
                        </FormItem>
                        <FormItem
                          label="Rfc Ordenante"
                          name="rfcEmisorCtaOrd"
                          rfc
                          margin
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          tooltip={{ title: "Se puede registrar la clave en el RFC de la entidad operadora de la cuenta destino, es decir, la operadora, el banco, la institución financiera, emisor de monedero electrónico, etc" }}
                        >
                          <InputItem placeholder="Rfc Emisor Cuenta Ordenante" />
                        </FormItem>
                        <FormItem
                          name="nomBancoOrdExt"
                          label="Banco Ordenante"
                          margin
                          pattern
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          tooltip={{ title: "Se puede registrar el nombre del banco ordenante, es requerido en caso de ser extranjero" }}
                        >
                          <InputItem placeholder="Nombre Banco Ordenante" />
                        </FormItem>
                        <FormItem
                          label="Cta Beneficiario"
                          name="ctaBeneficiario"
                          number
                          max={50}
                          min={10}
                          margin
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          tooltip={{ title: "Se puede registrar el número de cuenta en donde se recibió el pago." }}
                        >
                          <InputItem placeholder="Cuenta Beneficiario" />
                        </FormItem>
                        <FormItem
                          label="Rfc Beneficiaria"
                          name="rfcEmisorCtaBen"
                          rfc
                          margin
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          tooltip={{ title: "Se puede registrar la clave en el RFC de la entidad operadora de la cuenta destino, es decir, la operadora, el banco, la institución financiera, emisor de monedero electrónico, etc." }}
                        >
                          <InputItem placeholder="Rfc Emisor Cuenta Beneficiaria" />
                        </FormItem>
                      </Col>
                    </Row>
                  </BoxItem>
                </Col>
              </Row>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <FormInitial.List name="ventas">
                {(fields) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "10%" }}>
                              Codigo
                            </th>
                            <th className="th-border" style={{ width: "15%" }}>
                              Proyecto
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Fecha Vencimiento
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Moneda
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              T. C.
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Total a Pagar
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Total Pagado
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Saldo Pendiente
                            </th>
                            <th className="th-border" style={{ width: "15%" }}>
                              Cantidad a Pagar {this.state.monedaDePago}
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                        <tbody key={arrayKey}>
                          <tr>
                          <td className="td-border-center">
                              <p style={{ marginBottom: 0 }}>
                                {
                                  (this.state.ventasCliente[arrayKey].remision ? "R " + this.state.ventasCliente[arrayKey].remisionNumero : "") + (this.state.ventasCliente[arrayKey].remision ? ' ' : '') +
                                  (this.state.ventasCliente[arrayKey].factura ? "F " + this.state.ventasCliente[arrayKey].facturaNumero : "")
                                }
                              </p>
                            </td>
                            <td className="td-border-center">
                              <p style={{ marginBottom: 0 }}>
                                {
                                  this.state.ventasCliente[arrayKey]
                                    .proyectoNombre
                                }
                              </p>
                            </td>
                            <td className="td-border-center">
                              <p style={{ marginBottom: 0 }}>
                                {moment(
                                  this.state.ventasCliente[arrayKey]
                                    .fechaPago
                                ).locale('es').format('L')}
                              </p>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {
                                  this.state.ventasCliente[arrayKey]
                                    .moneda.abreviacion
                                }
                              </p>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "tipoDeCambioVenta"]}
                                fieldKey={[arrayKey, "tipoDeCambioVenta"]}
                                noStyle
                                numberCero
                                initialValue={1}
                                type='number'
                              >
                                <InputNumberItem 
                                  sinBorde
                                  disabled={this.state.ventasCliente[arrayKey].monedaId === '60c7e4396465ad440c41c15a'} 
                                  style={{width: '100%'}} 
                                  placeholder={'Venta: '+this.state.ventasCliente[arrayKey].tipoDeCambio} 
                                  dinero 
                                  size='small' 
                                />
                              </FormItem>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {dineroDisabledMask(
                                  this.state.ventasCliente[arrayKey]
                                    .totalPagar
                                )}
                              </p>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {dineroDisabledMask(
                                  this.state.ventasCliente[arrayKey]
                                    .totalPagado
                                )}
                              </p>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {dineroDisabledMask(
                                  this.state.ventasCliente[arrayKey]
                                    .saldoPendiente
                                )}
                              </p>
                            </td>
                            <td className="td-border">
                              <ButtonItem size='small' type='primary' style={{width:'30%'}} onClick={() => {
                                let ventas = this.formRef.current.getFieldValue('ventas')

                                const tipoDeCambioVenta = ventas[arrayKey].tipoDeCambioVenta
                                const tipoDeCambioPago = this.formRef.current.getFieldValue('tipoDeCambioPago')
                                
                                const equivalencia = tipoDeCambioPago / tipoDeCambioVenta

                                const saldoPendiente = round2Decimals(
                                  (this.state.ventasCliente[arrayKey].saldoPendiente) / equivalencia
                                );

                                ventas[arrayKey].cantidadPagar = saldoPendiente
                                this.formRef.current.setFieldsValue({ventas: ventas})
                              }}>Total</ButtonItem>
                              <FormItem noStyle shouldUpdate>
                                {({getFieldValue}) => {

                                  const ventas = getFieldValue('ventas')

                                  const tipoDeCambioVenta = ventas[arrayKey].tipoDeCambioVenta
                                  const tipoDeCambioPago = getFieldValue('tipoDeCambioPago')

                                  const equivalencia = tipoDeCambioPago / tipoDeCambioVenta

                                  const saldoPendiente = round2Decimals(
                                    (this.state.ventasCliente[arrayKey].saldoPendiente) / equivalencia
                                  );

                                  return (
                                    <FormItem
                                      {...restField}
                                      name={[name, "cantidadPagar"]}
                                      fieldKey={[arrayKey, "cantidadPagar"]}
                                      noStyle
                                      numberCero
                                      type='number'
                                      max={saldoPendiente}
                                    >
                                      <InputNumberItem style={{width: '70%'}} placeholder={'Max: '+saldoPendiente} dinero size='small' />
                                    </FormItem>
                                  )
                                }}
                              </FormItem>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </>
                )}
              </FormInitial.List>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <Row>
                <Col span={8}></Col>
                <Col span={8}></Col>
                <Col span={8}>
                  <FormItem noStyle shouldUpdate>
                    {({getFieldValue}) => {
                      let ventas = getFieldValue('ventas')
                      if(!ventas) return null
                      let totalPagar = 0
                      ventas.map(value => {
                        let cantidadPagar = value.cantidadPagar ? value.cantidadPagar : 0
                        totalPagar += cantidadPagar
                        return null
                      })
                      return (
                        <table className="table">
                        <tbody>
                          <tr>
                            <th
                              className="th-totales"
                              style={{ width: "50%" }}
                            >
                              Total
                            </th>
                            <td
                              className="td-totales-right"
                              style={{ width: "50%" }}
                            >
                              {dineroDisabledMask(totalPagar)}
                            </td>
                          </tr>
                        </tbody>
                        </table>
                      )
                    }}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
        <MandarCorreoComplemento
          visible={this.state.modalCorreo}
          id={this.state.modalCorreoId}
          cerrarMandarCorreoComplemento={this.cerrarMandarCorreoComplemento.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(VerVentasCliente);
