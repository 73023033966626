function numeroALetra(cantidad, abreviatura) {
    let numero = 0;
    cantidad = parseFloat(Number(cantidad.toString().replace(/\$\s?|(,*)/g, '')));
    
    if (cantidad === "0.00" || cantidad === "0") {
        return "CERO PESOS CON 00/100 M.N.";
    } else {
        let ent = cantidad.toFixed(2).split(".");
        let arreglo = separar_split(ent[0]);
        let longitud = arreglo.length;
    
        switch (longitud) {
            case 1:
                numero = unidades(arreglo[0]);
                break;
            case 2:
                numero = decenas(arreglo[0], arreglo[1]);
                break;
            case 3:
                numero = centenas(arreglo[0], arreglo[1], arreglo[2]);
                break;
            case 4:
                numero = unidadesdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3]);
                break;
            case 5:
                numero = decenasdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4]);
                break;
            case 6:
                numero = centenasdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5]);
                break;
            case 7:
                numero = millones(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5], arreglo[6])
                break;
            case 8:
                numero = decenasdemillon(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5], arreglo[6], arreglo[7])
                break;
            case 9:
                numero = centenasdemillon(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5], arreglo[6], arreglo[7], arreglo[8])
                break;
            case 10:
                numero = unidadesdemillardemillon(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5], arreglo[6], arreglo[7], arreglo[8], arreglo[9])
                break;
            case 11:
                numero = decenasdemillardemillon(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5], arreglo[6], arreglo[7], arreglo[8], arreglo[9], arreglo[10])
                break;
            default:
                break;
        }
    
        ent[1] = isNaN(ent[1]) ? '00' : ent[1];

        if(numero === 'UN '){
            return numero + ent[1] + "/100 " + abreviatura + '.'
        }else if(numero?.slice(-9) === 'MILLONES ' || numero?.slice(-7) === 'MILLON '){
            return numero + ent[1] + "/100 " + abreviatura + '.'
        }else{
            return (numero ? numero : 'CERO ') + ent[1] + "/100 " + abreviatura + '.'
        }
    
    }
    }
    
    function unidades(unidad) {
        unidad = Number(unidad)
    let unidades = ['UN ','DOS ','TRES ' ,'CUATRO ','CINCO ','SEIS ','SIETE ','OCHO ','NUEVE '];
    
    return unidades[unidad - 1];
    }
    
    function decenas(decena, unidad) {
        decena = Number(decena)
        unidad = Number(unidad)
    let diez = ['ONCE ','DOCE ','TRECE ','CATORCE ','QUINCE' ,'DIECISEIS ','DIECISIETE ','DIECIOCHO ','DIECINUEVE '];
    let decenas = ['DIEZ ','VEINTE ','TREINTA ','CUARENTA ','CINCUENTA ','SESENTA ','SETENTA ','OCHENTA ','NOVENTA '];
    
    if (decena ===0 && unidad === 0) {
        return "";
    }
    
    if (decena === 0 && unidad > 0) {
        return unidades(unidad);
    }
    
    if (decena === 1) {
        if (unidad === 0) {
            return decenas[decena -1];
        } else {
            return diez[unidad -1];
        }
    } else if (decena === 2) {
        if (unidad === 0) {
            return decenas[decena -1];
        }
        else if (unidad === 1) {
            return "VEINTIUN ";
        } 
        else {
            return "VEINTI" + unidades(unidad);
        }
    } else {
    
        if (unidad === 0) {
            return decenas[decena -1] + " ";
        }
        if (unidad === 1) {
            return decenas[decena -1] + "Y UN ";
        }
    
        return decenas[decena -1] + "Y " + unidades(unidad);
    }
    }
    
    function centenas(centena, decena, unidad) {
    centena = Number(centena)
    decena = Number(decena)
    unidad = Number(unidad)
    let centenas = [ "CIENTO ", "DOSCIENTOS ", "TRESCIENTOS ", "CUATROCIENTOS ","QUINIENTOS ","SEISCIENTOS ","SETECIENTOS ", "OCHOCIENTOS ","NOVECIENTOS "]
    
    if (centena === 0 && decena === 0 && unidad === 0) {
        return "";
    }
    if (centena === 1 && decena === 0 && unidad === 0) {
        return "CIEN ";
    }
    
    if (centena === 0 && decena === 0 && unidad > 0) {
        return unidades(unidad);
    }
    
    if (decena === 0 && unidad === 0) {
        return centenas[centena - 1]  +  "" ;
    }
    
    if (decena === 0) {
        let numero = centenas[centena - 1] + "" + decenas(decena, unidad);
        return numero.replace(" Y ", " ");
    }
    if (centena === 0) {
    
        return  decenas(decena, unidad);
    }
     
    return centenas[centena - 1]  +  "" + decenas(decena, unidad);
    }
    
    function unidadesdemillar(unimill, centena, decena, unidad) {
    let numero = unidades(unimill) + "MIL " + centenas(centena, decena, unidad);
    numero = numero.replace("UN MIL ", "MIL " );
    if (unidad === 0) {
    return numero.replace("Y ", " ");
    } else {
    return numero;
    }
    }
    
    function decenasdemillar(decemill, unimill, centena, decena, unidad) {

    let numero = decenas(decemill, unimill) + "MIL " + centenas(centena, decena, unidad);
    return numero;
    }
    
    function centenasdemillar(centenamill,decemill, unimill, centena, decena, unidad) {
    
    let numero = 0;
    numero = centenas(centenamill,decemill, unimill) + "MIL " + centenas(centena, decena, unidad);
    
    return numero;
    }

    function millones(millon,centenamill,decemill, unimill, centena, decena, unidad){
        millon = Number(millon)
        let millones = ['UN ','DOS ','TRES ' ,'CUATRO ','CINCO ','SEIS ','SIETE ','OCHO ','NUEVE '];
        let millonesLetra = ''
        if(millon === 1){
            millonesLetra = millones[millon -1]+'MILLON '
        }else{
            millonesLetra = millones[millon -1]+'MILLONES '
        }
        let numero = 0;
        if(centenas(centenamill,decemill, unimill) !== ''){
            numero = millonesLetra+centenas(centenamill,decemill, unimill) + "MIL " + centenas(centena, decena, unidad);
        }else{
            numero = millonesLetra+ centenas(centena, decena, unidad);
        }
        
        return numero;
    }

    function decenasdemillon(decemillon,millon,centenamill,decemill, unimill, centena, decena, unidad) {
        let numero
        if(centenas(centenamill,decemill, unimill) !== ''){
            numero = decenas(decemillon, millon)+'MILLONES '+centenas(centenamill,decemill, unimill) + "MIL " + centenas(centena, decena, unidad);
        }else{
            numero = decenas(decemillon, millon)+'MILLONES '+ centenas(centena, decena, unidad);
        }
        return numero
        }

        function centenasdemillon(centeMillon,decemillon,millon,centenamill,decemill, unimill, centena, decena, unidad) {
            let numero
            if(centenas(centenamill,decemill, unimill) !== ''){
                numero = centenas(centeMillon,decemillon, millon)+'MILLONES '+centenas(centenamill,decemill, unimill) + "MIL " + centenas(centena, decena, unidad);
            }else{
                numero = centenas(centeMillon,decemillon, millon)+'MILLONES '+ centenas(centena, decena, unidad);
            }
            return numero
        }

        function unidadesdemillardemillon(unimilmillon,centeMillon,decemillon,millon,centenamill,decemill, unimill, centena, decena, unidad) {
            let numero
            if(centenas(centenamill,decemill, unimill) !== ''){
                numero = unidades(unimilmillon) + "MIL " +centenas(centeMillon,decemillon, millon)+'MILLONES '+centenas(centenamill,decemill, unimill) + "MIL " + centenas(centena, decena, unidad);
            }else{
                numero = unidades(unimilmillon) + "MIL " +centenas(centeMillon,decemillon, millon)+'MILLONES ' + centenas(centena, decena, unidad);
            }
            numero = numero.replace("UN MIL ", "MIL " );
            if (unidad === 0) {
            return numero.replace("Y ", " ");
            } else {
            return numero;
            }
        }

        function decenasdemillardemillon(decemilmillon,unimilmillon,centeMillon,decemillon,millon,centenamill,decemill, unimill, centena, decena, unidad) {
            let numero
            if(centenas(centenamill,decemill, unimill) !== ''){
                numero = decenas(decemilmillon,unimilmillon) + "MIL " +centenas(centeMillon,decemillon, millon)+'MILLONES '+centenas(centenamill,decemill, unimill) + "MIL " + centenas(centena, decena, unidad);
            }else{
                numero = decenas(decemilmillon,unimilmillon) + "MIL " +centenas(centeMillon,decemillon, millon)+'MILLONES '+ centenas(centena, decena, unidad);
            }

            return numero;
        }
    
    function separar_split(texto){
    let contenido = [];
    for (let i = 0; i < texto.length; i++) {
    contenido[i] = texto.substr(i,1);
    }
    return contenido;
    }

export default numeroALetra