const locale = {
    filterTitle: 'Filtrar menu',
    filterConfirm: 'OK',
    filterReset: 'Resetear',
    filterEmptyText: 'Sin filtros',
    selectAll: 'Select pagina actual',
    selectInvert: 'Invertir pagina actual',
    selectionAll: 'Seleccionar toda la información',
    sortTitle: 'Ordenar',
    expand: 'Expandir fila',
    collapse: 'Colapsar fila',
    triggerDesc: 'Click para ordenar descendentemente',
    triggerAsc: 'Click para ordenar ascendentemente',
    cancelSort: 'Click para cancelar el ordenado',
}

export default locale