import moment from 'moment'
import 'moment/locale/es'
import {cuentaMask, dineroDisabledMask} from '../Components/Items'

moment.locale('es')

const polizaPdf = (values) => {
    const empresa = values.empresa
    const poliza = values.poliza
    let ied
    let iedInicial
    if(poliza.tipodepoliza.tipoDePolizaId === 1){
        ied = 'Ingreso'
        iedInicial = 'I'
    }
    if(poliza.tipodepoliza.tipoDePolizaId === 2){
        ied = 'Egreso'
        iedInicial = 'E'
    }
    if(poliza.tipodepoliza.tipoDePolizaId === 3){
        ied = 'Traslado'
        iedInicial = 'T'
    }
    if(poliza.tipodepoliza.tipoDePolizaId === 4){
        ied = 'Nomina'
        iedInicial = 'N'
    }
    if(poliza.tipodepoliza.tipoDePolizaId === 5){
        ied = 'Pago'
        iedInicial = 'P'
    }
    if(poliza.tipodepoliza.tipoDePolizaId === 6){
        ied = 'Diario'
        iedInicial = 'D'
    }
    let cargosTotal = 0
    let abonosTotal = 0
    let cargosAbonosTable = values.cargosabonos.map(valueCA => {
        let cargos = 0
        let abonos = 0
        if(valueCA.cargo){
            cargos = valueCA.monto
            cargosTotal += valueCA.monto
        }else{
            abonos = valueCA.monto
            abonosTotal += valueCA.monto
        }
        return [
            {text: cuentaMask(valueCA.cuentacontable.nivel1+valueCA.cuentacontable.nivel2+valueCA.cuentacontable.nivel3+valueCA.cuentacontable.nivel4+valueCA.cuentacontable.nivel5), style: 'textoChico',  alignment: 'left'}, 
            {text: valueCA.cuentacontable.descripcion, style: 'textoChico', alignment: 'left'}, 
            {text: valueCA.concepto, style: 'textoChico', alignment: 'left'},
            {text: cargos === 0 ? '-' : dineroDisabledMask(cargos), style: 'textoChico', alignment: 'right'},
            {text: abonos === 0 ? '-' : dineroDisabledMask(abonos), style: 'textoChico', alignment: 'right'}, 
        ]
    })
    let cancelado = {}
    if(poliza.estado === 0){
    cancelado = {watermark: { text: 'CANCELADO', color: 'GRAY', opacity: 0.3, bold: true, italics: false, angle: -50, fontSize: 110 }}}
    var dd = {
        ...cancelado,
        content: [
            {
                style: 'tableExample',
                table: {
                    widths: [ '18%', '52%', '30%'],
                    layout: {
                        defaultBorder: false,
                    },
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                image: 'logo',
                                fit: [80, 80] 
                            },
                            {
                                border: [false, false, false, false],
                                table: {
                                  headerRows: 1,
                                  widths: ["100%"],
                
                                  body: [
                                    [
                                        { 
                                        text: empresa.persona ? empresa.nombre : empresa.nombre+' '+empresa.apellidoPaterno+' '+empresa.apellidoMaterno, 
                                        style: "tableHeader" 
                                        }
                                    ],
                                    [
                                      {
                                        text: "R.F.C: "+empresa.rfc,
                                        style: "textoChico",
                                        alignment: "center",
                                      },
                                    ],
                                    [
                                        {
                                          text: empresa.calle+' '+
                                                empresa.numeroCalle+' '+
                                                (empresa.numeroInterior ? empresa.numeroInterior : '')
                                          ,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                    [
                                        {
                                          text: empresa.colonia.tipoAsentamiento+' '+
                                          empresa.colonia.nombre+' C.P. '+
                                          empresa.colonia.cp+' '+
                                          empresa.municipio.nombre+' '+
                                          empresa.estado.abreviatura+' '+
                                          empresa.pais.abreviatura+' '
                                          ,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                    // empresa.telefono ? [
                                    //     {
                                    //         text: 'Tel: '+phoneMask(empresa.telefono),
                                    //         style: "textoChico",
                                    //         alignment: "center",
                                    //       },
                                    // ] : [],
                                    [
                                        {
                                          text: 'Regimen Fiscal: ('+empresa.regimenFiscal.c_RegimenFiscal+') '+empresa.regimenFiscal.descripcion
                                          ,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                      [
                                        {
                                          text: 'Tipo De Comprobante: ('+iedInicial+') '+ied,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                  ],
                                },
                                layout: "noBorders",
                              },
                            {
                                border: [false, false, false, false],
                                    table: {
                                    headerRows: 1,
                                    widths: ['35%', '65%'],
                                
                                    body: [
                                        [{text: 'Poliza:', style: 'subheader', alignment: 'left'},
                                        {text: ied+': '+poliza.numeroTipoTipoPoliza, style: 'textoChico', alignment: 'left'}],
                                        [{text: '', style: 'subheader', alignment: 'left'},
                                        {text: poliza.tipodepoliza.descripcion+': '+poliza.numeroTipoPoliza, style: 'textoChico', alignment: 'left'}],
                                        [{text: 'Tipo de Poiza:', style: 'subheader', alignment: 'left'},
                                        {text: '('+iedInicial+') '+ied, style: 'textoChico', alignment: 'left'}],
                                        [{text: 'Fecha:', style: 'subheader', alignment: 'left'},
                                        {text: moment(poliza.fechaRegistro).locale('es').format('LL'), style: 'textoChico', alignment: 'left'}],
                                        [{text: 'Proyecto:', style: 'subheader', alignment: 'left'},
                                        {text: poliza.proyecto.nombre+' '+poliza.proyecto.descripcion, style: 'textoChico', alignment: 'left'}],
                                    ]
                                },
                                layout: 'noBorders'
                            },
                        ]
    
                    ]
                },
            },
            {
                style: 'tableExample',
                table: {
                    // widths: ['60%', '5%', '35%'],
                    layout: {
                        defaultBorder: false,
                     },
                    body: [
                            [
                              {text: 'Concepto: '+poliza.concepto, style: 'subheader',}, 
                            //   {text: 'UUID:', style: 'textoChico'}, 
                            //   {text: 'Variable UUID', style: 'textoChico'}
                            ]
                       ]
                },
                            layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    vLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return {dash: {length: 2, space: 1}};
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return {dash: {length: 2}};
                    },
                }
                
            },
            
            {
                style: 'tableExample',
                table: {
                    widths: ['14%', '35%', '31%', '10%', '10%'],
                    layout: {
                        defaultBorder: true,
                    },
                    body: [
                        [
                          {text: 'Cuenta', style: 'tituloTabla'},
                          {text: 'Descripción', style: 'tituloTabla'},
                          {text: 'Concepto', style: 'tituloTabla'},
                          {text: 'Cargo', style: 'tituloTabla'},
                          {text: 'Abono', style: 'tituloTabla'},
    
                        ],
                        ...cargosAbonosTable
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    vLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return {dash: {length: 2, space: 1}};
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return {dash: {length: 2}};
                    },
                }
            },
            {
                alignment: 'right',
              table: {
                  widths: ['15%', '30%', '25%','10%', '10%', '10%'],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                      [
                        {border: [false,false, false,false], text: '', style: 'subheader',},
                        {border: [false,false, false,false], text: '', style: 'subheader',},
                        {border: [false,false, false,false], text: '', style: 'subheader',},
                        {border: [false,false, false,false], text: 'Total:', style: 'subheader',},
                        {border: [false,false, false,false], text: dineroDisabledMask(cargosTotal), style: 'textoChico',},
                        {border: [false,false, false,false], text: dineroDisabledMask(abonosTotal), style: 'textoChico', alignment: 'right'}
                      ],
                  ]
              },
            },
        ],
        images: {
            logo: empresa.logo.base64Completa,
        },
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 8,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 5]
            },
            tablaSinMargen: {
                margin: [0, 0, 0, 0]
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black',
                alignment: 'center'
            },
            textoChico:{
                fontSize: 8
            },
            tituloTabla:{
                fontSize: 8,
                bold: true, 
                fillColor: '#cccccc', 
                style: 'subheader', 
                alignment: 'center',
                border: true
            },
            textoTabla: {
                fontSize: 8,
                italics: false
            }
        },
        pageSize: 'LETTER',
        // pageMargins: [ 20, 20, 20, 20 ],
    }
  return dd;
};


export default polizaPdf;
