import React, { Component } from "react";
import { Button, message, Row, Col, Checkbox, Upload, Spin, Divider, Select } from "antd";
import { Form, FormItem, InputItem, InputNumberItem, ModalItem, SelectItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class GeneralConfiguracion extends Component {
  formRef = React.createRef();
  state = {
    archivo: null,
    base64: null,
    archivosLogo: [],
    rfc: '',
    modalRfc: false,
    dataRfc: null,
    loading: false
  };
  componentDidMount() {
    this.consultarConfiguracion();
  }
  onFinish(values) {
    let fd = new FormData();
    fd.append("empresaId", this.props.empresaId);
    for (var key in values) {
      if (values[key] === undefined || values[key] === "") {
      } else {
        fd.append(key, values[key]);
      }
    }
    if (this.state.archivo) {
      fd.append("logo", this.state.archivo)
    }
    if (values.rfc !== this.state.rfc) {
      this.setState({
        modalRfc: true,
        dataRfc: values
      })
    } else {
      axios
        .post(
          "empresas/editarConfiguracionGeneral",
          fd,
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            message.success("Se ha modificado la configuracion de la empresa");
            this.consultarConfiguracion();
          } else if (res.data.success === 1) {
            message.error("No se pudo modificar la configuracion");
          } else {
            message.error("Hubo un error y no se modifico la configuracion");
          }
        });
    }
  }
  onFinishRfc(values) {
    let fd = new FormData();
    fd.append("empresaId", this.props.empresaId);
    for (var key in values) {
      if (values[key] === undefined || values[key] === "") {
      } else {
        fd.append(key, values[key]);
      }
    }
    if (this.state.archivo) {
      fd.append("logo", this.state.archivo)
    }
    axios
      .post(
        "empresas/editarConfiguracionGeneral",
        fd,
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha modificado la configuracion de la empresa");
          this.consultarConfiguracion();
        } else if (res.data.success === 1) {
          message.error("No se pudo modificar la configuracion");
        } else {
          message.error("Hubo un error y no se modifico la configuracion");
        }
      });
  }
  async consultarConfiguracion() {
    this.setState({ loading: true })
    await axios
      .post("paises/selectPaises", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasPaises: res.data });
          this.setState({
            objetoPaises: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviatura}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post("estados/selectEstados", null, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasEstados: res.data });
          this.setState({
            objetoEstados: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre + " - " + value.abreviatura}
                </Option>
              );
            }),
          });
        }
      });
    await axios
      .post(
        "empresas/selectConfiguracionGeneral",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro configuracion de esta empresa");
        } else {
          if (res.data.logo) {
            let mimetype =
              res.data.logo.extension !== "pdf"
                ? "image/" + res.data.logo.extension
                : "application/pdf";
            const base64 = "data:" + mimetype + ";base64," + res.data.logo.base64;
            this.setState({
              base64
            })
          }
          this.setState({ rfc: res.data.rfc })
          if (res.data.estadoId) {
            this.onChangeEstado(res.data.estadoId)
          }
          if (res.data.municipioId) {
            this.onChangeMunicipio(res.data.municipioId)
          }
          this.formRef.current.setFieldsValue({
            ...res.data
          });
        }
      });
    this.setState({ loading: false })
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
          e.errorFields[index].errors[0] +
          " en la fila " +
          (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  async onChangeArchivo(e) {
    if (e.file.type === 'image/jpeg' || e.file.type === 'image/png') {
      const base64 = await getBase64(e.file);
      this.setState({ archivo: e.file, base64 });
    } else {
      message.error('El archivo debe ser una imagen')
    }
  }
  onChangeEstado(value) {
    this.formRef.current.setFieldsValue({
      municipioId: undefined,
      coloniaId: undefined,
    });
    this.setState({ objetoMunicipios: null, objetoColonias: null });
    axios
      .post(
        "municipios/selectMunicipios",
        { estadoId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasMunicipios: res.data });
          this.setState({
            objetoMunicipios: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }
  onChangeMunicipio(value) {
    this.formRef.current.setFieldsValue({ coloniaId: undefined });
    this.setState({ objetoColonias: null });
    axios
      .post(
        "colonias/selectColonias",
        { municipioId: value },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasColonias: res.data });
          this.setState({
            objetoColonias: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.cp + " - " + value.nombre}
                </Option>
              );
            }),
          });
        }
      });
  }
  render() {
    return (
      <>
        <Spin spinning={this.state.loading}>
          <Form
            name="generalConfiguracion"
            layout
            onFinish={this.onFinish.bind(this)}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
          >
            <Row>
              <Col span={12}>
                <FormItem
                  label="Rfc"
                  name="rfc"
                  rfc
                  max={200}
                  min={5}
                  margin
                >
                  <InputItem placeholder="Registro Federal de Contribuyentes" />
                </FormItem>
                <FormItem
                  label="Nombre"
                  name="nombre"
                  pattern
                  required
                  max={200}
                  min={5}
                  margin
                >
                  <InputItem placeholder="Nombre" />
                </FormItem>
                <FormItem
                  name="persona"
                  label="Persona Moral"
                  valuePropName="checked"
                  margin
                >
                  <Checkbox />
                </FormItem>
                <FormItem shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const persona = getFieldValue("persona");
                    if (!persona) {
                      return (
                        <>
                          <FormItem
                            label="Apellido P."
                            name="apellidoPaterno"
                            pattern
                            max={200}
                            min={5}
                            margin
                          >
                            <InputItem placeholder="Apellido Paterno" />
                          </FormItem>
                          <FormItem
                            label="Apellido M."
                            name="apellidoMaterno"
                            pattern
                            max={200}
                            min={5}
                            margin
                          >
                            <InputItem placeholder="Apellido Materno" />
                          </FormItem>
                        </>
                      );
                    }
                  }}
                </FormItem>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    width: "50%",
                    height: "20vh",
                    display: "block",
                    margin: "auto",
                    border: '1px solid black',
                    marginBottom: 10
                  }}
                >
                  {this.state.base64 ? (
                    <img
                      src={this.state.base64}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <p style={{ textAlign: "center", textJustify: 'center' }}>
                      No tiene ningun logo registrado
                    </p>
                  )}
                </div>
                <FormItem name="logo" label="Logo">
                  <Upload
                    accept='image/*'
                    showUploadList={{
                      showRemoveIcon: false,
                      showPreviewIcon: false,
                    }}
                    fileList={this.state.archivosLogo}
                    customRequest={this.onChangeArchivo.bind(this)}
                  >
                    <Button icon={<UploadOutlined />}>Subir</Button>
                    <p>Recomendación 80px X 80px</p>
                  </Upload>
                </FormItem>
              </Col>
            </Row>
            <Divider style={{margin: 0}}>Direccion</Divider>
            <Row>
              <Col span={12}>
                <FormItem label="Pais" name="paisId" required margin>
                  <SelectItem placeholder="Pais">
                    {this.state.objetoPaises}
                  </SelectItem>
                </FormItem>
                <FormItem label="Estado" name="estadoId" required margin>
                  <SelectItem
                    placeholder="Estado"
                    onChange={this.onChangeEstado.bind(this)}
                  >
                    {this.state.objetoEstados}
                  </SelectItem>
                </FormItem>
                <FormItem
                  label="Municipio"
                  name="municipioId"
                  required
                  margin
                >
                  <SelectItem
                    placeholder="Municipio"
                    onChange={this.onChangeMunicipio.bind(this)}
                  >
                    {this.state.objetoMunicipios === null ? (
                      <Option disabled>Eliga primero un estado</Option>
                    ) : (
                      this.state.objetoMunicipios
                    )}
                  </SelectItem>
                </FormItem>
                <FormItem
                  label="Colonia"
                  name="coloniaId"
                  required
                  margin
                >
                  <SelectItem placeholder="Colonia">
                    {this.state.objetoColonias === null ? (
                      <Option disabled>Eliga primero un estado</Option>
                    ) : (
                      this.state.objetoColonias
                    )}
                  </SelectItem>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Calle"
                  name="calle"
                  max={200}
                  margin
                  pattern
                >
                  <InputItem placeholder="Calle" />
                </FormItem>
                <FormItem
                  label="Numero"
                  name="numeroCalle"
                  margin
                  number
                >
                  <InputNumberItem placeholder="Numero" numeroCalle />
                </FormItem>
                <FormItem
                  label="Numero Interior"
                  name="numeroInterior"
                  margin
                  pattern
                >
                  <InputItem placeholder="Numero Interior" />
                </FormItem>
              </Col>
            </Row>
            <Button
              type="primary"
              htmlType="submit"
              style={{ display: "block", margin: "0 0 0 auto" }}
            >
              Guardar Configuracion
            </Button>
          </Form>

          <ModalItem
            title="Confirmacion"
            visible={this.state.modalRfc}
            onCancel={() => this.setState({ modalRfc: false, dataRfc: null })}
            onOk={() => {
              this.setState({ modalRfc: false, dataRfc: null });
              this.onFinishRfc(this.state.dataRfc);
            }}
            spinning={false}
            footer
          >
            <h1 style={{ textAlign: "center" }}>Si cambia el rfc ya no podra facturar, hasta que verifique los certificados nuevamente.</h1>
          </ModalItem>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(GeneralConfiguracion);
