import React, { Component } from "react";
import {
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Divider,
  Select,
  Form as FormInitial,
} from "antd";
import {
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  InputItem,
  ButtonItem,
  NotificacionPolizas
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import AgregarMarbetes from "../../Marbetes/AgregarMarbetes/AgregarMarbetes";

const { Option } = Select;

moment.locale("es");

class RecibirInsumosFactura extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    datosFactura: undefined,
    todasInsumos: null,
    objetoInsumos: null,
    id: this.props.location.state.id,
    modalAgregarMarbetes: false,
    modalAgregarMarbetesIndex: null,
    modalAgregarMarbetesMarbetes: null
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "facturassinordendecompra/datosFacturaPRecibirP",
        {
          empresaId: this.props.empresaId,
          facturaId: this.state.id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error(
            "Error no se encontro informacion de la factura"
          );
        } else {
          this.setState({ datosFactura: res.data[0] });
          this.formRef.current.setFieldsValue({ insumos: res.data[0].insumos.map(value => {
            return {...value, precioUnitarioAjustado: (value.precioUnitario*(1-(value.descuento/100)))}
          })});
        }
      });
    await axios
      .post(
        "insumos/selectTodosInsumosLista",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasInsumos: res.data });
          this.setState({
            objetoInsumos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.stringBuscar}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if(e.errorFields[index].name[1]){

        message.error(
          "Error: " +
            e.errorFields[index].errors[0] +
            " en la fila " +
            (e.errorFields[index].name[1] + 1)
        )
      }else{
        message.error(
          "Error: Falta un campo"
        )
      }
      return null
    }
    );
  }
  onFinish(values) {
    let recibirTodos = 0;
    values.insumos = values.insumos.map(value => {
      let recibir = value.recibir ? value.recibir : 0
      recibirTodos += recibir
      return {...value, recibir}
    })
    if(recibirTodos === 0) return message.info('Se neceitan cantidades para poder hacer esta operación')
    this.setState({ loading: true });
    axios
      .post(
        "facturassinordendecompra/recibirInsumos",
        {
          ...values,
          empresaId: this.props.empresaId,
          facturaId: this.state.id,
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se han recibido insumos de la factura: " + res.data.codigo
          );
          NotificacionPolizas(res.data.polizasId)
          this.formRef.current.resetFields();
          this.atras(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se pueden recibir los insumos");
        } else {
          message.error("Hubo un error y no se recibieron los insumos");
        }
      });
  }
  atras() {
    this.props.history.goBack();
  }
  abrirAgregarMarbetes(value, marbetes) {
    this.setState({
      modalAgregarMarbetes: true,
      modalAgregarMarbetesIndex: value,
      modalAgregarMarbetesMarbetes: marbetes
    })
  }

  cerrarAgregarMarbetes(value) {
    const insumos = this.formRef.current.getFieldValue('insumos')
    insumos[this.state.modalAgregarMarbetesIndex].marbetes = value
    this.formRef.current.setFieldsValue({
      insumos: insumos
    })
    this.setState({
      modalAgregarMarbetes: false,
      modalAgregarMarbetesIndex: null,
      modalAgregarMarbetesMarbetes: null
    })
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Recibir Insumos sobre Factura"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={
            <h1>
              Fecha:{" "}
              {moment(this.state.datosFactura?.createdAt).format("LL")}
            </h1>
          }
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="recibirInsumosFactura"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Recibir
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>
                    Proyecto: {this.state.datosFactura?.proyecto.nombre}
                  </h1>
                  <p>
                    Descripcion:{" "}
                    {this.state.datosFactura?.proyecto.descripcion}
                  </p>
                </Col>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>Dirección de envio</h1>
                  {this.state.datosFactura?.proyecto.calle ? (
                    <>
                      <p style={{ margin: 0 }}>
                        Calle: {this.state.datosFactura?.proyecto.calle}
                      </p>
                      <p style={{ margin: 0 }}>
                        Numero:{" "}
                        {this.state.datosFactura?.proyecto.numeroCalle}
                      </p>
                    </>
                  ) : (
                    <>
                      <p style={{ margin: 0 }}>
                        Marca: {this.state.datosFactura?.proyecto.marca}
                      </p>
                      <p style={{ margin: 0 }}>
                        Modelo: {this.state.datosFactura?.proyecto.modelo}
                      </p>
                      <p style={{ margin: 0 }}>
                        Placas: {this.state.datosFactura?.proyecto.placas}
                      </p>
                    </>
                  )}
                </Col>
                <Col span={6}>
                  <h1 style={{ margin: 0 }}>
                    Proveedor:{" "}
                    {this.state.datosFactura?.proveedor.nombre
                      ? this.state.datosFactura?.proveedor.nombre +
                        " " +
                        this.state.datosFactura?.proveedor
                          .apellidoPaterno +
                        " " +
                        this.state.datosFactura?.proveedor.apellidoMaterno
                      : this.state.datosFactura?.proveedor.razonSocial}
                  </h1>
                  <p style={{ margin: 0 }}>
                    Rfc: {this.state.datosFactura?.proveedor.rfc}
                  </p>
                  <p style={{ margin: 0 }}>
                    Correo: {this.state.datosFactura?.proveedor.correo}
                  </p>
                  <p style={{ margin: 0 }}>
                    Telefono:{" "}
                    {this.state.datosFactura?.proveedor.telefono}
                  </p>
                  <p style={{ margin: 0 }}>
                    Celular: {this.state.datosFactura?.proveedor.celular}
                  </p>
                </Col>
                <Col span={6}>
                  <p style={{ margin: 0 }}>
                    Contacto:{" "}
                    {this.state.datosFactura?.proveedor.nombreContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Tel. Contacto:{" "}
                    {this.state.datosFactura?.proveedor.telefonoContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Correo Contacto:{" "}
                    {this.state.datosFactura?.proveedor.correoContacto}
                  </p>
                  <p style={{ margin: 0 }}>
                    Dias de crédito:{" "}
                    {this.state.datosFactura?.proveedor.diasCredito}
                  </p>
                </Col>
              </Row>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <Row>
                <Col span={8}>
                  <FormItem
                    name="codigoRemision"
                    label="Codigo Remision"
                    margin
                    pattern
                  >
                    <InputItem placeholder="Codigo Remision" />
                  </FormItem>
                </Col>
                <Col span={8}>
                <FormItem
                    label="Fecha de Recepcion"
                    name="fechaRecepcion"
                    required
                    margin
                    initialValue={moment()}
                  >
                    <DatePickerItem
                      placeholder="Fecha de Recepcion"
                      allowClear={false}
                    />
                  </FormItem>
                </Col>
                <Col span={8}></Col>
              </Row>
              <FormInitial.List name="insumos">
                {(fields) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "24%" }}>
                              Insumo
                            </th>
                            <th className="th-border" style={{ width: "16%" }}>
                              Precio Unitario
                            </th>
                            <th className="th-border" style={{ width: "16%" }}>
                              Cantidad Pedida
                            </th>
                            <th className="th-border" style={{ width: "16%" }}>
                              Cantidad recibida
                            </th>
                            <th className="th-border" style={{ width: "18%" }}>
                              Cantidad a recibir
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Accion
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(({ key, name, fieldKey, ...restField }, arrayKey) => (
                        <tbody key={fieldKey}>
                          <tr>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "insumoId"]}
                                fieldKey={[fieldKey, "insumoId"]}
                                required
                                noStyle
                              >
                                <SelectItem
                                  sinBorde
                                  autoFocus
                                  style={{ width: "100%" }}
                                  placeholder="Insumo"
                                  disabled
                                >
                                  {this.state.objetoInsumos}
                                </SelectItem>
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "precioUnitarioAjustado"]}
                                fieldKey={[fieldKey, "precioUnitarioAjustado"]}
                                noStyle
                              >
                                <InputNumberItem
                                  sinBorde
                                  placeholder="Precio Unitario"
                                  disabled
                                  dineroDisabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "cantidad"]}
                                fieldKey={[fieldKey, "cantidad"]}
                                required
                                numberCero
                                noStyle
                              >
                                <InputNumberItem
                                  sinBorde
                                  placeholder="Cantidad Pedida"
                                  disabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem
                                {...restField}
                                name={[name, "recibido"]}
                                fieldKey={[fieldKey, "recibido"]}
                                required
                                numberCero
                                noStyle
                                initialValue={0}
                              >
                                <InputNumberItem
                                  sinBorde
                                  placeholder="Cantidad Recibida"
                                  disabled
                                />
                              </FormItem>
                            </td>
                            <td className="td-border">
                              <FormItem shouldUpdate noStyle>
                                {() => {
                                  let cantidad =
                                    this.state.datosFactura.insumos[
                                      fieldKey
                                    ].cantidad;
                                  let recibido =
                                    this.state.datosFactura.insumos[
                                      fieldKey
                                    ].recibido;
                                  let max;
                                  if (recibido) {
                                    max = cantidad - recibido;
                                  } else {
                                    max = cantidad;
                                  }
                                  return (
                                    <FormItem
                                      {...restField}
                                      name={[name, "recibir"]}
                                      fieldKey={[fieldKey, "recibir"]}
                                      number
                                      noStyle
                                      max={max}
                                      type="number"
                                    >
                                      <InputNumberItem
                                        sinBorde
                                        placeholder={"Max: " + max}
                                      />
                                    </FormItem>
                                  );
                                }}
                              </FormItem>
                            </td>
                            <td className="td-border-sin-right">
                                <Row>
                                  <Col span={24}>
                                    <FormItem
                                      noStyle
                                      shouldUpdate
                                    >
                                      {({ getFieldValue }) => {
                                        let arrayValue = getFieldValue("insumos");
                                        let mostrarMarbetes = false
                                        if (arrayValue[arrayKey]?.insumoId && this.state.todasInsumos) {
                                          const insumo = this.state.todasInsumos.find(
                                            (valueInsumos) => valueInsumos._id === arrayValue[arrayKey].insumoId
                                          );
                                          mostrarMarbetes = insumo.marbetes
                                        }
                                        if (mostrarMarbetes) {
                                          return (
                                            <ButtonItem
                                              type="primary"
                                              size='small'
                                              block
                                              onClick={() => {
                                                this.abrirAgregarMarbetes(arrayKey, arrayValue[arrayKey].marbetes)
                                              }}
                                            >
                                              Marbetes
                                            </ButtonItem>
                                          )
                                        }
                                      }}
                                    </FormItem>
                                  </Col>
                                </Row>
                              </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </>
                )}
              </FormInitial.List>
            </Form>
          </Spin>
        </div>
        <AgregarMarbetes
          visible={this.state.modalAgregarMarbetes}
          cerrarAgregarMarbetes={this.cerrarAgregarMarbetes.bind(this)}
          marbetes={this.state.modalAgregarMarbetesMarbetes}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(RecibirInsumosFactura);
