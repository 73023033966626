import { message, PageHeader, Spin } from "antd";
import axios from "axios";
import React, {Component} from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import polizaPdf from "../../../../Pdf/polizaPdf";
import { connect } from "react-redux";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class VerPoliza extends Component{
    state = {
        loading: false,
        pdf: null,
        id: this.props.location.state.id,
      };
    
      atras() {
        this.props.history.goBack();
      }
    
      componentDidMount() {
        this.setState({ loading: true });
        axios
          .post(
            "polizas/datosPoliza",
            { polizaId: this.state.id, empresaId: this.props.empresaId },
            { headers: this.props.headersToken }
          )
          .then((res) => {
            if (res.data === "error") {
              message.error(
                "Ha sucedido un error y no se cargo bien la poliza"
              );
              this.setState({loading: false})
            } else {
                const pdf = pdfMake.createPdf(polizaPdf(res.data));
                pdf.open({}, window.frames['printPdf'])
                this.setState({
                  loading: false,
                  pdf: pdf
                });
            }
          });
      }
    render(){
        return(
            <>
            <PageHeader
             onBack={this.atras.bind(this)}
              title="Ver Poliza"
              style={{
                border: "1px solid rgb(235, 237, 240)",
                backgroundColor: "white",
                marginBottom: 10,
              }}
            />
            <div style={{ backgroundColor: "white", padding: 20, paddingTop: 5 }}>
                <Spin spinning={this.state.loading}>
                    <iframe title={'documentos'} style={{ width: '100%', height: '70vh' }} id="printPdf" name="printPdf"/>
                </Spin>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
export default connect(mapStateToProps)(VerPoliza);

