import {
  Button,
  message,
  PageHeader,
  Spin,
  Row,
  Col,
  Divider,
  Select,
  Switch,
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormItem,
  SelectItem,
  InputNumberItem,
  cuentaMask,
  InputItem,
} from "../../../../Components/Items";

const { Option } = Select;

class AgregarImpuesto extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    todasCuentasContablesFinales: null,
    objetoCuentasContablesFinales: null,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "cuentascontables/selectCuentasContablesFinales",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasCuentasContablesFinales: res.data });
          this.setState({
            objetoCuentasContablesFinales: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {cuentaMask(
                    value.nivel1 +
                      value.nivel2 +
                      value.nivel3 +
                      value.nivel4 +
                      value.nivel5
                  ) +
                    " - " +
                    value.descripcion}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  atras() {
    this.props.history.goBack();
  }
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    this.setState({ loading: true });
    axios
      .post(
        "impuestos/agregarImpuesto",
        { ...values, empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.id) {
          message.success("Se agrego un impuesto");
          this.formRef.current.resetFields();
          this.atras(res.data.id);
        } else {
          message.error("Sucedio un error y no se guardo");
        }
      });
  }
  onChangeCombustible(value){
    if(value){
      message.info('Utilizar esta funcion solamente en combustibles o impuestos que no sean exactos los porcentajes')
      this.formRef.current.setFieldsValue({
        tasaCuota: 4
      })
    }else{
      this.formRef.current.setFieldsValue({
        tasaCuota: 1
      })
    }
  }
  onChangeLocal(value){
    if(value){
      this.formRef.current.setFieldsValue({
        impuesto: undefined,
        combustible: false
      })
    }
    const tasaCuota = this.formRef.current.getFieldValue('tasaCuota')
    if(tasaCuota === 5){
      this.formRef.current.setFieldsValue({
        tasaCuota: undefined
      })
    }
  }
  onChangeImpuesto(value){
    const tasaCuota = this.formRef.current.getFieldValue('tasaCuota')
    if(tasaCuota === 5){
      this.formRef.current.setFieldsValue({
        tasaCuota: undefined
      })
    }
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title="Agregar Impuesto"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="agregarImpuesto"
              layout
              bottom={
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Agregar
                </Button>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={12}>
                  <FormItem
                    name="nombre"
                    label="Nombre"
                    required
                    margin
                    pattern
                  >
                    <InputItem placeholder="Nombre" />
                  </FormItem>
                  <FormItem shouldUpdate noStyle>
                    {({getFieldValue}) => {
                      const local = getFieldValue('local')
                      if(!local){
                        return (
                          <FormItem
                          label="Especial"
                          tooltip="Activar solamente en combustibles o impuestos que no sean exactos los porcentajes"
                          name="combustible"
                          margin
                          initialValue={false}
                          valuePropName="checked"
                        >
                          <Switch onChange={this.onChangeCombustible.bind(this)} />
                        </FormItem>
                        )
                      }
                    }}
                  </FormItem>
                </Col>
                <Col span={12}>
                <FormItem
                    label="Local"
                    tooltip="Activar en impuestos locales. Ej: ISH"
                    name="local"
                    margin
                    initialValue={false}
                    valuePropName="checked"
                  >
                    <Switch onChange={this.onChangeLocal.bind(this)} />
                  </FormItem>
                  <FormItem shouldUpdate noStyle>
                    {({getFieldValue}) => {
                      const local = getFieldValue('local')
                      if(!local){
                        return (
                          <FormItem
                            name="impuesto"
                            label="Impuesto"
                            required
                            margin
                            pattern
                          >
                            <SelectItem
                              width="100%"
                              placeholder="Impuesto"
                              onChange={this.onChangeImpuesto.bind(this)}
                            >
                              <Option key={2} value={2}>002 - IVA</Option>
                              <Option key={3} value={3}>003 - IEPS</Option>
                            </SelectItem>
                          </FormItem>
                        )
                      }
                    }}
                  </FormItem>
                </Col>
              </Row>
              <Divider
                style={{ marginBottom: 0, marginTop: 5 }}
                children="Cuentas del Impuesto"
              />
                            <FormItem shouldUpdate noStyle>
                {({getFieldValue}) => {
                  const combustible = getFieldValue('combustible')
                  return(
                      <>
                        <table className="table">
                            <thead>
                              <tr>
                                <th className="th-border" style={{width: '15%'}}>Impuesto Por Pagar</th>
                                <th className="th-border" style={{width: '15%'}}>Impuesto Pagado</th>
                                <th className="th-border" style={{width: '15%'}}>Impuesto No Cobrado</th>
                                <th className="th-border" style={{width: '15%'}}>Impuesto Cobrado</th>
                                <th className="th-border" style={{width: '15%'}}>Tasa o Cuota</th>
                                <th className="th-border" style={{width: '10%'}}>Monto</th>
                              </tr>
                            </thead>
                              <tbody >
                                <tr>
                                  <td className="td-border">
                                    <FormItem
                                      name={"cuentaContablePorPagarId"}
                                      required
                                      noStyle
                                    >
                                      <SelectItem
                                        sinBorde
                                        width="100%"
                                        placeholder="Impuesto Por Pagar"
                                      >
                                        {
                                          this.state
                                            .objetoCuentasContablesFinales
                                        }
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                    <FormItem                                
                                      name={"cuentaContablePagadoId"}
                                      required
                                      noStyle
                                    >
                                      <SelectItem
                                        sinBorde
                                        width="100%"
                                        placeholder="Impuesto Pagado"
                                      >
                                        {
                                          this.state
                                            .objetoCuentasContablesFinales
                                        }
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                    <FormItem                                
                                      name={"cuentaContableNoCobradoId"}
                                      required
                                      noStyle
                                    >
                                      <SelectItem
                                        sinBorde
                                        width="100%"
                                        placeholder="Impuesto No Cobrado"
                                      >
                                        {
                                          this.state
                                            .objetoCuentasContablesFinales
                                        }
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                    <FormItem    
                                      name={"cuentaContableCobradoId"}
                                      required
                                      noStyle
                                    >
                                      <SelectItem
                                        sinBorde
                                        width="100%"
                                        placeholder="Impuesto Cobrado"
                                      >
                                        {
                                          this.state
                                            .objetoCuentasContablesFinales
                                        }
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                    <FormItem shouldUpdate noStyle>
                                        {({getFieldValue}) => {
                                          const impuesto = getFieldValue('impuesto')

                                          return(
                                            <FormItem                                
                                              name="tasaCuota"
                                              required
                                              noStyle
                                              initialValue={1}
                                            >
                                              <SelectItem
                                                sinBorde
                                                width="100%"
                                                placeholder="Tasa o Cuota"
                                                disabled={combustible}
                                                onChange={(e) => {
                                                  this.formRef.current.resetFields(['monto'])
                                                  this.formRef.current.setFieldsValue({monto: 0})
                                                }}
                                              >
                                                <Option value={1} key={1}>
                                                  Tasa - Subtotal
                                                </Option>
                                                <Option value={2} key={2}>
                                                  Tasa - Acumulado
                                                </Option>
                                                <Option value={3} key={3}>
                                                  Cuota
                                                </Option>
                                                <Option value={4} key={4} disabled>
                                                  Elegible
                                                </Option>
                                                <Option value={5} key={5} disabled={impuesto !== 2}>
                                                  Exento
                                                </Option>
                                              </SelectItem>
                                            </FormItem>

                                          )
                                        }}
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                    <FormItem noStyle shouldUpdate>
                                      {({getFieldValue}) => {
                                        const tasaCuota = getFieldValue('tasaCuota')
                                        let properties = {
                                          placeholder:"Monto",
                                          porcentaje: (tasaCuota === 3 || !tasaCuota) ? false : true,
                                          dinero: (tasaCuota === 3 || !tasaCuota) ? true : false,
                                        }
                                        return (
                                          <FormItem                                      
                                            name={"monto"}
                                            required
                                            numberCero
                                            noStyle
                                            initialValue={undefined}
                                          >
                                            <InputNumberItem sinBorde disabled={tasaCuota === 5} {...properties}/>
                                          </FormItem>
                                        )
                                      }}
                                    </FormItem>
                                  </td>
                                </tr>
                              </tbody>
                        </table>
                      </>
                  )
                  }}
                  </FormItem>
            </Form>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(AgregarImpuesto);
