import { CheckCircleOutlined, EditOutlined, StopOutlined } from "@ant-design/icons";
import { PageHeader, Checkbox, Space, Popconfirm, message } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableItem, ButtonItem, dineroDisabledMask } from "../../../Components/Items";
import AgregarBanco from "./AgregarBanco/AgregarBanco";
import EditarBanco from "./EditarBanco/EditarBanco";

class Bancos extends Component {
  state = {
    todasBancos: [],
    modalAgregarBanco: false,
    modalEditarBanco: false,
    modalEditarBancoId: null,
    loading: false
  };
  componentDidMount() {
    this.consultarBancos();
  }

  consultarBancos() {
    this.setState({loading: true})
    axios
    .post(
      "bancos/selectTodosBancos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasBancos: res.data.map(value => {
            return {...value, accion: { _id: value._id, nombre: value.nombre, estado: value.estado }}
          }),
        });
      }
    });
  }

  abrirAgregarBanco() {
    this.setState({ modalAgregarBanco: true });
  }
  cerrarAgregarBanco() {
    this.setState({ modalAgregarBanco: false });
    this.consultarBancos();
  }
  abrirEditarBanco(id) {
    this.setState({ modalEditarBanco: true, modalEditarBancoId: id });
  }
  cerrarEditarBanco() {
    this.setState({ modalEditarBanco: false });
    this.consultarBancos();
  }
  deshabilitarBanco(value) {
    axios
      .post(
        "bancos/deshabilitarBanco",
        { bancoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Banco deshabilitado");
        } else {
          message.error("Sucedio un eror y no se pudo deshabilitar");
        }
        this.consultarBancos();
      });
  }
  habilitarBanco(value) {
    axios
      .post(
        "bancos/habilitarBanco",
        { bancoId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Banco habilitado");
        } else {
          message.error("Sucedio un eror y no se pudo habilitar");
        }
        this.consultarBancos();
      });
  }
  abrirTraspasoEntreCuentas() {
    this.props.history.push('/panel/traspasoEntreCuentas')
  }
  abrirConciliarCuenta(id, nombre) {
    this.props.history.push('/panel/conciliarCuenta', {id, nombre})
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Banco",
        dataIndex: "banco",
        key: "banco",
        sorter: (a, b) => {
          if (a.banco < b.banco) {
            return -1;
          }
          if (a.banco > b.banco) {
            return 1;
          }
          return 0;
        },
        buscar: "banco",
      },

      {
        title: "Moneda",
        dataIndex: "moneda",
        key: "moneda",
        render: (value) => value.nombre
      },

      {
        title: "Es inversion",
        dataIndex: "inversion",
        key: "inversion",
        render: (value) => <Checkbox checked={value} />,
        align: "center",
      },
      {
        title: "Saldo",
        dataIndex: "saldo",
        key: "saldo",
        render: (value) => dineroDisabledMask(value)
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value, record, index) => {
          return(
          <Space size="middle" key={index.toString()}>
            {this.props.permisoEditarBancos && 
            <EditOutlined
              key={index.toString()}
              onClick={() => this.abrirEditarBanco(value._id)}
              style={{ color: "blue" }}
            />
            }
            {this.props.permisoDeshabilitarBanco && 
              <>
                {value.estado === 1 ? (
                  <Popconfirm
                    title="Seguro que quieres deshabilitar este banco"
                    onConfirm={() => this.deshabilitarBanco(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Seguro que quieres habilitar este banco"
                    onConfirm={() => this.habilitarBanco(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <StopOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </>
            }
            <ButtonItem key={index.toString()} type='primary' onClick={() => this.abrirConciliarCuenta(value._id, value.nombre)} size='small'>
              Conciliar Cuenta
            </ButtonItem>
          </Space>
        )
          },
      },
    ]
    let pageHeaderExtra = []
    if(this.props.permisoAgregarBancos){
      pageHeaderExtra.push(
        <ButtonItem
        type="primary"
        key="agregarBanco"
        onClick={this.abrirAgregarBanco.bind(this)}
      >
        Agregar Banco
      </ButtonItem>
      )
    }
    if(this.props.permisoTraspasoEntreCuentas){
      pageHeaderExtra.push(
        <ButtonItem
        type="primary"
        key="traspaso"
        onClick={this.abrirTraspasoEntreCuentas.bind(this)}
      >
        Traspaso entre Cuentas
      </ButtonItem>
      )
    }
    return (
      <>
        <PageHeader
          title="Bancos"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={pageHeaderExtra}
        />
        <TableItem size='small' loading={this.state.loading} dataSource={this.state.todasBancos} columns={columns} />
        {this.props.permisoAgregarBancos && 
        <AgregarBanco
          visible={this.state.modalAgregarBanco}
          cerrarAgregarBanco={this.cerrarAgregarBanco.bind(
            this
          )}
        />
        }
        {this.props.permisoEditarBancos && 
                <EditarBanco
              id={this.state.modalEditarBancoId}
          visible={this.state.modalEditarBanco}
          cerrarEditarBanco={this.cerrarEditarBanco.bind(
            this
          )}
        />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarBancos: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ca4b4f30e81b54183cf4a")?.activado,
    permisoEditarBancos: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ca360f30e81b54183cf49")?.activado,
    permisoTraspasoEntreCuentas: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ca538f30e81b54183cf4b")?.activado,
    permisoDeshabilitarBanco: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ca591f30e81b54183cf4c")?.activado,
  };
};

export default connect(mapStateToProps)(Bancos);
