import React, {Component} from 'react'

class HeaderItem extends Component{
    render(){
        return(
            <h1 style={{
                // color: '#00D6F0',
                color: 'black',
                textAlign: 'center', 
                fontSize: 15,
                // backgroundColor: '#F6F6F6', 
                borderRadius: 1,
                marginTop: 6,
                margin: 2              
            } }>
            {this.props.children}</h1>
        )
    }
}

export default HeaderItem