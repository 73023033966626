const decodeHTMLEntities = (text) => {
    if(text === undefined){
        return ''
    }
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['cent', '¢'],
        ['pound', '£'],
        ['yen', '¥'],
        ['euro', '€'],
        ['copyright', '©'],
        ['trademark', '®'],
        ['quot', '"']
    ];

    for (var i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

    // text = text.replace(/&#xA;/g, ' ')

    return text;
  }

export default decodeHTMLEntities