import { message, Space, Form as FormInitial, Row, Col, Button } from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalItem,
  ButtonItem,
  Form,
  FormItem,
  InputItem,
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import cotizacionPdf from "../../../../Pdf/cotizacionPdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class MandarCorreoCotizacion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    pdf: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "cotizaciones/datosPdf",
        { cotizacionId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          if (res.data?.correosEnvio?.length > 0) {
            this.formRef.current.setFieldsValue({ correosEnvio: res.data.correosEnvio })
          }
          const pdf = pdfMake.createPdf(cotizacionPdf(res.data))
          this.setState(
            {
              loading: false,
              pdf: pdf,
              factura: res.data.factura
            },
            () => {
              pdf.open({}, window.frames["cotizacionPdf"]);
            }
          );

        } else {
          this.cerrarMandarCorreoCotizacion()
          message.warning("No se pudo cargar la cotizacion")
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    message.error("Porfavor ingrese bien los datos");
  }
  onFinish(values) {
    if (values?.correosEnvio?.length > 0) {
      this.setState({ loading: true });
      axios
        .post(
          "cotizaciones/enviarCotizacion",
          { ...values, cotizacionId: this.props.id, empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.success === 2) {
            message.success('Cotizacion enviada');
            this.formRef.current.resetFields();
            this.cerrarMandarCorreoCotizacion();
          } else if (res.data.success === 1) {
            message.error("No se pudo enviar la cotización a ese correo");
          } else {
            message.error("Sucedio un error y no se pudo enviar");
          }
        });
    } else {
      message.info('Proporcione al menos un correo de envio')
    }
  }
  cerrarMandarCorreoCotizacion() {
    this.props.cerrarMandarCorreoCotizacion();
    this.formRef.current.resetFields()
  }
  render() {
    return (
      <ModalItem
        title="Vista previa"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarMandarCorreoCotizacion.bind(this)}
        paddingTop
      >
        <Form
          ref={this.formRef}
          name="mandarCorreoCotizacion"
          layout
          width={700}
          onCancel={this.cerrarMandarCorreoCotizacion.bind(this)}
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
        >
          <FormInitial.List
            name="correosEnvio"
            initialValue={[{ correo: '' }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name }, arrayKey) => (
                  <Row>
                    <Col span={22}>
                      <FormItem
                        name={[name, "correo"]}
                        fieldKey={[arrayKey, "correo"]}
                        label='Correo'
                        required
                        margin
                        email
                        pattern
                      >
                        <InputItem style={{ width: '100%' }} placeholder="Correo" />
                      </FormItem>
                    </Col>
                    <Col span={2}>
                      <div style={{ textAlign: 'center' }}>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
                {fields.length < 5 ?
                  <div style={{ textAlign: 'center', marginTop: '5px' }}>
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Agregar
                    </Button>
                  </div>
                  : null}
              </>
            )}
          </FormInitial.List>
          <div style={{ display: "flex", justifyContent: "right", marginTop: 20, marginBottom: 10 }}>
            <Space>
              <ButtonItem
                type="primary"
                htmlType="submit"
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Mandar Correo
              </ButtonItem>
              <ButtonItem
                type="primary"
                danger
                onClick={this.cerrarMandarCorreoCotizacion.bind(this)}
                style={{ display: "block", margin: "0 0 0 auto" }}
              >
                Cancelar
              </ButtonItem>
            </Space>
          </div>
        </Form>
        <iframe
          title={"venta"}
          style={{ width: "100%", height: "100vh" }}
          id="cotizacionPdf"
          name="cotizacionPdf"
        />
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(MandarCorreoCotizacion);
