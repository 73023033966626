import moment from 'moment'
import 'moment/locale/es'
import {cuentaMask, dineroDisabledMask} from '../Components/Items'

moment.locale('es')

const reportePresupuestosPdf = (values) => {
    const empresa = values.empresa

    let filtrados = []

    if(values.presupuestosFilter.length > 0 ){
        let textoFiltrado = ''
        values.presupuestosFilter.map((valuePresupuestos) => {
            textoFiltrado += `${valuePresupuestos.nombre}\n`
            return null
        })
        filtrados.push([
            {text: 'Presupuestos:', style: 'subheader'},
            
            {text: textoFiltrado, style: 'textoChico'}
          ])
    }

    if(values.filtrarProyecto === 1){
        let textoFiltrado = ''
        values.proyectos.map((valueProyectos) => {
            textoFiltrado += `${valueProyectos.nombre}\n`
            return null
        })
        filtrados.push([
            {text: 'Proyectos:', style: 'subheader'},
            
            {text: textoFiltrado, style: 'textoChico'}
          ])
    }

    if(values.filtrarProyecto === 2){
        filtrados.push([
            {text: 'Unidad de Negocio:', style: 'subheader'},
            
            {text: `${cuentaMask(
                values.unidadDeNegocio.nivel1 + values.unidadDeNegocio.nivel2 + values.unidadDeNegocio.nivel3 + values.unidadDeNegocio.nivel4 +values.unidadDeNegocio.nivel5
              ) +
                " - " +
                values.unidadDeNegocio.nombre}`, style: 'textoChico'}
          ])
    }

    if(filtrados.length === 0){
        filtrados.push([
            {text: 'No tiene filtrados adicionales', style: 'subheader'},

            {text: '', style: 'textoChico'}
      ] )
    }

    let totalProgramadoIngresos = 0
    let totalSaldoIngresos = 0
    let totalPorEjercerIngresos = 0

    let ingresosTable = values.presupuestosIngresos.map(valuePresupuesto => {
        totalProgramadoIngresos += valuePresupuesto.montoTotal
        totalSaldoIngresos += valuePresupuesto.saldoTotal
        totalPorEjercerIngresos += valuePresupuesto.porEjercerTotal
        return [
            {text: cuentaMask(
                valuePresupuesto.cuentaContable.nivel1 +
                valuePresupuesto.cuentaContable.nivel2 +
                valuePresupuesto.cuentaContable.nivel3 +
                valuePresupuesto.cuentaContable.nivel4 +
                valuePresupuesto.cuentaContable.nivel5
              ), style: 'textoChico', alignment: 'left'}, 
            {text: valuePresupuesto.cuentaContable.descripcion, style: 'textoChico', alignment: 'left'}, 
            {text: valuePresupuesto.concepto, style: 'textoChico', alignment: 'left'},
            {text: dineroDisabledMask(valuePresupuesto.montoTotal), style: 'textoChico', alignment: 'right'},
            {text: dineroDisabledMask(valuePresupuesto.saldoTotal), style: 'textoChico', alignment: 'right'},
            {text: dineroDisabledMask(valuePresupuesto.porEjercerTotal), style: 'textoChico', alignment: 'right'},
        ]
    })

    let totalProgramadoEgresos = 0
    let totalSaldoEgresos = 0
    let totalPorEjercerEgresos = 0

    let egresosTable = values.presupuestosEgresos.map(valuePresupuesto => {
        totalProgramadoEgresos += valuePresupuesto.montoTotal
        totalSaldoEgresos += valuePresupuesto.saldoTotal
        totalPorEjercerEgresos += valuePresupuesto.porEjercerTotal
        return [
            {text: cuentaMask(
                valuePresupuesto.cuentaContable.nivel1 +
                valuePresupuesto.cuentaContable.nivel2 +
                valuePresupuesto.cuentaContable.nivel3 +
                valuePresupuesto.cuentaContable.nivel4 +
                valuePresupuesto.cuentaContable.nivel5
              ), style: 'textoChico', alignment: 'left'}, 
            {text: valuePresupuesto.cuentaContable.descripcion, style: 'textoChico', alignment: 'left'}, 
            {text: valuePresupuesto.concepto, style: 'textoChico', alignment: 'left'},
            {text: dineroDisabledMask(valuePresupuesto.montoTotal), style: 'textoChico', alignment: 'right'},
            {text: dineroDisabledMask(valuePresupuesto.saldoTotal), style: 'textoChico', alignment: 'right'},
            {text: dineroDisabledMask(valuePresupuesto.porEjercerTotal), style: 'textoChico', alignment: 'right'},
        ]
    })

    var dd = {
        content: [
            {
                style: 'tableExample',
                table: {
                    widths: [ '18%', '52%', '30%'],
                    layout: {
                        defaultBorder: false,
                    },
                    body: [
                        [
                            {
    //							rowSpan: 3,
                                border: [false, false, false, false],
                                image: 'logo',
                                fit: [80, 80] 
                                //text: 'rowSpan: 3\n\nborder:\n[true, true, true, true]'
                            },
                            {
                            //	rowSpan: 2,
                                border: [false, false, false, false],
                                table: {
                                    headerRows: 1,
                                    widths: ['100%'],
                                
                                    body: [
                                      [
                                          { 
                                          text: empresa.persona ? empresa.nombre : empresa.nombre+' '+empresa.apellidoPaterno+' '+empresa.apellidoMaterno, 
                                          style: "tableHeader" 
                                          }
                                      ],
                                      [
                                        {
                                          text: "R.F.C: "+empresa.rfc,
                                          style: "textoChico",
                                          alignment: "center",
                                        },
                                      ],
                                      [
                                          {
                                            text: empresa.calle+' '+
                                                  empresa.numeroCalle+' '+
                                                  (empresa.numeroInterior ? empresa.numeroInterior : '')
                                            ,
                                            style: "textoChico",
                                            alignment: "center",
                                          },
                                        ],
                                      [
                                          {
                                            text: empresa.colonia.tipoAsentamiento+' '+
                                            empresa.colonia.nombre+' C.P. '+
                                            empresa.colonia.cp+' '+
                                            empresa.municipio.nombre+' '+
                                            empresa.estado.abreviatura+' '+
                                            empresa.pais.abreviatura+' '
                                            ,
                                            style: "textoChico",
                                            alignment: "center",
                                          },
                                        ],
                                        [{text: 'Presupuestos', style: 'tableHeader'}],
                                    ]
                                },
                                layout: 'noBorders'
                            },
                            {
                                border: [false, false, false, false],
                                    table: {
                                    headerRows: 1,
                                    widths: ['100%'],
                                
                                    body: [
                                        [{text: 'Fecha Inicial:', style: 'subheader', alignment: 'center'}],
                                        [{text: moment(values.fechaInicial).locale('es').format('LL'), style: 'textoChico', alignment: 'center'}],
                                        [{text: 'Fecha Final:', style: 'subheader', alignment: 'center'}],
                                        [{text: moment(values.fechaFinal).locale('es').format('LL'), style: 'textoChico', alignment: 'center'}],
                                        [{text: 'Fecha de Impresión:', style: 'subheader', alignment: 'center'}],
                                        [{text: moment().locale('es').format('LL'), style: 'textoChico', alignment: 'center'}],
                                    ]
                                },
                                layout: 'noBorders'
                            },
                        ]
    
                    ]
                },
            },
            {text: 'Datos de filtrado:'},
            {
                style: 'tableExample',
                table: {
                    widths: ['20%', '80%'],
                    layout: {
                                        defaultBorder: false,
                                    },
                    
                    body: filtrados,
                },
                            layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 1 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                    },
                    hLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    vLineColor: function (i, node) {
                        return '#adaaaa';
                    },
                    hLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return null;
                        }
                        return {dash: {length: 2, space: 1}};
                    },
                    vLineStyle: function (i, node) {
                        if (i === 0 || i === node.table.widths.length) {
                            return null;
                        }
                        return {dash: {length: 2}};
                    },
                    // paddingLeft: function(i, node) { return 4; },
                    // paddingRight: function(i, node) { return 4; },
                    // paddingTop: function(i, node) { return 2; },
                    // paddingBottom: function(i, node) { return 2; },
                    // fillColor: function (i, node) { return null; }
                }
                
            },
            
            {
            table: {
                widths: ['15%', '25%', '15%', '15%', '15%', '15%'],
                layout: {
                    defaultBorder: true,
                },
                body: [
                    [
                        {colSpan: 6,text: 'INGRESOS', style: 'tituloTabla1'},
                        {},
                        {},
                        {},
                        {},
                        {}
                    ],
                    [
                        {text: 'Cuenta', style: 'tituloTabla'},
                        {text: 'Descripción', style: 'tituloTabla'},
                        {text: 'Concepto', style: 'tituloTabla'},
                        {text: 'Programado', style: 'tituloTabla'},
                        {text: 'Saldo Contable', style: 'tituloTabla'},
                        {text: 'Por Ejercer', style: 'tituloTabla'},
                    ],
                    ...ingresosTable,
                    [
                        {border: [false,false, false,false], text: 'Suma de los Ingresos', style: 'subheader', colSpan: 2},
                        {border: [false,false, false,false], text: '', style: 'textoChico',},
                        {border: [false,false, false,false], text: '', style: 'textoChico',},
                        {border: [false,true, false,false], text: dineroDisabledMask(totalProgramadoIngresos), style: 'textoChico', alignment: 'right'},
                        {border: [false,true, false,false], text: dineroDisabledMask(totalSaldoIngresos), style: 'textoChico', alignment: 'right'},
                        {border: [false,true, false,false], text: dineroDisabledMask(totalPorEjercerIngresos), style: 'textoChico', alignment: 'right'}
                    ],
                ]
            },
            layout: {
                fillColor: (rowIndex, node, columnIndex) => {
                    return (rowIndex % 2 === 0) && rowIndex > 2  ? "#e8e8e8" : "#ffffff";
                },
                hLineWidth: function (i, node) {
                    return 0
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
            }
            },
            {
                table: {
                    widths: ['15%', '25%', '15%', '15%', '15%', '15%'],
                    layout: {
                        defaultBorder: true,
                    },
                    body: [
                        [
                            {colSpan: 6,text: 'EGRESOS', style: 'tituloTabla1'},
                            {},
                            {},
                            {},
                            {},
                            {}
                        ],
                        [
                            {text: 'Cuenta', style: 'tituloTabla'},
                            {text: 'Descripción', style: 'tituloTabla'},
                            {text: 'Concepto', style: 'tituloTabla'},
                            {text: 'Programado', style: 'tituloTabla'},
                            {text: 'Saldo Contable', style: 'tituloTabla'},
                            {text: 'Por Ejercer', style: 'tituloTabla'},
                        ],
                        ...egresosTable,
                        [
                            {border: [false,false, false,false], text: 'Suma de los Egresos', style: 'subheader', colSpan: 2},
                            {border: [false,false, false,false], text: '', style: 'textoChico',},
                            {border: [false,false, false,false], text: '', style: 'textoChico',},
                            {border: [false,true, false,false], text: dineroDisabledMask(totalProgramadoEgresos), style: 'textoChico', alignment: 'right'},
                            {border: [false,true, false,false], text: dineroDisabledMask(totalSaldoEgresos), style: 'textoChico', alignment: 'right'},
                            {border: [false,true, false,false], text: dineroDisabledMask(totalPorEjercerEgresos), style: 'textoChico', alignment: 'right'}
                        ],
                    ]
                },
                layout: {
                    fillColor: (rowIndex, node, columnIndex) => {
                        return (rowIndex % 2 === 0) && rowIndex > 2  ? "#e8e8e8" : "#ffffff";
                    },
                    hLineWidth: function (i, node) {
                        return 0
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                }
                },
        ],
        images: {
            logo: empresa.logo.base64Completa,
        },
        styles: {
            header: {
                fontSize: 9,
                bold: true,
            },
            subheader: {
                fontSize: 8,
                bold: true,
            },
            tableExample: {
                margin: [0, 5, 0, 5]
            },
            tablaSinMargen: {
                margin: [0, 0, 0, 0]
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black',
                alignment: 'center'
            },
            textoChico:{
                fontSize: 8
            },
            tituloTabla:{
                fontSize: 8,
                bold: true, 
                fillColor: '#cccccc', 
                alignment: 'center',
            },
            tituloTabla1:{
                fontSize: 12,
                bold: true, 
                alignment: 'center',
            },
            textoTabla: {
                fontSize: 8,
                italics: false
            },
            tituloCuentasMayor: {
                fontSize: 8,
                bold: true, 
                fillColor: '#E0E0E0', 
                alignment: 'center',
            },
            tituloCuentasMenor: {
                fontSize: 8,
                bold: true, 
                fillColor: '#F9F9F9', 
                alignment: 'center',
            },
        },
        pageSize: 'LETTER',
    }
  return dd;
};


export default reportePresupuestosPdf;
