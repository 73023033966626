import moment from "moment";
import "moment/locale/es";
import { dineroDisabledMask } from "../Components/Items";

moment.locale("es");

const complementoXmlPdf = (values) => {
  const dataXml = values.dataXml;
  const comprobante = dataXml.attributes;
  const emisor = dataXml.children.find(
    (valueXml) => valueXml.name === "cfdi:Emisor"
  )?.attributes;
  const receptor = dataXml.children.find(
    (valueXml) => valueXml.name === "cfdi:Receptor"
  )?.attributes;
  const conceptosXml = dataXml.children.find(
    (valueXml) => valueXml.name === "cfdi:Conceptos"
  )?.children;
  const timbre = dataXml.children
    .find((valueXml) => valueXml.name === "cfdi:Complemento")
    .children.find(
      (valueXml) => valueXml.name === "tfd:TimbreFiscalDigital"
    )?.attributes;
  const pagosXml = dataXml.children.find(
    (valueXml) => valueXml.name === "cfdi:Complemento"
  )?.children?.find(
    (valueXml) => valueXml.name === "pago20:Pagos"
  )?.children

  const pagosXml33 = dataXml.children.find(
    (valueXml) => valueXml.name === "cfdi:Complemento"
  )?.children?.find(
    (valueXml) => valueXml.name === "Pago10:Pagos"
  )?.children

  let tipoComprobante = ''

  if (comprobante.TipoDeComprobante === "I") {
    tipoComprobante = "I - Ingreso";
  } else if (comprobante.TipoDeComprobante === "P") {
    tipoComprobante = "P - Pago";
  } else if (comprobante.TipoDeComprobante === "E") {
    tipoComprobante = "E - Egreso";
  } else if (comprobante.TipoDeComprobante === "N") {
    tipoComprobante = "N - Nomina";
  } else if (comprobante.TipoDeComprobante === "T") {
    tipoComprobante = "T - Traslado";
  }

  let dd
  if(comprobante.Version.toString() === '4.0'){
    const conceptos = conceptosXml.map((valueConcepto) => {
      const attrConcepto = valueConcepto.attributes;
      return [
        { style: "textoTablaChico", text: attrConcepto.Cantidad, alignment: "right" },
        {
          text: attrConcepto.ClaveUnidad,
          style: "textoTablaChico",
          alignment: "center",
        },
        {
          text: attrConcepto.ClaveProdServ,
          style: "textoTablaChico",
          alignment: "center",
        },
        {
          text: attrConcepto.Descripcion,
          style: "textoTablaChico",
          alignment: "left",
        },
        {
          text: dineroDisabledMask(attrConcepto.ValorUnitario),
          style: "textoTablaChico",
          alignment: "right",
        },
        {
          text: dineroDisabledMask(attrConcepto.Importe),
          style: "textoTablaChico",
          alignment: "right",
        },
      ];
    });

    let pagos = []

    let RfcEmisorCtaOrd = ''
    let CtaOrdenante = ''
    let NomBancoOrdExt = ''
    let RfcEmisorCtaBen = ''
    let CtaBeneficiario = ''


    for (const valuePago of pagosXml) {

      
      if(valuePago.name === 'pago20:Pago'){
        
        const attrPago = valuePago.attributes

        if(attrPago.RfcEmisorCtaOrd){
          RfcEmisorCtaOrd = attrPago.RfcEmisorCtaOrd
        }
        if(attrPago.NomBancoOrdExt){
          NomBancoOrdExt = attrPago.NomBancoOrdExt
        }
        if(attrPago.RfcEmisorCtaBen){
          RfcEmisorCtaBen = attrPago.RfcEmisorCtaBen
        }
        if(attrPago.CtaBeneficiario){
          CtaBeneficiario = attrPago.CtaBeneficiario
        }
        if(attrPago.CtaOrdenante){
          CtaOrdenante = attrPago.CtaOrdenante
        }

        let pagosHijos = []

        for (const valuePagoHijo of valuePago.children) {
          
          if(valuePagoHijo.name === 'pago20:DoctoRelacionado'){
            const attrPagoHijo = valuePagoHijo.attributes
            pagosHijos.push([
              {
                style: "textoTabla",
                text: attrPagoHijo.IdDocumento,
                alignment: "center",
              },
              { text: attrPagoHijo.MonedaDR, style: "textoTabla", alignment: "center" },
              { text: attrPagoHijo.MetodoDePagoDR, style: "textoTabla", alignment: "center" },
              { text: attrPagoHijo.NumParcialidad, style: "textoTabla", alignment: "center" },
              { text: dineroDisabledMask(attrPagoHijo.ImpSaldoAnt), style: "textoTabla", alignment: "right" },
              { text: dineroDisabledMask(attrPagoHijo.ImpPagado), style: "textoTabla", alignment: "right" },
              { text: dineroDisabledMask(attrPagoHijo.ImpSaldoInsoluto), style: "textoTabla", alignment: "right" },
            ])
          }
        }

        pagos.push(              {
          text: "Forma De Pago",
          style: "tableHeader",
        },{
          style: "tableExample",
          table: {
            widths: ["20%", "18%", "14%", "18%", "30%"],
            layout: { defaultBorder: true },
            body: [
              [
                { text: "Fecha de Pago", style: "tituloTabla" },
                { text: "Forma de pago", style: "tituloTabla" },
                { text: "Moneda", style: "tituloTabla" },
                { text: "Num. Operacion", style: "tituloTabla" },
                { text: "Total", style: "tituloTabla" },
              ],
              [
                { text: attrPago.FechaPago, style: "textoTabla", alignment: "center" },
                { text: attrPago.FormaDePagoP, style: "textoTabla", alignment: "center" },
                { text: attrPago.MonedaP, style: "textoTabla", alignment: "center" },
                { text: attrPago.NumOperacion, style: "textoTabla", alignment: "center" },
                { text: dineroDisabledMask(attrPago.Monto), style: "textoTabla", alignment: "right" },
              ],
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        },
        {
          text: "Pagos",
          style: "tableHeader",
        },
        {
          style: "tableExample",
          table: {
            widths: ["26%", "8%", "8%", "10%", "16%", "16%", "16%"],
            layout: { defaultBorder: true },
            body: [
              [
                { text: "Documento UUID", style: "tituloTabla" },
                { text: "Moneda DR", style: "tituloTabla" },
                { text: "Parcialidad", style: "tituloTabla" },
                { text: "Monto", style: "tituloTabla" },
                { text: "Importe saldo anterior", style: "tituloTabla" },
                { text: "Importe pagado", style: "tituloTabla" },
                { text: "Importe saldo insoluto", style: "tituloTabla" },
              ],
              ...pagosHijos
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        })

      }
    }

    dd = {
      content: [
        {
          style: "tableExample",
          table: {
            widths: ["70%", "30%"],
            layout: { defaultBorder: false },
            body: [
              [
                {
                  border: [false, false, false, false],
                  table: {
                    headerRows: 1,
                    widths: ["100%"],
  
                    body: [
                      [
                        {
                          text: emisor.Nombre,
                          style: "tableHeader",
                          alignment: "left",
                        },
                      ],
                      [
                        {
                          text: "R.F.C: " + emisor.Rfc,
                          style: "textoChico",
                          alignment: "left",
                        },
                      ],
                      [
                        {
                          text: "Codigo Postal: " + comprobante.LugarExpedicion,
                          style: "textoChico",
                          alignment: "left",
                        },
                      ],
                      // empresa.telefono ? [
                      //     {
                      //         text: 'Tel: '+phoneMask(empresa.telefono),
                      //         style: "textoChico",
                      //         alignment: "left",
                      //       },
                      // ] : [],
                      [
                        {
                          text: "Regimen Fiscal: (" + emisor.RegimenFiscal + ") " + values.regimenFiscalEmisorString,
                          style: "textoChico",
                          alignment: "left",
                        },
                      ],
                      [
                        {
                          text: "Tipo De Comprobante: "+tipoComprobante,
                          style: "textoChico",
                          alignment: "left",
                        },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
                {
                  border: [false, false, false, false],
                  table: {
                    headerRows: 1,
                    widths: ["100%"],
  
                    body: [
                      [
                        {
                          text: "N° Complemento:",
                          style: "subheader",
                          alignment: "center",
                        },
                      ],
                      [
                        {
                          text: comprobante.Serie + " - " + comprobante.Folio,
                          style: "textoChico",
                          alignment: "center",
                        },
                      ],
                      [
                        {
                          text: "Moneda:",
                          style: "subheader",
                          alignment: "center",
                        },
                      ],
                      [
                        {
                          text: comprobante.Moneda,
                          style: "textoChico",
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          table: {
            widths: ["60%", "40%"],
            layout: { defaultBorder: false },
            body: [
              [
                {
                  table: {
                    widths: ["30%", "70%"],
                    layout: { defaultBorder: false },
                    body: [
                      [
                          { text: "Nombre del cliente :", style: "subheader" },
                          { text: receptor.Nombre, style: "textoChico" },
                        ],
                        [
                          { text: "R.F.C :", style: "subheader" },
                          { text: receptor.Rfc, style: "textoChico" },
                        ],
                        [
                          { text: "Regimen Fiscal :", style: "subheader" },
                          { text: "(" + receptor.RegimenFiscalReceptor + ") " + values.regimenFiscalReceptorString, style: "textoChico" },
                        ],
                        // [
                        //   { text: "Direccion :", style: "subheader" },
                        //   { text: "Variable", style: "textoChico" },
                        // ],
                        [
                          { text: "Uso de CFDI:", style: "subheader" },
                          {
                            text:"(" + receptor.UsoCFDI + ") " + values.usoDeCFDIString,
                            style: "textoChico",
                          },
                        ],
                    ],
                  },
                  layout: "noBorders",
                },
                {
                  table: {
                    widths: ["40%", "60%"],
                    layout: { defaultBorder: false },
                    body: [
                      [
                        { text: "Fecha:", style: "subheader" },
                        { text: moment(comprobante.Fecha)
                          .locale("es")
                          .format("LL"), style: "textoChico" },
                      ],
                      [
                        { text: "Lugar de expedicion:", style: "subheader" },
                        { text: comprobante.LugarExpedicion, style: "textoChico" },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        },
        {
          text: "Concepto",
          style: "tableHeader",
        },
        {
          style: "tableExample",
          table: {
            widths: ["7%", "8%", "10%", "50%", "10%", "15%"],
            layout: { defaultBorder: true },
            body: [
              [
                { text: "Cantidad", style: "tituloTabla" },
                { text: "Unidad", style: "tituloTabla" },
                { text: "Calve SAT", style: "tituloTabla" },
                { text: "Descripción", style: "tituloTabla" },
                { text: "P/U", style: "tituloTabla" },
                { text: "Importe", style: "tituloTabla" },
              ],
              ...conceptos
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        },
        ...pagos,{
                  table: {
                    widths: ["25%", "25%", "25%", "25%"],
                    layout: { defaultBorder: false },
                    body: [
                      [
                          {
                            border: [true, true, false, false],
                            text: "Cuenta Ordenante:",
                            style: "subheader",
                          },
                          {
                            border: [false, true, false, false],
                            text: CtaOrdenante || "",
                            style: "textoChico",
                            fontSize: 7,
                          },
                          {
                              border: [false, true, false, false],
                              text: "Rfc Ordenante:",
                              style: "subheader",
                            },
                            {
                              border: [false, true, true, false],
                              text: RfcEmisorCtaOrd || "",
                              style: "textoChico",
                              fontSize: 7,
                            },
                        ],
                        [
                            {
                              border: [true, false, false, false],
                              text: "Banco Ordenante:",
                              style: "subheader",
                            },
                            {
                              border: [false, false, true, false],
                              text: NomBancoOrdExt || "",
                              style: "textoChico",
                              colSpan: 3
                            },
                            {},
                            {}
                          ],
                        [
                          {
                            border: [true, false, false, true],
                            text: "Cuenta Beneficiario:",
                            style: "subheader",
                          },
                          {
                            border: [false, false, false, true],
                            text: CtaBeneficiario || "",
                            style: "textoChico",
                          },
                          {
                              border: [false, false, false, true],
                              text: "Rfc Beneficiario:",
                              style: "subheader",
                            },
                            {
                              border: [false, false, true, true],
                              text: RfcEmisorCtaBen || "",
                              style: "textoChico",
                            },
                        ],
                      [
                          {
                            border: [true, false, false, false],
                            text: "Moneda:",
                            style: "subheader",
                            colSpan: 2
                          },
                          {},
                          {
                            border: [false, false, true, false],
                            text: comprobante.Moneda,
                            style: "textoChico",
                            colSpan: 2
                          },
                          {}
                        ],
                      [
                        {
                          border: [true, false, false, true],
                          text: "Uso de CFDI:",
                          style: "subheader",
                          colSpan: 2
                        },
                        {},
                        {
                          border: [false, false, true, true],
                          text: "(" +
                          receptor.UsoCFDI +
                          ") ",
                          style: "textoChico",
                          colSpan: 2
                        },
                        {}
                      ]
                    ],
                  },
                  layout: {},
                },
        {
          table: {
            widths: ["15%", "30%", "23%", "32%"],
            layout: {
              defaultBorder: false,
            },
            body: [
              [
                {
                  colSpan: 4,
                  text: '"Este documento es una representacion impresa de un CFDI"',
                  style: "header",
                  alignment: "center",
                },
                {},
                {},
                {},
              ],
              [
                { text: "Folio Fiscal: ", style: "subheader" },
                { text: timbre.UUID, style: "textoChicoData" },
                { text: "Fecha y Hora de Certificación: ", style: "subheader" },
                {
                  text: comprobante.Fecha,
                  style: "textoChicoData",
                },
              ],
              [
                { colSpan: 4, text: "Sello digital CFDI: ", style: "subheader" },
                {},
                {},
                {},
              ],
              [
                {
                  colSpan: 4,
                  text: comprobante.Sello,
                  style: "textoChicoData",
                },
                {},
                {},
                {},
              ],
              [
                {
                  colSpan: 2,
                  text: "Numero de serie del Certificado de Sello digital: ",
                  style: "subheader",
                },
                {},
                {
                  colSpan: 2,
                  text: "Numero de serie del Certificado de Sello digital del SAT: ",
                  style: "subheader",
                },
                {},
              ],
              [
                {
                  colSpan: 2,
                  text: comprobante.NoCertificado,
                  style: "textoChicoData",
                },
                {},
                {
                  colSpan: 2,
                  text: timbre.NoCertificadoSAT,
                  style: "textoChicoData",
                },
                {},
              ],
              [
                {
                  colSpan: 4,
                  text: "Sello Digital del SAT: ",
                  style: "subheader",
                },
                {},
                {},
                {},
              ],
              [
                {
                  colSpan: 4,
                  text: timbre.SelloSAT,
                  style: "textoChicoData",
                },
                {},
                {},
                {},
              ],
              [
                {
                  colSpan: 4,
                  text: "Sello Digital del CFDI: ",
                  style: "subheader",
                },
                {},
                {},
                {},
              ],
              [
                {
                  colSpan: 4,
                  text: timbre.SelloCFD,
                  style: "textoChicoData",
                },
                {},
                {},
                {},
              ],
            ],
          },
          layout: "noBorders",
        },
      ],
      styles: {
        header: { fontSize: 9, bold: true },
        subheader: { fontSize: 8, bold: true },
        tableExample: { margin: [0, 5, 0, 5] },
        tablaSinMargen: { margin: [0, 0, 0, 0] },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "black",
          alignment: "center",
        },
        textoChico: { fontSize: 8 },
        textoChicoData: { fontSize: 6 },
        tituloTabla: {
          fontSize: 8,
          bold: true,
          fillColor: "#cccccc",
          style: "subheader",
          alignment: "center",
          border: true,
        },
        textoTabla: { fontSize: 8, italics: false },
        textoTablaChico: {
          fontSize: 6,
          italics: false,
        },
      },
      pageSize: "LETTER",
      defaultStyle: {
        columnGap: 20,
      },
    };
  }
  
  else if(comprobante.Version.toString() === '3.3'){
    const conceptos = conceptosXml.map((valueConcepto) => {
      const attrConcepto = valueConcepto.attributes;
      return [
        { style: "textoTablaChico", text: attrConcepto.Cantidad, alignment: "right" },
        {
          text: attrConcepto.ClaveUnidad,
          style: "textoTablaChico",
          alignment: "center",
        },
        {
          text: attrConcepto.ClaveProdServ,
          style: "textoTablaChico",
          alignment: "center",
        },
        {
          text: attrConcepto.Descripcion,
          style: "textoTablaChico",
          alignment: "left",
        },
        {
          text: dineroDisabledMask(attrConcepto.ValorUnitario),
          style: "textoTablaChico",
          alignment: "right",
        },
        {
          text: dineroDisabledMask(attrConcepto.Importe),
          style: "textoTablaChico",
          alignment: "right",
        },
      ];
    });

    let pagos = []

    let RfcEmisorCtaOrd = ''
    let CtaOrdenante = ''
    let NomBancoOrdExt = ''
    let RfcEmisorCtaBen = ''
    let CtaBeneficiario = ''


    for (const valuePago of pagosXml33) {

      if(valuePago.name === 'pago10:Pago'){
        
        const attrPago = valuePago.attributes

        if(attrPago.RfcEmisorCtaOrd){
          RfcEmisorCtaOrd = attrPago.RfcEmisorCtaOrd
        }
        if(attrPago.NomBancoOrdExt){
          NomBancoOrdExt = attrPago.NomBancoOrdExt
        }
        if(attrPago.RfcEmisorCtaBen){
          RfcEmisorCtaBen = attrPago.RfcEmisorCtaBen
        }
        if(attrPago.CtaBeneficiario){
          CtaBeneficiario = attrPago.CtaBeneficiario
        }
        if(attrPago.CtaOrdenante){
          CtaOrdenante = attrPago.CtaOrdenante
        }

        let pagosHijos = []

        for (const valuePagoHijo of valuePago.children) {
          
          if(valuePagoHijo.name === 'pago20:DoctoRelacionado'){
            const attrPagoHijo = valuePagoHijo.attributes
            pagosHijos.push([
              {
                style: "textoTabla",
                text: attrPagoHijo.IdDocumento,
                alignment: "center",
              },
              { text: attrPagoHijo.MonedaDR, style: "textoTabla", alignment: "center" },
              { text: attrPagoHijo.MetodoDePagoDR, style: "textoTabla", alignment: "center" },
              { text: attrPagoHijo.NumParcialidad, style: "textoTabla", alignment: "center" },
              { text: dineroDisabledMask(attrPagoHijo.ImpSaldoAnt), style: "textoTabla", alignment: "right" },
              { text: dineroDisabledMask(attrPagoHijo.ImpPagado), style: "textoTabla", alignment: "right" },
              { text: dineroDisabledMask(attrPagoHijo.ImpSaldoInsoluto), style: "textoTabla", alignment: "right" },
            ])
          }
        }

        pagos.push(              {
          text: "Forma De Pago",
          style: "tableHeader",
        },{
          style: "tableExample",
          table: {
            widths: ["20%", "18%", "14%", "18%", "30%"],
            layout: { defaultBorder: true },
            body: [
              [
                { text: "Fecha de Pago", style: "tituloTabla" },
                { text: "Forma de pago", style: "tituloTabla" },
                { text: "Moneda", style: "tituloTabla" },
                { text: "Num. Operacion", style: "tituloTabla" },
                { text: "Total", style: "tituloTabla" },
              ],
              [
                { text: attrPago.FechaPago, style: "textoTabla", alignment: "center" },
                { text: attrPago.FormaDePagoP, style: "textoTabla", alignment: "center" },
                { text: attrPago.MonedaP, style: "textoTabla", alignment: "center" },
                { text: attrPago.NumOperacion, style: "textoTabla", alignment: "center" },
                { text: dineroDisabledMask(attrPago.Monto), style: "textoTabla", alignment: "right" },
              ],
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        },
        {
          text: "Pagos",
          style: "tableHeader",
        },
        {
          style: "tableExample",
          table: {
            widths: ["26%", "8%", "8%", "10%", "16%", "16%", "16%"],
            layout: { defaultBorder: true },
            body: [
              [
                { text: "Documento UUID", style: "tituloTabla" },
                { text: "Moneda DR", style: "tituloTabla" },
                { text: "Parcialidad", style: "tituloTabla" },
                { text: "Monto", style: "tituloTabla" },
                { text: "Importe saldo anterior", style: "tituloTabla" },
                { text: "Importe pagado", style: "tituloTabla" },
                { text: "Importe saldo insoluto", style: "tituloTabla" },
              ],
              ...pagosHijos
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        })

      }
    }
    dd = {
      content: [
        {
          style: "tableExample",
          table: {
            widths: ["70%", "30%"],
            layout: { defaultBorder: false },
            body: [
              [
                {
                  border: [false, false, false, false],
                  table: {
                    headerRows: 1,
                    widths: ["100%"],
  
                    body: [
                      [
                        {
                          text: emisor.Nombre,
                          style: "tableHeader",
                          alignment: "left",
                        },
                      ],
                      [
                        {
                          text: "R.F.C: " + emisor.Rfc,
                          style: "textoChico",
                          alignment: "left",
                        },
                      ],
                      [
                        {
                          text: "Codigo Postal: " + comprobante.LugarExpedicion,
                          style: "textoChico",
                          alignment: "left",
                        },
                      ],
                      // empresa.telefono ? [
                      //     {
                      //         text: 'Tel: '+phoneMask(empresa.telefono),
                      //         style: "textoChico",
                      //         alignment: "left",
                      //       },
                      // ] : [],
                      [
                        {
                          text: "Regimen Fiscal: (" + emisor.RegimenFiscal + ") " + values.regimenFiscalEmisorString,
                          style: "textoChico",
                          alignment: "left",
                        },
                      ],
                      [
                        {
                          text: "Tipo De Comprobante: "+tipoComprobante,
                          style: "textoChico",
                          alignment: "left",
                        },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
                {
                  border: [false, false, false, false],
                  table: {
                    headerRows: 1,
                    widths: ["100%"],
  
                    body: [
                      [
                        {
                          text: "N° Complemento:",
                          style: "subheader",
                          alignment: "center",
                        },
                      ],
                      [
                        {
                          text: comprobante.Serie + " - " + comprobante.Folio,
                          style: "textoChico",
                          alignment: "center",
                        },
                      ],
                      [
                        {
                          text: "Moneda:",
                          style: "subheader",
                          alignment: "center",
                        },
                      ],
                      [
                        {
                          text: comprobante.Moneda,
                          style: "textoChico",
                          alignment: "center",
                        },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          table: {
            widths: ["60%", "40%"],
            layout: { defaultBorder: false },
            body: [
              [
                {
                  table: {
                    widths: ["30%", "70%"],
                    layout: { defaultBorder: false },
                    body: [
                      [
                          { text: "Nombre del cliente :", style: "subheader" },
                          { text: receptor.Nombre, style: "textoChico" },
                        ],
                        [
                          { text: "R.F.C :", style: "subheader" },
                          { text: receptor.Rfc, style: "textoChico" },
                        ],
                        [
                          { text: "Regimen Fiscal :", style: "subheader" },
                          { text: "(" + receptor.RegimenFiscalReceptor + ") " + values.regimenFiscalReceptorString, style: "textoChico" },
                        ],
                        // [
                        //   { text: "Direccion :", style: "subheader" },
                        //   { text: "Variable", style: "textoChico" },
                        // ],
                        [
                          { text: "Uso de CFDI:", style: "subheader" },
                          {
                            text:"(" + receptor.UsoCFDI + ") " + values.usoDeCFDIString,
                            style: "textoChico",
                          },
                        ],
                    ],
                  },
                  layout: "noBorders",
                },
                {
                  table: {
                    widths: ["40%", "60%"],
                    layout: { defaultBorder: false },
                    body: [
                      [
                        { text: "Fecha:", style: "subheader" },
                        { text: moment(comprobante.Fecha)
                          .locale("es")
                          .format("LL"), style: "textoChico" },
                      ],
                      [
                        { text: "Lugar de expedicion:", style: "subheader" },
                        { text: comprobante.LugarExpedicion, style: "textoChico" },
                      ],
                    ],
                  },
                  layout: "noBorders",
                },
              ],
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        },
        {
          text: "Concepto",
          style: "tableHeader",
        },
        {
          style: "tableExample",
          table: {
            widths: ["7%", "8%", "10%", "50%", "10%", "15%"],
            layout: { defaultBorder: true },
            body: [
              [
                { text: "Cantidad", style: "tituloTabla" },
                { text: "Unidad", style: "tituloTabla" },
                { text: "Calve SAT", style: "tituloTabla" },
                { text: "Descripción", style: "tituloTabla" },
                { text: "P/U", style: "tituloTabla" },
                { text: "Importe", style: "tituloTabla" },
              ],
              ...conceptos
            ],
          },
          layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
              },
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
              hLineColor: function (i, node) {
                return "#adaaaa";
              },
              vLineColor: function (i, node) {
                return "#adaaaa";
              },
              hLineStyle: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                  return null;
                }
                return { dash: { length: 2, space: 1 } };
              },
              vLineStyle: function (i, node) {
                if (i === 0 || i === node.table.widths.length) {
                  return null;
                }
                return { dash: { length: 2 } };
              },
            },
        },
        ...pagos,
        {
          table: {
            widths: ["25%", "25%", "25%", "25%"],
            layout: { defaultBorder: false },
            body: [
              [
                  {
                    border: [true, true, false, false],
                    text: "Cuenta Ordenante:",
                    style: "subheader",
                  },
                  {
                    border: [false, true, false, false],
                    text: CtaOrdenante || "",
                    style: "textoChico",
                    fontSize: 7,
                  },
                  {
                      border: [false, true, false, false],
                      text: "Rfc Ordenante:",
                      style: "subheader",
                    },
                    {
                      border: [false, true, true, false],
                      text: RfcEmisorCtaOrd || "",
                      style: "textoChico",
                      fontSize: 7,
                    },
                ],
                [
                    {
                      border: [true, false, false, false],
                      text: "Banco Ordenante:",
                      style: "subheader",
                    },
                    {
                      border: [false, false, true, false],
                      text: NomBancoOrdExt || "",
                      style: "textoChico",
                      colSpan: 3
                    },
                    {},
                    {}
                  ],
                [
                  {
                    border: [true, false, false, true],
                    text: "Cuenta Beneficiario:",
                    style: "subheader",
                  },
                  {
                    border: [false, false, false, true],
                    text: CtaBeneficiario || "",
                    style: "textoChico",
                  },
                  {
                      border: [false, false, false, true],
                      text: "Rfc Beneficiario:",
                      style: "subheader",
                    },
                    {
                      border: [false, false, true, true],
                      text: RfcEmisorCtaBen || "",
                      style: "textoChico",
                    },
                ],
              [
                  {
                    border: [true, false, false, false],
                    text: "Moneda:",
                    style: "subheader",
                    colSpan: 2
                  },
                  {},
                  {
                    border: [false, false, true, false],
                    text: comprobante.Moneda,
                    style: "textoChico",
                    colSpan: 2
                  },
                  {}
                ],
              [
                {
                  border: [true, false, false, true],
                  text: "Uso de CFDI:",
                  style: "subheader",
                  colSpan: 2
                },
                {},
                {
                  border: [false, false, true, true],
                  text: "(" +
                  receptor.UsoCFDI +
                  ") ",
                  style: "textoChico",
                  colSpan: 2
                },
                {}
              ]
            ],
          },
          layout: {},
        },
{
  table: {
    widths: ["15%", "30%", "23%", "32%"],
    layout: {
      defaultBorder: false,
    },
    body: [
      [
        {
          colSpan: 4,
          text: '"Este documento es una representacion impresa de un CFDI"',
          style: "header",
          alignment: "center",
        },
        {},
        {},
        {},
      ],
      [
        { text: "Folio Fiscal: ", style: "subheader" },
        { text: timbre.UUID, style: "textoChicoData" },
        { text: "Fecha y Hora de Certificación: ", style: "subheader" },
        {
          text: comprobante.Fecha,
          style: "textoChicoData",
        },
      ],
      [
        { colSpan: 4, text: "Sello digital CFDI: ", style: "subheader" },
        {},
        {},
        {},
      ],
      [
        {
          colSpan: 4,
          text: comprobante.Sello,
          style: "textoChicoData",
        },
        {},
        {},
        {},
      ],
      [
        {
          colSpan: 2,
          text: "Numero de serie del Certificado de Sello digital: ",
          style: "subheader",
        },
        {},
        {
          colSpan: 2,
          text: "Numero de serie del Certificado de Sello digital del SAT: ",
          style: "subheader",
        },
        {},
      ],
      [
        {
          colSpan: 2,
          text: comprobante.NoCertificado,
          style: "textoChicoData",
        },
        {},
        {
          colSpan: 2,
          text: timbre.NoCertificadoSAT,
          style: "textoChicoData",
        },
        {},
      ],
      [
        {
          colSpan: 4,
          text: "Sello Digital del SAT: ",
          style: "subheader",
        },
        {},
        {},
        {},
      ],
      [
        {
          colSpan: 4,
          text: timbre.SelloSAT,
          style: "textoChicoData",
        },
        {},
        {},
        {},
      ],
      [
        {
          colSpan: 4,
          text: "Sello Digital del CFDI: ",
          style: "subheader",
        },
        {},
        {},
        {},
      ],
      [
        {
          colSpan: 4,
          text: timbre.SelloCFD,
          style: "textoChicoData",
        },
        {},
        {},
        {},
      ],
    ],
  },
  layout: "noBorders",
},
      ],
      styles: {
        header: { fontSize: 9, bold: true },
        subheader: { fontSize: 8, bold: true },
        tableExample: { margin: [0, 5, 0, 5] },
        tablaSinMargen: { margin: [0, 0, 0, 0] },
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "black",
          alignment: "center",
        },
        textoChico: { fontSize: 8 },
        textoChicoData: { fontSize: 6 },
        tituloTabla: {
          fontSize: 8,
          bold: true,
          fillColor: "#cccccc",
          style: "subheader",
          alignment: "center",
          border: true,
        },
        textoTabla: { fontSize: 8, italics: false },
        textoTablaChico: {
          fontSize: 6,
          italics: false,
        },
      },
      pageSize: "LETTER",
      defaultStyle: {
        columnGap: 20,
      },
    };
  }
  return dd;
};

export default complementoXmlPdf;