import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalItem,
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import traspasoEntreAlmacenesPdf from "../../../../Pdf/traspasoEntreAlmacenesPdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class VistaPrevia extends Component {
  state = {
    loading: false,
    pdf: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "traspasoEntreAlmacenes/datosPdf",
        { traspasoId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        const pdf = pdfMake.createPdf(traspasoEntreAlmacenesPdf(res.data))
        this.setState(
          {
            loading: false,
            pdf: pdf,
          },
          () => {
            pdf.open({}, window.frames["traspasoPdf"]);
          }
        );
      });
    this.setState({ loading: false });
  }
  cerrarVistaPrevia() {
    this.props.cerrarVistaPrevia();
  }
  render() {
    return (
      <ModalItem
        title="Vista previa"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarVistaPrevia.bind(this)}
        paddingTop
      >
        <iframe
          title={"Traspaso Entre Almacenes"}
          style={{ width: "100%", height: "100vh" }}
          id="traspasoPdf"
          name="traspasoPdf"
        />
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(VistaPrevia);
