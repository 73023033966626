import {
    Button,
    message,
    PageHeader,
    Spin,
    Row,
    Col,
    Divider,
    Select,
    Switch,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import { connect } from "react-redux";
  import {
    Form,
    FormItem,
    SelectItem,
    InputNumberItem,
    cuentaMask,
    InputItem,
  } from "../../../../Components/Items";
  import AgregarCuentaContable from "../../CuentasContables/AgregarCuentaContable/AgregarCuentaContable";
  
  const { Option } = Select;
  
  class AgregarRetencion extends Component {
    formRef = React.createRef();
    state = {
      loading: false,
      modalAgregarCuentaContable: false,
      todasCuentasContablesFinales: null,
      objetoCuentasContablesFinales: null,
      todasRegimenFiscal: null,
      objetoRegimenFiscal: null,
      objetoCalcularSobre: (
        <>
        <Option value={1} key={1}>
          Subtotal
        </Option>
        <Option value={2} key={2}>
          Acumulado
        </Option>
        </>
      )
    };
    componentDidMount() {
      this.consultarTodo();
    }
    async consultarTodo() {
      this.setState({ loading: true });
      await axios
        .post(
          "cuentascontables/selectCuentasContablesFinales",
          { empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasCuentasContablesFinales: res.data });
            this.setState({
              objetoCuentasContablesFinales: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {cuentaMask(
                      value.nivel1 +
                        value.nivel2 +
                        value.nivel3 +
                        value.nivel4 +
                        value.nivel5
                    ) +
                      " - " +
                      value.descripcion}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
        .post(
          "regimenfiscal/selectRegimenesFiscales",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasRegimenFiscal: res.data });
            this.setState({
              objetoRegimenFiscal: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.c_RegimenFiscal + " - " + value.descripcion}
                  </Option>
                );
              }),
            });
          }
        });
      this.setState({ loading: false });
    }
    atras() {
      this.props.history.goBack();
    }
    onFinishFailed(e) {
      message.error("Porfavor ingrese bien los datos");
    }
    onFinish(values) {
      this.setState({ loading: true });
      axios
        .post(
          "retenciones/agregarRetencion",
          { ...values, empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.id) {
            message.success("Se agrego una retencion");
            this.formRef.current.resetFields();
            this.atras(res.data.id);
          } else {
            message.error("Sucedio un error y no se guardo");
          }
        });
    }
    abrirAgregarCuentaContable() {
      this.setState({ modalAgregarCuentaContable: true });
    }
    cerrarAgregarCuentaContable(codigo) {
      this.setState({ modalAgregarCuentaContable: false });
      this.consultarTodo()
    }
    onChangeLocal(value){
      if(value){
        this.formRef.current.setFieldsValue({
          impuesto: undefined,
          combustible: false
        })
      }
    }
    render() {
      return (
        <>
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Agregar Retencion"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
          />
          <div style={{ backgroundColor: "white", padding: 20 }}>
            <Spin spinning={this.state.loading}>
              <Form
                ref={this.formRef}
                name="agregarRetencion"
                layout
                bottom={
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ display: "block", margin: "0 0 0 auto" }}
                  >
                    Agregar
                  </Button>
                }
                onFinish={this.onFinish.bind(this)}
                onFinishFailed={this.onFinishFailed}

              >
                <Row>
                  <Col span={12}>
                    <FormItem
                      name="nombre"
                      label="Nombre"
                      required
                      margin
                      pattern
                    >
                      <InputItem placeholder="Nombre" />
                    </FormItem>
                    <FormItem
                    label="Local"
                    tooltip="Activar en impuestos locales. Ej: ISR"
                    name="local"
                    margin
                    initialValue={false}
                    valuePropName="checked"
                  >
                    <Switch onChange={this.onChangeLocal.bind(this)} />
                  </FormItem>
                  <FormItem shouldUpdate noStyle>
                    {({getFieldValue}) => {
                      const local = getFieldValue('local')
                      if(!local){
                        return (
                          <FormItem
                            name="impuesto"
                            label="Impuesto"
                            required
                            margin
                            pattern
                          >
                            <SelectItem
                              width="100%"
                              placeholder="Impuesto"
                            >
                              <Option key={1} value={1}>001 - ISR</Option>
                              <Option key={2} value={2}>002 - IVA</Option>
                            </SelectItem>
                          </FormItem>
                        )
                      }
                    }}
                  </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem shouldUpdate noStyle>
                      {({getFieldValue}) => {

                        const enProvClien = getFieldValue('enProvClien')

                        return (
                          <FormItem
                            label={enProvClien ? "En Proveedores/Clientes" :"En insumos"}
                            tooltip="Activar para elegir en proveeedores y clientes"
                            name="enProvClien"
                            margin
                            initialValue={false}
                            valuePropName="checked"
                          >
                            <Switch />
                          </FormItem>
                        )
                      }}
                    </FormItem>
                    <FormItem shouldUpdate noStyle>
                        {({getFieldValue}) => {
                            const enProvClien = getFieldValue('enProvClien')
                          if(enProvClien){
                            return (
                              <>
                              <FormItem
                              name="regimenFiscalId"
                              label="R. Fiscal"
                              margin
                              tooltip={{ title: "Opcional para incluir al crear un proveedor" }}
                            >
                              <SelectItem placeholder="Regimen Fiscal">
                                {this.state.objetoRegimenFiscal}
                              </SelectItem>
                            </FormItem>
                            <FormItem shouldUpdate noStyle>
                              {({getFieldValue}) => {

                                const enClientes = getFieldValue('enClientes')

                                return (
                                  <FormItem
                                    label={enClientes ? "En Clientes" :"En Proveedores"}
                                    name="enClientes"
                                    margin
                                    initialValue={false}
                                    valuePropName="checked"
                                  >
                                    <Switch />
                                  </FormItem>
                                )
                              }}
                            </FormItem>
                            </>
                            )
                          }else{
                            return (
                              <>
                              <FormItem
                              required
                              margin
                              label='Aplica Compra'
                              name="aplicaCompra"
                            >
                              <SelectItem width="100%">
                                <Option value={0}>Ninguno</Option>
                                <Option value={1}>Personas fisicas</Option>
                                <Option value={2}>Personas morales</Option>
                                <Option value={3}>Todas</Option>
                              </SelectItem>
                            </FormItem>
                            <FormItem
                              required
                              margin
                              label='Aplica Venta'
                              name="aplicaVenta"
                            >
                              <SelectItem width="100%">
                              <Option value={0}>Ninguno</Option>
                                <Option value={1}>Personas fisicas</Option>
                                <Option value={2}>Personas morales</Option>
                                <Option value={3}>Todas</Option>
                              </SelectItem>
                            </FormItem>
                            </>
                            )
                          }
                        }}
                    </FormItem>
                  </Col>
                </Row>
                <Divider
                  style={{ marginBottom: 0, marginTop: 5 }}
                  children="Cuentas de la Retencion"
                />
                        <table className="table">
                            <thead>
                              <tr>
                                <th className="th-border" style={{width: '25%'}}>Retención Compra</th>
                                <th className="th-border" style={{width: '25%'}}>Retención Venta</th>
                                <th className="th-border" style={{width: '25%'}}>Calcular Sobre</th>
                                <th className="th-border" style={{width: '25%'}}>Monto</th>

                              </tr>
                            </thead>
                              <tbody>
                                <tr>
                                  <td className="td-border">
                                    <FormItem                                      
                                      name={"cuentaContableCompraId"}
                                      required
                                      noStyle
                                    >
                                      <SelectItem
                                        sinBorde
                                        width="100%"
                                        placeholder="Retención Compra"
                                      >
                                        {
                                          this.state
                                            .objetoCuentasContablesFinales
                                        }
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                    <FormItem                                      
                                      name={"cuentaContableVentaId"}
                                      required
                                      noStyle
                                    >
                                      <SelectItem
                                        sinBorde
                                        width="100%"
                                        placeholder="Retención Venta"
                                      >
                                        {
                                          this.state
                                            .objetoCuentasContablesFinales
                                        }
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                    <FormItem                                      
                                      name={"tasaCuota"}
                                      required
                                      noStyle
                                      initialValue={1}
                                    >
                                      <SelectItem
                                        sinBorde
                                        width="100%"
                                        placeholder="Calcular Sobre"
                                        onChange={async () => {
                                          let monto = await this.formRef.current.getFieldValue('monto')
                                          monto = undefined
                                          this.formRef.current.setFieldsValue(monto)
                                        }}
                                      >
                                        {this.state.objetoCalcularSobre}
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                    <FormItem noStyle shouldUpdate>
                                      {({getFieldValue}) => {
                                        const tasaCuota = getFieldValue('tasaCuota')
                                        let properties = {
                                          placeholder:"Monto",
                                          porcentaje: tasaCuota === 3 || !tasaCuota? false : true,
                                          dinero: tasaCuota === 3 || !tasaCuota ? true : false,
                                        }
                                        return (
                                          <FormItem                                            
                                            name={"monto"}
                                            required
                                            number
                                            noStyle
                                            initialValue={undefined}
                                          >
                                            <InputNumberItem sinBorde {...properties}/>
                                          </FormItem>
                                        )
                                      }}
                                    </FormItem>
                                  </td>
                                </tr>
                              </tbody>
                        </table>
              </Form>
            </Spin>
          </div>
          <AgregarCuentaContable
            visible={this.state.modalAgregarCuentaContable}
            cerrarAgregarCuentaContable={this.cerrarAgregarCuentaContable.bind(this)}
          />
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(AgregarRetencion);
  