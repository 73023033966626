import {
    Button,
    PageHeader,
    Spin,
    Select,
    Form as FormInitial,
    Divider,
    Row,
    Col,
    message,
    Popconfirm,
    Space,
  } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import { connect } from "react-redux";
  import {
    Form,
    FormItem,
    SelectItem,
    InputNumberItem,
    InputItem,
    NotificacionPolizas,
    ButtonItem
  } from "../../../../Components/Items";
  
  const { Option } = Select;
  
  class VerOrdenProductosAlCosto extends Component {
    formRef = React.createRef();
    state = {
      loading: false,
      objetoProyectos: null,
      todasProyectos: null,
      todasInsumos: null,
      objetoInsumos: null,
      modalAgregarCuentaContable: false,
      numOrden: 0,
      estadoOrden: 0,
    };
    componentDidMount() {
      this.consultarTodo();
    }
    async consultarTodo() {
      this.setState({ loading: true });
      await axios
        .post(
          "proyectos/selectProyectos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasProyectos: res.data });
            this.setState({
              objetoProyectos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.nombre}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
        .post(
          "insumos/selectInsumosInventariables",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          if (res.data.status === 404) {
          } else {
            this.setState({ todasInsumos: res.data });
            this.setState({
              objetoInsumos: res.data.map((value) => {
                return (
                  <Option value={value._id} key={value._id}>
                    {value.stringBuscar}
                  </Option>
                );
              }),
            });
          }
        });
        await axios
        .post(
          "productosAlCosto/datosOrdenProductosAlCosto",
          {
            empresaId: this.props.empresaId,
            ordenId: this.props.location.state.id,
          },
          { headers: this.props.headersToken }
        )
        .then(async (res) => {
          if (res.data.status === 404) {
          } else {
            await this.formRef.current.setFieldsValue(res.data);
            await this.setState({ numOrden: res.data.numero, estadoOrden: res.data.estado });
            res.data.insumos.map((valueInsumo, keyInsumo) => {
                return this.onChangeInsumo(null, keyInsumo)
              })
          }
        });
      this.setState({ loading: false });
    }
    atras() {
      this.props.history.goBack();
    }
    async onChangeInsumo(value, key) {
        let arrayValue = await this.formRef.current.getFieldValue("insumos");
        let proyecto = await this.formRef.current.getFieldValue("proyectoId");
        const insumoId = arrayValue[key].insumoId;
        const insumo = this.state.todasInsumos.find(
          (valueInsumos) => valueInsumos._id === insumoId
        );
        if(!insumo) return
          await axios
            .post(
              "inventarios/inventarioInsumoProyecto",
              {
                proyectoId: proyecto,
                insumoId: insumoId,
                empresaId: this.props.empresaId,
              },
              { headers: this.props.headersToken }
            )
            .then((res) => {
              this.setState({ loading: false });
              arrayValue[key].cantidadProyecto = res.data.cantidad;
            });
        this.formRef.current.setFieldsValue({
          insumos: arrayValue,
        });
      }
      confirmarProductosAlCosto() {
        axios
          .post(
            "productosAlCosto/confirmarOrdenProductosAlCosto",
            {
              ordenId: this.props.location.state.id,
              empresaId: this.props.empresaId,
            },
            { headers: this.props.headersToken }
          )
          .then((res) => {
            this.setState({ loading: false });
            if (res.data.success === 2) {
              message.success(
                "Se mandaron los productos al costo"
              );
              NotificacionPolizas(res.data.polizasId)
            } else if (res.data.success === 1) {
              message.error("No hay suficiente inventario");
            } else {
              message.error("Hubo un error y el inventario no se redujo");
            }
            this.consultarTodo();
          });
      }
    
      cancelarOrdenProductosAlCosto() {
        axios
          .post(
            "productosAlCosto/cancelarOrdenProductosAlCosto",
            {
              ordenId: this.props.location.state.id,
              empresaId: this.props.empresaId,
            },
            { headers: this.props.headersToken }
          )
          .then((res) => {
            if (res.data.success === 2) {
                message.success("Orden cancelada");
              } else {
                message.error("Sucedio un error y no se pudo cancelar");
              }
              this.consultarTodo();
          });
      }
    render() {
      return (
        <>
          <PageHeader
            onBack={this.atras.bind(this)}
            title="Agregar Orden - Productos al costo"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
          />
          <div style={{ backgroundColor: "white", padding: 20 }}>
            <Spin spinning={this.state.loading}>
              <Form
                ref={this.formRef}
                name="productosAlCosto"
                layout
                bottom={
                    <div style={{ display: "flex", justifyContent: "right" }}>
                    <Space>
                      {this.state.estadoOrden === 1 && (
                        <Popconfirm
                          title={
                            "Seguro que quieres confirmar esta orden de productos al costo"
                          }
                          onConfirm={() => this.confirmarProductosAlCosto()}
                          okText="Si"
                          cancelText="No"
                        >
                          <Button type="primary">Confirmar</Button>
                        </Popconfirm>
                      )}
                      {this.state.estadoOrden === 1 && (
                        <Popconfirm
                          title={
                            "Seguro que quieres cancelar esta orden de productos al costo"
                          }
                          onConfirm={() => this.cancelarOrdenProductosAlCosto()}
                          okText="Si"
                          cancelText="No"
                        >
                          <ButtonItem type="primary" danger>
                            Cancelar
                          </ButtonItem>
                        </Popconfirm>
                      )}
                      <ButtonItem onClick={this.atras.bind(this)}>
                        Regresar
                      </ButtonItem>
                    </Space>
                  </div>
                }
              >
                <Row>
                <Col span={12}>
                    <FormItem label="Proyecto" name="proyectoId" margin required>
                        <SelectItem placeholder="Proyecto" disabled>
                        {this.state.objetoProyectos}
                        </SelectItem>
                    </FormItem>
                    </Col>
                    <Col span={12}>
                    <FormItem
                        name="referencia"
                        label="Referencia"
                        margin
                        pattern
                    >
                        <InputItem disabled placeholder="Referencia" />
                    </FormItem>
                </Col>
                </Row>
                <Divider
                  style={{ marginBottom: 10, marginTop: 5 }}
                  children="Insumos"
                />
                   <FormInitial.List name="insumos">
                    {(fields, { add, remove }) => (
                      <>
                        <table className="table">
                          {fields.length > 0 ? (
                            <thead>
                              <tr>
                                <th className="th-border" style={{width: '60%'}}>Insumo</th>
                                <th className="th-border" style={{width: '20%'}}>Cantidad Proyecto</th>
                                <th className="th-border" style={{width: '20%'}}>Cantidad</th>
                                </tr>
                            </thead>
                          ) : null}
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }, arrayKey) => (
                              <tbody>
                                <tr>
                                  <td className="td-border">
                                    <FormItem
                                      {...restField}
                                      name={[name, "insumoId"]}
                                      fieldKey={[arrayKey, "insumoId"]}
                                      required
                                      noStyle
                                    >
                                      <SelectItem
                                        sinBorde
                                        autoFocus
                                        disabled
                                        width="100%"
                                        placeholder="Insumo"
                                        onChange={(value) =>
                                            this.onChangeInsumo(
                                              value,
                                              arrayKey
                                            )
                                          }
                                      >
                                        {
                                          this.state
                                            .objetoInsumos
                                        }
                                      </SelectItem>
                                    </FormItem>
                                  </td>
                                  <td className="td-border">
                                        <FormItem
                                        {...restField}
                                        name={[name, "cantidadProyecto"]}
                                        fieldKey={[arrayKey, "cantidadProyecto"]}
                                        number
                                        noStyle
                                        disabled
                                        required
                                        initialValue={undefined}
                                        >
                                        <InputNumberItem sinBorde disabled/>
                                        </FormItem>
                                  </td>
                                  <td className="td-border">
                                        <FormItem
                                        {...restField}
                                        name={[name, "cantidad"]}
                                        fieldKey={[arrayKey, "cantidad"]}
                                        number
                                        noStyle
                                        required
                                        disabled
                                        type='number'
                                        initialValue={undefined}
                                        >
                                        <InputNumberItem sinBorde disabled />
                                        </FormItem>
                                  </td>
                                  <td>
                                  </td>
                                </tr>
                              </tbody>
                            )
                          )}
                        </table>
                      </>
                    )}
                  </FormInitial.List>
              </Form>
            </Spin>
          </div>
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(VerOrdenProductosAlCosto);
  