import {
    CheckCircleOutlined,
    EditOutlined,
    StopOutlined,
  } from "@ant-design/icons";
  import { Button, PageHeader, Space, Popconfirm, message } from "antd";
  import axios from "axios";
  import React, { Component } from "react";
  import { connect } from "react-redux";
  import { TableItem } from "../../../Components/Items";
  
  class InsumosCompuestos extends Component {
    state = {
      idEditarInsumoCompuesto: null,
      todasInsumosCompuestos: [],
      loading: false
    };
    componentDidMount() {
      this.consultarInsumosCompuestos();
    }
    consultarInsumosCompuestos() {
      this.setState({loading: true})
      axios
        .post(
          "insumoscompuestos/selectTodosInsumosCompuestos",
          { empresaId: this.props.empresaId },
          {
            headers: this.props.headersToken,
          }
        )
        .then((res) => {
          this.setState({loading: false})
          if (res.data.status === 404) {
          } else {
            this.setState({
              todasInsumosCompuestos: res.data
            });
          }
        });
    }
    deshabilitarInsumoCompuesto(value) {
      axios
        .post(
          "insumos/deshabilitarInsumo",
          { insumoId: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            message.success("Insumo Compuesto deshabilitado");
          } else {
            message.error("Sucedio un eror y no se pudo deshabilitar");
          }
          this.consultarInsumosCompuestos();
        });
    }
    habilitarInsumoCompuesto(value) {
      axios
        .post(
          "insumos/habilitarInsumo",
          { insumoId: value, empresaId: this.props.empresaId },
          { headers: this.props.headersToken }
        )
        .then((res) => {
          if (res.data.success === 2) {
            message.success("Insumo Compuesto habilitado");
          } else {
            message.error("Sucedio un eror y no se pudo habilitar");
          }
          this.consultarInsumosCompuestos();
        });
    }
    agregarInsumoCompuesto() {
      this.props.history.push("/panel/agregarInsumoCompuesto");
    }
    editarInsumoCompuesto(id) {
      this.props.history.push("/panel/editarInsumoCompuesto", { id });
    }
    abrirAgregarFamilia() {
      this.setState({ modalAgregarFamilia: true });
    }
    cerrarAgregarFamilia() {
      this.setState({ modalAgregarFamilia: false });
      this.consultarInsumosCompuestos();
    }
    render() {
      const columns = [
        {
          title: "Codigo",
          dataIndex: "codigo",
          key: "codigo",
          defaultSortOrder: 'ascend',
          sorter: (a, b) => {
            if (a.codigo < b.codigo) {
              return -1;
            }
            if (a.codigo > b.codigo) {
              return 1;
            }
            return 0;
          },
          buscar: "codigo",
          width: "10%",
        },
        {
          title: "Clave",
          dataIndex: "clave",
          key: "clave",
          sorter: (a, b) => {
            if (a.clave < b.clave) {
              return -1;
            }
            if (a.clave > b.clave) {
              return 1;
            }
            return 0;
          },
          buscar: "clave",
          width: "10%",
        },
        {
          title: "Descripcion",
          dataIndex: "descripcion",
          key: "descripcion",
          sorter: (a, b) => {
            if (a.descripcion < b.descripcion) {
              return -1;
            }
            if (a.descripcion > b.descripcion) {
              return 1;
            }
            return 0;
          },
          buscar: "descripcion",
          width: "36%",
        },
        {
          title: "Costo",
          dataIndex: "costo",
          key: "costo",
          sorter: (a, b) => {
            if (a.costo < b.costo) {
              return -1;
            }
            if (a.costo > b.costo) {
              return 1;
            }
            return 0;
          },
          render: (value) => "$ " + value.toFixed(2),
          width: "6%",
        },
        {
          title: "Unidad",
          dataIndex: "unidad",
          key: "unidad",
          sorter: (a, b) => {
            if (a.unidad < b.unidad) {
              return -1;
            }
            if (a.unidad > b.unidad) {
              return 1;
            }
            return 0;
          },
          width: "6%",
        },
        {
          title: "Familia",
          dataIndex: "familia",
          key: "familia",
          sorter: (a, b) => {
            if (a.familia < b.familia) {
              return -1;
            }
            if (a.familia > b.familia) {
              return 1;
            }
            return 0;
          },
          width: "21%",
        },
        {
          title: "Accion",
          dataIndex: "accion",
          key: "accion",
          render: (value) => (
            <Space size="middle">
              {this.props.permisoEditarInsumoCompuesto &&
                <EditOutlined
                  onClick={() => this.editarInsumoCompuesto(value._id)}
                  style={{ color: "blue" }}
                />
              }
              {this.props.permisoDeshabilitarInsumoCompuesto &&
              <>
                {value.estado === 1 ? (
                  <Popconfirm
                    title="Seguro que quieres deshabilitar este insumos compuesto"
                    onConfirm={() => this.deshabilitarInsumoCompuesto(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Seguro que quieres habilitar este insumos compuesto"
                    onConfirm={() => this.habilitarInsumoCompuesto(value._id)}
                    okText="Si"
                    cancelText="No"
                  >
                    <StopOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </>
              }
            </Space>
          ),
          width: "12%",
        },
      ];
      return (
        <>
          <PageHeader
            title="Insumos Compuestos"
            style={{
              border: "1px solid rgb(235, 237, 240)",
              backgroundColor: "white",
              marginBottom: 10,
            }}
            extra={this.props.permisoAgregarInsumoCompuesto &&
              <Button
                type="primary"
                key="agregarInsumoCompuesto"
                onClick={this.agregarInsumoCompuesto.bind(this)}
              >
                Agregar Insumo Compuesto
              </Button>
            }
          />
          <TableItem
          loading={this.state.loading}
            size="small"
            dataSource={this.state.todasInsumosCompuestos}
            columns={columns}
            pagination={false}
          />
        </>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      permisoAgregarInsumoCompuesto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472d8d84b2da7c85c1a8d0b")?.activado,
      permisoEditarInsumoCompuesto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472d66d4b2da7c85c1a8d06")?.activado,
      permisoDeshabilitarInsumoCompuesto: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "6472d69a4b2da7c85c1a8d07")?.activado,
      headersToken: state.user.headersToken,
      empresaId: state.empresa.id,
    };
  };
  
  export default connect(mapStateToProps)(InsumosCompuestos);
  