import { PageHeader, Space, Button, Tag, Popconfirm, message } from "antd";
import React, { Component } from "react";
import axios from 'axios'
import {ButtonItem, TableItem} from '../../../Components/Items'
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");


class Polizas extends Component {
  state = {
    todasPolizas: [],
    loading: false
  }
  componentDidMount() {
    this.consultarPolizas();
  }

  consultarPolizas() {
    this.setState({loading: true})
    axios
    .post(
      "polizas/selectTodasPolizas",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasPolizas: res.data.map(value => {
              return {...value, accion: {_id: value._id, estado: value.estado, manual: value.manual}}
          }),
        });
      }
    });
  }
  verPoliza(id){
    this.props.history.push("/panel/verPoliza", { id });
  }
  agregarPoliza(){
    this.props.history.push("/panel/polizaManual");
  }
  cancelarPoliza(value) {
    axios
      .post(
        "polizas/cancelarPoliza",
        { polizaId: value, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Poliza cancelada");
        } else {
          message.error("Sucedio un eror y no se pudo cancelar");
        }
        this.consultarPolizas();
      });
  }
  editarPolizaManual(id){
    this.props.history.push('/panel/editarPolizaManual', {id})
  }
  render() {
    const columns = [
      {
        title: "IED",
        dataIndex: "tipodepoliza",
        key: "tipodepoliza",
        render: (value) => {
            let ied
            if(value.tipoDePolizaId === 1)ied = 'Ingreso'
            if(value.tipoDePolizaId === 2)ied = 'Egreso'
            if(value.tipoDePolizaId === 3)ied = 'Traslado'
            if(value.tipoDePolizaId === 4)ied = 'Nomina'
            if(value.tipoDePolizaId === 5)ied = 'Pago'
            if(value.tipoDePolizaId === 6)ied = 'Diario'
            return ied
        },
        width: '10%'
      },
      {
        title: "Concepto",
        dataIndex: "concepto",
        key: "concepto",
        width: '25%',
        buscar: "concepto",
      },
      {
        title: "Fecha",
        dataIndex: "fechaRegistro",
        key: "fechaRegistro",
        sorter: (a, b) => {
          if (moment(a.fechaRegistro) < moment(b.fechaRegistro)) {
            return -1;
          }
          if (moment(a.fechaRegistro) > moment(b.fechaRegistro)) {
            return 1;
          }
          return 0;
        },
        render: (value) => moment(value).locale('es').format('L'),
        width: '15%',
      },
      {
        title: "Numero Poliza",
        dataIndex: "numeroTipoPoliza",
        key: "numeroTipoPoliza",
        width: '10%',
        buscar: "numeroTipoPoliza",
      },
      {
        title: "Numero Poliza",
        dataIndex: "numeroTipoTipoPoliza",
        key: "numeroTipoTipoPoliza",
        width: '10%',
        buscar: "numeroTipoTipoPoliza",
      },
      {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        render: (value) => {
          if(value === 0){
            return <Tag color='red'>Cancelada</Tag>
          }
          if(value === 1){
            return <Tag color='blue'>Vigente</Tag>
          }
        },
        width: '10%'
      },
      {
        title: "Accion",
        dataIndex: "accion",
        key: "accion",
        render: (value) => (
          <Space size="middle">
            <ButtonItem
              blanco={true}
              onClick={() => this.verPoliza(value._id)}
              type='primary'
              children='Ver'
              size='small'
            />
             {(value.manual && value.estado !== 0) && this.props.permisoCancelarPolizaManualmente ?
             <>
              <Popconfirm
                title="Seguro que quieres cancelar esta poliza"
                onConfirm={() => this.cancelarPoliza(value._id)}
                okText="Si"
                cancelText="No"
              >
                <ButtonItem type='primary' size='small' danger>Cancelar</ButtonItem>
              </Popconfirm>
              <ButtonItem type='primary' size='small' onClick={() => this.editarPolizaManual(value._id)}>Editar</ButtonItem>
              </>
            : null}
          </Space>
        ),
        width: "30%",
      },
    ]
    return (
      <>
        <PageHeader
          title="Polizas"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarPolizaManualmente &&
            <Button
              type="primary"
              key="agregarPoliza"
              onClick={this.agregarPoliza.bind(this)}
            >
              Agregar Poliza Manualmente
            </Button>
          }
        />
        <TableItem rowClassName={(record, index) => {
          if(index % 2 === 0){
            return 'zebra-gris'
          }
        }} size='small' loading={this.state.loading} dataSource={this.state.todasPolizas} columns={columns} pagination={false} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarPolizaManualmente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cea8ff30e81b54183cfbf")?.activado,
    permisoCancelarPolizaManualmente: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cea8ff30e81b54183cfbf")?.activado,
  };
};

export default connect(mapStateToProps)(Polizas);