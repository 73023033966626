import { PageHeader } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";


class DescargaMasiva extends Component {
  state = {
    loading: false
  }
  componentDidMount() {
  }

  render() {
    return (
      <>
        <PageHeader
          title="Descarga Masiva"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(DescargaMasiva);