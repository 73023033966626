import React, { Component } from "react";
import { Button, message, PageHeader, Spin, Form as FormInitial, Row, Col, Select, Divider, Checkbox } from "antd";
import {
  dineroDisabledMask,
  Form,
  FormItem,
  InputNumberItem,
  SelectItem,
  DatePickerItem,
  InputItem,
  ButtonItem,
  NotificacionPolizas
} from "../../../../Components/Items";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";

const {Option} = Select
moment.locale("es");

class ReponerCajaChica extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    facturasCajaChica: undefined,
    todasBancos: null,
    objetoBancos: null,
    todasProyectos: null,
    objetoProyectos: null,
    id: this.props.location.state.id,
  };
  componentDidMount() {
    this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
    .post(
      "bancos/selectBancos",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      if (res.data.status === 404) {
      } else {
        this.setState({ todasBancos: res.data });
        this.setState({
          objetoBancos: res.data.map((value) => {
            return (
              <Option value={value._id} key={value._id}>
                {value.nombre+' - '+value.banco}
              </Option>
            );
          }),
        });
      }
    });
    await axios
      .post(
        "cajaefectivo/selectFacturasCaja",
        {
          empresaId: this.props.empresaId,
          cajaId: this.state.id,
        },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontraron facturas para reponer");
        } else {
          let nuevasFacturas = res.data.map((value) => {
            return {
              ...value,
              proyectoNombre: value.proyecto.nombre,
              saldoPendiente: (value.totalPagar*value.tipoDeCambio)-(value.totalPagadoCajaChica*value.tipoDeCambio)
            };
          });
          this.setState({ facturasCajaChica: nuevasFacturas });
          this.formRef.current.setFieldsValue({ facturas: nuevasFacturas });
        }
      });
      await axios
      .post(
        "proyectos/selectProyectos",
        { empresaId: this.props.empresaId },
        {
          headers: this.props.headersToken,
        }
      )
      .then((res) => {
        if (res.data.status === 404) {
        } else {
          this.setState({ todasProyectos: res.data });
          this.setState({
            objetoProyectos: res.data.map((value) => {
              return (
                <Option value={value._id} key={value._id}>
                  {value.nombre}
                </Option>
              );
            }),
          });
        }
      });
    this.setState({ loading: false });
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (value.name[1] || value.name[1] === 0) {
        message.error(
          "Error: " +
            value.errors[0] +
            " en la fila " +
            (value.name[1] + 1)
        );
      } else {
        message.error("Error");
      }
      return null;
    });
  }
  onFinish(values) {
    let cantidadPagarTotal = 0;
    values.facturas.map(value => {
      let cantidadPagar = value.cantidadPagar ? value.cantidadPagar : 0
      cantidadPagarTotal += cantidadPagar
      return null
    })
    if(cantidadPagarTotal === 0) return message.info('Se neceitan cantidades para poder hacer esta operación')
    this.setState({ loading: true });
    axios
      .post(
        "cajaefectivo/reponerCaja",
        {
          ...values,
          empresaId: this.props.empresaId,
          cajaId: this.state.id,
          cantidadPagarTotal
        },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success === 2) {
          message.success(
            "Se ha recibido un pago"
          );
          NotificacionPolizas(res.data.polizasId)
          this.formRef.current.resetFields();
          this.atras(res.data.codigo);
        } else if (res.data.success === 1) {
          message.error("No se puede recibir el pago");
        } else {
          message.error("Hubo un error y no se recibio el pago");
        }
      });
  }
  atras() {
    this.props.history.goBack();
  }
  render() {
    return (
      <>
        <PageHeader
          onBack={this.atras.bind(this)}
          title={"Reponer Caja : " + this.props.location.state.nombre}
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
        />
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              name="reponerCaja"
              layout
              bottom={
                <ButtonItem
                  type="primary"
                  htmlType="submit"
                  style={{ display: "block", margin: "0 0 0 auto" }}
                >
                  Pagar
                </ButtonItem>
              }
              onFinish={this.onFinish.bind(this)}
              onFinishFailed={this.onFinishFailed}
            >
              <Row>
                <Col span={10}>
                  <FormItem label="Banco" name="bancoId" margin required>
                    <SelectItem placeholder="Banco">
                      {this.state.objetoBancos}
                    </SelectItem>
                  </FormItem>
                </Col>
                <Col span={6}>
                  <Row>
                    <Col span={12}>
                      <FormItem
                        label="Cheque"
                        name="cheque"
                        margin
                        labelCol={{ span: 20 }}
                        wrapperCol={{ span: 4 }} 
                        valuePropName="checked"
                        initialValue={this.props.location.state.cheque}
                      >
                        <Checkbox></Checkbox>
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem shouldUpdate noStyle>
                        {({getFieldValue}) => {
                          const cheque = getFieldValue('cheque')
                          const bancoId = getFieldValue('bancoId')
                          if(cheque && bancoId){
                            let consecutivoCheque = this.state.todasBancos.find(tBancos => tBancos._id.toString() === bancoId.toString()).consecutivoCheque
                            if(!consecutivoCheque){
                              consecutivoCheque = 1
                            }
                            return (
                              <h1 style={{textAlign: "center", justifyContent: "center"}}>N° Cheque: {consecutivoCheque}</h1>
                            )
                          }
                        }}
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                <FormItem
                    label="Fecha de Pago"
                    name="fechaPago"
                    required
                    margin
                    initialValue={moment()}
                  >
                    <DatePickerItem
                      placeholder="Fecha de Pago"
                      allowClear={false}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={10}>
                <FormItem
                    name="referencia"
                    label="Referencia"
                    margin
                    pattern
                  >
                    <InputItem placeholder="Referencia" />
                  </FormItem>
                </Col>
                <Col span={10}>
                <FormItem label="Proyecto" name="proyectoId" margin required>
                    <SelectItem placeholder="Proyecto">
                      {this.state.objetoProyectos}
                    </SelectItem>
                  </FormItem>
                </Col>
              </Row>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <FormInitial.List name="facturas">
                {(fields) => (
                  <>
                    <table className="table">
                      {fields.length > 0 ? (
                        <thead>
                          <tr>
                            <th className="th-border" style={{ width: "10%" }}>
                              Codigo
                            </th>
                            <th className="th-border" style={{ width: "10%" }}>
                              Fecha
                            </th>
                            <th className="th-border" style={{ width: "15%" }}>
                              Proyecto
                            </th>
                            <th className="th-border" style={{ width: "15%" }}>
                              Total a Pagar MXN
                            </th>
                            <th className="th-border" style={{ width: "15%" }}>
                              Total Pagado MXN
                            </th>
                            <th className="th-border" style={{ width: "15%" }}>
                              Saldo Pendiente MXN
                            </th>
                            <th className="th-border" style={{ width: "20%" }}>
                              Cantidad a Pagar MXN
                            </th>
                          </tr>
                        </thead>
                      ) : null}
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <tbody key={fieldKey}>
                          <tr>
                          <td className="td-border-center">
                              <p style={{ marginBottom: 0 }}>
                                {
                                  this.state.facturasCajaChica[fieldKey]
                                    .codigoFactura
                                }
                              </p>
                            </td>
                            <td className="td-border-center">
                              <p style={{ marginBottom: 0 }}>
                                {
                                  moment(this.state.facturasCajaChica[fieldKey]
                                    .fechaFactura).format('L')
                                }
                              </p>
                            </td>
                            <td className="td-border-center">
                              <p style={{ marginBottom: 0 }}>
                                {
                                  this.state.facturasCajaChica[fieldKey]
                                    .proyectoNombre
                                }
                              </p>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {dineroDisabledMask(
                                  this.state.facturasCajaChica[fieldKey]
                                    .totalPagar *
                                  this.state.facturasCajaChica[fieldKey]
                                    .tipoDeCambio
                                )}
                              </p>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {dineroDisabledMask(
                                  this.state.facturasCajaChica[fieldKey]
                                    .totalPagadoCajaChica *
                                  this.state.facturasCajaChica[fieldKey]
                                    .tipoDeCambio
                                )}
                              </p>
                            </td>
                            <td className="td-border-right">
                              <p style={{ marginBottom: 0 }}>
                                {dineroDisabledMask(
                                  this.state.facturasCajaChica[fieldKey]
                                    .saldoPendiente
                                )}
                              </p>
                            </td>
                            <td className="td-border">
                              <Button size='small' type='primary' style={{width:'20%'}} onClick={() => {
                                let facturas = this.formRef.current.getFieldValue('facturas')
                                facturas[fieldKey].cantidadPagar = this.state.facturasCajaChica[fieldKey].saldoPendiente
                                this.formRef.current.setFieldsValue({facturas: facturas})
                              }}>Total</Button>
                              <FormItem
                                {...restField}
                                name={[name, "cantidadPagar"]}
                                fieldKey={[fieldKey, "cantidadPagar"]}
                                noStyle
                                numberCero
                                type='number'
                                max={this.state.facturasCajaChica[fieldKey].saldoPendiente}
                              >
                                <InputNumberItem sinBorde style={{width: '80%'}} placeholder={'Max: '+this.state.facturasCajaChica[fieldKey].saldoPendiente} dinero size='small' />
                              </FormItem>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </>
                )}
              </FormInitial.List>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <Row>
                <Col span={8}></Col>
                <Col span={8}></Col>
                <Col span={8}>
                  <FormItem noStyle shouldUpdate>
                    {({getFieldValue}) => {
                      let facturas = getFieldValue('facturas')
                      if(!facturas) return null
                      let total = 0
                      facturas.map(value => {
                        let cantidadPagar = value.cantidadPagar ? value.cantidadPagar : 0
                        total += cantidadPagar
                        return null
                      })
                      return (
                        <table className="table">
                        <tbody>
                          <tr>
                            <th
                              className="th-totales"
                              style={{ width: "50%" }}
                            >
                              Total:
                            </th>
                            <td
                              className="td-totales-right"
                              style={{ width: "50%" }}
                            >
                              {dineroDisabledMask(total)}
                            </td>
                          </tr>
                        </tbody>
                        </table>
                      )
                    }}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ReponerCajaChica);
