import React, { Component } from "react";
import {
    message,
    PageHeader,
    Col,
    Row,
    Spin,
    Select,
    Tabs,
    Switch
} from "antd";
import axios from "axios";
import { connect } from "react-redux";
import {
    Form,
    FormItem,
    ButtonItem,
    InputItem,
    SelectItem,
    InputNumberItem
} from "../../../../Components/Items";

const { Option } = Select
const { TabPane } = Tabs

class EditarEmpleado extends Component {
    formRef = React.createRef();
    state = {
        loading: false,
        todasFigurasTranporte: null,
        objetoFigurasTranporte: null,
        todasPaises: null,
        objetoPaises: null,
        todasPaisesResidencia: null,
        objetoPaisesResidencia: null,
        todasEstados: null,
        objetoEstados: null,
        todasMunicipios: null,
        objetoMunicipios: [],
        todasColonias: null,
        objetoColonias: [],
        dataInicial: null,
        id: this.props.location.state.id,
    };
    atras() {
        this.props.history.goBack();
    }
    componentDidMount() {
        this.consultarTodo();
    }
    async consultarTodo() {
        this.setState({ loading: true });
        await axios
            .post(
                "empleados/datosParaEmpleado",
                { empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({
                        todasFigurasTranporte: res.data.figurasTranporte,
                        todasPaises: res.data.paises,
                        todasPaisesResidencia: res.data.paisesResidencia,
                    });
                    this.setState({
                        objetoFigurasTranporte: res.data.figurasTranporte.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.Clave + " - " + value.Descripcion}
                                </Option>
                            );
                        }),
                        objetoPaises: res.data.paises.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre + " - " + value.abreviatura}
                                </Option>
                            );
                        }),
                        objetoPaisesResidencia: res.data.paisesResidencia.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre + " - " + value.abreviatura}
                                </Option>
                            );
                        }),
                        objetoEstados: res.data.estados.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre + " - " + value.abreviatura}
                                </Option>
                            );
                        }),
                    });
                }
            });
        await axios
            .post(
                "empleados/datosEmpleado",
                { empresaId: this.props.empresaId, empleadoId: this.state.id },
                { headers: this.props.headersToken }
            )
            .then((res) => {
                if (res.data.status === 404) {
                    message.error("Error no se encontro informacion del empleado");
                } else {
                    if (res.data.estadoId) {
                        this.onChangeEstado(res.data.estadoId);
                    }
                    if (res.data.municipioId) {
                        this.onChangeMunicipio(res.data.municipioId);
                    }
                    this.formRef.current.setFieldsValue({
                        ...res.data,
                    });
                    this.setState({
                        dataInicial: res.data,
                    });
                }
            });
        this.setState({ loading: false });
    }
    onFinishFailed(e) {
        message.error("Porfavor ingrese bien los datos");
    }

    onFinish(values) {
        this.setState({ loading: true });
        axios
            .post(
                "empleados/editarEmpleado",
                { ...values, empleadoId: this.state.id, empresaId: this.props.empresaId },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                this.setState({ loading: false });
                if (res.data.success === 2) {
                    message.success("Se edito un empleado");
                    this.formRef.current.resetFields();
                    this.atras(res.data.id);
                } else {
                    message.error("Sucedio un error y no se guardo");
                }
            });
    }

    onChangeEstado(value, inicial) {
        if (!inicial) {
            this.formRef.current.setFieldsValue({
                municipioId: undefined,
                coloniaId: undefined,
            });
            this.setState({ objetoMunicipios: null, objetoColonias: null });
        }
        axios
            .post(
                "municipios/selectMunicipios",
                { estadoId: value },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({ todasMunicipios: res.data });
                    this.setState({
                        objetoMunicipios: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.nombre}
                                </Option>
                            );
                        }),
                    });
                }
            });
    }
    onChangeMunicipio(value, inicial) {
        if (!inicial) {
            this.formRef.current.setFieldsValue({ coloniaId: undefined });
            this.setState({ objetoColonias: null });
        }
        axios
            .post(
                "colonias/selectColonias",
                { municipioId: value },
                {
                    headers: this.props.headersToken,
                }
            )
            .then((res) => {
                if (res.data.status === 404) {
                } else {
                    this.setState({ todasColonias: res.data });
                    this.setState({
                        objetoColonias: res.data.map((value) => {
                            return (
                                <Option value={value._id} key={value._id}>
                                    {value.cp + " - " + value.nombre}
                                </Option>
                            );
                        }),
                    });
                }
            });
    }
    onSearchColonia(value) {
        if (value.toString().length === 5) {
            axios
                .post(
                    "colonias/buscarCP",
                    { cp: value },
                    {
                        headers: this.props.headersToken,
                    }
                )
                .then((res) => {
                    if (res.data.status === 404) {
                        message.info("No se encontro el codigo postal");
                    } else {
                        this.formRef.current.setFieldsValue({
                            paisId: res.data.paisId,
                            estadoId: res.data.estadoId,
                            municipioId: res.data.municipioId,
                        });
                        this.onChangeEstado(res.data.estadoId, true);
                        this.onChangeMunicipio(res.data.municipioId, true);
                    }
                });
        }
    }
    onSelectColoniaId(coloniaId) {
        const colonia = this.formRef.current.getFieldValue('colonia')
        if (!colonia) {
            const nombreColonia = this.state.todasColonias.find((todasColonia) => todasColonia._id.toString() === coloniaId.toString())?.nombre
            this.formRef.current.setFieldsValue({
                colonia: nombreColonia
            })
        }
    }
    render() {
        return (
            <>
                <PageHeader
                    onBack={this.atras.bind(this)}
                    title="Editar Empleado"
                    style={{
                        border: "1px solid rgb(235, 237, 240)",
                        backgroundColor: "white",
                        marginBottom: 10,
                    }}
                />
                <div style={{ backgroundColor: "white", paddingLeft: 20, paddingRight: 20 }}>
                    <Spin spinning={this.state.loading}>
                        <Form
                            ref={this.formRef}
                            name="editarEmpleado"
                            layout
                            bottom={
                                <ButtonItem
                                    htmlType="submit"
                                    style={{ display: "block", margin: "0 0 0 auto" }}
                                >
                                    Editar Empleado
                                </ButtonItem>
                            }
                            onFinish={this.onFinish.bind(this)}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Tabs defaultActiveKey={1}>
                                <TabPane tab="General" tabKey={1} key={1} forceRender>
                                    <Row>
                                        <Col span={10}>
                                            <FormItem
                                                label="Nombre"
                                                margin
                                                pattern
                                                required
                                                name="nombre"
                                                labelCol={{ span: 7 }}
                                                wrapperCol={{ span: 17 }}
                                            >
                                                <InputItem
                                                    placeholder="Nombre"
                                                    width="98%"
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={7}>
                                            <FormItem
                                                margin
                                                pattern
                                                name="apellidoPaterno"
                                                labelCol={{ span: 0 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <InputItem
                                                    placeholder="Apelido Paterno"
                                                    width="98%"
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={7}>
                                            <FormItem
                                                margin
                                                pattern
                                                name="apellidoMaterno"
                                                labelCol={{ span: 0 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <InputItem placeholder="Apellido Materno" />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem
                                                label="RFC"
                                                name="rfc"
                                                margin
                                                rfc
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                            >
                                                <InputItem placeholder="RFC" />
                                            </FormItem>
                                            <FormItem
                                                label="Extranjero"
                                                name="extranjero"
                                                margin
                                                initialValue={false}
                                                valuePropName="checked"
                                            >
                                                <Switch />
                                            </FormItem>
                                            <FormItem shouldUpdate noStyle>
                                                {({ getFieldValue }) => {
                                                    if (getFieldValue('extranjero')) {
                                                        return (
                                                            <>
                                                                <FormItem
                                                                    label="N° Registro ID Tributaria"
                                                                    name="registroIDTributario"
                                                                    margin
                                                                    pattern
                                                                    required
                                                                >
                                                                    <InputItem placeholder="N° Registro ID Tributaria" />
                                                                </FormItem>
                                                                <FormItem label="Residencia Fiscal" name="paisResidenciaId" margin required>
                                                                    <SelectItem placeholder="Residencia Fiscal">
                                                                        {this.state.objetoPaisesResidencia}
                                                                    </SelectItem>
                                                                </FormItem>
                                                            </>
                                                        )
                                                    }
                                                }}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label="Chofer"
                                                name="chofer"
                                                margin
                                                initialValue={false}
                                                valuePropName="checked"
                                            >
                                                <Switch />
                                            </FormItem>
                                            <FormItem shouldUpdate noStyle>
                                                {({ getFieldValue }) => {
                                                    if (getFieldValue('chofer')) {
                                                        return (
                                                            <>
                                                                <FormItem label="Figura Transporte" name="figuraTransporteId" margin required>
                                                                    <SelectItem placeholder="Figura Transporte">
                                                                        {this.state.objetoFigurasTranporte}
                                                                    </SelectItem>
                                                                </FormItem>
                                                                <FormItem
                                                                    label="N° Licencia"
                                                                    name="numLicencia"
                                                                    margin
                                                                    pattern
                                                                    required
                                                                >
                                                                    <InputItem placeholder="N° Licencia" />
                                                                </FormItem>
                                                            </>
                                                        )
                                                    }
                                                }}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="Domicilio" tabKey={2} key={2} forceRender>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem
                                                label="Código Postal"
                                                name="coloniaId"
                                                margin
                                            >
                                                <SelectItem
                                                    placeholder="Colonia"
                                                    onSearch={this.onSearchColonia.bind(this)}
                                                    onSelect={this.onSelectColoniaId.bind(this)}
                                                >
                                                    {this.state.objetoColonias === null ? (
                                                        <Option disabled>
                                                            Eliga primero un municipio
                                                        </Option>
                                                    ) : (
                                                        this.state.objetoColonias
                                                    )}
                                                    <Option disabled>Busque por codigo postal</Option>
                                                </SelectItem>
                                            </FormItem>
                                            <FormItem
                                                label="Municipio"
                                                name="municipioId"
                                                margin
                                            >
                                                <SelectItem
                                                    placeholder="Municipio"
                                                    onChange={this.onChangeMunicipio.bind(this)}
                                                >
                                                    {this.state.objetoMunicipios === null ? (
                                                        <Option disabled>Eliga primero un estado</Option>
                                                    ) : (
                                                        this.state.objetoMunicipios
                                                    )}
                                                </SelectItem>
                                            </FormItem>
                                            <FormItem label="Estado" name="estadoId" margin>
                                                <SelectItem
                                                    placeholder="Estado"
                                                    onChange={this.onChangeEstado.bind(this)}
                                                >
                                                    {this.state.objetoEstados}
                                                </SelectItem>
                                            </FormItem>
                                            <FormItem label="Pais" name="paisId" margin>
                                                <SelectItem placeholder="Pais">
                                                    {this.state.objetoPaises}
                                                </SelectItem>
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem name="colonia" label="Colonia" margin pattern>
                                                <InputItem placeholder="Colonia" />
                                            </FormItem>
                                            <FormItem
                                                label="Calle"
                                                name="calle"
                                                max={200}
                                                margin
                                                pattern
                                            >
                                                <InputItem placeholder="Calle" />
                                            </FormItem>
                                            <FormItem label="Numero" name="numeroCalle" margin number>
                                                <InputNumberItem placeholder="Numero" numeroCalle />
                                            </FormItem>
                                            <FormItem
                                                label="Numero Interior"
                                                name="numeroInterior"
                                                margin
                                            >
                                                <InputNumberItem
                                                    placeholder="Numero Interior"
                                                    numeroCalle
                                                />
                                            </FormItem>
                                            <FormItem
                                                label="Referencia"
                                                name="referencia"
                                                margin
                                                pattern
                                            >
                                                <InputItem placeholder="Referencia" />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Form>
                    </Spin>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        headersToken: state.user.headersToken,
        empresaId: state.empresa.id,
    };
};

export default connect(mapStateToProps)(EditarEmpleado);
