import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ModalItem,
} from "../../../../Components/Items";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import reporteDeReposicionCajaChicaPdf from "../../../../Pdf/reporteDeReposicionCajaChicaPdf";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class VerReposicionCajaChica extends Component {
  state = {
    loading: false,
    pdf: null,
  };
  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible && this.props.visible)
      this.consultarTodo();
  }
  async consultarTodo() {
    this.setState({ loading: true });
    await axios
      .post(
        "cajaefectivo/datosReposicionCajaChica",
        { reposicionId: this.props.id, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        const pdf = pdfMake.createPdf(reporteDeReposicionCajaChicaPdf(res.data))
        this.setState(
          {
            loading: false,
            pdf: pdf,
          },
          () => {
            pdf.open({}, window.frames["reposicionPdf"]);
          }
        );
      });
    this.setState({ loading: false });
  }
  cerrarVerReposicionCajaChica() {
    this.props.cerrarVerReposicionCajaChica();
  }
  render() {
    return (
      <ModalItem
        title="Vista previa"
        visible={this.props.visible}
        spinning={this.state.loading}
        onCancel={this.cerrarVerReposicionCajaChica.bind(this)}
        paddingTop
      >
        <iframe
          title={"Reposicion Caja Chica"}
          style={{ width: "100%", height: "100vh" }}
          id="reposicionPdf"
          name="reposicionPdf"
        />
      </ModalItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(VerReposicionCajaChica);
