// import phoneMask from "../Components/phoneMask";
import moment from "moment";
import "moment/locale/es";
import { dineroDisabledMask } from "../Components/Items";
import numeroALetra from "../Components/numeroALetra";

moment.locale("es");

const facturaPdf = (values) => {
  const empresa = values.respuestaFactura.empresa;
  const dataSat = values.respuestaFactura.dataSat;
  const dataExtra = values.respuestaFactura.data;
  const conceptos = dataSat.Conceptos.map((valueConcepto) => {
    let iva = 0;
    let ieps = 0;
    valueConcepto.Impuestos.Traslados.map((valueImpuesto) => {
      if (valueImpuesto.Impuesto === "002") {
        iva += valueImpuesto.Importe;
      }
      if (valueImpuesto.Impuesto === "003") {
        ieps += valueImpuesto.Importe;
      }
      return null;
    });
    return [
      { style: "textoTablaChico", text: valueConcepto.Cantidad, alignment: "right" },
      {
        text: valueConcepto.ClaveUnidad,
        style: "textoTablaChico",
        alignment: "center",
      },
      {
        text: valueConcepto.ClaveProdServ,
        style: "textoTablaChico",
        alignment: "center",
      },
      {
        text: valueConcepto.Descripcion,
        style: "textoTablaChico",
        alignment: "left",
      },
      {
        text: dineroDisabledMask(iva),
        style: "textoTablaChico",
        alignment: "right",
      },
      {
        text: dineroDisabledMask(ieps),
        style: "textoTablaChico",
        alignment: "right",
      },
      {
        text: dineroDisabledMask(valueConcepto.ValorUnitario),
        style: "textoTablaChico",
        alignment: "right",
      },
      {
        text: dineroDisabledMask(valueConcepto.Importe),
        style: "textoTablaChico",
        alignment: "right",
      },
    ];
  });

  const nuevoArrayImpFL = Array.isArray(dataSat.Impuestos.Traslados) ? 
    [...dataSat.Impuestos.Traslados, ...dataSat.Complemento.Any[0]["implocal:ImpuestosLocales"]["implocal:TrasladosLocales"]]
    :
    [...dataSat.Complemento.Any[0]["implocal:ImpuestosLocales"]["implocal:TrasladosLocales"]]

  const impuestosTotales = nuevoArrayImpFL.map(
    (valueImpuesto, indexImpuesto) => {
      let nombre = "";
      if (valueImpuesto.Impuesto === "002") {
        nombre = "IVA";
      }else if (valueImpuesto.Impuesto === "003") {
        nombre = "IEPS";
      }else{
        nombre = valueImpuesto["@ImpLocTrasladado"]
      }

      if (valueImpuesto.TipoFactor === "Tasa") {
        nombre = nombre + " " + Number(valueImpuesto.TasaOCuota * 100) + "%";
      }else if (valueImpuesto.TipoFactor === "Cuota") {
        nombre = nombre + " $" + Number(valueImpuesto.TasaOCuota);
      }

      let border = indexImpuesto + 1 === nuevoArrayImpFL.length;
      return [
        {
          border: [true, false, false, border],
          text: nombre,
          style: "subheader",
        },
        {
          border: [false, false, true, border],
          text: dineroDisabledMask(valueImpuesto.Importe || valueImpuesto["@Importe"]),
          style: "textoChico",
          alignment: "right",
        },
      ];
    }
  );

  const nuevoArrayRetFL = Array.isArray(dataSat.Impuestos.Retenciones) ? 
    [...dataSat.Impuestos.Retenciones, ...dataSat.Complemento.Any[0]["implocal:ImpuestosLocales"]["implocal:RetencionesLocales"]]
    :
    [...dataSat.Complemento.Any[0]["implocal:ImpuestosLocales"]["implocal:RetencionesLocales"]]

  const retencionesTotales = nuevoArrayRetFL.map(
    (valueRetencion, indexRetencion) => {
      let nombre = "Retención ";
      if (valueRetencion.Impuesto === "001") {
        nombre = nombre + "ISR";
      }else if (valueRetencion.Impuesto === "002") {
        nombre = nombre + "IVA";
      }else{
        nombre = valueRetencion["@ImpLocRetenido"]
      }

      if (valueRetencion.TipoFactor === "Tasa") {
        nombre = nombre + " " + Number(valueRetencion.TasaOCuota * 100) + "%";
      }else if (valueRetencion.TipoFactor === "Cuota") {
        nombre = nombre + " " + Number(valueRetencion.TasaOCuota);
      }

      let border = indexRetencion + 1 === nuevoArrayRetFL.length;
      return [
        {
          border: [true, false, false, border],
          text: nombre,
          style: "subheader",
        },
        {
          border: [false, false, true, border],
          text: dineroDisabledMask(valueRetencion.Importe || valueRetencion["@Importe"]),
          style: "textoChico",
          alignment: "right",
        },
      ];
    }
  )

    const totalImpFL = dataSat.Impuestos.TotalImpuestosTrasladados + dataSat.Complemento.Any[0]["implocal:ImpuestosLocales"]["@TotaldeTraslados"] || 0
    const totalRetFL = dataSat.Impuestos.TotalImpuestosRetenidos + dataSat.Complemento.Any[0]["implocal:ImpuestosLocales"]["@TotaldeRetenciones"] || 0

  let cancelado = {}
  if(values.estado === 0){
    cancelado = {watermark: { text: 'CANCELADO', color: 'GRAY', opacity: 0.3, bold: true, italics: false, angle: -50, fontSize: 110 }}
  }
  let observaciones = {}
  if(values.imprimirObservaciones && values.observaciones){
    observaciones = {
      table: {
        body: [
          [
            {
              border: [true, true, true, false],
              text: 'Observaciones:',
              margin: [0,0,0,5],
              style: 'header',
            },
          ],
          [
            {
              border: [true, false, true, true],
              text: values.observaciones,
              margin: [0,0,0,5],
              style: 'textoChico'
            }
          ]

        ],
        layout: {
          defaultBorder: false,
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return "#adaaaa";
          },
          vLineColor: function (i, node) {
            return "#adaaaa";
          },
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 2, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 2 } };
          },
        },
      }
    }
  }

  const arraySiHayRetenciones = totalRetFL > 0 ? [[
    {
      border: [true, false, false, false],
      text: "Retenciones:",
      style: "subheader",
    },
    {
      border: [false, false, true, false],
      text: dineroDisabledMask(
        totalRetFL
      ),
      style: "textoChico",
      alignment: "right",
    },
  ],
  [
    {
      border: [true, false, false, true],
      text: "TOTAL PAGO:",
      style: "header",
      fillColor: "#e8e8e8",
    },
    {
      border: [false, false, true, true],
      text: dineroDisabledMask(dataSat.Total),
      style: "header",
      fillColor: "#e8e8e8",
      alignment: "right",
    },
  ]] : []

  const total = dataSat.SubTotal - dataSat.Descuento + totalImpFL 

  var dd = {
    ...cancelado,
    content: [
      {
        style: "tableExample",
        table: {
          widths: ["18%", "52%", "30%"],
          layout: {
            defaultBorder: false,
          },
          body: [
            [
              {
                border: [false, false, false, false],
                image: "logo",
                fit: [80, 80] 
              },
              {
                border: [false, false, false, false],
                table: {
                  headerRows: 1,
                  widths: ["100%"],

                  body: [
                    [
                      {
                        text: empresa.persona
                          ? empresa.nombre
                          : empresa.nombre +
                            " " +
                            empresa.apellidoPaterno +
                            " " +
                            empresa.apellidoMaterno,
                        style: "tableHeader",
                      },
                    ],
                    [
                      {
                        text: "R.F.C: " + empresa.rfc,
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text:
                          empresa.calle +
                          " " +
                          empresa.numeroCalle +
                          " " +
                          (empresa.numeroInterior
                            ? empresa.numeroInterior
                            : ""),
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text:
                          empresa.colonia.tipoAsentamiento +
                          " " +
                          empresa.colonia.nombre +
                          " C.P. " +
                          empresa.colonia.cp +
                          " " +
                          empresa.municipio.nombre +
                          " " +
                          empresa.estado.abreviatura +
                          " " +
                          empresa.pais.abreviatura +
                          " ",
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                    // empresa.telefono ? [
                    //     {
                    //         text: 'Tel: '+phoneMask(empresa.telefono),
                    //         style: "textoChico",
                    //         alignment: "center",
                    //       },
                    // ] : [],
                    [
                      {
                        text:
                          "Regimen Fiscal: (" +
                          empresa.regimenFiscal.c_RegimenFiscal +
                          ") " +
                          empresa.regimenFiscal.descripcion,
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: "Tipo De Comprobante: (I) Ingreso",
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
              {
                border: [false, false, false, false],
                table: {
                  headerRows: 1,
                  widths: ["100%"],

                  body: [
                    [
                      {
                        text: "N° Factura:",
                        style: "subheader",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: (dataSat.Serie ? dataSat.Serie : 'F') + ' - ' + dataSat.Folio,
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: "Moneda:",
                        style: "subheader",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: dataSat.Moneda,
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: "Tipo de Cambio:",
                        style: "subheader",
                        alignment: "center",
                      },
                    ],
                    [
                      {
                        text: dineroDisabledMask(dataSat.TipoCambio),
                        style: "textoChico",
                        alignment: "center",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          ],
        },
      },
      {
        style: "tableExample",
        table: {
          widths: ["60%", "40%"],
          layout: {
            defaultBorder: false,
          },

          body: [
            [
              {
                table: {
                  widths: ["30%", "70%"],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                    [
                      { text: "Nombre del cliente :", style: "subheader" },
                      { text: dataSat.Receptor.Nombre, style: "textoChico" },
                    ],
                    [
                      { text: "R.F.C :", style: "subheader" },
                      { text: dataSat.Receptor.Rfc, style: "textoChico" },
                    ],
                    // [
                    //   { text: "Direccion :", style: "subheader" },
                    //   { text: "Variable", style: "textoChico" },
                    // ],
                    [
                      { text: "Uso de CFDI:", style: "subheader" },
                      {
                        text:
                          "(" +
                          dataSat.usoDeCFDI.c_UsoCFDI +
                          ") " +
                          dataSat.usoDeCFDI.descripcion,
                        style: "textoChico",
                      },
                    ],
                    [
                      { text: "Forma de pago :", style: "subheader" },
                      { text:
                        "(" +
                        dataSat.formaDePago.c_FormaPago +
                        ") " +
                        dataSat.formaDePago.descripcion, style: "textoChico" },
                    ],
                    [
                      { text: "Metodo de pago :", style: "subheader" },
                      { text:
                        dataSat.MetodoPago === "PUE"
                          ? "PUE - Pago en una sola exhibición"
                          : "PPD - Pago en parcialidades o diferido",
                        style: "textoChico" },
                    ],
                  ],
                },
                layout: "noBorders",
              },

              {
                table: {
                  widths: ["40%", "60%"],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                    [
                      { text: "Fecha:", style: "subheader" },
                      {
                        text: moment(dataSat.Fecha).locale("es").format("LL"),
                        style: "textoChico",
                      },
                    ],
                    [
                      { text: "Lugar de expedicion:", style: "subheader" },
                      { text: dataSat.LugarExpedicion, style: "textoChico" },
                    ],
                    [
                      { text: "Pedido:", style: "subheader" },
                      { text: dataSat.pedido, style: "textoChico" },
                    ],
                    [
                      { text: "Codiciones de pago:", style: "subheader" },
                      {
                        text:
                          dataSat.condicion === 1
                            ? "Contado"
                            : dataSat.condicion === 2
                            ? "Credito sin enganche"
                            : "Credito con enganche",
                        style: "textoChico",
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return "#adaaaa";
          },
          vLineColor: function (i, node) {
            return "#adaaaa";
          },
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 2, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 2 } };
          },
        },
      },

      {
        style: "tableExample",
        table: {
          widths: ["8%", "6%", "9%", "38%", "8%", "8%", "10.5%", "12.5%"],
          layout: {
            defaultBorder: true,
          },
          body: [
            [
              { text: "Cantidad", style: "tituloTabla" },
              { text: "Unidad", style: "tituloTabla" },
              { text: "Clave SAT", style: "tituloTabla" },
              { text: "Descripción", style: "tituloTabla" },
              { text: "IVA", style: "tituloTabla" },
              { text: "IEPS", style: "tituloTabla" },
              { text: "P/U", style: "tituloTabla" },
              { text: "Importe", style: "tituloTabla" },
            ],
            ...conceptos,
          ],
        },
        layout: {
          fillColor: (rowIndex, node, columnIndex) => {
            return rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff";
          },
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 1;
          },
          hLineColor: function (i, node) {
            return "#adaaaa";
          },
          vLineColor: function (i, node) {
            return "#adaaaa";
          },
          hLineStyle: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return null;
            }
            return { dash: { length: 2, space: 1 } };
          },
          vLineStyle: function (i, node) {
            if (i === 0 || i === node.table.widths.length) {
              return null;
            }
            return { dash: { length: 2 } };
          },
        },
      },
      {
        style: "tablaSinMargen",
        table: {
          widths: ["20%", "50%", "30%"],
          layout: {
            defaultBorder: true,
          },
          body: [
            [
              { image: "qr", alignment: "left", width: 100, height: 100 },
              {
                table: {
                  widths: ["30%", "70%"],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                    [
                      {
                        border: [true, true, false, false],
                        text: "Cantidad con letra:",
                        style: "subheader",
                      },
                      {
                        border: [false, true, true, false],
                        text: "**( " + numeroALetra(dataSat.Total, dataSat.Moneda) + " )**",
                        style: "textoChico",
                        fontSize: 7,
                      },
                    ],
                    [
                      {
                        border: [true, false, false, false],
                        text: "Uso de CFDI:",
                        style: "subheader",
                      },
                      {
                        border: [false, false, true, false],
                        text:
                          "(" +
                          dataSat.usoDeCFDI.c_UsoCFDI +
                          ") " +
                          dataSat.usoDeCFDI.descripcion,
                        style: "textoChico",
                      },
                    ],
                    [
                      {
                        border: [true, true, false, false],
                        text: "Banco:",
                        style: "subheader",
                      },
                      {
                        border: [false, true, true, false],
                        text: empresa.banco ? empresa.banco : "",
                        style: "textoChico",
                      },
                    ],
                    [
                      {
                        border: [true, false, false, false],
                        text: "Cuenta:",
                        style: "subheader",
                      },
                      {
                        border: [false, false, true, false],
                        text: empresa.cuentaBanco ? empresa.cuentaBanco : "",
                        style: "textoChico",
                      },
                    ],
                    [
                      {
                        border: [true, false, false, true],
                        text: "Cuenta CLABE:",
                        style: "subheader",
                      },
                      {
                        border: [false, false, true, true],
                        text: empresa.cuentaClabe ? empresa.cuentaClabe : "",
                        style: "textoChico",
                      },
                    ],
                  ],
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return i === 0 || i === node.table.body.length ? 1 : 1;
                  },
                  vLineWidth: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? 1 : 1;
                  },
                  hLineColor: function (i, node) {
                    return "#adaaaa";
                  },
                  vLineColor: function (i, node) {
                    return "#adaaaa";
                  },
                  hLineStyle: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                      return null;
                    }
                    return { dash: { length: 2, space: 1 } };
                  },
                  vLineStyle: function (i, node) {
                    if (i === 0 || i === node.table.widths.length) {
                      return null;
                    }
                    return { dash: { length: 2 } };
                  },
                },
              },
              {
                table: {
                  widths: ["50%", "50%"],
                  layout: {
                    defaultBorder: false,
                  },
                  body: [
                    [
                      {
                        border: [true, true, false, false],
                        text: "Subtotal:",
                        style: "subheader",
                      },
                      {
                        border: [false, true, true, false],
                        text: dineroDisabledMask(dataSat.SubTotal),
                        style: "textoChico",
                        alignment: "right",
                      },
                    ],
                    [
                      {
                        border: [true, false, false, false],
                        text: "Descuento:",
                        style: "subheader",
                      },
                      {
                        border: [false, false, true, false],
                        text: dineroDisabledMask(dataSat.Descuento),
                        style: "textoChico",
                        alignment: "right",
                      },
                    ],
                    [
                      {
                        border: [true, false, false, false],
                        text: "Impuestos:",
                        style: "subheader",
                      },
                      {
                        border: [false, false, true, false],
                        text: dineroDisabledMask(
                          totalImpFL
                        ),
                        style: "textoChico",
                        alignment: "right",
                      },
                    ],
                    [
                      {
                        border: [true, false, false, true],
                        text: "TOTAL:",
                        style: "header",
                        fillColor: "#e8e8e8",
                      },
                      {
                        border: [false, false, true, true],
                        text: dineroDisabledMask(total),
                        style: "header",
                        fillColor: "#e8e8e8",
                        alignment: "right",
                      },
                    ],
                    ...arraySiHayRetenciones,
                    ...impuestosTotales,
                    ...retencionesTotales
                  ],
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return i === 0 || i === node.table.body.length ? 1 : 1;
                  },
                  vLineWidth: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? 1 : 1;
                  },
                  hLineColor: function (i, node) {
                    return "#adaaaa";
                  },
                  vLineColor: function (i, node) {
                    return "#adaaaa";
                  },
                  hLineStyle: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                      return null;
                    }
                    return { dash: { length: 2, space: 1 } };
                  },
                  vLineStyle: function (i, node) {
                    if (i === 0 || i === node.table.widths.length) {
                      return null;
                    }
                    return { dash: { length: 2 } };
                  },
                },
              },
            ],
          ],
        },
        layout: "noBorders",
      },
      observaciones,
      {
        table: {
          widths: ["15%", "30%", "23%", "32%"],
          layout: {
            defaultBorder: false,
          },
          body: [
            [
              {
                colSpan: 4,
                text: '"Este documento es una representacion impresa de un CFDI"',
                style: "header",
                alignment: "center",
              },
              {},
              {},
              {},
            ],
            [
              { text: "Folio Fiscal: ", style: "subheader" },
              { text: dataExtra.uuid, style: "textoChicoData" },
              { text: "Fecha y Hora de Certificación: ", style: "subheader" },
              {
                text: dataSat.Fecha,
                style: "textoChicoData",
              },
            ],
            [
              { colSpan: 4, text: "Sello digital CFDI: ", style: "subheader" },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 4,
                text: dataExtra.selloCFDI,
                style: "textoChicoData",
              },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 2,
                text: "Numero de serie del Certificado de Sello digital: ",
                style: "subheader",
              },
              {},
              {
                colSpan: 2,
                text: "Numero de serie del Certificado de Sello digital del SAT: ",
                style: "subheader",
              },
              {},
            ],
            [
              {
                colSpan: 2,
                text: dataExtra.noCertificadoCFDI,
                style: "textoChicoData",
              },
              {},
              {
                colSpan: 2,
                text: dataExtra.noCertificadoSAT,
                style: "textoChicoData",
              },
              {},
            ],
            [
              {
                colSpan: 4,
                text: "Cadena Original del complemento de certificación Digital SAT: ",
                style: "subheader",
              },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 4,
                text: dataExtra.cadenaOriginalSAT,
                style: "textoChicoData",
              },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 4,
                text: "Sello Digital del SAT: ",
                style: "subheader",
              },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 4,
                text: dataExtra.selloSAT,
                style: "textoChicoData",
              },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 4,
                text: "Sello Digital del CFDI: ",
                style: "subheader",
              },
              {},
              {},
              {},
            ],
            [
              {
                colSpan: 4,
                text: dataExtra.selloCFDI,
                style: "textoChicoData",
              },
              {},
              {},
              {},
            ],
          ],
        },
        layout: "noBorders",
      },
    ],
    images: {
      qr: "data:image/jpeg;base64," + dataExtra.qrCode,
      logo: empresa.logo.base64Completa,
    },
    styles: {
      header: {
        fontSize: 9,
        bold: true,
      },
      subheader: {
        fontSize: 8,
        bold: true,
      },
      tableExample: {
        margin: [0, 5, 0, 5],
      },
      tablaSinMargen: {
        margin: [0, 0, 0, 0],
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: "black",
        alignment: "center",
      },
      textoChico: {
        fontSize: 8,
      },
      textoChicoData: {
        fontSize: 6,
      },
      tituloTabla: {
        fontSize: 8,
        bold: true,
        fillColor: "#cccccc",
        style: "subheader",
        alignment: "center",
        border: true,
      },
      textoTabla: {
        fontSize: 8,
        italics: false,
      },
      textoTablaChico: {
        fontSize: 6,
        italics: false,
      },
    },
    pageSize: "LETTER",
  };
  return dd;
};

export default facturaPdf;
