import React, { Component } from "react";
import {
  Switch,
  Button,
  message,
  Spin,
} from "antd";
import { Form, FormItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";

class ComplementoDetallistaConfiguracion extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
  }

  componentDidMount() {
    this.consultarConfiguracion()
  }
  onFinish(values) {
    axios
      .post(
        "empresas/editarConfiguracionComplementoDetallista",
        { ...values, empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha modificado la configuracion de la empresa");
          this.consultarConfiguracion();
        } else if (res.data.success === 1) {
          message.error("No se pudo modificar la configuracion");
        } else {
          message.error("Hubo un error y no se modifico la configuracion");
        }
      });
  }
  async consultarConfiguracion() {
    this.setState({ loading: true })
    await axios
      .post(
        "empresas/selectConfiguracionComplementoDetallista",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro configuracion de esta empresa");
        } else {
          this.formRef.current.setFieldsValue({
            complementoDetallista:
              res.data.complementoDetallista,
          });
        }
      });
    this.setState({ loading: false })
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
          e.errorFields[index].errors[0] +
          " en la fila " +
          (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Form
          name="complementoDetallistaConfiguracion"
          layout
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
        >
          <FormItem
            label="Activar Complemento Detallista"
            name="complementoDetallista"
            margin
            initialValue={false}
            valuePropName="checked"
            tooltip='Al activar esta casilla se activa el complemento detallista para las ventas. La informacion se proporciona en el cliente.'
          >
            <Switch />
          </FormItem>
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "0 0 0 auto" }}
          >
            Guardar Configuracion
          </Button>
        </Form>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(ComplementoDetallistaConfiguracion);
