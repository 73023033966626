import React, {Component} from 'react'
import locale from "antd/es/date-picker/locale/es_ES";
import { DatePicker } from 'antd';

class DatePickerItem extends Component{
    pickerRef = React.createRef();
    focus(value){
      return this.pickerRef.current.focus(value)
    }
    render(){
        const style = {
            color: this.props.disabled ? 'black' : null,
            backgroundColor: this.props.disabled ? '#F5F5F5' : null,
            borderBottom: this.props.sinBorde ? null : '1px solid #DADADA',
            borderRadius: 0,
            width: this.props.width ? this.props.width : undefined,
            ...this.props.style
          }
        const dateFormat = this.props.dateFormat ? this.props.dateFormat : 'DD/MM/YYYY'
        return(
            <DatePicker ref={this.pickerRef} locale={locale} style={style} format={dateFormat} bordered={false} {...this.props} />
        )
    }
}

export default DatePickerItem