import { PageHeader, Button } from "antd";
import axios from "axios";
import React, { Component } from "react";
import AgregarTipoDePoliza from "./AgregarTipoDePoliza/AgregarTipoDePoliza";
import {TableItem} from '../../../Components/Items'
import { connect } from "react-redux";

class TiposDePolizas extends Component {
  state = {
    modalAgregarTipoDePoliza: false,
    todasTiposDePolizas: [],
    loading: false
  };
  componentDidMount() {
    this.consultarTiposDePolizas();
  }

  consultarTiposDePolizas() {
    this.setState({loading: true})
    axios
    .post(
      "tiposdepolizas/selectTodasTiposDePolizas",
      { empresaId: this.props.empresaId },
      {
        headers: this.props.headersToken,
      }
    )
    .then((res) => {
      this.setState({loading: false})
      if (res.data.status === 404) {
      } else {
        this.setState({
          todasInsumos: res.data.map(value => {
            return {...value, accion: { _id: value._id, estado: value.estado }}
          }),
        });
      }
    });
  }
  abrirAgregarTipoDePoliza() {
    this.setState({ modalAgregarTipoDePoliza: true });
  }
  cerrarAgregarTipoDePoliza() {
    this.setState({ modalAgregarTipoDePoliza: false });
    this.consultarTiposDePolizas();
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        key: "nombre",
        sorter: (a, b) => {
          if (a.nombre < b.nombre) {
            return -1;
          }
          if (a.nombre > b.nombre) {
            return 1;
          }
          return 0;
        },
        buscar: "nombre",
      },
      {
        title: "Descripcion",
        dataIndex: "descripcion",
        key: "descripcion",
        sorter: (a, b) => {
          if (a.descripcion < b.descripcion) {
            return -1;
          }
          if (a.descripcion > b.descripcion) {
            return 1;
          }
          return 0;
        },
        buscar: "descripcion",
      },
      {
        title: "Poliza",
        dataIndex: "tipoDePolizaId",
        key: "tipoDePolizaId",
        sorter: (a, b) => {
          if (a.tipoDePolizaId < b.tipoDePolizaId) {
            return -1;
          }
          if (a.tipoDePolizaId > b.tipoDePolizaId) {
            return 1;
          }
          return 0;
        },
        render: (value) => {
          if(value === 1){
            return 'Ingreso'
          }
          if(value === 2){
            return 'Egreso'
          }
          if(value === 3){
            return 'Traslado'
          }
          if(value === 4){
            return 'Nomina'
          }
          if(value === 5){
            return 'Pago'
          }
          if(value === 6){
            return 'Diario'
          }
        }
      },
      // {
      //   title: "Contador Por Mes",
      //   dataIndex: "contadorPorMes",
      //   key: "contadorPorMes",
      //   render: (value) => <Checkbox checked={value} />,
      //   align: 'center'
      // },
      // {
      //   title: "Usar Numero de Cheque",
      //   dataIndex: "usarNumeroCheque",
      //   key: "usarNumeroCheque",
      //   render: (value) => <Checkbox checked={value} />,
      //   align: 'center'
      // },
      // {
      //   title: "Es automatica",
      //   dataIndex: "automatica",
      //   key: "automatica",
      //   render: (value) => <Checkbox checked={value} />,
      //   align: 'center'
      // },
    ]
    return (
      <>
        <PageHeader
          title="Tipos De Polizas"
          style={{
            border: "1px solid rgb(235, 237, 240)",
            backgroundColor: "white",
            marginBottom: 10,
          }}
          extra={this.props.permisoAgregarTipoDePoliza &&
            <Button
              type="primary"
              key="agregarTipoDePoliza"
              onClick={this.abrirAgregarTipoDePoliza.bind(this)}
            >
              Agregar Tipo De Poliza
            </Button>
          }
        />
        <TableItem loading={this.state.loading} size='small' dataSource={this.state.todasInsumos} columns={columns} />
        <AgregarTipoDePoliza
          visible={this.state.modalAgregarTipoDePoliza}
          cerrarAgregarTipoDePoliza={this.cerrarAgregarTipoDePoliza.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
    permisoAgregarTipoDePoliza: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cdc56f30e81b54183cfa0")?.activado,
    permisoEditarTipoDePoliza: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615cdc82f30e81b54183cfa1")?.activado,
    permisoDeshabilitarTipoDePoliza: state.user.tipo === 0 || state.user.permisos.find(valueTodos => valueTodos.permisoId.toString() === "615ce771f30e81b54183cfb6")?.activado,
  };
};

export default connect(mapStateToProps)(TiposDePolizas);