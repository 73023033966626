import React, { Component } from "react";
import { Input, Button, message, Row, Col, Upload, Tag, Space, notification, Spin } from "antd";
import { Form, FormItem } from "../../../../Components/Items";
import axios from "axios";
import { connect } from "react-redux";
import {
  UploadOutlined,
  LockOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

class FiscalConfiguracion extends Component {
  formRef = React.createRef();
  state = {
    archivosCertificado: [],
    archivoCertificado: [],
    archivosLlavePrivada: [],
    archivoLlavePrivada: [],
    llavePrivada: false,
    certificado: false,
    passwordSAT: false,
    loading: false
  };
  componentDidMount() {
    this.consultarConfiguracion();
  }
  onFinish(values) {
    let fd = new FormData();
    fd.append("empresaId", this.props.empresaId);
    for (var key in values) {
      if (values[key] === undefined || values[key] === "") {
      } else {
        fd.append(key, values[key]);
      }
    }
    if (this.state.archivoLlavePrivada) {
      fd.append("llavePrivada", this.state.archivoLlavePrivada);
    }
    if (this.state.archivoCertificado) {
      fd.append("certificado", this.state.archivoCertificado);
    }
    axios
      .post("empresas/editarConfiguracionFiscal", fd, {
        headers: this.props.headersToken,
      })
      .then((res) => {
        if (res.data.success === 2) {
          message.success("Se ha modificado la configuracion de la empresa");
          message.success("Sus certificados fueron validados")
          this.consultarConfiguracion();
        } else if (res.data.success === 1) {
          message.error("Los certificados se subieron pero no fueron validados");
          notification.error({
            message: 'Error',
            description: res.data.message,
            duration: null
          })
        } else {
          message.error("Hubo un error y no se modifico la configuracion");
        }
      });
  }
  async consultarConfiguracion() {
    this.setState({loading: true})
    await axios
      .post(
        "empresas/selectConfiguracionFiscal",
        { empresaId: this.props.empresaId },
        { headers: this.props.headersToken }
      )
      .then((res) => {
        if (res.data.status === 404) {
          message.error("Error no se encontro configuracion de esta empresa");
        } else {
          this.setState({
            llavePrivada: !!res.data.llavePrivada,
            certificado: !!res.data.certificado,
            passwordSAT: !!res.data.passwordSAT,
          });
        }
      });
      this.setState({loading: false})
  }
  onFinishFailed(e) {
    e.errorFields.map((value, index) => {
      if (e.errorFields[index].name[1]) {
        message.error(
          "Error: " +
            e.errorFields[index].errors[0] +
            " en la fila " +
            (e.errorFields[index].name[1] + 1)
        );
      } else {
        message.error("Error: Falta un campo");
      }
      return null;
    });
  }
  async onChangeArchivoCertificado(e) {
    if (e.file.type === "application/x-x509-ca-cert") {
      this.setState({ archivosCertificado: [e.file], archivoCertificado: e.file });
    } else {
      message.error("El archivo debe ser de tipo .cer");
    }
  }
  async onChangeArchivoLlavePrivada(e) {
    if (e.file.name.split('.').pop() === "key") {
      this.setState({ archivosLlavePrivada: [e.file], archivoLlavePrivada: e.file });
    } else {
      message.error("El archivo debe ser de tipo .key");
    }
  }
  render() {
    return (
      <>
      <Spin spinning={this.state.loading}>
        <Form
          name="fiscalConfiguracion"
          layout
          onFinish={this.onFinish.bind(this)}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
        >
          <Row>
            <Col span={12}>
              <FormItem
                name="passwordSAT"
                margin
                label="Contraseña SAT"
                pattern
                required
              >
                <Space>
                  <Input.Password
                    placeholder="Se remplazara al guardar"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                  />
                  {this.state.passwordSAT ? (
                    <Tag color="success" icon={<CheckOutlined />}>
                      Subido
                    </Tag>
                  ) : (
                    <Tag color="error" icon={<ExclamationCircleOutlined />}>
                      Faltante
                    </Tag>
                  )}
                </Space>
              </FormItem>
              <FormItem required margin name="certificado" label="Certificado (.cer)">
                <Upload
                  accept=".cer"
                  showUploadList={{
                    showRemoveIcon: false,
                    showPreviewIcon: false,
                  }}
                  fileList={this.state.archivosCertificado}
                  customRequest={this.onChangeArchivoCertificado.bind(this)}
                >
                  <Space>
                    <Button icon={<UploadOutlined />}>Subir</Button>
                    {this.state.certificado ? (
                      <Tag color="success" icon={<CheckOutlined />}>
                        Subido
                      </Tag>
                    ) : (
                      <Tag color="error" icon={<ExclamationCircleOutlined />}>
                        Faltante
                      </Tag>
                    )}
                  </Space>
                </Upload>
              </FormItem>
              <FormItem required margin name="llavePrivada" label="Clave privada (.key)">
                <Upload
                  accept=".key"
                  showUploadList={{
                    showRemoveIcon: false,
                    showPreviewIcon: false,
                  }}
                  fileList={this.state.archivosLlavePrivada}
                  customRequest={this.onChangeArchivoLlavePrivada.bind(this)}
                >
                  <Space>
                    <Button icon={<UploadOutlined />}>Subir</Button>
                    {this.state.llavePrivada ? (
                      <Tag color="success" icon={<CheckOutlined />}>
                        Subido
                      </Tag>
                    ) : (
                      <Tag color="error" icon={<ExclamationCircleOutlined />}>
                        Faltante
                      </Tag>
                    )}
                  </Space>
                </Upload>
              </FormItem>
            </Col>
            <Col span={12}>
              <h1>Estos documentos seran procesados por nosotros para ofrecer el sistema de timbrado, comuniquese con nosotros si no se ha completado</h1>
            </Col>
          </Row>
          <Button
            type="primary"
            htmlType="submit"
            style={{ display: "block", margin: "0 0 0 auto" }}
          >
            Guardar Configuracion
          </Button>
        </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headersToken: state.user.headersToken,
    empresaId: state.empresa.id,
  };
};

export default connect(mapStateToProps)(FiscalConfiguracion);
